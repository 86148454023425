import React from 'react';
import { Popup } from 'semantic-ui-react';

import '../css/svg_filters2.css';
import '../css/progressBar.css';

import stripedEndBar from '../resources/striped_end_bar_tall.svg';
import hollowEndBar from '../resources/hollow_end_bar_tall.svg';
import filledEndBar from '../resources/filled_end_bar_tall.svg';
import stripedBar from '../resources/striped_bar_tall.svg';
import hollowBar from '../resources/hollow_bar_tall.svg';
import filledBar from '../resources/filled_bar_tall.svg';

import filledBarPreWash from '../resources/filled_bar_prewash_tall.svg';
import filledBarPreWash10 from '../resources/filled_bar_prewash10_tall.svg';
import filledBarShotStrip from '../resources/filled_bar_shotStrip_tall.svg';
import filledBarLiquidStrip from '../resources/filled_bar_liquidStrip_tall.svg';
import filledBarWPC from '../resources/filled_bar_WPC_tall.svg';
import filledBarSMAP from '../resources/filled_bar_SMAP_tall.svg';
import filledBarAEROhand from '../resources/filled_bar_AERO_hand_tall.svg';
import filledBarM1 from '../resources/filled_bar_M1_tall.svg';
import filledBarM2 from '../resources/filled_bar_M2_tall.svg';
import filledBarM3 from '../resources/filled_bar_M3_tall.svg';
import filledBarPostlapSMAP from '../resources/filled_bar_postlapSMAP_tall.svg';
import filledBarPostlapAEROhand from '../resources/filled_bar_postlapAERO_hand_tall.svg';
import filledBarPackShip from '../resources/filled_bar_packShip_tall.svg';

import hollowBarPrewash from '../resources/hollow_bar_prewash_tall.svg';
import hollowBarPrewash10 from '../resources/hollow_bar_prewash10_tall.svg';
import hollowBarShotStrip from '../resources/hollow_bar_shotStrip_tall.svg';
import hollowBarLiquidStrip from '../resources/hollow_bar_liquidStrip_tall.svg';
import hollowBarWPC from '../resources/hollow_bar_WPC_tall.svg';
import hollowBarSMAP from '../resources/hollow_bar_SMAP_tall.svg';
import hollowBarAEROhand from '../resources/hollow_bar_AERO_hand_tall.svg';
import hollowBarM1 from '../resources/hollow_bar_M1_tall.svg';
import hollowBarM2 from '../resources/hollow_bar_M2_tall.svg';
import hollowBarM3 from '../resources/hollow_bar_M3_tall.svg';
import hollowBarPostlapSMAP from '../resources/hollow_bar_postlapSMAP_tall.svg';
import hollowBarPostlapAEROhand from '../resources/hollow_bar_postlapAERO_hand_tall.svg';
import hollowBarPackShip from '../resources/hollow_bar_packShip_tall.svg';

import stripedBarPrewash from '../resources/striped_bar_prewash_tall.svg';
import stripedBarPrewash10 from '../resources/striped_bar_prewash10_tall.svg';
import stripedBarShotStrip from '../resources/striped_bar_shotStrip_tall.svg';
import stripedBarLiquidStrip from '../resources/striped_bar_liquidStrip_tall.svg';
import stripedBarWPC from '../resources/striped_bar_WPC_tall.svg';
import stripedBarSMAP from '../resources/striped_bar_SMAP_tall.svg';
import stripedBarAEROhand from '../resources/striped_bar_AERO_hand_tall.svg';
import stripedBarM1 from '../resources/striped_bar_M1_tall.svg';
import stripedBarM2 from '../resources/striped_bar_M2_tall.svg';
import stripedBarM3 from '../resources/striped_bar_M3_tall.svg';
import stripedBarPostlapSMAP from '../resources/striped_bar_postlapSMAP_tall.svg';
import stripedBarPostlapAEROhand from '../resources/striped_bar_postlapAERO_hand_tall.svg';
import stripedBarPackShip from '../resources/striped_bar_packShip_tall.svg';

class ProgressBar extends React.Component {

    render() {

        if (this.props.data === null) {
            return "";
        }
        //console.log(this.props.data);
        const bars = []
        let dataLength = Object.keys(this.props.data).length;

        //first bar
        if (this.props.data[0].filter !== "filter-preinspection") {
            if (this.props.data[0].state === "striped") {
                bars.push(this.getFlippedBar("the left end of a striped progress bar", 0, stripedEndBar));
            } else if (this.props.data[0].state === "hollow") {
                bars.push(this.getFlippedBar("the left end of a hollow progress bar", 0, hollowEndBar));
            } else {
                bars.push(this.getFlippedBar("the left end of a solid progress bar", 0, filledEndBar));
            }
        }

        //skip the first and last bar, since they have to be an end bar
        for (let i = 1; i < dataLength - 1; i++) {
            let wpcflag = 0;
            switch (true) {
                case (this.props.data[i].filter === "filter-ceramic" && this.props.data[i - 1].filter !== "filter-wpcskh"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarWPC));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarWPC));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarWPC));
                    }
                    break;
                case (this.props.data[i].filter === "filter-wpcskh" && this.props.data[i + 1].filter !== "filter-ceramic"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarWPC));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarWPC));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarWPC));
                    }
                    break;
                case (this.props.data[i].filter === "filter-ceramic"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarWPC));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarWPC));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarWPC));
                    }
                    break;
                case (this.props.data[i].filter === "filter-prelapHand" && this.props.data[i - 1].filter !== "filter-prelapAERO"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarAEROhand));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarAEROhand));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarAEROhand));
                    }
                    break;
                case (this.props.data[i].filter === "filter-prelapAERO" && this.props.data[i + 1].filter !== "filter-prelapHand"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarAEROhand));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarAEROhand));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarAEROhand));
                    }
                    break;
                case (this.props.data[i].filter === "filter-prelapHand"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarAEROhand));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarAEROhand));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarAEROhand));
                    }
                    break;

                case (this.props.data[i].filter === "filter-postlappingHand" && this.props.data[i - 1].filter !== "filter-postlappingAERO"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarPostlapAEROhand));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarPostlapAEROhand));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarPostlapAEROhand));
                    }
                    break;
                case (this.props.data[i].filter === "filter-prewashing"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarPrewash));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarPrewash));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarPreWash));
                    }
                    break;
                case (this.props.data[i].filter === "filter-washing"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarPrewash10));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarPrewash10));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarPreWash10));
                    }
                    break;
                case (this.props.data[i].filter === "filter-strippingShot"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarShotStrip));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarShotStrip));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarShotStrip));
                    }
                    break;
                case (this.props.data[i].filter === "filter-strippingLiquid"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarLiquidStrip));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarLiquidStrip));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarLiquidStrip));
                    }
                    break;
                case (this.props.data[i].filter === "filter-prelapSMAP"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarSMAP));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarSMAP));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarSMAP));
                    }
                    break;
                case (this.props.data[i].filter === "filter-coatingM1"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarM1));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarM1));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarM1));
                    }
                    break;
                case (this.props.data[i].filter === "filter-coatingM2"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarM2));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarM2));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarM2));
                    }
                    break;
                case (this.props.data[i].filter === "filter-coatingM3"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarM3));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarM3));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarM3));
                    }
                    break;
                case (this.props.data[i].filter === "filter-postlappingSMAP"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarPostlapSMAP));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarPostlapSMAP));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarPostlapSMAP));
                    }
                    break;
                case (this.props.data[i].filter === "filter-postlappingHand" && this.props.data[i - 1].filter !== "filter-postlappingAERO"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarPostlapAEROhand));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarPostlapAEROhand));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarPostlapAEROhand));
                    }
                    break;
                case (this.props.data[i].filter === "filter-postlappingAERO" && this.props.data[i + 1].filter !== "filter-postlappingHand"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarPostlapAEROhand));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarPostlapAEROhand));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarPostlapAEROhand));
                    }
                    break;
                case (this.props.data[i].filter === "filter-postlappingHand"):
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBarPostlapAEROhand));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBarPostlapAEROhand));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBarPostlapAEROhand));
                    }
                    break;

            }
            if (this.props.data[i].filter !== "filter-postInspection" && this.props.data[i].filter !== "filter-finalInspect") {
                if (this.props.data[i].filter === "filter-wpcskh" && this.props.data[i + 1].filter === "filter-ceramic" ||
                    this.props.data[i].filter === "filter-ceramic" && this.props.data[i - 1].filter !== "filter-wpcskh" ||
                    this.props.data[i].filter === "filter-prelapAERO" && this.props.data[i + 1].filter === "filter-prelapHand" ||
                    this.props.data[i].filter === "filter-prelapHand" && this.props.data[i - 1].filter !== "filter-prelapAERO" ||
                    this.props.data[i].filter === "filter-postlappingAERO" && this.props.data[i + 1].filter === "filter-postlappingHand" ||
                    this.props.data[i].filter === "filter-postlappingHand" && this.props.data[i - 1].filter !== "filter-postlappingAERO" ||
                    this.props.data[i].filter === "filter-prewashing" ||
                    this.props.data[i].filter === "filter-washing" ||
                    this.props.data[i].filter === "filter-strippingShot" ||
                    this.props.data[i].filter === "filter-strippingLiquid" ||
                    this.props.data[i].filter === "filter-ceramic" ||
                    this.props.data[i].filter === "filter-prelapSMAP" ||
                    this.props.data[i].filter === "filter-prelapHand" ||
                    this.props.data[i].filter === "filter-coatingM1" ||
                    this.props.data[i].filter === "filter-coatingM2" ||
                    this.props.data[i].filter === "filter-coatingM3" ||
                    this.props.data[i].filter === "filter-prelapAERO" ||
                    this.props.data[i].filter === "filter-postlappingSMAP" ||
                    this.props.data[i].filter === "filter-postlappingAERO" ||
                    this.props.data[i].filter === "filter-postlappingHand") {
                }
                else
                {
                    console.log("test");
                    if (this.props.data[i].state === "striped") {
                        bars.push(this.getBar("the middle of a striped progress bar", i, stripedBar));
                    } else if (this.props.data[i].state === "hollow") {
                        bars.push(this.getBar("the middle of a hollow progress bar", i, hollowBar));
                    } else {
                        bars.push(this.getBar("the middle of a solid progress bar", i, filledBar));
                    }
                }
            }
        }

        //last bar
        if (this.props.data[dataLength - 1].state === "striped") {
            bars.push(this.getBar("the right end of a striped progress bar", dataLength - 1, stripedBarPackShip));
        } else if (this.props.data[dataLength - 1].state === "hollow") {
            bars.push(this.getBar("the right end of a hollow progress bar", dataLength - 1, hollowBarPackShip));
        } else {
            bars.push(this.getBar("the right end of a solid progress bar", dataLength - 1, filledBarPackShip));
        }

        return (<div className="progressBarDiv">
            {bars}
        </div>);
    }

    getBar(alt, index, barType) {
        return (<Popup
            key={index}
            trigger={<img
                alt={alt}
                height={this.props.height + "%"}
                width={this.props.width * 1.25 + "%"}
                src={barType}
                className={this.props.data[index].filter + " bar-segment"}
            />}
            content={this.props.data[index].tooltipText}
            basic
        />);
    }

    getFlippedBar(alt, index, barType) {
        return (<Popup
            key={index}
            trigger={<img
                alt={alt}
                height={this.props.height + "%"}
                width={this.props.width * 1.1 + "%"}
                src={barType}
                className={this.props.data[index].filter + " flipped bar-segment"}
            />}
            content={this.props.data[index].tooltipText}
            basic
        />);
    }

}

export default ProgressBar;
