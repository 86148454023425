import {createUseStyles} from 'react-jss';
 
const managerViewStyle = createUseStyles({
    control: {
        paddingRight: '2%',
        verticalAlign: 'middle',
    },    
    slider: {
        verticalAlign: 'middle',
        width: '20%'
    },
    controlDiv: {
        verticalAlign: 'middle'
    },
    container: {
        height: 'auto',
        margin: '0px',
        padding: '10px',
        backgroundColor: 'white'
    },
    statusContainer: {
        width: '60%',
    },
    productContainer: {
        marginBottom: '20px'
    },
    progressBar: {
        width: '200%',
        height: '100%'
    },
    reworkProgressBar: {
        width: '200%',
        height: '100%',
        marginLeft: '6%'
    },
    progressBarTitle: {
        paddingBottom: '1%'
    },
    modalOpenLink: {
        cursor: 'pointer',
        color: 'CornflowerBlue',
        textDecoration: 'underline',
        fontSize: '16px'
    },
    printable: {
        display: 'none'
    },
    printHide: {},
    "@media print": { //hide/show things when printing
        printHide: {
            display: 'none'
        },
        printable: {
            display: 'block'
        }
    },
    title: {
        display: 'inline-block',
        marginRight: '1.2%',
        marginBottom: '.6%',
        marginTop: '.7%'
    },
    count: {
        display: 'inline-block',
        width: '',
        marginRight: '1%'
    },
    barCountContainer: {
        width: '100%',
    },
    stuck: {
        overflow: 'visible',
        position: 'fixed',
        backgroundColor: 'white',
        top: '0',
        width: '100%',
        zIndex: '99'
    }
});

export default managerViewStyle;