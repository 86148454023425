import React from 'react';
import Navbar from './navbar';
import { Redirect } from 'react-router-dom'


import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import productViewStyle from '../jss/productView';
import containersStyle from '../jss/containers';
import { Table} from 'semantic-ui-react';



import CoatingSchedule from '../resources/ScheduleColored.png';

const withStyles = hocify(() => {
    const productView = productViewStyle();
    const containers = containersStyle();

    return { productView, containers };
});


class ScheduleView extends React.Component {


    constructor(properties) {
        super(properties);

        //set form values
        this.state = {
            productID: '',
            requestAll: true,
            QueryData: null,
            Processes: null,
            typeChoice: 1,
            flag: " ASC"
        };

        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");

        this.handleChange = this.handleChange.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.abortController = new AbortController();
    }


    //get the report data after the component is ready
    componentDidMount() {
        this.processEvtSource.onmessage = e => this.receiveProcessData(e);

        this.getProducts("ShipmentSchedule");
    }

    receiveProcessData(e) {
        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    //if the component gets unmounted cancle the fetch request
    componentWillUnmount() {
    }

    handleClick(event, { name }) {

    }

    handleChange(event) {
    }


    renderFilterControls() {

    }

    //get the product data from the db
    async getProducts(sortby) {

        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            flagAD: this.state.flag,
            orderby: sortby,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID,
            //orderby: "ProductID",
            //flagAD: " ASC"
        });
        if(this.state.flag === " ASC")
        {
            this.state.flag = " DESC";
        }
        else{
            this.state.flag = " ASC";
        }

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/read.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryData: responseJSON
                });

            } else {
                alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    renderProducts() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const products = [];
        //add each product from the db to the table
        for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
            let value = this.state.QueryData[index];
            let daysUntil = "N/A";
            let today = new Date();

            switch (value["Coating"]) {
                case "TH":
                    daysUntil = "Today";
                    products.push(<Table.Row key={index}>
                        <Table.Cell>{value["ProductID"]}</Table.Cell>
                        <Table.Cell>{value["Customer"]}</Table.Cell>
                        <Table.Cell>{value["ProductName"]}</Table.Cell>
                        <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                        <Table.Cell style={{ background: 'rgba(255, 180, 0, 0.7)' }}>{value["Coating"]}</Table.Cell>
                        <Table.Cell>{daysUntil}</Table.Cell>
                    </Table.Row>);
                    break;
                case "TiAlN":
                    if (today.getDay() === 1 || today.getDay() === 4) {
                        daysUntil = "Today";
                    }
                    else if (today.getDay() === 2) {
                        daysUntil = "2 days from now";
                    }
                    else if (today.getDay() === 3) {
                        daysUntil = "Tomorrow";
                    }
                    else if (today.getDay() === 5) {
                        daysUntil = "Monday of next week";
                    }
                    products.push(<Table.Row key={index}>
                        <Table.Cell>{value["ProductID"]}</Table.Cell>
                        <Table.Cell>{value["Customer"]}</Table.Cell>
                        <Table.Cell>{value["ProductName"]}</Table.Cell>
                        <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                        <Table.Cell style={{ background: 'rgba(202, 0, 255, 0.5)' }}>{value["Coating"]}</Table.Cell>
                        <Table.Cell>{daysUntil}</Table.Cell>
                    </Table.Row>);
                    break;
                case "D-TiAlN":
                    if (today.getDay() === 2) {
                        daysUntil = "Today";
                    }
                    else if (today.getDay() === 1) {
                        daysUntil = "Tomorrow";
                    }
                    else {
                        daysUntil = "Tuesday of next week";
                    }
                    products.push(<Table.Row key={index}>
                        <Table.Cell>{value["ProductID"]}</Table.Cell>
                        <Table.Cell>{value["Customer"]}</Table.Cell>
                        <Table.Cell>{value["ProductName"]}</Table.Cell>
                        <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                        <Table.Cell style={{ background: 'rgba(202, 0, 255, 0.5)' }}>{value["Coating"]}</Table.Cell>
                        <Table.Cell>{daysUntil}</Table.Cell>
                    </Table.Row>);
                    break;
                case "ZERO-I":
                    if (today.getDay() === 2 || today.getDay() === 5) {
                        daysUntil = "Today";
                    }
                    else if (today.getDay() === 1 || today.getDay() === 4) {
                        daysUntil = "Tomorrow";
                    }
                    else {
                        daysUntil = "2 days from now";
                    }
                    products.push(<Table.Row key={index}>
                        <Table.Cell>{value["ProductID"]}</Table.Cell>
                        <Table.Cell>{value["Customer"]}</Table.Cell>
                        <Table.Cell>{value["ProductName"]}</Table.Cell>
                        <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                        <Table.Cell style={{ background: 'rgba(0, 140, 255, 0.5)' }}>{value["Coating"]}</Table.Cell>
                        <Table.Cell>{daysUntil}</Table.Cell>
                    </Table.Row>);
                    break;
                case "TiN":
                    if (today.getDay() === 3) {
                        daysUntil = "Today";
                    }
                    else if (today.getDay() === 1) {
                        daysUntil = "2 days from now";
                    }
                    else if (today.getDay() === 2) {
                        daysUntil = "Tomorrow";
                    }
                    else {
                        daysUntil = "Wednesday of next week";
                    }
                    products.push(<Table.Row key={index}>
                        <Table.Cell>{value["ProductID"]}</Table.Cell>
                        <Table.Cell>{value["Customer"]}</Table.Cell>
                        <Table.Cell>{value["ProductName"]}</Table.Cell>
                        <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                        <Table.Cell style={{ background: 'rgba(255, 255, 0, 0.5)' }}>{value["Coating"]}</Table.Cell>
                        <Table.Cell>{daysUntil}</Table.Cell>
                    </Table.Row>);
                    break;
                case "S-PVD":
                    if (today.getDay() === 3) {
                        daysUntil = "Today";
                    }
                    else if (today.getDay() === 1) {
                        daysUntil = "2 days from now";
                    }
                    else if (today.getDay() === 2) {
                        daysUntil = "Tomorrow";
                    }
                    else {
                        daysUntil = "Wednesday of next week";
                    }
                    products.push(<Table.Row key={index}>
                        <Table.Cell>{value["ProductID"]}</Table.Cell>
                        <Table.Cell>{value["Customer"]}</Table.Cell>
                        <Table.Cell>{value["ProductName"]}</Table.Cell>
                        <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                        <Table.Cell style={{ background: 'rgba(255, 255, 0, 0.5)' }}>{value["Coating"]}</Table.Cell>
                        <Table.Cell>{daysUntil}</Table.Cell>
                    </Table.Row>);
                    break;
                default:
                    {
                        products.push(<Table.Row key={index}>
                            <Table.Cell>{value["ProductID"]}</Table.Cell>
                            <Table.Cell>{value["Customer"]}</Table.Cell>
                            <Table.Cell>{value["ProductName"]}</Table.Cell>
                            <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                            <Table.Cell>{value["Coating"]}</Table.Cell>
                            <Table.Cell>{daysUntil}</Table.Cell>
                        </Table.Row>);
                    }
            }

            //Only show cutting tools




        }


        return (products);

    }

    render() {

        return (<div>
            <Navbar selectedPage="scheduleView" />
            <img src={CoatingSchedule} alt="coating schedule chart" style={{ verticalAlign: "middle", margin: "auto", display: "block", marginTop: "1em" }}></img>
            <Table compact celled unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{cursor: "pointer"}} onClick={() =>{this.getProducts("ProductID")}}>Product ID</Table.HeaderCell>
                        <Table.HeaderCell style={{cursor: "pointer"}} onClick={() =>{this.getProducts("Customer")}}>Customer</Table.HeaderCell>
                        <Table.HeaderCell style={{cursor: "pointer"}} onClick={() =>{this.getProducts("ProductName")}}>Product Name</Table.HeaderCell>
                        <Table.HeaderCell style={{cursor: "pointer"}} onClick={() =>{this.getProducts("ShipmentSchedule")}}>Ship Date</Table.HeaderCell>
                        <Table.HeaderCell style={{cursor: "pointer"}} onClick={() =>{this.getProducts("Coating")}}>Coating</Table.HeaderCell>
                        <Table.HeaderCell>Coating Day</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.renderProducts()}
                </Table.Body>
            </Table>

        </div>);
    }

}

export default withStyles(ScheduleView);
