import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav } from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router-dom'
import AccordionMobile from "../AccordionMobile/AccordionMobile";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import './DashboardMobile.css';



const DashboardMobile = () => {
    function handleLogout() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('jobRole');
        this.forceUpdate();
    }
    
    const [data, setData] = useState({});
    const [processData,setProcessData] = useState({});

    // useEffect to update the date of the component so that the most up to date information is in the component
    useEffect(() => {

        const evtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT+ "analytics/dashboard/sseRead.php");
        

        function turnEventToJSON(e) {
            let message = JSON.parse(e.data);
            delete message["status"];
            setData(message); //this will not reflect immediately not until the next render of the screen
        }

        

        evtSource.onmessage = e => turnEventToJSON(e);
        

    }, []);

    if (sessionStorage.getItem('token') == null) {
        return (<Redirect to="/" />);
    }
    else {
    
        return (

        <div className="container" id="top-level">
            <Navbar bg="dark" expand="lg" variant="dark">

                <Navbar.Brand href="#home">SEAVAC</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">

                        <Link id="link1" to="/dashboard" style={{ textDecoration: 'none' }}>Orders</Link>
                        <Link id="link2" to="/analytics" style={{ textDecoration: 'none' }}>Analytics</Link>
                        <button id="logoutButton" className="btn btn-danger" onClick={handleLogout}>Logout </button>

                    </Nav>
                </Navbar.Collapse>  
            </Navbar>
            <h2>Orders Dashboard</h2>
            
            {Array.from(Array(Object.keys(data).length)).map((x, index) => <AccordionMobile receive={data[index].ReceivedDate} ship={data[index].ShipmentSchedule} customer={data[index].Customer} order={data[index].ProductName} currentStage={data[index].CurrentStage} quantity={data[index].Quantity} process={data[index].Process} ></AccordionMobile>)}
        </div>
      )
    }
}

export default DashboardMobile;