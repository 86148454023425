import React from 'react';
import Navbar from './navbar';
import { Redirect } from 'react-router-dom';
import { Table, Form } from 'semantic-ui-react';

import hocify from 'hocify';
import containerStyle from '../jss/containers';

const withStyles = hocify(containerStyle);

class CoatingTime extends React.Component {


    constructor(properties) {
        super(properties);

        //set form values
        this.state = {
            productID: '',
            requestAll: true,
            QueryData: null,
            QueryDataArchive: null,
            QueryDataSub: null,
            QueryDataSubArchive: null,
            Processes: null,
            typeChoice: 1
        };

        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");

        this.handleChange = this.handleChange.bind(this);
        this.getProductsArch = this.getProductsArch.bind(this);
        this.setTypeChoice = this.setTypeChoice.bind(this);
        this.setTypeChoice = (e, { value }) => this.setState({ value })

        this.abortController = new AbortController();
    }

    handleChange(event) {
        //console.log(event);
        //update the value for which ever field was changed
        /*  if(event.target.id === "productID"){
              this.setState({productID: event.target.value});
          }else if(event.target.id === "requestAll"){
              this.setState({requestAll: event.target.checked});
          }*/


        if (event !== "") {
            this.setState({ requestAll: false });
        }
        else {
            this.setState({ requestAll: true });
        }

        this.setState({ productID: event }, this.getProductsArch);
    }

    setTypeChoice(event) {
        this.setState(this.state.typeChoice = event.target.value);
    }



    //get the product data from the db
    async getProductsArch() {
        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/getCoatingDate.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryDataArchive: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    componentDidMount() {
        //send all onmessage events to the sse receive method
        this.processEvtSource.onmessage = e => this.receiveProcessData(e);
        var hashParams = window.location.hash.substr(1)
        //console.log(hashParams);
        if (hashParams.length > 0) {
            this.handleChange(decodeURIComponent(hashParams));
        }

        let ar1 = [1, 5, 8];
        let ar2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

        let ar3 = this.arr_diff(ar1, ar2);
        //console.log(ar3);



        this.getProductsArch();
    }

    arr_diff(a1, a2) {
        var a = [], diff = [];

        for (var i = 0; i < a1.length; i++) {
            a[a1[i]] = true;
        }

        for (var i = 0; i < a2.length; i++) {
            if (a[a2[i]]) {
                delete a[a2[i]];
            } else {
                a[a2[i]] = true;
            }
        }

        for (var k in a) {
            diff.push(k);
        }

        return diff;
    }

    componentWillUnmount() {
        this.processEvtSource.close();
        this.abortController.abort();
    }

    receiveProcessData(e) {
        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    //renders everything for controlling the page
    renderControls() {


        return (<div>
            <Navbar selectedPage="coatingTime" />

        </div>);
    }

    renderProductsArchive() {

        if (this.state.QueryDataArchive === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const products = [];
        //add each product from the db to the table
        for (let index = 0; index < Object.keys(this.state.QueryDataArchive).length; index++) {
            let value = this.state.QueryDataArchive[index];

            products.push(<Table.Row key={index}>
                <Table.Cell>{value["product"]}</Table.Cell>
                <Table.Cell>{value["Customer"]}</Table.Cell>
                <Table.Cell>{value["machine"]}</Table.Cell>
                <Table.Cell>{value["DateTimeAdded"]}</Table.Cell>
            </Table.Row>);


        }

        //console.log(products);
        if (products.length === 0) {
            products.push(<Table.Row key={0}>
                <Table.Cell>No products found.</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
            </Table.Row>);
            return (products);
        }

        return (products);

    }

    render() {

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager' && job !== 'Employee') {
            return (<Redirect to="/signin" />);
        }
        return (
            <div>
                {this.renderControls()}
                <div className={this.props.container + " " + "blueBackground"}>
                    <Form>
                        <Form.Field>
                            <label>Customer Search</label>
                            <input onChange={e => this.handleChange(e.target.value)} placeholder='Search by customer name:' />
                        </Form.Field>
                    </Form>
                    <h3>Date Coated:</h3>
                    <Table compact color='blue' celled unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Product ID</Table.HeaderCell>
                                <Table.HeaderCell>Customer</Table.HeaderCell>
                                <Table.HeaderCell>Machine</Table.HeaderCell>
                                <Table.HeaderCell>Time Coated</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.renderProductsArchive()}
                        </Table.Body>
                    </Table>
                </div>
            </div>);
    }

}

export default withStyles(CoatingTime);
