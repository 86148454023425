import React from 'react';
import { Redirect } from 'react-router-dom'
import { Table, Button, Modal, Input, Checkbox, Popup, Dropdown, Label, Message } from 'semantic-ui-react';
import Navbar from './navbar';

import hocify from 'hocify';
//import containerStyle from '../jss/containers';
import enterProductStyle from '../jss/enterProduct';

const withStyles = hocify(enterProductStyle);

class GenerateCSV extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            QueryData: null,
            response: null,
            codes: null,
            Processes: null,
            latest: " ",
            productID: '',
            customer: '',
            DeliveryDate: '',
            ReceivedDate: '',
            Material: '',
            ProductName: '',
            Quantity: '',
            ProductWeight: '',
            ProductDiameter: '',
            ProductLength: '',
            ShipmentSchedule: '',
            PO: '',
            Process: '',
            NumberOfItems: '',
            NumberOfItemsStorage: '',   //save original number of items when opening subproduct modal
            Coating: '',
            Notes: '',
            weightSerial: '',
            weightArrSerial: '',
            weightHolder: '',
            cuttingtools: 0,
            fileName: 'export.csv',
            isSubProduct: 'false',
            string: [['ProductID', 'Customer', 'DeliveryDate', 'ReceivedDate', 'Material', 'ProductName',
                'Quantity', 'ProductWeight', 'ShipmentSchedule', 'PO', 'Process', 'NumberOfItems', 'Coating', 'Notes', 'ProductDiameter', 'ProductLength'],
            ],
            outputString: [['']]

        }


        this.getLatestProduct = this.getLatestProduct.bind(this);
        this.getCustomers = this.getCustomers.bind(this);
        this.productIDChange = this.productIDChange.bind(this);
        this.customerChange = this.customerChange.bind(this);
        this.deliveryChange = this.deliveryChange.bind(this);
        this.receivedChange = this.receivedChange.bind(this);
        this.materialChange = this.materialChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.quantityChange = this.quantityChange.bind(this);
        this.weightChange = this.weightChange.bind(this);
        this.diameterChange = this.diameterChange.bind(this);
        this.lengthChange = this.lengthChange.bind(this);
        this.shipChange = this.shipChange.bind(this);
        this.poChange = this.poChange.bind(this);
        this.processChange = this.processChange.bind(this);
        this.numberChange = this.numberChange.bind(this);
        this.modalNumberChange = this.modalNumberChange.bind(this);
        this.coatingChange = this.coatingChange.bind(this);
        this.notesChange = this.notesChange.bind(this);
        this.addToString = this.addToString.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleCut = this.handleCut.bind(this);
        this.displayModalMessage = this.displayModalMessage.bind(this);
        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");
        this.abortController = new AbortController();
    }


    async getLatestProduct() {

        //get the json ready to be sent
        let queryBody = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/getLatestProduct.php", {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: queryBody,
                signal: this.abortController.signal
            });


            let responseJSON = await response.json();
            this.state.latest = responseJSON;
            this.state.productID = this.state.latest + 1; //Set product ID to 1 higher than the latest product by default

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async getCustomers() {

        //get the json ready to be sent
        let queryBody = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/getCustomers.php", {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: queryBody,
                signal: this.abortController.signal
            });


            let responseJSON = await response.json();
            this.setState({
                QueryData: responseJSON
            });

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async exportToCsv(filename, rows) {

        //push every row up by one to remove the non-sub product
        //an unanticipated blank row will cause problems when input
        let subProductOnlyRows = JSON.parse(JSON.stringify(rows));
        for (let i = 1, j = 2; i < subProductOnlyRows.length - 1 && j < subProductOnlyRows.length; i++, j++) {
            subProductOnlyRows[i] = subProductOnlyRows[j];
        }
        subProductOnlyRows[subProductOnlyRows.length - 1] = [];
        subProductOnlyRows.pop();

        if (this.state.isSubProduct == "true") {
            //remove the non-subproduct row
            rows = subProductOnlyRows;
            filename = (this.state.fileName + " " + this.state.customer + " Sub-Product.csv").toString();
        }
        else {
            filename = (this.state.fileName + " " + this.state.customer + ".csv").toString();
        }

        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

        this.setState({
            outputString: [['']]
        });
    }

    componentDidMount() {
        //send all onmessage events to the sse receive method
        this.processEvtSource.onmessage = e => this.receiveProcessSSEData(e);
        this.getLatestProduct();
        this.getCustomers();
        //this.exportToCsv('export.csv', string);
        var d = new Date()
        this.state.fileName = (d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getFullYear().toString().substring(2);
    }

    componentWillUnmount() {
        this.abortController.abort();
        this.processEvtSource.close();
    }

    receiveProcessSSEData(e) {
        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    diameterChange(event) {
        this.state.ProductDiameter = event.target.value;
        this.setState({ ProductWeight: this.state.ProductDiameter + "x" + this.state.ProductLength });
        this.setState({ ProductDiameter: this.state.ProductDiameter });
    }

    lengthChange(event) {
        this.state.ProductLength = event.target.value;
        this.setState({ ProductWeight: this.state.ProductDiameter + "x" + this.state.ProductLength });
        this.setState({ ProductLength: this.state.ProductLength });
    }

    productIDChange(event) {
        this.state.productID = event.target.value;
    }
    deliveryChange(event) {
        this.state.DeliveryDate = event.target.value;
        this.state.DeliveryDate = this.state.DeliveryDate.replace(new RegExp('-', "g"), "/");
    }
    receivedChange(event) {
        this.state.ReceivedDate = event.target.value;
        this.state.ReceivedDate = this.state.ReceivedDate.replace(new RegExp('-', "g"), "/");
    }
    materialChange(event) { this.state.Material = event.target.value; }
    nameChange(event) { this.state.ProductName = event.target.value; }
    quantityChange(event) { this.state.Quantity = event.target.value }
    weightChange(event) { this.state.ProductWeight = event.target.value; }
    shipChange(event) {
        this.deliveryChange(event);
        this.state.ShipmentSchedule = event.target.value;
        this.state.ShipmentSchedule = this.state.ShipmentSchedule.replace(new RegExp('-', "g"), "/");
    }
    poChange(event) { this.state.PO = event.target.value; }
    processChange(event) { this.state.Process = event.target.value; }
    numberChange(event) {
        this.state.NumberOfItems = event.target.value;
    }

    //set the number of items to 1 when adding sub-products
    modalNumberChange(i) {
        this.setState({ NumberOfItems: i });
    }
    customerChange = (event, data) => {
        this.setState({ customer: data.value });
    }
    coatingChange = (event, data) => {
        this.setState({ Coating: data.value });
    }
    notesChange(event) { this.state.Notes = event.target.value; }


    handleAddition = (e, { value }) => {
        this.setState({
            customer: value
        })
    }


    async addToString() {
        var stringAdd = [
            [this.state.productID, this.state.customer, this.state.DeliveryDate, this.state.ReceivedDate, this.state.Material, this.state.ProductName,
            this.state.Quantity, this.state.ProductWeight, this.state.ShipmentSchedule, this.state.PO, this.state.Process, this.state.NumberOfItems,
            this.state.Coating, this.state.Notes, this.state.ProductDiameter, this.state.ProductLength,],
        ];
        this.state.string = this.state.string.concat(stringAdd);

        this.state.outputString = JSON.parse(JSON.stringify(this.state.string));
        //for (var i = 0; i < this.state.outputString.length; i++) {
        //    this.state.outputString[i].splice(0, 1);
        //    this.state.outputString[i].splice(7, 4);
        //}

        this.setState({
            itemvalues: [{}]
        });
    }

    handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setState({
            itemvalues: [{}],
            productID: '',
            customer: '',
            DeliveryDate: '',
            ReceivedDate: '',
            Material: '',
            ProductName: '',
            Quantity: '',
            ProductWeight: '',
            ProductDiameter: '',
            ProductLength: '',
            ShipmentSchedule: '',
            PO: '',
            Process: '',
            NumberOfItems: '',
            Coating: '',
            Notes: '',
        });
    }

    handleResetCSV = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setState({
            itemvalues: [{}],
            outputString: [['']],
            productID: '',
            customer: '',
            DeliveryDate: '',
            ReceivedDate: '',
            Material: '',
            ProductName: '',
            Quantity: '',
            ProductWeight: '',
            ProductDiameter: '',
            ProductLength: '',
            ShipmentSchedule: '',
            PO: '',
            Process: '',
            NumberOfItems: '',
            Coating: '',
            Notes: '',
            string: [['ProductID', 'Customer', 'DeliveryDate', 'ReceivedDate', 'Material', 'ProductName',
                'Quantity', 'ProductWeight', 'ShipmentSchedule', 'PO', 'Process', 'NumberOfItems', 'Coating', 'Notes', 'ProductDiameter', 'ProductLength'],
            ]
        });
    }

    handleResetFileOnly = () => {
        this.setState({
            outputString: [['']],
            string: [['ProductID', 'Customer', 'DeliveryDate', 'ReceivedDate', 'Material', 'ProductName',
                'Quantity', 'ProductWeight', 'ShipmentSchedule', 'PO', 'Process', 'NumberOfItems', 'Coating', 'Notes', 'ProductDiameter', 'ProductLength'],
            ]
        });
    }

    handleClick() {
        if (this.state.isSubProduct == "false") {
            this.state.isSubProduct = "true";
        }
        else this.state.isSubProduct = "false";
    }

    handleCut() {
        if (this.state.cuttingtools === 0) {
            this.setState({ cuttingtools: 1 });
        }
        else if (this.state.cuttingtools === 1) {
            this.setState({ cuttingtools: 0 });
        }
    }

    displayModalMessage() {
        if (this.state.ProductName.length !== 0) {
            return (
                <Message positive>
                    Added Sub-Product: {this.state.ProductName}
                </Message>)
        }
        else
            return;
    }

    render() {

        const customerChoices = [];
        var choiceOBJ = {};

        if (this.state.QueryData != null) {

            for (let index = 0; index < Object.keys(this.state.QueryData).length - 1; index++) {
                let val = this.state.QueryData[index];
                choiceOBJ = { key: val["Customer"], text: val["Customer"], value: val["Customer"] }
                customerChoices.push(choiceOBJ);
            }
        }
        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager') {
            return (<Redirect to="/signin" />);
        }

        const coatingOptions = [
            {
                key: 'ZERO-I',
                text: 'ZERO-I',
                value: 'ZERO-I',
            },
            {
                key: 'TH',
                text: 'TH',
                value: 'TH',
            },
            {
                key: 'TH-Plus',
                text: 'TH-Plus',
                value: 'TH-Plus',
            },
            {
                key: 'TiAlN',
                text: 'TiAlN',
                value: 'TiAlN',
            },
            {
                key: 'TiN',
                text: 'TiN',
                value: 'TiN',
            },
            {
                key: 'S-PVD',
                text: 'S-PVD',
                value: 'S-PVD',
            },
            {
                key: 'S-TH',
                text: 'S-TH',
                value: 'S-TH',
            },
            {
                key: 'S-TiAlN',
                text: 'S-TiAlN',
                value: 'S-TiAlN',
            },
            {
                key: 'D-TiAlN',
                text: 'D-TiAlN',
                value: 'D-TiAlN',
            },
            {
                key: 'None',
                text: 'None',
                value: 'None',
            },
            {
                key: 'BLANK',
                text: '',
                value: '',
            }

        ]

        return (
            <div>
                <div className={this.props.printHide}>
                    <Navbar selectedPage={"generateCSV"} />
                    <div className={this.props.container + " " + "blueBackground"}>
                        <div style={{ textAlign: "center" }}>

                        </div>
                        <label className="uploadFileDesc" style={{ marginTop: "1em" }}>Most recent ProductID: {this.state.latest}</label>
                        <div style={{ textAlign: "center" }}>
                            <Input label={'ProductID:'.padStart(30, "\u00a0")} classname="input" placeholder={this.state.productID} style={{ width: '60%', padding: "0.2em" }} type="number" onChange={this.productIDChange} />
                            <br></br><Label size="big" >
                                Customer:</Label><Dropdown id="customerForm" selection clearable search allowAdditions style={{ width: '54.5%' }} options={customerChoices} onAddItem={this.handleAddition} onChange={this.customerChange}></Dropdown>
                            <Input label={'Received Date:'.padStart(27, "\u00a0")} classname="input" style={{ width: '60%', padding: "0.2em" }} type="date" onChange={this.receivedChange} />
                            <Input label={'Shipping & Delivery'.padStart(22, "\u00a0")} classname="input" style={{ width: '60%', padding: "0.2em" }} type="date" onChange={this.shipChange} />
                            <Input label={'Product Material:'.padStart(26, "\u00a0")} classname="input" style={{ width: '60%', padding: "0.2em" }} onChange={this.materialChange} />
                            <Input label={'Product Name:'.padStart(25, "\u00a0")} classname="input" style={{ width: '60%', padding: "0.2em" }} onChange={this.nameChange} />
                            <Popup content='Total items in entire order' position='left center' trigger={
                                <Input label={'Quantity:'.padStart(30, "\u00a0")} classname="input" style={{ width: '60%', padding: "0.2em" }} type="number" onChange={this.quantityChange} />} />
                            <Input label={'Product Weight:'.padStart(25, "\u00a0")} id="weightBox" classname="input" style={{ width: '60%', padding: "0.2em" }} onChange={this.weightChange} />
                            <Input label={'Product Process:'.padStart(25, "\u00a0")} classname="input" style={{ width: '60%', padding: "0.2em" }} type="number" onChange={this.processChange} />
                            <Popup content='Different items in this order' position='left center' trigger={
                                <Input label={'Number of Items:'.padStart(24, "\u00a0")} classname="input" style={{ width: '60%', padding: "0.2em" }} type="number" onChange={this.numberChange} />} />
                            <br></br>
                            <Label size="big" >Coating:</Label>
                            <Dropdown selection clearable search options={coatingOptions} style={{ width: '54.5%' }} value={this.state.Coating} onChange={this.coatingChange} />
                            <br></br>
                            <Input label={'Description:'.padStart(30, "\u00a0")} classname="input" style={{ width: '60%', padding: "0.2em" }} onChange={this.notesChange} />
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <Modal
                                onClose={() => { this.setState({ NumberOfItems: this.state.NumberOfItemsStorage }); this.setState({ isSubProduct: "false" }) }}
                                onOpen={() => { this.setState({ NumberOfItemsStorage: this.state.NumberOfItems }); this.setState({ NumberOfItems: 1 }); this.setState({ isSubProduct: "true" }) }}
                                open={this.state.viewProcessesOpen}
                                trigger={<Button>Add Sub-Products</Button>}>
                                <Modal.Header>Add Sub-Products</Modal.Header>
                                <Modal.Content>
                                    <Input label={'Product Name:'.padStart(25, "\u00a0")} classname="input" style={{ width: '60%', padding: "0.2em" }} onChange={this.nameChange} />
                                    <Input label={'Quantity:'.padStart(30, "\u00a0")} classname="input" style={{ width: '60%', padding: "0.2em" }} type="number" onChange={this.quantityChange} />
                                    <Input label={'Product Weight:'.padStart(25, "\u00a0")} id="weightBox" classname="input" style={{ width: '60%', padding: "0.2em" }} onChange={this.weightChange} />
                                    <div></div>
                                    <Button onClick={() => { this.modalNumberChange(1); this.addToString() }}>Add to file</Button>
                                    <Button onClick={() => { this.exportToCsv('export.csv', this.state.string) }}>Sub-Products to CSV</Button>
                                    {this.displayModalMessage()}
                                </Modal.Content>
                            </Modal>
                        </div>
                        <div>{this.state.outputString != '' &&
                            <Table celled unstackable>
                                <tbody>
                                    {
                                        this.state.outputString.map((numList, i) => (
                                            <tr key={i}>
                                                {
                                                    numList.map((num, j) =>
                                                        <td key={j}>{num}</td>
                                                    )
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <Button onClick={async () => {
                                await this.addToString();
                                await this.exportToCsv('export.csv', this.state.string);
                            }}>Send to CSV</Button>
                            <Button onClick={this.handleResetCSV}>Clear everything</Button>
                            <Button onClick={this.handleResetFileOnly}>Clear file only</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(GenerateCSV);
