import React from 'react';
import { Table, Grid, Dropdown, Button } from 'semantic-ui-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import ExportChart from './exportChartReport3';

import MachineUptimeTooltip from './machineUptimeTooltip';

import BlueComputerIcon from '../../resources/blue_computer.svg';
import YellowComputerIcon from '../../resources/yellow_computer.svg';
import GreyComputerIcon from '../../resources/grey_computer.svg';

import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import machineUptimeStyle from '../../jss/reports/machineUptime';

//css but in json form written in js
const withStyles = hocify(machineUptimeStyle);

class MachineUptime extends React.Component {

    constructor(props){
        super(props);

		let today = new Date();
        this.state = {
            timePeriodSelection: 'daily',
            startDate: 0,
            currentDate: (today.getMonth()+1) + '/' + today.getDate() + '/' + today.getFullYear(),
            machineFilter: '',
            orderFilter: '',
            queryResults: null,
            stageIDs: {
                'Coating (M1) - Set/Disassemble': 10,
                'Coating (M2) – Set/Disassemble': 11,
                'Coating (M3) – Set/Disassemble': 12,
                'Final Inspection': 17,
                'Packing/Shipping': 18,
                'Post-Inspection': 13,
                'Post-Lapping (AERO)': 15,
                'Post-Lapping (Hand Lapping)': 16,
                'Post-Lapping (SMAP)': 14,
                'Pre-Inspection': 0,
                'Pre-Lapping (AERO)': 7,
                'Pre-Lapping (Hand Lapping)': 8,
                'Pre-Lapping (SMAP)': "6",
                'Pre-Washing (F1 Clean Washer)': 9,
                'Pre-Washing (Jig-Washer)': 1,
                'Stripping (Liquid)': 3,
                'Stripping (Shot)': 2,
                'WPC-Ceramic': 5,
                'WPC-SKH': 4
            },
            stageNames: {
                0: 'Pre-Inspection',
                1: 'Pre-Washing (Jig-Washer)',
                2: 'Stripping (Shot)',
                3: 'Stripping (Liquid)',
                4: 'WPC-SKH',
                5: 'WPC-Ceramic',
                6: 'Pre-Lapping (SMAP)',
                7: 'Pre-Lapping (AERO)',
                8: 'Pre-Lapping (Hand Lapping)',
                9: 'Pre-Washing (F1 Clean Washer)',
                10: 'Coating (M1) - Set/Disassemble',
                11: 'Coating (M2) – Set/Disassemble',
                12: 'Coating (M3) – Set/Disassemble',
                13: 'Post-Inspection',
                14: 'Post-Lapping (SMAP)',
                15: 'Post-Lapping (AERO)',
                16: 'Post-Lapping (Hand Lapping)',
                17: 'Final Inspection',
                18: 'Packing/Shipping'
            },
            displayNames: {
                0: 'Pre-Inspect',
                1: 'Pre-Wash (Jig)',
                2: 'Strip (Shot)',
                3: 'Strip (Liquid)',
                4: 'WPC-SKH',
                5: 'WPC-Ceramic',
                6: 'Pre-L (SMAP)',
                7: 'Pre-L (AERO)',
                8: 'Pre-L (Hand)',
                9: 'Pre-Wash (F1)',
                10: 'Coating (M1)',
                11: 'Coating (M2)',
                12: 'Coating (M3)',
                13: 'Post-Inspect',
                14: 'Post-L (SMAP)',
                15: 'Post-L (AERO)',
                16: 'Post-L (Hand)',
                17: 'Final-Inspect',
                18: 'Packing/Shipping'
            },
            machineCounts: null
        }

        this.handleMachineFilter = this.handleMachineFilter.bind(this);
        this.handleOrderFilter = this.handleOrderFilter.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.performMaintenance = this.performMaintenance.bind(this);
        this.clearFilters = this.clearFilters.bind(this);

        this.abortController = new AbortController();

    }

    //callback to view reports, to send the report data
    sendData(){
        this.props.sendData(this.state.queryResults);
    }

    //store which machine to filter to
    handleMachineFilter(event, {name}){
        this.setState({ machineFilter: name });
    }

    //store which order to filter to
    handleOrderFilter(event, {name}){
        this.setState({ orderFilter: name });
    }

    //store the time period selected and update the report data with that date
    handleClick(event, {name}){
        this.setState({ timePeriodSelection: name });
        this.getReportData(name, this.state.startDate);
    }

    //resets all filter fileds
    clearFilters(){
        this.setState({
            machineFilter: '',
            orderFilter: ''
        });
    }

    handleChange(event){

        //update the value for which ever field was changed
        if(event.target.id === "startDate"){
            this.setState({startDate: event.target.value});
            this.getReportData(this.state.timePeriodSelection, event.target.value)
        }

    }

    //get the report data after the component is ready
    componentDidMount(){
        this.getReportData(this.state.timePeriodSelection, this.state.startDate);
    }

    //sends a request to the server, and handles the recieved data
	async getReportData(customTimePeriod, customDate){

        //don't let the custom date selection have a empty or null value
        if(customTimePeriod === "custom" && (customDate === "" || customDate === null)){
            customDate = 0;
        }

        try{
            let reportJSON = JSON.stringify({
                timePeriod: customTimePeriod,
                customRange: customDate,
                token: sessionStorage.getItem("token"),
                jobRole: sessionStorage.getItem("jobRole")
            });
           
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/machineUsage/read.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: reportJSON,
                signal: this.abortController.signal
                });
            //process the results of the operation
            let responseJSON = await response.json();

            if(responseJSON.hasOwnProperty("status") && responseJSON["status"] === 403){
                this.setState({queryResults: null});
                return;
            }

            this.setState({queryResults: responseJSON});
            this.sendData();
            this.setMachineTypeUsage(responseJSON["machineOrders"]);
        } catch (error) {
            if(error.name === 'AbortError'){
            }
        }
    }

    async performMaintenance(ID)
    {
        let maintenanceJSON = JSON.stringify({
            machineID: ID,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        const requestPath = process.env.REACT_APP_SERVER_ROOT + "machine/performMaintenance.php";
        let response = await fetch(requestPath, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: maintenanceJSON,
            signal: this.abortController.signal
        });
        let responseJSON = await response.json();
        this.getReportData(this.state.timePeriodSelection, this.state.startDate);
    }

    //if the component gets unmounted cancle the fetch request
    componentWillUnmount(){
        this.abortController.abort();
    }

    //go through all the orders, keeping track of the type of each machine
    setMachineTypeUsage(json){

        //set up the default values of the counts array, to be ready for incrementing
        let counts = [];
        for(let i = 0; i < Object.keys(this.state.stageNames).length; i++){
            counts.push(0);
        }

        //if a valid stage name is found in the json, add one to the counter for that stage
        for(let i = 0; i < Object.keys(json).length; i++){
            if(this.state.stageIDs.hasOwnProperty(json[i]["machineType"])){
                counts[this.state.stageIDs[json[i]["machineType"]]]+=Number(this.state.queryResults.machineOrders[i].numberOfOrders);
            }
        }

        this.setState({
            machineCounts: counts
        });
    }

    renderBarGraph(){

        //json format
        //set the names and numbrt of orders for each machine type
        // const data = [
        //     {
        //       name: 'Inspection',
        //       orders: this.state.numInspection
        //     },
        //     {
        //       name: 'Cleaning',
        //       orders: this.state.numCleaning
        //     },
        //     {
        //       name: 'Coating',
        //       orders: this.state.numCoating
        //     },
        //     {
        //       name: 'Inspection2',
        //       orders: this.state.numSecondInspection
        //     },
        //     {
        //       name: 'Disassembly',
        //       orders: this.state.numDisassembly
        //     },
        //     {
        //       name: 'Packaging',
        //       orders: this.state.numPackaging
        //     }
        //   ];
        //build the graph off all machine counts using the key to grab the stage name
        const data = [];
        for(let i = 0; i < this.state.machineCounts.length; i++){
            data.push({
                name: this.state.displayNames[i],
                orders: this.state.machineCounts[i]
            });
        }

        const color = "#3063A5";

        return(<div id="barChart" className="whiteBackground" style={{padding: '1%', width: '900px'}}>
            <BarChart
                width={900}
                height={600}
                data={data}
                layout="vertical"
                barCategoryGap={3}
                margin={{
                top: 5, right: 30, left: 30, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" interval={0} allowDecimals={false} label={{fontWeight: 'bold', value: "Number of Orders", position: "insideBottom", dy: 9}}/>
                <YAxis dataKey="name" type="category" interval={0} width={100}/>
                <Tooltip content={<MachineUptimeTooltip color={color} stageNames={this.state.stageNames} displayNames={this.state.displayNames}/>}/>
                <Bar dataKey="orders" fill={color} />
            </BarChart>
        </div>);
    }

    renderOrderTypes(){

        if(this.state.machineCounts == null){
            return;
        }

        const rows = [];
        for(let i = 0; i < this.state.machineCounts.length; i++){
            rows.push(<Table.Row key={i}>
                <Table.Cell>{this.state.stageNames[i]}</Table.Cell>
                <Table.Cell>{this.state.machineCounts[i]}</Table.Cell>

            </Table.Row>);
        }

        return(<div>

            {this.renderBarGraph()}
            {/*this.renderBarGraphAvg()*/}

            <h2>Work Order Data</h2>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Stage Type</Table.HeaderCell>
                        <Table.HeaderCell>Number of Orders</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}

                </Table.Body>
            </Table>
        </div>);

    }

    renderMachineOrders(){

        let machineOrders = this.state.queryResults;
       
        if(machineOrders === null){
            return("");
        }

        //how many orders a machine can be off from the average and still be under "normal" uses
        let avgOrderBounds = 5;

        //calc the average from all orders, to decied how over/under worked a machine is
        let avgOrders = machineOrders["totalOrders"] / Object.keys(machineOrders["machineOrders"]).length;

        const machineData = []

        for(let i = 0; i < Object.keys(machineOrders["machineOrders"]).length; i++){

            //if this machine is not of the filter type and the filter is not empty
            if(machineOrders["machineOrders"][i].machineType !== this.state.machineFilter && this.state.machineFilter !== ""){
                continue;
            }

            //if this order is not of the filter type and the filter is not empty
            if(machineOrders["machineOrders"][i].orderType !== this.state.orderFilter && this.state.orderFilter !== ""){
                continue;
            }

            const icon = []
            let numOrders = machineOrders["machineOrders"][i].numberOrders;
            if(numOrders > avgOrders + avgOrderBounds){ //high uptime
                icon.push(<img alt="a yellow computer icon" key="0" src={YellowComputerIcon} style={{width: "30px", height: "30px"}}/>);
            }else if(numOrders <  avgOrderBounds - avgOrderBounds){ //low uptime
                icon.push(<img alt="a blue computer icon" key="0" src={BlueComputerIcon} style={{width: "30px", height: "30px"}}/>);
            }else{ //avg uptime
                icon.push(<img alt="a grey computer icon" key="0" src={GreyComputerIcon} style={{width: "30px", height: "30px"}}/>);
            }

              machineData.push(<Table.Row key={i}>
                  <Table.Cell>{machineOrders["machineOrders"][i].machineID}</Table.Cell>
                  <Table.Cell>{icon}</Table.Cell>
                  <Table.Cell>{machineOrders["machineOrders"][i].numberOfOrders}</Table.Cell>
                  <Table.Cell>{machineOrders["machineOrders"][i].hours}</Table.Cell>
                  <Table.Cell>{machineOrders["machineOrders"][i].machineType}</Table.Cell>
                  <Table.Cell>{machineOrders["machineOrders"][i].orderType}</Table.Cell>
                  <Table.Cell>
                        <button class="ui primary button" onClick={() => this.performMaintenance(machineOrders["machineOrders"][i].machineID)}>
                            Perform Maintenance
                        </button>
                  </Table.Cell>
              </Table.Row>);

        }

        //get todays date and use the selected time period to decide how far back to go
        let startDate = new Date();
        switch(this.state.timePeriodSelection){
            case "daily":
                startDate.setDate(startDate.getDate() - 1);
                break;
            case "weekly":
                startDate.setDate(startDate.getDate() - 7);
                break;
            case "monthly":
                startDate.setDate(startDate.getDate() - 30);
                break;
            case "custom":
                startDate.setDate(startDate.getDate() - this.state.startDate);
                break;
            default:
        }

        //if there are no orders remove the ghost entry sent by the server
        if(parseInt(machineOrders["totalOrders"]) === 0 && machineOrders["machineOrders"][0].machineID === ""){
            machineData.pop();
        }

        return(<div>

            <Grid relaxed columns={4}>
                <Grid.Column>
                    <div><b>{machineOrders["totalOrders"]}</b> Orders processed</div>
                    <div>between <b>{(startDate.getMonth()+1) + '/' + startDate.getDate() + '/' + startDate.getFullYear()} - {this.state.currentDate}</b></div>
                    <hr></hr>
                    <div><b>{machineOrders["currentOrders"]}</b> Orders currently in progress</div>
                </Grid.Column>
                <Grid.Column>

                </Grid.Column>
                <Grid.Column>

                </Grid.Column>
                <Grid.Column>
                    <div>{this.renderStatusIconLegend()}</div>
                </Grid.Column>
            </Grid>

            {this.renderOrderTypes()}

            <div className={this.props.machineOrderTable}>
                <h2>Machine Order Data</h2>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Machine ID</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Number of Orders</Table.HeaderCell>
                                <Table.HeaderCell>Hours</Table.HeaderCell>
                                <Table.HeaderCell>Machine Type</Table.HeaderCell>
                                <Table.HeaderCell>Order Type</Table.HeaderCell>
                                <Table.HeaderCell>Maintenance</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {machineData}
                        </Table.Body>
                    </Table>
            </div>

        </div>);

    }

    renderFilterControls(){

        let machineTypes = [""];
        let orderTypes = [""];
        
        if(this.state.queryResults !== null){
            for(let i = 0; i < Object.keys(this.state.queryResults["machineOrders"]).length; i++){
                let currentElement = this.state.queryResults["machineOrders"][i];
                if(!machineTypes.includes(currentElement["machineType"])){
                    machineTypes.push(currentElement["machineType"]);
                }

                if(!orderTypes.includes(currentElement["orderType"])){
                    orderTypes.push(currentElement["orderType"])
                }
            }
        }

        //these set up the machine type filter and order type filter---------//
        const machineTypesData = []
        const orderTypesData = []

        for(let i = 0; i < machineTypes.length; i++){
            machineTypesData.push(<Dropdown.Item
                key={i}
                name={machineTypes[i]}
                active={this.state.machineFilter === machineTypes[i]}
                onClick={this.handleMachineFilter}
            >
                {machineTypes[i]}
            </Dropdown.Item>);
        }

        for(let i = 0; i < orderTypes.length; i++){
            orderTypesData.push(<Dropdown.Item
                key={i}
                name={orderTypes[i]}
                active={this.state.orderFilter === orderTypes[i]}
                onClick={this.handleOrderFilter}
            >
                {orderTypes[i]}
            </Dropdown.Item>);
        }
        //-------------------------------------------------------------------//

        //build the custom date selector if enabled
        const customDateControls = []
        if(this.state.timePeriodSelection === "custom"){
            customDateControls.push(<span key='0' className={this.props.machineFilter}>
                <label>
                    Number of days to search:{" "}
                    <input type="number" placeholder={this.state.startDate} onChange={this.handleChange} id="startDate" name="startDate" />
                </label>
            </span>);
        }

        return(<div>
            <h3>Filters:</h3>

            <span>
                Time Period:{" "}
                <Dropdown item text={this.state.timePeriodSelection} className={this.props.machineFilter}>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            name="daily"
                            active={this.state.timePeriodSelection === 'daily'}
                            onClick={this.handleClick}
                        >
                            Past 24 Hours
                        </Dropdown.Item>

                        <Dropdown.Item
                            name="weekly"
                            active={this.state.timePeriodSelection === 'weekly'}
                            onClick={this.handleClick}
                        >
                            Weekly
                        </Dropdown.Item>

                        <Dropdown.Item
                            name="monthly"
                            active={this.state.timePeriodSelection === 'monthly'}
                            onClick={this.handleClick}
                        >
                            Monthly
                        </Dropdown.Item>

                        <Dropdown.Item
                            name="custom"
                            active={this.state.timePeriodSelection === 'custom'}
                            onClick={this.handleClick}
                        >
                            Custom
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </span>

            {customDateControls}


            <span className={this.props.machineFilter}>
                Order Type:{" "}
                <Dropdown item text={this.state.orderFilter}>
                    <Dropdown.Menu>
                        {orderTypesData}
                    </Dropdown.Menu>
                </Dropdown>
            </span>
            <ExportChart chart={"barChart"} orientationH={900} orientationW={900} chartName={"machineUptime_" + new Date().toJSON().split("T")[0] + "_" + this.state.timePeriodSelection}/>

            <span className={this.props.clearFilterButton}><Button onClick={this.clearFilters}>Clear Filter Tags</Button></span>

        </div>);

    }


    //creates a legend with three icons for the machine usage status
    renderStatusIconLegend(){
        return(<div className={this.props.statusIconLegend}>

            <div className={this.props.statusIconLegendItem}>
                <img alt="a blue computer icon" className="statusIcon" src={BlueComputerIcon} style={{width: "30px", height: "30px"}}/> Low Uptime
            </div>

            <div className={this.props.statusIconLegendItem}>
                <img alt="a grey computer icon" className="statusIcon" src={GreyComputerIcon} style={{width: "30px", height: "30px"}}/> Average Uptime
            </div>

            <div className={this.props.statusIconLegendItem}>
                <img alt="a yellow computer icon" className="statusIcon" src={YellowComputerIcon} style={{width: "30px", height: "30px"}}/> High Uptime
            </div>

        </div>);
    }

    render(){

        return(<div>

            {this.renderFilterControls()}
            <hr />
            {this.renderMachineOrders()}

        </div>);
    }

}

export default withStyles(MachineUptime);
