import {createUseStyles} from 'react-jss';

const employeeStatisticsStyle = createUseStyles({

  employeeTableCycle: {
      marginRight: '1%'
  },

  filter: {
      paddingRight: '1.4%'
  },

  machineFilter: {
    paddingRight: '1%'
  },
  barChart: {
    border: '4px solid',
    padding: '6%',
    paddingLeft: '0%',
    marginTop: '1.5%',
    width: '650px',
    height: 'auto',
    backgroundColor: 'white'
  },
  printable: {
    display: 'none'
  },
  printHide: {},
  "@media print": { //hide/show things when printing
      printHide: {
          display: 'none'
      },
      printable: {
          display: 'block'
      }
  },

})

export default employeeStatisticsStyle;
