import React from "react";
import XLSX from "xlsx";
import { Redirect } from 'react-router-dom'
import { Table, Button, Input } from 'semantic-ui-react';
import Navbar from './navbar';
import ExportCSV from './reports/exportCSV';
import hocify from 'hocify';
//import containerStyle from '../jss/containers';
import {
    LineChart,
    Line,
    Label,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine
} from "recharts";
import enterProductStyle from '../jss/enterProduct';

const withStyles = hocify(enterProductStyle);


export default class enterExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
            cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
            inspector: "-",
            date: "-",
            coating: "Coating: -",
            thickness: "-",
            adhesion: "-",
            QueryData: null,
            maxVal: 0
        };
        this.handleFile = this.handleFile.bind(this);
        this.pushtoDB = this.pushtoDB.bind(this);
        this.getInspections = this.getInspections.bind(this);
        this.getInspectionsLimited = this.getInspectionsLimited.bind(this);

        this.abortController = new AbortController();


    }
    handleFile(file /*:File*/) {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        var dateformat = 0;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            console.log(rABS, wb);
            console.log("Inspected by: " + wb.Sheets.Sheet1.E2.w);
            this.state.inspector = wb.Sheets.Sheet1.E2.w;
            console.log("Date: " + wb.Sheets.Sheet1.E3.w);
            this.state.date = wb.Sheets.Sheet1.E3.w;
            dateformat = this.state.date.split('/');
            this.state.date = "20" + dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1];
            console.log(this.state.date);
            console.log(wb.Sheets.Sheet1.A5.w)
            this.state.coating = wb.Sheets.Sheet1.A5.w;
            if (wb.Sheets.Sheet1.C7 != undefined) {
                console.log("Thickness: " + wb.Sheets.Sheet1.C7.w)
                this.state.thickness = wb.Sheets.Sheet1.C7.w
            }
            else {
                this.state.thickness = "-";
            }

            if (wb.Sheets.Sheet1.C29 != undefined) {
                console.log("Adhesion: " + wb.Sheets.Sheet1.C29.w)
                this.state.adhesion = wb.Sheets.Sheet1.C29.w;
            }
            else {
                this.state.adhesion = "-";
            }
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Update state */
            this.setState({ data: data, cols: make_cols(ws["!ref"]) });
            this.pushtoDB();
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);

    }
    async pushtoDB() {

        //get the json ready to be sent
        let exportBody = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            coating: this.state.coating,
            inspector: this.state.inspector,
            date: this.state.date,
            thickness: this.state.thickness,
            adhesion: this.state.adhesion
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "analytics/inspectionReports/writeInspection.php", {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: exportBody,
                signal: this.abortController.signal
            });

            let responseJSON = await response.json();
            console.log(responseJSON);
            this.state.latest = responseJSON;

            if (responseJSON.status == 200) {
                console.log("Successful entry")
            }

            if (responseJSON.status == 400) {
                //alert("Inspection report not entered. Is it already in the database?");
                console.log("Inspection report not entered. Is it already in the database?");
            }

        } catch (error) {
            console.log(error);
            if (error.name === 'AbortError') {
            }
        }


        ///* convert state to workbook */
        //const ws = XLSX.utils.aoa_to_sheet(this.state.data);
        //const wb = XLSX.utils.book_new();
        //XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        ///* generate XLSX file and send to client */
        //XLSX.writeFile(wb, "sheetjs.xlsx");
        this.getInspections();
    }

    componentDidMount() {
        //by default only show the items between today and january 1 of the current year
        let start = new Date().getFullYear() + "-01-01";

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;
        this.getInspectionsLimited(start, today);
        //this.getInspections();
    }

    //get the product data from the db
    async getInspections() {

        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/inspectionReports/readInspection.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            console.log(responseJSON);
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryData: responseJSON
                });
                //console.log(this.state.QueryData);
            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    //get the product data from the db
    async getInspectionsLimited(start, end) {
        //console.log(start);
        //console.log(end);
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            startDate: start,
            endDate: end
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/inspectionReports/readInspectionLimited.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });
            //process the response
            let responseJSON = await response.json();
            //console.log(responseJSON);
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];
                //console.log("success");
                //store the product data in the state
                this.setState({
                    QueryData: responseJSON
                });
                //console.log(this.state.QueryData);
            } else {
                console.log("fail");
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    renderProducts() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const products = [];



        //add each product from the db to the table
        for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
            let value = this.state.QueryData[index];


            products.push(<Table.Row key={index}>
                <Table.Cell>{value["Coating"]}</Table.Cell>
                <Table.Cell>{value["Inspector"]}</Table.Cell>
                <Table.Cell>{value["InspectDate"]}</Table.Cell>
                <Table.Cell>{value["Thickness"]} μm</Table.Cell>
                <Table.Cell>{value["Adhesion"]}</Table.Cell>
            </Table.Row>);

        }
        //console.log(products[54].props.children[3].props.children[0]);

        return (products);

    }

    renderProductsChart(flag) {
        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const productsChart = [];

        let chartOBJ = { thickness: "", InspectDate: "" };

        for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
            let value = this.state.QueryData[index];

            if (flag == "ZERO-I") {
                if (value["Coating"].includes("ZERO-I") && !value["Coating"].includes("S-ZERO-I")) {
                    chartOBJ = { thickness: value["Thickness"], InspectDate: value["InspectDate"] };
                    productsChart.push(chartOBJ);
                }
            }
            else if (flag == "TH") {
                if (value["Coating"].includes("TH") && !value["Coating"].includes("S-TH")) {
                    chartOBJ = { thickness: value["Thickness"], InspectDate: value["InspectDate"] };
                    if (value["Thickness"] > this.state.maxVal) {
                        this.state.maxVal = value["Thickness"];
                    }
                    productsChart.push(chartOBJ);
                }
            }
            else if (flag == "S-PVD") {
                if (value["Coating"].includes("S-PVD")) {
                    chartOBJ = { thickness: value["Thickness"], InspectDate: value["InspectDate"] };
                    if (value["Thickness"] > this.state.maxVal) {
                        this.state.maxVal = value["Thickness"];
                    }
                    productsChart.push(chartOBJ);
                }
            }
            else if (flag == "TiAlN") {
                if (value["Coating"].includes("TiAlN") && !value["Coating"].includes("S-TiAlN") && !value["Coating"].includes("D-TiAlN")) {
                    chartOBJ = { thickness: value["Thickness"], InspectDate: value["InspectDate"] };
                    if (value["Thickness"] > this.state.maxVal) {
                        this.state.maxVal = value["Thickness"];
                    }
                    productsChart.push(chartOBJ);
                }
            }
            else if (flag == "TiN") {
                if (value["Coating"].includes("TiN") && !value["Coating"].includes("S-TiN")) {
                    chartOBJ = { thickness: value["Thickness"], InspectDate: value["InspectDate"] };
                    if (value["Thickness"] > this.state.maxVal) {
                        this.state.maxVal = value["Thickness"];
                    }
                    productsChart.push(chartOBJ);
                }
            }
            else if (flag == "S-TiAlN") {
                if (value["Coating"].includes("S-TiAlN")) {
                    chartOBJ = { thickness: value["Thickness"], InspectDate: value["InspectDate"] };
                    if (value["Thickness"] > this.state.maxVal) {
                        this.state.maxVal = value["Thickness"];
                    }
                    productsChart.push(chartOBJ);
                }
            }
            else if (flag == "D-TiAlN") {
                if (value["Coating"].includes("D-TiAlN")) {
                    chartOBJ = { thickness: value["Thickness"], InspectDate: value["InspectDate"] };
                    if (value["Thickness"] > this.state.maxVal) {
                        this.state.maxVal = value["Thickness"];
                    }
                    productsChart.push(chartOBJ);
                }
            }
            else if (flag == "S-TH") {
                if (value["Coating"].includes("S-TH")) {
                    chartOBJ = { thickness: value["Thickness"], InspectDate: value["InspectDate"] };
                    if (value["Thickness"] > this.state.maxVal) {
                        this.state.maxVal = value["Thickness"];
                    }
                    productsChart.push(chartOBJ);
                }
            }
            else if (flag == "S-ZERO-I") {
                if (value["Coating"].includes("S-ZERO-I")) {
                    chartOBJ = { thickness: value["Thickness"], InspectDate: value["InspectDate"] };
                    if (value["Thickness"] > this.state.maxVal) {
                        this.state.maxVal = value["Thickness"];
                    }
                    productsChart.push(chartOBJ);
                }
            }
            else {
                chartOBJ = { thickness: value["Thickness"], InspectDate: value["InspectDate"] };
                productsChart.push(chartOBJ);
            }

        }
        var productsChartOut = "";
        //console.log(productsChart);

        productsChartOut = productsChart.reverse();

        return (productsChartOut);
    }


    renderControls() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return;
        }

        //convert the query data into a format the csv export wants
        let json = this.state.QueryData;

        let csvJson = {};

        //move all the json values into keys with the same name as the table
        for (let i = 0; i < Object.keys(json).length; i++) {
            csvJson[i] = {};

            csvJson[i]["Coating"] = json[i]["Coating"];
            csvJson[i]["Inspector"] = json[i]["Inspector"];
            csvJson[i]["InspectDate"] = json[i]["InspectDate"];
            csvJson[i]["Thickness"] = json[i]["Thickness"];
            csvJson[i]["Adhesion"] = json[i]["Adhesion"];
        }

        return (<div>
            <div className={this.props.container + " " + "blueBackground"}>
                <div className={this.props.inlineContainer}>
                    <ExportCSV
                        csvData={Object.values(csvJson)}
                        fileName={"InspectionReports_" + new Date().toJSON().split("T")[0]}
                    />
                </div>
            </div>
        </div>);
    }

    render() {

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager') {
            return (<Redirect to="/signin" />);
        }


        let data = this.renderProductsChart("S-TiAlN");
        var job = sessionStorage.getItem("jobRole");
        //console.log(data);
        //console.log(data.length)


        return (

            <DragDropFile handleFile={this.handleFile}>

                <div className="row">
                    <div className="col-xs-12">
                        <DataInput handleFile={this.handleFile} />
                    </div>

                </div>
                <div className="row">
                    <div className="col-xs-12">

                        {/*<button style={{ fontSize: '2em', marginLeft: '2em' }}
                            disabled={!this.state.data.length}
                            className="btn btn-success"
                            onClick={this.pushtoDB}>
                            Input into database
                        </button>*/}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                    {(job === 'Manager') && <div>
                        <div style={{ fontSize: '1.5em', marginLeft: '2em', padding: '0.5em' }}>Inspected by: {this.state.inspector}</div>
                        <div style={{ fontSize: '1.5em', marginLeft: '2em', padding: '0.5em' }}>Date: {this.state.date}</div>
                        <div style={{ fontSize: '1.5em', marginLeft: '2em', padding: '0.5em' }}>{this.state.coating}</div>
                        <div style={{ fontSize: '1.5em', marginLeft: '2em', padding: '0.5em' }}>Thickness: {this.state.thickness}</div>
                        <div style={{ fontSize: '1.5em', marginLeft: '2em', padding: '0.5em' }}>Adhesion: {this.state.adhesion}</div>
                        </div>}
                        {/*<OutTable data={this.state.data} cols={this.state.cols} />*/}


                        {this.renderControls()}
                        <div style={{ fontSize: '1.5em', marginLeft: '2em', padding: '0.5em' }}>Default range: January 1 of this year to today</div>
                        <div style={{ fontSize: '1.5em', marginLeft: '2em', padding: '0.5em' }}>
                            Custom Range: Between
                            <Input type="date" name="start" id="start" />
                            {'\u00A0'}and{'\u00A0'}
                            <Input type="date" name="end" id="end" />
                            <Button className="smallBtn" style={{ width: '25%' }}
                                onClick={() => this.getInspectionsLimited(document.getElementById("start").value, document.getElementById("end").value)}>
                                Change Range</Button>
                        </div>
                        <div style={{ backgroundColor: 'white' }}>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={window.innerWidth}
                                    height={300}
                                    data={this.renderProductsChart("TiAlN")}
                                    margin={{
                                        top: 10,
                                        right: 60,
                                        left: 20,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="InspectDate">
                                        <Label fontSize='20' value="TiAlN Thickness" position="top" />
                                    </XAxis>
                                    <YAxis domain={[0, 6]} />
                                    <Tooltip />
                                    <ReferenceLine y={4} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={2} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={3} stroke="blue" strokeWidth='.7' />
                                    <ReferenceLine y={2.05} stroke="blue" strokeWidth='.7' />
                                    <Line
                                        type="monotone"
                                        dataKey="thickness"
                                        stroke="#900C3F"
                                        strokeWidth='2'
                                        activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            <hr></hr>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={window.innerWidth * .95}
                                    height={300}
                                    data={this.renderProductsChart("TH")}
                                    margin={{
                                        top: 10,
                                        right: 60,
                                        left: 20,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="InspectDate">
                                        <Label fontSize='20' value="TH Thickness" position="top" />
                                    </XAxis>
                                    <YAxis domain={[0, 6]} />
                                    <Tooltip />
                                    <ReferenceLine y={4} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={2} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={3.5} stroke="blue" strokeWidth='.7' />
                                    <ReferenceLine y={2.5} stroke="blue" strokeWidth='.7' />
                                    <Line
                                        type="monotone"
                                        dataKey="thickness"
                                        stroke="#E67E22"
                                        strokeWidth='2'
                                        activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            <hr></hr>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={window.innerWidth * .95}
                                    height={300}
                                    data={this.renderProductsChart("TiN")}
                                    margin={{
                                        top: 10,
                                        right: 60,
                                        left: 20,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="InspectDate">
                                        <Label fontSize='20' value="TiN Thickness" position="top" />
                                    </XAxis>
                                    <YAxis domain={[0, 6]} />
                                    <Tooltip />
                                    <ReferenceLine y={4} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={2} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={3} stroke="blue" strokeWidth='.7' />
                                    <ReferenceLine y={2.05} stroke="blue" strokeWidth='.7' />
                                    <Line
                                        type="monotone"
                                        dataKey="thickness"
                                        stroke="#FFC300"
                                        strokeWidth='2'
                                        activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            <hr></hr>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={window.innerWidth * .95}
                                    height={300}
                                    data={this.renderProductsChart("S-PVD")}
                                    margin={{
                                        top: 10,
                                        right: 60,
                                        left: 20,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="InspectDate">
                                        <Label fontSize='20' value="S-PVD Thickness" position="top" />
                                    </XAxis>
                                    <YAxis domain={[0, 8]} />
                                    <Tooltip />
                                    <ReferenceLine y={8} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={4} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={6} stroke="blue" strokeWidth='.7' />
                                    <ReferenceLine y={4.05} stroke="blue" strokeWidth='.7' />
                                    <Line
                                        type="monotone"
                                        dataKey="thickness"
                                        stroke="#FFC300"
                                        strokeWidth='2'
                                        activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            <hr></hr>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={window.innerWidth * .95}
                                    height={300}
                                    data={this.renderProductsChart("ZERO-I")}
                                    margin={{
                                        top: 10,
                                        right: 60,
                                        left: 20,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="InspectDate">
                                        <Label fontSize='20' value="ZERO-I Thickness" position="top" />
                                    </XAxis>
                                    <YAxis domain={[0, 8]} />
                                    <Tooltip />
                                    <ReferenceLine y={8} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={4} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={6} stroke="blue" strokeWidth='.7' />
                                    <ReferenceLine y={4.05} stroke="blue" strokeWidth='.7' />
                                    <Line
                                        type="monotone"
                                        dataKey="thickness"
                                        stroke="#8884d8"
                                        strokeWidth='2'
                                        activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            <hr></hr>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={window.innerWidth * .95}
                                    height={300}
                                    data={this.renderProductsChart("S-ZERO-I")}
                                    margin={{
                                        top: 10,
                                        right: 60,
                                        left: 20,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="InspectDate">
                                        <Label fontSize='20' value="S-ZERO-I Thickness" position="top" />
                                    </XAxis>
                                    <YAxis domain={[0, 8]} />
                                    <Tooltip />
                                    <ReferenceLine y={8} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={4} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={6} stroke="blue" strokeWidth='.7' />
                                    <ReferenceLine y={4.05} stroke="blue" strokeWidth='.7' />
                                    <Line
                                        type="monotone"
                                        dataKey="thickness"
                                        stroke="#8884d8"
                                        strokeWidth='2'
                                        activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            <hr></hr>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={window.innerWidth * .95}
                                    height={300}
                                    data={this.renderProductsChart("D-TiAlN")}
                                    margin={{
                                        top: 10,
                                        right: 60,
                                        left: 20,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="InspectDate">
                                        <Label fontSize='20' value="D-TiAlN Thickness" position="top" />
                                    </XAxis>
                                    <YAxis domain={[0, 8]} />
                                    <Tooltip />
                                    <Line
                                        type="monotone"
                                        dataKey="thickness"
                                        stroke="#900C3F"
                                        strokeWidth='2'
                                        activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            <hr></hr>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={window.innerWidth * .95}
                                    height={300}
                                    data={this.renderProductsChart("S-TiAlN")}
                                    margin={{
                                        top: 10,
                                        right: 60,
                                        left: 20,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="InspectDate">
                                        <Label fontSize='20' value="S-TiAlN Thickness" position="top" />
                                    </XAxis>
                                    <YAxis domain={[0, 8]} />
                                    <Tooltip />
                                    <ReferenceLine y={8} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={4} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={6} stroke="blue" strokeWidth='.7' />
                                    <ReferenceLine y={4.05} stroke="blue" strokeWidth='.7' />
                                    <Line
                                        type="monotone"
                                        dataKey="thickness"
                                        stroke="#900C3F"
                                        strokeWidth='2'
                                        activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            <hr></hr>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={window.innerWidth * .95}
                                    height={300}
                                    data={this.renderProductsChart("S-TH")}
                                    margin={{
                                        top: 10,
                                        right: 60,
                                        left: 20,
                                        bottom: 10
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="InspectDate">
                                        <Label fontSize='20' value="S-TH Thickness" position="top" />
                                    </XAxis>
                                    <YAxis domain={[0, 8]} />
                                    <Tooltip />
                                    <ReferenceLine y={8} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={4} stroke="red" strokeWidth='.7' />
                                    <ReferenceLine y={6} stroke="blue" strokeWidth='.7' />
                                    <ReferenceLine y={4.05} stroke="blue" strokeWidth='.7' />
                                    <Line
                                        type="monotone"
                                        dataKey="thickness"
                                        stroke="#E67E22"
                                        strokeWidth='2'
                                        activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <Table compact color='blue' celled unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Coating</Table.HeaderCell>
                                    <Table.HeaderCell>Inspector</Table.HeaderCell>
                                    <Table.HeaderCell>InspectDate</Table.HeaderCell>
                                    <Table.HeaderCell>Thickness</Table.HeaderCell>
                                    <Table.HeaderCell>Adhesion</Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {this.renderProducts()}
                            </Table.Body>
                        </Table>


                    </div>
                </div>
            </DragDropFile>

        );
    }
}

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
    }
    suppress(evt) {
        evt.stopPropagation();
        evt.preventDefault();
    }
    onDrop(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        const files = evt.dataTransfer.files;
        if (files && files[0]) this.props.handleFile(files[0]);
    }
    render() {
        return (
            <div
                onDrop={this.onDrop}
                onDragEnter={this.suppress}
                onDragOver={this.suppress}
            >
                {this.props.children}
            </div>
        );
    }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.fileName = "";
    }
    handleChange(e) {
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {

            console.log(files[i].name);
            this.fileName = files[i].name;
            if (files && files[i]) this.props.handleFile(files[i]);
        }
    }

    render() {
        var job = sessionStorage.getItem("jobRole");
        return (

            <div> <Navbar selectedPage={"enterExcel"} />
                <form className="form-inline">
                {(job === 'Manager') && <div style={{ fontSize: '2em', padding: '2em' }} className="form-group">
                        <label htmlFor="file">Upload .xls or .xlsx Inspection Report: </label>
                        <input
                            type="file"
                            className="form-control"
                            id="file"
                            accept={SheetJSFT}
                            onChange={this.handleChange}
                            multiple/>
                    </div>}

                </form>
            </div>
        );
    }
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
/*class OutTable extends React.Component {
    render() {
        return (
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            {this.props.cols.map(c => (
                                <th key={c.key}>{c.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((r, i) => (
                            <tr key={i}>
                                {this.props.cols.map(c => (
                                    <td key={c.key}>{r[c.key]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}*/

/* list of supported file types */
const SheetJSFT = [
    "xlsx",
    "xlsb",
    "xlsm",
    "xls",
    "xml",
    "csv",
    "txt",
    "ods",
    "fods",
    "uos",
    "sylk",
    "dif",
    "dbf",
    "prn",
    "qpw",
    "123",
    "wb*",
    "wq*",
    "html",
    "htm"
]
    .map(function (x) {
        return "." + x;
    })
    .join(",");

/* generate an array of column objects */
const make_cols = refstr => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};
