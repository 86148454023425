import React from 'react';
import {Redirect} from 'react-router-dom'
import { Checkbox, Table, Button, Confirm, Header, Modal } from 'semantic-ui-react'

import hocify from 'hocify';
import viewEmployeesStyle from '../jss/viewEmployees';

const withStyles = hocify(viewEmployeesStyle);

class ViewEmployee extends React.Component {


    constructor(properties){
        super(properties);

        //set form values
        this.state = {
            employeeID: '',
            requestAll: false,
            queryData: '',
            confirmOpen: false,
            employeeToDelete: '',
            editOpen: false,
            newEmployeeID: '',
            newStatus: '',
            newFirstname: '',
            newLastname: ''
        };

        //bind event handlers
        this.handleChange = this.handleChange.bind(this);
        this.getEmployees = this.getEmployees.bind(this);

        //delete methods
        this.deleteEmployee = this.deleteEmployee.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);

        //update methods
        this.updateEmployee = this.updateEmployee.bind(this);
        this.updateCancel = this.updateCancel.bind(this);
        this.handleUpdateInput = this.handleUpdateInput.bind(this);
        this.submitEmployee = this.submitEmployee.bind(this);

        this.abortController = new AbortController();
    }

    handleChange(event){

        //update the value for which ever field was changed
        if(event.target.id === "employeeID"){
            this.setState({employeeID: event.target.value});
        }else if(event.target.id === "requestAll"){
            this.setState({requestAll: event.target.checked});
        }

    }

    async getEmployees(){

        let getAll = this.state.requestAll;
        let id;
        if(!getAll){
            id = this.state.employeeID;
        }else{
            id = "";
        }

        //make sure that the needed data has been entered
        if(getAll === false && id === ""){
            alert("Please enter an ID, or request all employees");
            return;
        }

        let EmployeeJSON = JSON.stringify({
            readAll: getAll,
            employeeID: id,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try{
        //send the request to the employee read page
        const requestPath = process.env.REACT_APP_SERVER_ROOT + "employee/read.php";
        let response = await fetch(requestPath, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: EmployeeJSON,
            signal: this.abortController.signal
            });

        //store the response
        let responseJSON = await response.json();
        if(responseJSON['status'] === 200){
            delete responseJSON["status"];
        }else{
            alert("Could not get employee(s)");
            return;
        }


        this.setState({queryData: responseJSON});

          } catch (error) {
              if(error.name === 'AbortError'){
              }
          }
        }

    componentWillUnmount(){
        this.abortController.abort();
    }

    //renders everything for controlling the page
    renderControls(){
        return(<div>

            <div className={this.props.container}>
                <div className={this.props.inlineContainer}>

                    <label>
                        Employee ID: {" "}
                        <input type="number" className={this.props.textBox} id="employeeID" name="employeeID" onChange={this.handleChange}/>
                    </label>


                    <Checkbox name="requestAll" id="requestAll" label='Get All Employees' onChange={this.handleChange}/>

                </div>

                <div>
                    <button type="submit" onClick={this.getEmployees}>Get Employees</button>
                </div>

                <hr />
            </div>

        </div>);
    }

    //prep deleting an employee
    deleteEmployee(event){

        //open the deletion dialog
        this.setState({confirmOpen: true});

        //store the employee to be deleted
        this.setState({employeeToDelete: event.currentTarget.dataset.id});
    }

    //send the request to delete the employee
    async handleDelete(){

        let EmployeeJSON = JSON.stringify({
            employeeID: this.state.employeeToDelete,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try{
        //send the delete request
        const requestPath = process.env.REACT_APP_SERVER_ROOT + "employee/delete.php";
        let response = await fetch(requestPath, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: EmployeeJSON,
            signal: this.abortController.signal
            });

        //process the response
        let responseJSON = await response.json();
        if(responseJSON['status'] === 200){
            alert("Employee Deleted Succesfully");
        }else{
            alert("Could not delete employee");
        }

        //render changes
        this.setState({confirmOpen: false});
        this.getEmployees();
      } catch (error) {
          if(error.name === 'AbortError'){
          }
      }
    }

    //the delete was cancled, close the dialog
    handleDeleteCancel(){
        this.setState({confirmOpen: false});
    }

    updateEmployee(event){

        if(this.state.queryData === null || this.state.queryData === ""){
            return;
        }

        //get the employee to update
        let employee = this.state.queryData[event.currentTarget.dataset.id];

        //store the data of the employee to edit
        this.setState({
            newEmployeeID: employee['username'],
            newStatus: employee['status'],
            newFirstname: employee['firstName'],
            newLastname: employee['lastName']
        });

        //set the flag to open the edit dialog
        this.setState({editOpen: true});
    }

    handleUpdateInput(event){
        //update the value for which ever field was changed
        if(event.target.id === "status"){
            this.setState({newStatus: event.target.value});
        }else if(event.target.id === "firstname"){
            this.setState({newFirstname: event.target.value});
        }else if(event.target.id === "lastname"){
            this.setState({newLastname: event.target.value});
        }
    }

    //updating has been cancled, close the dialog
    updateCancel(){
        this.setState({editOpen: false});
    }

    //send the updated employee to the db
    async submitEmployee(){

        let employee = JSON.stringify({
            employeeID: this.state.newEmployeeID,
            status: this.state.newStatus,
            firstname: this.state.newFirstname,
            lastname: this.state.newLastname
        });

        try{
          //send the updated data to the server
          const requestPath = process.env.REACT_APP_SERVER_ROOT + "employee.update.php";
          let response = await fetch(requestPath, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: employee,
              signal: this.abortController.signal
              });

          let responseJSON = await response.json();

          if(responseJSON['status'] === 200){
              alert("Employee Updated Succesfully");
          }else{
              alert("Could not update employee");
          }

          this.setState({editOpen: false});
          this.getEmployees();
      } catch (error) {
          if(error.name === 'AbortError'){
          }
      }
    }

    //creates an employee table to be rendered
    renderEmployees(){

        if(this.state.queryData === null || this.state.queryData === ""){
            return;
        }

        const employees = [];

        for(var i = 0; i < Object.keys(this.state.queryData).length; i++){
            let current = this.state.queryData[i];

            employees.push(<Table.Row key={i}>
                <Table.Cell>{current["username"]}</Table.Cell>
                <Table.Cell>{current["dateHired"]}</Table.Cell>
                <Table.Cell>{current["jobRole"]}</Table.Cell>
                <Table.Cell>{current["status"]}</Table.Cell>
                <Table.Cell>{current["firstName"]}</Table.Cell>
                <Table.Cell>{current["lastName"]}</Table.Cell>
                <Table.Cell>
                    <Button data-id={i} onClick={this.updateEmployee}>Update</Button>
                    <Button inverted color='red' data-id={current["username"]} onClick={this.deleteEmployee}>Delete</Button>
                </Table.Cell>
            </Table.Row>);
        }

        return employees;

    }

    render(){

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null){
            return(<Redirect to="/signin" />);
        }

        return(<div>
            {this.renderControls()}

            <div className={this.props.container}>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Username</Table.HeaderCell>
                            <Table.HeaderCell>Date Hired</Table.HeaderCell>
                            <Table.HeaderCell>Role</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>First Name</Table.HeaderCell>
                            <Table.HeaderCell>Last Name</Table.HeaderCell>
                            <Table.HeaderCell>Controls</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.renderEmployees()}
                        <Confirm
                            open={this.state.confirmOpen}
                            content='Are you sure you want to delete the employee?'
                            onCancel={this.handleDeleteCancel}
                            onConfirm={this.handleDelete}
                        />
                        <Modal
                            open={this.state.editOpen}
                            onClose={this.updateCancel}
                            size='large'
                        >
                            <Header icon='browser' content='Edit Employee' />
                            <Modal.Content>
                                Status: <input type="text" className={this.props.textBox} value={this.state.newStatus} onChange={this.handleUpdateInput} id="status" name="status"/>
                                First Name: <input type="text" className={this.props.textBox} value={this.state.newFirstname} onChange={this.handleUpdateInput} id="firstname" name="firstname"/>
                                Last Name: <input type="text" className={this.props.textBox} value={this.state.newLastname} onChange={this.handleUpdateInput} id="lastname" name="lastname"/>
                            </Modal.Content>
                            <Modal.Actions>
                            <Button onClick={this.updateCancel}>Cancel</Button>
                            <Button onClick={this.submitEmployee}>Update</Button>
                            </Modal.Actions>
                        </Modal>
                    </Table.Body>
                </Table>
            </div>
        </div>);
    }

}

export default withStyles(ViewEmployee);
