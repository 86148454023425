import React from 'react';
import { Redirect, Link } from 'react-router-dom'
import { Table, Button, Grid } from 'semantic-ui-react';
import Navbar from './navbar';

import hocify from 'hocify';
import enterProductStyle from '../jss/enterProduct';

const withStyles = hocify(enterProductStyle);

class EnterCuttingTools extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            response: null,
            codes: null,
            Processes: null,
            latest: " "
        }

        this.uploadFile = this.uploadFile.bind(this);
        this.getLatestProduct = this.getLatestProduct.bind(this);
        this.setUnscanned = this.setUnscanned.bind(this);
        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");

        this.abortController = new AbortController();
    }

    async uploadFile(event) {

        event.preventDefault();

        const form = new FormData(document.querySelector("#fileUpload"));
        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "/product/create.php", {
                method: "POST",
                body: form,
                signal: this.abortController.signal,
                token: sessionStorage.getItem("token"),
                jobRole: sessionStorage.getItem("jobRole")
            });
            let responseJSON = await response.json();
            this.setState({ response: responseJSON });

            this.getCodeData();
            this.setUnscanned();
        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async setUnscanned() {

        //get the json ready to be sent
        let queryBody = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/setUnscannedto1.php", {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: queryBody,
                signal: this.abortController.signal
            });


            let responseJSON = await response.json();
            //console.log(responseJSON);
            this.state.latest = responseJSON;

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async getLatestProduct() {

        //get the json ready to be sent
        let queryBody = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/getLatestProduct.php", {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: queryBody,
                signal: this.abortController.signal
            });


            let responseJSON = await response.json();
            //console.log(responseJSON);
            this.state.latest = responseJSON;

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }


    async getCodeData() {

        //get the json ready to be sent
        let queryBody = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/readAllCodes.php", {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: queryBody,
                signal: this.abortController.signal
            });

            let responseJSON = await response.json();
            console.log(responseJSON);

            if (responseJSON['status'] === 200) {
                delete responseJSON["status"];
            }

            if (responseJSON['status'] === 201) {
                return;
            }

            this.setState({
                codes: responseJSON
            });

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }


    }

    componentDidMount() {
        //send all onmessage events to the sse receive method
        this.processEvtSource.onmessage = e => this.receiveProcessSSEData(e);
        this.getLatestProduct();
        this.setUnscanned();
    }


    componentWillUnmount() {
        this.abortController.abort();
        this.processEvtSource.close();
    }

    receiveProcessSSEData(e) {
        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    failedProducts() {

        if (this.state.response === null) {
            return ("");
        }

        const failed = []
        for (let i = 0; i < Object.keys(this.state.response).length; i++) {
            if (this.state.response[i]["status"] === 582) {
                failed.push(<Table.Row key={i}>
                    <Table.Cell>{this.state.response[i]["productID"]}</Table.Cell>
                    <Table.Cell>{this.state.response[i]["error"]}</Table.Cell>
                </Table.Row>);
            }
        }

        //no products failed, so don't need to render anything
        if (failed.length === 0) {
            return ("");
        }

        return (<div className={this.props.container + " " + this.props.printHide}>
            <h2>Failed Entries</h2>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Product ID</Table.HeaderCell>
                        <Table.HeaderCell>Error Message</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {failed}
                </Table.Body>
            </Table>
        </div>);

    }

    //this.state.codes
    // MA11:
    //     QRCode: "\resources\qrCodes\products\P5f9b33388b75a.png"
    //     coating: "Test"
    //     customer: "Toyota"
    //     process: null
    //     productID: "MA11"
    //     productName: "1"
    //     quantity: "5"
    //     weight: null

    //this.state.response
    // 0:
    //     QRCode: "\resources\qrCodes\products\P5f9b33a93c86e.png"
    //     productID: "MA15"
    //     status: 200

    enteredProducts() {

        if (this.state.response === null || this.state.codes === null || this.state.Processes === null) {
            return ("");
        }

        const succeeded = []
        for (let i = 0; i < Object.keys(this.state.response).length; i++) {
            if (this.state.response[i]["status"] === 200) {

                let currentProductID = this.state.response[i]["productID"];
                let processStages = null;
                let processID = this.state.codes[currentProductID].process;
                let stageIDs = this.state.Processes[processID].stageIDs;

                processStages = "";
                for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                    processStages += stageIDs[i + 1];

                    if (i !== Object.keys(stageIDs).length - 1) {
                        processStages += ", ";
                    }
                }

                succeeded.push(<Table.Row key={i}>
                    <Table.Cell className={this.props.printHide}>{currentProductID}</Table.Cell>
                    <Table.Cell className={this.props.printHide}><a target="_blank" href={process.env.REACT_APP_SERVER_ROOT + this.state.response[i]["QRCode"]}>{process.env.REACT_APP_SERVER_ROOT + this.state.response[i]["QRCode"]}</a></Table.Cell>
                    <Table.Cell>
                        <Grid.Column>
                            <div className={this.props.qrcodeDiv}>
                                <Grid columns={2} padded>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <img src={process.env.REACT_APP_SERVER_ROOT + this.state.response[i]["QRCode"]} alt="A QR Code to be scanned to select a machine, product, rework, or employee" />
                                            <span className={this.props.printable}><h4>Product {currentProductID}</h4></span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span className={this.props.printable + " " + this.props.qrcodeData}>
                                                Product Name: {this.state.codes[currentProductID].productName}<br />
                                                Customer: {this.state.codes[currentProductID].customer}<br />
                                                Coating: {this.state.codes[currentProductID].coating}<br />
                                                Quantity: {this.state.codes[currentProductID].quantity}<br />
                                                PO Number: {this.state.codes[currentProductID].PONumber}<br />
                                                Process: {processStages}<br />
                                            </span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>
                    </Table.Cell>
                    <Table.Cell className={this.props.printHide}>{<Button onClick={() => {
                        window.print();
                    }}>Print</Button>}</Table.Cell>
                </Table.Row>);
            }
        }


        //no products succeeded, so don't need to render anything
        if (succeeded.length === 0) {
            return ("");
        }

        return (<div>

            <h2 className={this.props.printHide}>Successful Entries</h2>
            <Table className={this.props.printHide} celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Product ID</Table.HeaderCell>
                        <Table.HeaderCell>QR Code Link</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {succeeded}
                </Table.Body>
            </Table>

            <span className={this.props.printable}>{succeeded}</span>
        </div>);

    }

    render() {

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager' && job !== 'Employee') {
            return (<Redirect to="/signin" />);
        }


        return (
            <div>

                <div className={this.props.printHide}>
                    <Navbar selectedPage={"enterProduct"} />

                    <div className={this.props.container + " blueBackground"}>
                        <label className="uploadFileDesc" style={{ marginTop: "1em" }}>Cutting Tool Upload</label>
                        <label className="uploadFileDesc" style={{ marginTop: "1em" }}>Most recent ProductID: {this.state.latest}</label>
                        <label className="uploadFileDesc">Select a CSV file to upload</label>


                        <form method="POST" encType="multipart/form-data" id="fileUpload" name="fileUpload" onChange={this.uploadFile}>
                            <label class="button" for="file">Select File & Upload</label>
                            <input type="file" name="file" id="file" accept=".csv" />
                        </form>
                        <Button as={Link} to='/cuttingTools'>
                            Entering Cutting Tools
                        </Button>
                    </div>

                    {this.failedProducts()}

                </div>

                {this.enteredProducts()}
            </div>

        );
    }
}

export default withStyles(EnterCuttingTools);
