import React from 'react';
import {Redirect} from 'react-router-dom'
import { Table, Message, Icon } from 'semantic-ui-react';
import Navbar from './navbar';

import hocify from 'hocify';
//import containerStyle from '../jss/containers';
import enterProductStyle from '../jss/enterProduct';
import template from '../resources/uploadTemplate.csv';

const withStyles = hocify(enterProductStyle);

class EnterProduct2 extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            response: null,
            codes: null,
            Processes: null
        }

        this.uploadFile = this.uploadFile.bind(this);

        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");

        this.abortController = new AbortController();
    
        this.setUnscanned = this.setUnscanned.bind(this);

        this.setProductWeights = this.setProductWeights.bind(this);
    }

    async uploadFile(event){

        event.preventDefault();

        const form = new FormData(document.querySelector("#fileUpload"));
        try{
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT  + "/product/create2.php", {
                method: "POST",
                body: form,
                signal: this.abortController.signal,
                token: sessionStorage.getItem("token"),
                jobRole: sessionStorage.getItem("jobRole")
                });
            //json format
            //[{"status":582,"error":"Duplicate entry '1009' for key 'PRIMARY'"},{"status":200,"QRCode":"\\resources\\qrCodes\\P5f1091d54dab7.png"}]
            let responseJSON = await response.json();
            this.setState({response: responseJSON});
            alert("Products were entered successfully");
            this.setProductWeights();
            this.setUnscanned();

      } catch (error) {
          if(error.name === 'AbortError'){
          }
      }
    }


    componentDidMount(){
        //send all onmessage events to the sse receive method
        this.processEvtSource.onmessage = e => this.receiveProcessSSEData(e);
    }

    componentWillUnmount(){
        this.abortController.abort();
        this.processEvtSource.close();
    }

    async setUnscanned() {

        //get the json ready to be sent
        let queryBody = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/setUnscannedto1.php", {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: queryBody,
                signal: this.abortController.signal
            });


            let responseJSON = await response.json();
            //console.log(responseJSON);
            this.state.latest = responseJSON;

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

        //get the product data from the db
        async setProductWeights() {
            //encode the token, productID if not requesting all products, or if all products are being requested
            let productJSON = JSON.stringify({
                token: sessionStorage.getItem("token"),
                jobRole: sessionStorage.getItem("jobRole"),
            });
    
            try {
                //send the request data to the product stat read page
                const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/setProductWeights.php";
                let response = await fetch(requestPath, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: productJSON,
                    signal: this.abortController.signal
                });
    
                //process the response
                let responseJSON = await response.json();
                if (responseJSON['status'] === 200) {
                    //remove the status element from the returned json, to allow eaiser process of the data
                    delete responseJSON['status'];
    
                    //store the product data in the state
                    this.setState({
                        QueryData: responseJSON
                    });
    
                } else {
                    //alert("Could not get product(s)");
                }
    
            } catch (error) {
                if (error.name === 'AbortError') {
                }
            }
        }

    receiveProcessSSEData(e)
    {
        let message = JSON.parse(e.data);

        if (message["status"] === 200)
        {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes))
            {
                this.setState({Processes: message});
            }
        }
    }

    failedProducts(){

        if(this.state.response === null){
            return("");
        }

        const failed = []
        for(let i = 0; i < Object.keys(this.state.response).length; i++){
            if(this.state.response[i]["status"] === 582){
                failed.push(<Table.Row key={i}>
                    <Table.Cell>{this.state.response[i]["productID"]}</Table.Cell>
                    <Table.Cell>{this.state.response[i]["error"]}</Table.Cell>
                </Table.Row>);
            }
        }

        //no products failed, so don't need to render anything
        if(failed.length === 0){
            return("");
        }

        return(<div className={this.props.container + " " + this.props.printHide}>
            <h2>Failed Entries</h2>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Product ID</Table.HeaderCell>
                        <Table.HeaderCell>Error Message</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {failed}
                </Table.Body>
            </Table>
        </div>);

    }

    enteredProducts(){

        if(this.state.response === null || this.state.codes === null || this.state.Processes === null){
            return("");
        }
        const succeeded = []
        for(let i = 0; i < Object.keys(this.state.response).length; i++){
            if(this.state.response[i]["status"] === 200){

                let currentProductID = this.state.response[i]["productID"];
                let processStages = null;
                let processID = this.state.codes[currentProductID].process;
                let stageIDs = this.state.Processes[processID].stageIDs;
                
                processStages = "";
                for(let i = 0; i < Object.keys(stageIDs).length; i++){
                    processStages += stageIDs[i+1];
                   
                    if(i !== Object.keys(stageIDs).length - 1){
                        processStages += ", ";
                    }
                }
            }
        }

        //no products succeeded, so don't need to render anything
        if(succeeded.length === 0){
            return("");
        }
        return("");

    }

    render(){

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null){
            return(<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager')
        {
            return(<Redirect to="/signin" />);
        }

        return(
            <div>

                <div className={this.props.printHide}>
                    <Navbar selectedPage={"enterProduct2"}/>

                    <div className={this.props.container + " " + "blueBackground"}>
                    <Message icon size='massive' negative><Icon name='warning sign'/><Message.Header>Warning: Only upload sub-products here</Message.Header></Message>
                    <div style={{ textAlign: "center" }}><a href={template} download="template.csv" >Download .csv template</a></div>
                        <label className="uploadFileDesc">Upload sub-products (for large orders)</label>

                        <form method="POST" encType="multipart/form-data" id="fileUpload" name="fileUpload" onSubmit={this.uploadFile}>
                            <label class="button" for="file">Select File</label>
                            <input type="file" name="file" id="file" accept=".csv"/>
                            <button type="submit" name="Upload">Upload</button>
                        </form>
                    </div>

                    {this.failedProducts()}

                </div>

                {this.enteredProducts()}

            </div>
        );
    }
}

export default withStyles(EnterProduct2);
