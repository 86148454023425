import React from 'react';
import Navbar from './navbar';
import {Redirect} from 'react-router-dom'
import {Table, Checkbox} from 'semantic-ui-react'


import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import productViewStyle from '../jss/productView';
import containersStyle from '../jss/containers';

const withStyles = hocify(() => {
  const productView = productViewStyle();
  const containers = containersStyle();

  return { productView, containers };
});


class ProductView extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            dategroup: 'daily',
            QueryData: null,
            getAll: false,
            customRange: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.customDateChange = this.customDateChange.bind(this);

        this.abortController = new AbortController();
    }

    handleChange(event){

        //update the value for which ever field was changed
        if(event.target.id === "dategroup"){
            this.setState({dategroup: event.target.value});
        }else if(event.target.id === "getAll"){
            this.setState({getAll: event.target.checked});
        }
    }

    async getProducts(event){

        let ProductJSON = JSON.stringify({
            orderPeriod: this.state.dategroup,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.getAll,
            customRange: this.state.customRange
        });

        try{
          //send the request to recieve the products
          const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productView/read.php";
          let response = await fetch(requestPath, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: ProductJSON,
              signal: this.abortController.signal
              });

          let responseJSON = await response.json();

          if(responseJSON['status'] === 200){
              delete responseJSON['status'];
              this.setState({
                  QueryData: responseJSON
              });
          }else{
              alert("Could not fetch data!");
          }

      } catch (error) {
          if(error.name === 'AbortError'){
          }
      }
    }

    customDateChange(event){
        this.setState({customRange: event.target.value});
    }

    renderCustomDateControls(){

        const controls = []

        if(this.state.dategroup === "custom"){
            controls.push("Number of days to search: ");
            controls.push(<input key="0" type="number" onChange={this.customDateChange}></input>);
        }

        return(<div>
            {controls}
        </div>);
    }

    componentDidMount()
    {
        this.getProducts();
    }

    componentWillUnmount(){
        this.abortController.abort();
    }

    render(){

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null){
            return(<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager')
        {
            return (<Redirect to="/signin" />);
        }

        const products = []

        //don't render if query data hasn't been gotten set yet
        if(this.state.QueryData != null){
            for(let index = 0; index < Object.keys(this.state.QueryData).length; index++){
                let value = this.state.QueryData[index];
                products.push(<Table.Row key={index}>
                    <Table.Cell>{value["Employee"]}</Table.Cell>
                    <Table.Cell>{value["Product"]}</Table.Cell>
                    <Table.Cell>{value["Machine"]}</Table.Cell>
                    <Table.Cell>{value["DateAdded"]}</Table.Cell>
                </Table.Row>);
            }
        }

        return(<div>
            <Navbar selectedPage="productView" />

            <div className={this.props.containers.container + " " + "blueBackground"}>
                <label>Time Period:{" "}</label>
                <select
                    id="dategroup" name="dategroup"
                    value={this.state.dategroup}
                    onChange={this.handleChange}
                    className={this.props.containers.textBox}
                >
                    <option value="daily">Past 24 hours</option>
                    <option value="weekly">This Week</option>
                    <option value="monthly">This Month</option>
                    <option value="custom">Custom Range</option>
                </select>

                {this.renderCustomDateControls()}

                <div className={this.props.productView.requestAll}>
                    <Checkbox name="getAll" id="getAll" label='Request All Products' onChange={this.handleChange}/>
                </div>

                <button type="submit" onClick={this.getProducts}>Get Products</button>

                <hr />
            </div>

            <Table celled unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Employee</Table.HeaderCell>
                        <Table.HeaderCell>Product ID</Table.HeaderCell>
                        <Table.HeaderCell>Machine ID</Table.HeaderCell>
                        <Table.HeaderCell>Time Added</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {products}
                </Table.Body>
            </Table>

        </div>);

    }

}

export default withStyles(ProductView);
