import React, { createRef } from 'react';
import Navbar from './navbar';
import { Form } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SignInMobile/SignIn.css';
import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import signinStyle from '../jss/signin';

//css but in json form written in js
const withStyles = hocify(signinStyle);


class Signin extends React.Component {

    constructor(properties) {
        super(properties);

        //set form values
        this.state = {
            QRCode: '',
            jobRole: '',
            flag: '0'
        };

        // setting up service worker
        
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', function() {
              navigator.serviceWorker.register('/serviceWorker.js', {scope: '/signintest'}).then(function(registration) {
                // Registration was successful
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
              }, function(err) {
                // registration failed :(
                console.log('ServiceWorker registration failed: ', err);
              });
            });
          }

        //setup event handlers
        this.handleChange = this.handleChange.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.getUserJobRole = this.getUserJobRole.bind(this);
        this.abortController = new AbortController();
    }

    componentDidMount() {
        //Put password at end of url:
        //#A:Employee:1190:1:Employee
        var hashParams = window.location.hash.substr(1)
        console.log(hashParams);
        if (hashParams.length > 0) {
            this.state.QRCode = decodeURIComponent(hashParams);
        }
    }

    async login() {

        //{scannerid}:{type}:{username}:{password}:{role}
        let loginComponents = this.state.QRCode.split(":");
        console.log(loginComponents.length);
        var loginJSON = "";
        //Allow quick login
        loginComponents[0] = loginComponents[0].toLowerCase();
        if (loginComponents[0] === "emp") {
            loginComponents[0] = "A";
            loginComponents[1] = "Employee";
            loginComponents[2] = "1190";
            loginComponents[3] = "1";
            loginComponents[4] = "Employee";
            this.setState({ flag: 1 }); //auto redirect to dashboard when logging in with "emp"
        }
        if (loginComponents.length === 3) {
            loginJSON = JSON.stringify({
                scannerID: "",
                determiner: "",
                username: loginComponents[0],
                password: loginComponents[1],
                jobRole: loginComponents[2]
            });
        }

        else if (loginComponents.length === 2) {
            await this.getUserJobRole(loginComponents[0], loginComponents[1]);
            console.log(this.state.jobRole);
            //auto redirect every user to dashboard
            if (this.state.jobRole != null) {
                this.setState({ flag: 1 });
            }
            loginJSON = JSON.stringify({
                scannerID: "",
                determiner: "",
                username: loginComponents[0],
                password: loginComponents[1],
                jobRole: this.state.jobRole
            });
        }


        else {
            loginJSON = JSON.stringify({
                scannerID: loginComponents[0],
                determiner: loginComponents[1],
                username: loginComponents[2],
                password: loginComponents[3],
                jobRole: loginComponents[4]
            });
        }

        this.setState({ QRCode: "" });

        try {
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "login.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: loginJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON.hasOwnProperty('message')) {
                alert(responseJSON['message']);
            }
            if (responseJSON.hasOwnProperty('token')) {
                sessionStorage.setItem('token', responseJSON['token']);
                sessionStorage.setItem('jobRole', responseJSON['jobRole']);
                this.forceUpdate();
                //TODO use local storage if want remembered
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async getUserJobRole(userName, pass) {
        let roleJSON = JSON.stringify({
            password: pass,
            username: userName
        });

        try {
            //send the token of the user to log out
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "getRole.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: roleJSON,
                signal: this.abortController.signal
            });

            let responseJSON = await response.json();
            this.setState({ jobRole: responseJSON[0].JobRole })

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }



    async logout() {

        let tokenJSON = JSON.stringify({
            token: sessionStorage.getItem("token")
        });

        try {
            //send the token of the user to log out
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "logout.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: tokenJSON,
                signal: this.abortController.signal
            });

            let responseJSON = await response.json();

            //if logged out ok, delete the token and reload the page
            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 200) {
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('jobRole');
                this.forceUpdate();
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    handleChange(event) {
        this.setState({ QRCode: event.target.value });

    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    const 

    render() {
        // if (isMobile) {
        //     return (
        //         <Redirect to="/" />
        //     );
        // }

        if (sessionStorage.getItem('token') !== null && this.state.flag == 1) {
            return (<Redirect to="/dashboardView" />);

        }
        else if (sessionStorage.getItem('token') !== null && this.state.flag == 0) {

            return (<div>
                <Navbar selectedPage={"signin"} />

                <div className={this.props.container}>
                    <input className="buttonLogout" type="button" value="Log Out" onClick={this.logout} />
                </div>

            </div>);
        }
        else {
            return (
                <div>
                    <Navbar selectedPage={"signin"} />

                    <div className={this.props.container}>
                        <label className="greeting" for="greeting">Scan QR code or Enter Login Information</label>
                        <Form id="passwordInput">
                            <input ref={(input) => { this.scanInput = input; }} type="text" name="QRCode" value={this.state.QRCode} onChange={this.handleChange} />
                            <input className="buttonLogin" type="submit" name="Submit" onClick={this.login} />
                        </Form>

                    </div>

                </div>
            );
        }

    }

}

export default withStyles(Signin);
