import {createUseStyles} from 'react-jss';

const fontStyle = createUseStyles({
    '@font-face': {
        fontFamily: 'AccuratistFont',
        src: 'url(Fonts/AccuratistFont/Accuratist.otf)'
    },
    Accuratist: {
         fontFamily: 'AccuratistFont'
     }
})

export default  fontStyle;
