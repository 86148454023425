import React, { createRef } from 'react';
import Navbar from './navbar';
import {Redirect} from 'react-router-dom';
import { Table, Dropdown } from 'semantic-ui-react';

import hocify from 'hocify';
import containerStyle from '../jss/containers';

const withStyles = hocify(containerStyle);

class MachineStatistics extends React.Component {
  inputRef = createRef()

  constructor(properties){
      super(properties);

      //set form values
      this.state = {
          data: null,
          selectedData: null,
          selectedID: 'Request All',
          machineID: ''
      };

      //setup event handlers
      this.getMachines = this.getMachines.bind(this);
      this.handleSelected = this.handleSelected.bind(this);
      
      this.abortController = new AbortController();
  }

  componentDidMount(){
      this.getMachines();
  }

    async getMachines(){

        let machineJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: true,
            machineID: this.state.machineID
        });

        try{
          let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "analytics/machineStatistics/read.php ", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: machineJSON,
              signal: this.abortController.signal
              });
          let responseJSON = await response.json();
          if(responseJSON['status'] === 200){
              delete responseJSON['status'];
              this.setState({
                  /*QueryData: responseJSON*/
                  data: responseJSON
              });
          }else{
              alert("Could not get machine(s)");
        }

      } catch (error) {
          if(error.name === 'AbortError'){
          }
      }

    }

    componentWillUnmount(){
        this.abortController.abort();
    }

    //updates the current item in the dropdown
    handleSelected(event, {name}){

        let index;

        //find the index of the element with the slected id
        for(let i = 0; i < Object.keys(this.state.data).length; i++){
            if(this.state.data[i]["MachineID"] === name){
                index = i;
                break;
            }
        }

        this.setState({
            selectedID: name,
            selectedData: this.state.data[index]
        });
    }

    buildDropDown(){

        if(this.state.data == null)
        {
            return(<div></div>);
        }

        const dropdownItems = [];

        //add a request all option before adding the employee ids
        dropdownItems.push(<Dropdown.Item
            key={0}
            name={"Request All"}
            active={this.state.selectedID === "Request All"}
            onClick={this.handleSelected}
        >
            Request All
        </Dropdown.Item>);

        for(let i = 0; i < Object.keys(this.state.data).length; i++){
            dropdownItems.push(<Dropdown.Item
                key={i + 1}
                name={this.state.data[i]["MachineID"]}
                active={this.state.selectedID === this.state.data[i]["MachineID"]}
                onClick={this.handleSelected}
            >
                {this.state.data[i]["MachineID"]}
            </Dropdown.Item>);
        }

        return(<Dropdown item text={this.state.selectedID}>
            <Dropdown.Menu>
                {dropdownItems}
            </Dropdown.Menu>
        </Dropdown>);
    }


    //everything needed for entering data
    renderControls()    {

            //if the token is not present, the user is not signed in send them to signin
            if(sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null){
                return(<Redirect to="/signin" />);
            }
            var job = sessionStorage.getItem("jobRole");
            if (job !== 'Manager')
            {
                return (<Redirect to="/signin" />);
            }

            return(<div>
                <Navbar selectedPage={"machineStatistics"}/>

                <div className={this.props.container + " " + "blueBackground"}>
                    <div className={this.props.inlineContainer}>
                        <label>
                            MachineID: {" "}
                            {this.buildDropDown()}
                        </label>
                    </div>

                    <hr/>
                </div>
            </div>);
    }

    //renders machine data retrived from the db
    /*renderMachines(){

        if(this.state.QueryData == null){
            return("");
        }

        const machines = []

        for(let index = 0; index < Object.keys(this.state.QueryData).length; index++){
            let value = this.state.QueryData[index];
            machines.push(<Table.Row key={index}>
                <Table.Cell>{value["MachineID"]}</Table.Cell>
                <Table.Cell>{value["NumberOfTimesUsed"]}</Table.Cell>
            </Table.Row>);
        }

        return(machines);

    }*/

    renderMachines()
    {
        if(this.state.data == null)
        {
            return(<div></div>);
        }

        const hours = [];

        if(this.state.selectedID === "Request All"){ //if we want all the rows to show, use the json we got from the server without filtering
            for(let index = 0; index < Object.keys(this.state.data).length; index++)
            {
                let value = this.state.data[index];
                hours.push(<Table.Row key={index}>
                  <Table.Cell>{value["MachineID"]}</Table.Cell>
                  <Table.Cell>{value["NumberOfTimesUsed"]}</Table.Cell>
                </Table.Row>);
            }
        }else{ //just show the filtered row

            if(this.state.selectedData == null)
            {
                return(<div></div>);
            }

            let value = this.state.selectedData;
            hours.push(<Table.Row key={0}>
              <Table.Cell>{value["MachineID"]}</Table.Cell>
              <Table.Cell>{value["NumberOfTimesUsed"]}</Table.Cell>
            </Table.Row>);

        }

        return(hours);
    }

    render(){

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null){
            return(<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager')
        {
            return (<Redirect to="/signin" />);
        }

        return(<div>
            {this.renderControls()}
            <div className={this.props.container + " " + "blueBackground"}>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Machine ID</Table.HeaderCell>
                            <Table.HeaderCell>Times Used</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.renderMachines()}
                    </Table.Body>
                </Table>
            </div>
        </div>);
    }

}

export default withStyles(MachineStatistics);
