import {createUseStyles} from 'react-jss';

const createProcessStyle = createUseStyles({

processName: {
    marginRight: '1%'
},
stageCounter: {
    marginRight: '1%'
},
stageRow: {
    marginBottom: '1%'
},
modalOpenLink: {
    cursor: 'pointer',
    color: 'CornflowerBlue',
    textDecoration: 'underline',
    fontSize: '16px'
}

});

export default createProcessStyle;
