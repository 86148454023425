import React from 'react';
import Navbar from './navbar';
import { Redirect } from 'react-router-dom';
import ExportCSV from './reports/exportCSV';
import { Table, Form, Radio} from 'semantic-ui-react';

import hocify from 'hocify';
import containerStyle from '../jss/containers';

const withStyles = hocify(containerStyle);

class productStatisticsSubArchive extends React.Component {


    constructor(properties) {
        super(properties);

        //set form values
        this.state = {
            productID: '',
            requestAll: true,
            QueryData: null,
            Processes: null,
            typeChoice: 1
        };

        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");

        this.handleChange = this.handleChange.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.setTypeChoice = this.setTypeChoice.bind(this);
        this.setTypeChoice = (e, { value }) => this.setState({ value })

        this.abortController = new AbortController();
    }

    handleChange(event) {

        if (event !== "") {
            this.setState({ requestAll: false });
        }
        else {
            this.setState({ requestAll: true });
        }
        this.setState({ productID: event }, this.getProducts);
    }

    setTypeChoice(event) {
        this.setState({typeChoice: event.target.value});
    }

    //get the product data from the db
    async getProducts() {

        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/read2Archive.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];
                //console.log(responseJSON);
                //store the product data in the state
                this.setState({
                    QueryData: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }


    componentDidMount() {
        //send all onmessage events to the sse receive method
        this.processEvtSource.onmessage = e => this.receiveProcessData(e);
        var hashParams = window.location.hash.substr(1)
        //console.log(hashParams);
        if (hashParams.length > 0) {
            this.handleChange(decodeURIComponent(hashParams));
        }

        this.getProducts();
    }

    componentWillUnmount() {
        this.processEvtSource.close();
        this.abortController.abort();
    }

    receiveProcessData(e) {
        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    //renders everything for controlling the page
    renderControls() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div><Navbar selectedPage="productStatisticsSubArchive" /></div>);
        }

        //convert the query data into a format the csv export wants
        let json = this.state.QueryData;

        let csvJson = {};

        //move all the json values into keys with the same name as the table
        for (let i = 0; i < Object.keys(json).length; i++) {
            csvJson[i] = {};

            csvJson[i]["Product ID"] = json[i]["ProductID"];
            csvJson[i]["Customer"] = json[i]["Customer"];
            csvJson[i]["Delivery Date"] = json[i]["DeliveryDate"];
            csvJson[i]["Received Date"] = json[i]["ReceivedDate"];
            csvJson[i]["Material"] = json[i]["Material"];
            csvJson[i]["Product Name"] = json[i]["ProductName"];
            csvJson[i]["Quantity"] = json[i]["Quantity"];
            csvJson[i]["Product Weight"] = json[i]["ProductWeight"];
            csvJson[i]["Shipment Schedule"] = json[i]["ShipmentSchedule"];
            csvJson[i]["PO Number"] = json[i]["PONumber"];
            csvJson[i]["Process"] = json[i]["Process"];
            csvJson[i]["Number of Items"] = json[i]["NumberOfItems"];
            csvJson[i]["Coating"] = json[i]["Coating"];
            csvJson[i]["Notes"] = json[i]["Notes"];

        }

        //console.log(csvJson[0]);

        //switch from the process id to a list of stages
        for (let i = 0; i < Object.keys(csvJson).length; i++) {
            //get stages
            let stageIDs = this.state.Processes[csvJson[i].Process].stageIDs

            let processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }

            csvJson[i]["Process"] = processStages;

        }

        return (<div>
            <Navbar selectedPage="productStatisticsSubArchive" />

            <div className={this.props.container + " " + "blueBackground"}>
                <div className={this.props.inlineContainer}>
                    <ExportCSV
                        csvData={Object.values(csvJson)}
                        fileName={"productStatistics_" + new Date().toJSON().split("T")[0]}
                    />
                </div>
            </div>
        </div>);
    }

    renderProducts() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const products = [];
        //add each product from the db to the table
        for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
            let value = this.state.QueryData[index];

            let processStages = null;

            let processID = value["Process"];

            //get stages
            let stageIDs = this.state.Processes[processID].stageIDs

            processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }

            let bgColor = 'black';
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd;


            if (this.state.typeChoice === "cutting tools" && value.ProductWeight == null) {
                products.push(<Table.Row key={index}>
                    <Table.Cell>{value["ProductID"]}</Table.Cell>
                    <Table.Cell>{value["Customer"]}</Table.Cell>
                    <Table.Cell>{value["DeliveryDate"]}</Table.Cell>
                    <Table.Cell>{value["ReceivedDate"]}</Table.Cell>
                    <Table.Cell>{value["Material"]}</Table.Cell>
                    <Table.Cell>{value["ProductName"]}</Table.Cell>
                    <Table.Cell>{value["Quantity"]}</Table.Cell>
                    <Table.Cell>{value["ProductDiameter"]}</Table.Cell>
                    <Table.Cell>{value["ProductLength"]}</Table.Cell>
                    <Table.Cell style={{ color: bgColor }}>{value["ShipmentSchedule"]}</Table.Cell>
                    <Table.Cell>{value["PONumber"]}</Table.Cell>
                    <Table.Cell>{processStages}</Table.Cell>
                    <Table.Cell>{value["NumberOfItems"]}</Table.Cell>
                    <Table.Cell>{value["Coating"]}</Table.Cell>
                    <Table.Cell>{value["Notes"]}</Table.Cell>
                </Table.Row>);
            }
            else if (this.state.typeChoice === "dies"&& value.ProductWeight != null) {
                products.push(<Table.Row key={index}>
                    <Table.Cell>{value["ProductID"]}</Table.Cell>
                    <Table.Cell>{value["Customer"]}</Table.Cell>
                    <Table.Cell>{value["DeliveryDate"]}</Table.Cell>
                    <Table.Cell>{value["ReceivedDate"]}</Table.Cell>
                    <Table.Cell>{value["Material"]}</Table.Cell>
                    <Table.Cell>{value["ProductName"]}</Table.Cell>
                    <Table.Cell>{value["Quantity"]}</Table.Cell>
                    <Table.Cell>{value["ProductWeight"]}</Table.Cell>
                    <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                    <Table.Cell>{value["PONumber"]}</Table.Cell>
                    <Table.Cell>{processStages}</Table.Cell>
                    <Table.Cell>{value["NumberOfItems"]}</Table.Cell>
                    <Table.Cell>{value["Coating"]}</Table.Cell>
                    <Table.Cell>{value["Notes"]}</Table.Cell>
                </Table.Row>);
            }
            else if (this.state.typeChoice !== 'dies' && this.state.typeChoice !== 'cutting tools') {
                products.push(<Table.Row key={index}>
                    <Table.Cell>{value["ProductID"]}</Table.Cell>
                    <Table.Cell>{value["Customer"]}</Table.Cell>
                    <Table.Cell>{value["DeliveryDate"]}</Table.Cell>
                    <Table.Cell>{value["ReceivedDate"]}</Table.Cell>
                    <Table.Cell>{value["Material"]}</Table.Cell>
                    <Table.Cell>{value["ProductName"]}</Table.Cell>
                    <Table.Cell>{value["Quantity"]}</Table.Cell>
                    {value["ProductWeight"] !== null &&
                    <Table.Cell>{value["ProductWeight"]}</Table.Cell>}
                    {value["ProductWeight"] === null &&
                    <Table.Cell>Cutting Tool</Table.Cell>}
                    <Table.Cell style={{ color: bgColor }}>{value["ShipmentSchedule"]}</Table.Cell>
                    <Table.Cell>{value["PONumber"]}</Table.Cell>
                    <Table.Cell>{processStages}</Table.Cell>
                    <Table.Cell>{value["NumberOfItems"]}</Table.Cell>
                    <Table.Cell>{value["Coating"]}</Table.Cell>
                    <Table.Cell>{value["Notes"]}</Table.Cell>
                </Table.Row>);
            }
        }

        //console.log(products);
        if (products.length === 0) {
            products.push(<Table.Row key={0}>
                <Table.Cell>No products found.</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
            </Table.Row>);
            return (products);
        }

        return (products);

    }


    render() {

        const ddChoices = [];
        var choiceOBJ = {};

        if (this.state.QueryData != null) {

            for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
                let val = this.state.QueryData[index];
                //console.log(val.ProductName);
                choiceOBJ = { key: val["ProductName"], text: val["ProductName"], value: val["ProductName"] }
                ddChoices.push(choiceOBJ);
            }
        }
        //console.log(ddChoices);


        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager' && job !== 'Employee') {
            return (<Redirect to="/signin" />);
        }


        return (
            <div>


                {this.renderControls()}
                <div className={this.props.container + " " + "blueBackground"}>
                    <Form>
                        <Form.Field>
                            <label>Product Search</label>
                            <input onChange={e => this.handleChange(e.target.value)} placeholder='Search by product ID:' />
                        </Form.Field>
                    </Form>
                    <br></br>
                    <Form>
                        <Form.Field>
                            Current filter: <b>{this.state.typeChoice = this.state.value}</b>
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='No filter'
                                name='radioGroup'
                                value='none'
                                checked={this.state.value === 'none'}
                                onChange={this.setTypeChoice}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Dies'
                                name='radioGroup'
                                value='dies'
                                checked={this.state.value === 'dies'}
                                onChange={this.setTypeChoice}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Cutting Tools'
                                name='radioGroup'
                                value='cutting tools'
                                checked={this.state.value === 'cutting tools'}
                                onChange={this.setTypeChoice}
                            />
                        </Form.Field>
                    </Form>
                    {this.state.typeChoice !== 'cutting tools' &&
                    <Table compact color='blue' celled unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Product ID</Table.HeaderCell>
                                <Table.HeaderCell>Customer</Table.HeaderCell>
                                <Table.HeaderCell>Delivery Date</Table.HeaderCell>
                                <Table.HeaderCell>Received Date</Table.HeaderCell>
                                <Table.HeaderCell>Material</Table.HeaderCell>
                                <Table.HeaderCell>Product Name</Table.HeaderCell>
                                <Table.HeaderCell>Quantity</Table.HeaderCell>
                                <Table.HeaderCell>Product Weight</Table.HeaderCell>
                                <Table.HeaderCell>Shipment Schedule</Table.HeaderCell>
                                <Table.HeaderCell>PO Number</Table.HeaderCell>
                                <Table.HeaderCell>Process Stages</Table.HeaderCell>
                                <Table.HeaderCell>Number of Items</Table.HeaderCell>
                                <Table.HeaderCell>Coating</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.renderProducts()}
                        </Table.Body>
                    </Table>}
                    {this.state.typeChoice === 'cutting tools' &&
                <Table compact celled unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ProductID") }}>Product ID</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("Customer") }}>Customer</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("DeliveryDate") }}>Delivery Date</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ReceivedDate") }}>Received Date</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("Material") }}>Material</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ProductName") }}>Product Name</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("Quantity") }}>Quantity</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ProductDiameter") }}>Product Diameter</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ProductLength") }}>Product Length</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ShipmentSchedule") }}>Shipment Schedule</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("PONumber") }}>PO Number</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("Process") }}>Process</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("NumberOfItems") }}>Number of Items</Table.HeaderCell>
                            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("Coating") }}>Coating</Table.HeaderCell>
                            <Table.HeaderCell >Description</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.renderProducts()}
                    </Table.Body>
                </Table>}
                </div>
            </div>);
    }

}

export default withStyles(productStatisticsSubArchive);
