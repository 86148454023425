import React from 'react';
import { Redirect } from 'react-router-dom'
import Navbar from './navbar';
import ProgressBar from './progressBar';
import ProgressBarCut from './progressBarCut';
import ProgressBar2 from './progressBar2';
import ProgressBar2Cut from './progressBar2cut';
import MultiColorProgressBarLegend from './multiColorProgressBarLegend';
import MultiProgCut from './multiColorProgressBarLegendCut';
import { Icon, Label, Message, Form, Radio, } from 'semantic-ui-react'


import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import dashboardStyle from '../jss/dashboard';

//css but in json form written in js
const withStyles = hocify(dashboardStyle);

class DashboardView extends React.Component {

    constructor(props) {
        super(props);

        //set the state of this component to have a item named QueryData
        this.state = {
            QueryData: null,
            Processes: null,
            ScannerError: null,
            ReworkedData: null,
            TickerData: null,
            barHeight: 1.5,
            barWidth: 3.8,
            width: 1280,
            height: 0,
            filterValue: -1,
            cuttingOrDies: 0
        };
        const dashboardRequestPath = process.env.REACT_APP_SERVER_ROOT + "/analytics/dashboard/sseRead.php";
        this.evtSource = new EventSource(dashboardRequestPath);
        const processRequestPath = process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php";
        this.processEvtSource = new EventSource(processRequestPath);
        const reworkRequestPath = process.env.REACT_APP_SERVER_ROOT + "/analytics/rework/sseRead.php";
        this.reworkEvtSource = new EventSource(reworkRequestPath);
        this.scannerErrorEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "analytics/scannerError/read.php");

        this.rangeUpdate = this.rangeUpdate.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    windowResize = () => {
        this.forceUpdate();
    };

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        this.forceUpdate();
    }

    componentDidMount() {
        //send all onmessage events to the sse receive method
        this.evtSource.onmessage = e => this.receiveSSEData(e);
        this.processEvtSource.onmessage = e => this.receiveProcessData(e);
        this.reworkEvtSource.onmessage = e => this.receiveReworkedSSEData(e);
        this.scannerErrorEvtSource.onmessage = e => this.receiveScannerErrorData(e);

        //setup a resize event handler to force a rerender of the page so the stuck header margins get recalculated
        window.addEventListener('resize', this.windowResize);

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        this.evtSource.close();
        this.processEvtSource.close();
        this.reworkEvtSource.close();
        this.scannerErrorEvtSource.close();

        window.removeEventListener('resize', this.windowResize);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    rangeUpdate(event) {
        this.setState({
            barHeight: event.target.value,
            barWidth: event.target.value * 2
        });
    }

    receiveSSEData(e) {

        let message = JSON.parse(e.data);
        if (message["status"] === 200) {
            delete message["status"];

            //the stored data is not the same as the recived data, update it
            if (JSON.stringify(message) !== JSON.stringify(this.state.QueryData)) {
                this.setState({ QueryData: message });
                this.forceUpdate();
            }
        }
    }

    receiveProcessData(e) {
        let message = JSON.parse(e.data);
        if (message["status"] === 200) {
            delete message["status"];
            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
                this.forceUpdate();
            }
        }
    }

    receiveReworkedSSEData(e) {

        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];
            if (Object.keys(message).length === 0) {
                message = null;
            }
            //the stored data is not the same as the recived data, update it
            if (JSON.stringify(message) !== JSON.stringify(this.state.ReworkedData)) {
                this.setState({ ReworkedData: message });
            }

        }
    }

    receiveScannerErrorData(e) {

        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (Object.keys(message).length === 0) {
                message = null;
            }

            //the stored data is not the same as the recived data, update it
            if (JSON.stringify(message) !== JSON.stringify(this.state.ScannerError)) {
                this.setState({ ScannerError: message });
            }

        }

    }

    getStages(process) {
        for (var key in this.state.Processes) {
            if (this.state.Processes.hasOwnProperty(key) && this.state.Processes[key].processID === process) {
                return this.state.Processes[key].stages;
            }
        }
    }

    findIndex(stages, stage) {

        for (let i = 0; i < Object.keys(stages).length; i++) {
            if (stages[i] === stage) {
                return i - 1;
            }
            if (stage === stages[Object.keys(stages).length]) {
                return Object.keys(stages).length - 1;
            }
        }

    }

    changefilterValue = (e, data) => {

        this.state.filterValue = data.value;
        this.handlechange(e, data);
        this.forceUpdate();
    }

    changeDieCutValue = (e, data) => {

        this.state.cuttingOrDies = data.value;
        console.log(this.state.cuttingOrDies);
        this.handlechange(e, data);
        this.forceUpdate();
    }



    handlechange = (e, { value }) => this.setState({ value })

    //current stage = current value denotes which stage is in progress
    //process = the process
    buildProgressBar(process, currentStage, height, width, requirement) {
        let flag = "";
        //missing data skip this product
        if (process === null || currentStage === null || this.state.Processes == null) {
            return ("");
        }
        let stages = this.getStages(process);
        //scaffold the progressBarData object as a blank formatted object
        var progressBarData = {};
        /////////////////////////////////////////////////////////////////////////////////////////
        //loop through the stages and determine which stage occurs when in the process
        //Then set the color filter for this progress bar based on the order of stages
        /////////////////////////////////////////////////////////////////////////////////////////
        for (let i = 0; i < Object.keys(stages).length; i++) {
            let currentIndex = i + 1;

            progressBarData[i] =
            {
                filter: "", //filter to determine the color of the segment at this location in the progress bar
                state: "",   //determine if the bar segment should be solid fill, striped fill, or white fill
                tooltipText: stages[currentIndex]
            };
            if (stages[currentIndex] === "Pre-Inspection")
                progressBarData[i].filter = "filter-preinspection";
            else if (stages[currentIndex] === "Pre-Washing (Jig-Washer)")
                progressBarData[i].filter = "filter-prewashing";
            else if (stages[currentIndex] === "Stripping (Shot)")
                progressBarData[i].filter = "filter-strippingShot";
            else if (stages[currentIndex] === "Stripping (Liquid)")
                progressBarData[i].filter = "filter-strippingLiquid";
            else if (stages[currentIndex] === "WPC (SKH+Ceramic)")
                progressBarData[i].filter = "filter-wpcskh";
            else if (stages[currentIndex] === "WPC-Ceramic") {
                progressBarData[i].filter = "filter-ceramic";
                progressBarData[i].tooltipText = "WPC";
            }
            else if (stages[currentIndex] === "Pre-Lapping (SMAP)")
                progressBarData[i].filter = "filter-prelapSMAP";
            else if (stages[currentIndex] === "Pre-Lapping (AERO)")
                progressBarData[i].filter = "filter-prelapAERO";
            else if (stages[currentIndex] === "Pre-Lapping (Hand Lapping)")
                progressBarData[i].filter = "filter-prelapHand";
            else if (stages[currentIndex] === "Pre-Washing (F1 Clean Washer)")
                progressBarData[i].filter = "filter-washing";
            else if (stages[currentIndex] === "Coating (M1) - Set/Disassemble") {
                flag = 1;
                progressBarData[i].filter = "filter-coatingM1";
            }
            else if (stages[currentIndex] === "Coating (M2) - Set/Disassemble") {
                flag = 2;
                progressBarData[i].filter = "filter-coatingM2";
            }
            else if (stages[currentIndex] === "Coating (M3) - Set/Disassemble") {
                flag = 3;
                progressBarData[i].filter = "filter-coatingM3";
            }
            else if (stages[currentIndex] === "Post-Inspection")
                progressBarData[i].filter = "filter-postInspection";
            else if (stages[currentIndex] === "Post-Lapping (SMAP)")
                progressBarData[i].filter = "filter-postlappingSMAP";
            else if (stages[currentIndex] === "Post-Lapping (AERO)")
                progressBarData[i].filter = "filter-postlappingAERO";
            else if (stages[currentIndex] === "Post-Lapping (Hand Lapping)")
                progressBarData[i].filter = "filter-postlappingHand";
            else if (stages[currentIndex] === "Final Inspection")
                progressBarData[i].filter = "filter-finalInspect";
            else if (stages[currentIndex] === "Packing/Shipping")
                progressBarData[i].filter = "filter-shipping";
        }

        for (let i = 0; i < Object.keys(progressBarData).length; i++) {
            //get the index of the currentStage the product is on.
            var currentStageIndex = this.findIndex(stages, currentStage);
            //base case is segment that is in progress
            let segmentType = "striped"
            if (i < currentStageIndex) { //stages that are done
                segmentType = "filled";
            } else if (i > currentStageIndex) { //stages that haven't been started yet
                segmentType = "hollow"
            }
            //update the state of the segment
            progressBarData[i].state = segmentType;
        }

        if (flag == requirement || requirement == undefined || requirement == -1) {
            if (this.state.width >= 1280 && this.state.cuttingOrDies == 20) {
                return (<ProgressBarCut data={progressBarData} height={height} width={width} />)
            }
            else if (this.state.width >= 1280) {
                return (<ProgressBar data={progressBarData} height={height} width={width} />)
            }
            else if (this.state.cuttingOrDies == 20) {
                return (<ProgressBar2Cut data={progressBarData} height={height} width={width} />)
            }
            else {
                console.log("returning this");
                return (<ProgressBar2 data={progressBarData} height={height} width={width} />)
            }
        }
        else
            return (false);
    }

    //checks if there are any reworks for the given product returning the data render ready, returns "" if there are none
    getReworksForProduct(prodID) {

        if (this.state.ReworkedData === null) {
            return ("");
        }

        let reworkJSON = this.state.ReworkedData;

        //grab all reworks for this product
        const reworkForProduct = [];
        for (let i = 0; i < Object.keys(reworkJSON["reworkedProducts"]).length; i++) {
            let current = reworkJSON["reworkedProducts"][i];
            if (parseInt(prodID) === parseInt(current["ProductID"])) {
                reworkForProduct.push(current);
            }

        }

        if (reworkForProduct.length > 0) {

            const reworkBars = [];
            for (let i = 0; i < reworkForProduct.length; i++) {
                reworkBars.push(<div key={i + reworkForProduct.length}>
                    <div className={this.props.barCountContainer}>
                        <span className={this.props.title}>
                            <b key={i}>{reworkForProduct[i].ReworkID + " - " + reworkForProduct[i].Reason}</b>
                        </span>
                        <span className={this.props.count}>
                            <Label size='big' horizontal>Number of Rework Items:</Label>{reworkForProduct[i].NumberofItems}
                        </span>
                        <span className={this.props.count}>
                            <Label size='big' horizontal>Notes:</Label>{reworkForProduct[i].Notes}
                        </span>
                    </div>
                    {this.buildProgressBar(reworkForProduct[i].Process, reworkForProduct[i].CurrentStage, this.state.barHeight * .7, this.state.barWidth * .7)}
                </div>);
            }

            return (reworkBars);
        }

        return ("");

    }

    render() {



        if (sessionStorage.getItem('jobRole') === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem('jobRole');
        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || (job !== 'Manager' && job !== 'Employee' && job !== 'Viewer')) {
            return (<Redirect to="/signin" />);
        }

        //don't render if query data hasn't been gotten set yet
        if (this.state.QueryData == null || this.state.Processes === null) {
            return (<div>
                <Navbar selectedPage="dashboardView" />
            </div>)
        }

        const items = []

        var isDueToday = "";
        //loop through each element returned from the dashboard read
        for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
            var due = this.state.QueryData[index].ShipmentSchedule;
            var today = new Date();
            today.setTime(today.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
            today = today.toJSON().slice(0, 10);
            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 11);
            yesterday = yesterday.toJSON().slice(0, 10);
            var ontime = (due >= today);
            var dueToday = (due == today);
            if (dueToday) {
                isDueToday += "Customer: " + this.state.QueryData[index].Customer + "\u00A0\u00A0\u00A0ProductID: " + this.state.QueryData[index].productID + "\u00A0\u00A0\u00A0Name: " + this.state.QueryData[index].ProductName;
                isDueToday += "\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0";
            }
            if (this.state.QueryData[index].productWeight === null) {
                this.state.QueryData[index].productWeight = "-";
            }
            if (this.state.QueryData[index].productWeight.includes("x")) {
            }

            //get the current row from the list of elements
            let value = this.state.QueryData[index];
            //\u00A0

            let received = value.ReceivedDate.split('-');
            let receivedOutput = received[1] + "/" + received[2] + "/" + received[0];

            let delivery = value.ShipmentSchedule.split('-');
            let deliveryOutput = delivery[1] + "/" + delivery[2] + "/" + delivery[0];

            //let custOutput = ( (value.Customer.substring(0,19)) ).padEnd(20, "\u00A0");
            let prodOutput = ((value.ProductName.substring(0, 22)) + ")");
            let custOutput = ((value.Customer.substring(0, 33)) + " (" + value.Quantity + "pcs, " + prodOutput).padEnd(63, "\u00A0");
            let recOutput = ("Received: " + receivedOutput).padEnd(25, "\u00A0");
            let delivOutput = (" Shipment: " + deliveryOutput.replace(/-/g, '/'));

            if (this.state.width < 1280) {
                this.state.barWidth = 3;
                custOutput = custOutput.replaceAll("\u00A0", "");
                //custOutput = value.Customer + " (";
                //prodOutput = value.ProductName + ") ";
                recOutput = " Received: " + receivedOutput + ",";
                delivOutput = " Shipment: " + deliveryOutput;
            }
            else {
                this.state.barWidth = 3.8;
            }
            //<Icon name='users' />
            //Old title
            //<div className={this.props.progressBarTitle}><b>{value.productID + " - (" + value.ProductName + ", " + value.Customer + ") - Received: " + value.DeliveryDate.replace(/-/g, '/') + ", Expected: " + value.ShipmentSchedule.replace(/-/g, '/')}</b></div>
            //console.log(this.state.QueryData[index].Notes);
            var waiting = false;
            var textcolor = 'red';
            if (this.state.QueryData[index].Notes.includes("⌛")) {
                waiting = true;
                textcolor = '#ff7b00';
                //console.log(this.state.QueryData[index].Notes);
            }
            //add the product id, the progress bar, and some formating items to the list of things to be rendered
            if ((this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)) !== false) {
                //NOT ON TIME
                if (!ontime) {
                    //sizing on 1080p non-laptop screen
                    //Not on time, is a cutting tool
                    if (this.state.width >= 1920 && this.state.QueryData[index].productWeight === '-' && this.state.cuttingOrDies != 10) {
                        items.push(
                            <div id="ProductID" style={{ color: textcolor }} className={this.props.productContainer} key={index}>
                                <div className={this.props.pBTitleBig}><Icon name='cut' />{custOutput + recOutput + delivOutput}</div>
                                <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                                <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                <hr />
                            </div>);
                    }
                    //sizing on 1080p non-laptop screen
                    //Not on time, is a hot item
                    else if (this.state.width >= 1920 && this.state.QueryData[index].Notes.includes("Hot") && this.state.cuttingOrDies != 20) {
                        items.push(
                            <div id="ProductID" style={{ color: textcolor }} className={this.props.productContainer} key={index}>
                                <div className={this.props.pBTitleBig}><Icon color='red' name='fire' />{custOutput + recOutput + delivOutput}</div>
                                <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                                <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                <hr />
                            </div>);
                    }
                    //sizing on 1080p non-laptop screen
                    //Not on time, Not a cutting tool
                    else if (this.state.width >= 1920 && this.state.cuttingOrDies != 20 && (this.state.QueryData[index].productWeight !== '-')) {
                        items.push(<div id="ProductID" style={{ color: textcolor }} className={this.props.productContainer} key={index}>
                            <div className={this.props.pBTitleBig}><Icon inverted color='white' name='circle' />{custOutput + recOutput + delivOutput}</div>
                            <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                            <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                            <hr />
                        </div>);
                    }
                    //size on mobile screen
                    //Not on time, is a cutting tool
                    else if (this.state.width < 1280 && this.state.QueryData[index].productWeight === '-' && this.state.cuttingOrDies != 10) {
                        items.push(<div id="ProductID" style={{ fontSize: '1.1em', color: textcolor }} className={this.props.productContainer} key={index}>
                            <div className={this.props.progressBarTitle_NONE} style={{ fontWeight: 'bold' }}><Icon name='cut' />{custOutput}</div>
                            <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                            <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                            <div>{recOutput + "\u00A0\u00A0\u00A0\u00A0\u00A0" + delivOutput}</div>
                            <hr />
                        </div>);
                    }
                    //size on mobile screen
                    //Not on time, is a hot item
                    else if (this.state.width < 1280 && this.state.QueryData[index].Notes.includes("Hot") && this.state.cuttingOrDies != 20) {
                        items.push(
                            <div id="ProductID" style={{ fontSize: '1.1em', color: textcolor }} className={this.props.productContainer} key={index}>
                                <div className={this.props.progressBarTitle_NONE} style={{ fontWeight: 'bold' }}><Icon color='red' name='fire' />{custOutput}</div>
                                <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                                <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                <div>{recOutput + "\u00A0\u00A0\u00A0\u00A0\u00A0" + delivOutput}</div>
                                <hr />
                            </div>);
                    }
                    //size on mobile screen
                    //Not on time, Not a cutting tool
                    else if (this.state.width < 1280 && this.state.cuttingOrDies != 20 && (this.state.QueryData[index].productWeight !== '-')) {
                        items.push(<div id="ProductID" style={{ fontSize: '1.1em', color: textcolor }} className={this.props.productContainer} key={index}>
                            <div className={this.props.progressBarTitle_NONE} style={{ fontWeight: 'bold' }}>{custOutput}</div>
                            <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                            <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                            <div>{recOutput + "\u00A0\u00A0\u00A0\u00A0\u00A0" + delivOutput}</div>
                            <hr />
                        </div>);
                    }
                    //size on laptop screen (1920 width on laptop goes to 1280 due to text zooming)
                    //Not on time, is a cutting tool
                    else if (this.state.QueryData[index].productWeight === '-' && this.state.cuttingOrDies != 10) {
                        items.push(<div id="ProductID" style={{ fontSize: '1.3em', color: textcolor }} className={this.props.productContainer} key={index}>
                            <div className={this.props.progressBarTitle}><Icon name='cut' />{custOutput + recOutput + delivOutput}</div>
                            <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                            <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                            <hr />
                        </div>);
                    }

                    //size on laptop screen
                    //Not on time, is a hot item
                    else if (this.state.QueryData[index].Notes.includes("Hot") && this.state.cuttingOrDies != 20) {
                        items.push(
                            <div id="ProductID" style={{ fontSize: '1.3em', color: textcolor }} className={this.props.productContainer} key={index}>
                                <div className={this.props.progressBarTitle}><Icon color='red' name='fire' />{custOutput + recOutput + delivOutput}</div>
                                <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                                <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                <hr />
                            </div>);
                    }
                    //size on laptop screen
                    //Not on time, Not a cutting tool
                    else if (this.state.cuttingOrDies != 20 && (this.state.QueryData[index].productWeight !== '-')) {
                        items.push(<div id="ProductID" style={{ fontSize: '1.3em', color: textcolor }} className={this.props.productContainer} key={index}>
                            <div className={this.props.progressBarTitle}>{"\u00A0\u00A0"}{custOutput + recOutput + delivOutput}</div>
                            <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                            <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                            <hr />
                        </div>);
                    }
                }
                //ON TIME
                else {
                    //sizing on 1080p non-laptop screen
                    //On time, is a cutting tool
                    if (this.state.width >= 1920 && this.state.QueryData[index].productWeight === '-' && this.state.cuttingOrDies != 10) {
                        items.push(
                            <div id="ProductID" style={{}} className={this.props.productContainer} key={index}>
                                <div className={this.props.pBTitleBig}><Icon name='cut' />{custOutput + recOutput + delivOutput}</div>
                                <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                                <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                <hr />
                            </div>);
                    }
                    //sizing on 1080p non-laptop screen
                    //On time, is a hot item
                    else if (this.state.width >= 1920 && this.state.QueryData[index].Notes.includes("Hot") && this.state.cuttingOrDies != 20) {
                        items.push(
                            <div id="ProductID" style={{}} className={this.props.productContainer} key={index}>
                                <div className={this.props.pBTitleBig}><Icon color='red' name='fire' />{custOutput + recOutput + delivOutput}</div>
                                <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                                <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                <hr />
                            </div>);
                    }
                    //sizing on 1080p non-laptop screen
                    //On time, Not a cutting tool
                    else if (this.state.width >= 1920 && this.state.cuttingOrDies != 20 && (this.state.QueryData[index].productWeight !== '-')) {
                        items.push(<div id="ProductID" style={{}} className={this.props.productContainer} key={index}>
                            <div className={this.props.pBTitleBig}><Icon inverted color='white' name='circle' />{custOutput + recOutput + delivOutput}</div>
                            <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                            <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                            <hr />
                        </div>);
                    }
                    //size on mobile screen
                    //On time, is a cutting tool
                    else if (this.state.width < 1280 && this.state.QueryData[index].productWeight === '-' && this.state.cuttingOrDies != 10) {
                        items.push(<div id="ProductID" style={{ fontSize: '1.1em' }} className={this.props.productContainer} key={index}>
                            <div className={this.props.progressBarTitle_NONE} style={{ fontWeight: 'bold' }}><Icon name='cut' />{custOutput}</div>
                            <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                            <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                            <div>{recOutput + "\u00A0\u00A0\u00A0\u00A0\u00A0" + delivOutput}</div>
                            <hr />
                        </div>);
                    }
                    //size on mobile screen
                    //On time, is a hot item
                    else if (this.state.width < 1280 && this.state.QueryData[index].Notes.includes("Hot") && this.state.cuttingOrDies != 20) {
                        items.push(
                            <div id="ProductID" style={{ fontSize: '1.1em' }} className={this.props.productContainer} key={index}>
                                <div className={this.props.progressBarTitle_NONE} style={{ fontWeight: 'bold' }}><Icon color='red' name='fire' />{custOutput}</div>
                                <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                                <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                <div>{recOutput + "\u00A0\u00A0\u00A0\u00A0\u00A0" + delivOutput}</div>
                                <hr />
                            </div>);
                    }
                    //size on mobile screen
                    //On time, Not a cutting tool
                    else if (this.state.width < 1280 && this.state.cuttingOrDies != 20 && (this.state.QueryData[index].productWeight !== '-')) {
                        items.push(<div id="ProductID" style={{ fontSize: '1.1em' }} className={this.props.productContainer} key={index}>
                            <div className={this.props.progressBarTitle_NONE} style={{ fontWeight: 'bold' }}>{custOutput}</div>
                            <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                            <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                            <div>{recOutput + "\u00A0\u00A0\u00A0\u00A0\u00A0" + delivOutput}</div>
                            <hr />
                        </div>);
                    }
                    //size on laptop screen (1920 width on laptop goes to 1280 due to text zooming)
                    //On time, is a cutting tool
                    else if (this.state.QueryData[index].productWeight === '-' && this.state.cuttingOrDies != 10) {
                        items.push(<div id="ProductID" style={{ fontSize: '1.3em' }} className={this.props.productContainer} key={index}>
                            <div className={this.props.progressBarTitle}><Icon name='cut' />{custOutput + recOutput + delivOutput}</div>
                            <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                            <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                            <hr />
                        </div>);
                    }
                    //size on laptop screen (1920 width on laptop goes to 1280 due to text zooming)
                    //On time, is a hot item
                    else if (this.state.QueryData[index].Notes.includes("Hot") && this.state.cuttingOrDies != 20) {
                        items.push(
                            <div id="ProductID" style={{ fontSize: '1.3em' }} className={this.props.productContainer} key={index}>
                                <div className={this.props.progressBarTitle}><Icon color='red' name='fire' />{custOutput + recOutput + delivOutput}</div>
                                <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                                <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                <hr />
                            </div>);
                    }
                    //size on laptop screen (1920 width on laptop goes to 1280 due to text zooming)
                    //On time, Not a cutting tool
                    else if (this.state.cuttingOrDies != 20 && (this.state.QueryData[index].productWeight !== '-')) {
                        items.push(<div id="ProductID" style={{ fontSize: '1.3em' }} className={this.props.productContainer} key={index}>
                            <div className={this.props.progressBarTitle}>{"\u00A0\u00A0"}{custOutput + recOutput + delivOutput}</div>
                            <div className={this.props.productProgressBar}>{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, this.state.filterValue)}</div>
                            <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                            <hr />
                        </div>);
                    }
                }
            }
        }

        //create another array for the legened
        var readings = [
            {
                name: 'Pre-Inspection',
                value: 16.6,
                color: '#c8a2c8',
                inprogress: false
            },
            {
                name: 'Pre-Washing (Jig-Washer)',
                value: 16.6,
                color: '#bbf0fc',
                inprogress: false
            },
            {
                name: 'Stripping (Shot)',
                value: 16.6,
                color: '#ff6200',
                inprogress: false
            },
            {
                name: 'Stripping (Liquid)',
                value: 16.6,
                color: '#8B0000',
                inprogress: false
            },
            {
                name: 'WPC-SKH',
                value: 16.6,
                color: '#ffe261',
                inprogress: false
            },
            {
                name: 'WPC-Ceramic',
                value: 16.6,
                color: '#ffd000',
                inprogress: false
            },
            {
                name: 'Pre-Lapping (SMAP)',
                value: 16.6,
                color: '#b9ff69',
                inprogress: false
            },
            {
                name: 'Pre-Lapping (AERO)',
                value: 16.6,
                color: '#89ff00',
                inprogress: false
            },
            {
                name: 'Pre-Lapping (Hand Lapping)',
                value: 16.6,
                color: '#70d100',
                inprogress: false
            },
            {
                name: 'Pre-Washing (F1 Clean Washer)',
                value: 16.6,
                color: '#00a82d',
                inprogress: false
            },
            {
                name: 'Coating (M1) - Set/Disassemble',
                value: 16.6,
                color: '#c9c9c9',
                inprogress: false
            },
            {
                name: 'Coating (M2) - Set/Disassemble',
                value: 16.6,
                color: '#9e9d9d',
                inprogress: false
            },
            {
                name: 'Coating (M3) - Set/Disassemble',
                value: 16.6,
                color: '#000000',
                inprogress: false
            },
            {
                name: 'Post-Inspection',
                value: 16.6,
                color: '#df6bff',
                inprogress: false
            },
            {
                name: 'Post-Lapping (SMAP)',
                value: 16.6,
                color: '#0077ff',
                inprogress: false
            },
            {
                name: 'Post-Lapping (AERO)',
                value: 16.6,
                color: '#0040d6',
                inprogress: false
            },
            {
                name: 'Post-Lapping (Hand Lapping)',
                value: 16.6,
                color: '#002885',
                inprogress: false
            },
            {
                name: 'Final Inspection',
                value: 16.6,
                color: '#4b0085',
                inprogress: false
            },
            {
                name: 'Packing/Shipping',
                value: 16.6,
                color: '#855900',
                inprogress: false
            }
        ];

        var message = "";
        if (this.state.ScannerError !== null) {

            let scannerString = "";
            let counter = 0;
            for (let key of Object.keys(this.state.ScannerError)) {

                //use the correct plural of errors based on if the scanner had one or more errors
                if (this.state.ScannerError[key] == 1) {
                    scannerString += "Scanner '" + key + "' had " + this.state.ScannerError[key] + " error";
                }
                else {
                    scannerString += "Scanner '" + key + "' had " + this.state.ScannerError[key] + " errors";
                }

                //only add the comma if this isn't the last element
                if (counter < Object.keys(this.state.ScannerError).length - 1) {
                    scannerString += ", ";
                }

                counter++;
            }

            //build the error message
            message =
                <div class="ui negative message">
                    <div class="header">
                        Scanner Errors
                    </div>
                    <p>
                        {scannerString}
                    </p>
                </div>;
        }
        //Do this if set to cutting tools
        if (this.state.width >= 1280 && isDueToday != "" && this.state.cuttingOrDies == 20) {
            return (<div classname={this.props.main}>

                <div className={this.props.stuck + " " + this.props.printHide}>
                    <Navbar selectedPage="dashboardView" />
                    <MultiProgCut readings={readings} />
                    <Form style={{ paddingLeft: '1em' }}>
                        <Form.Field>
                            <Radio
                                label='No filter'
                                name='radioGroup'
                                value='-1'
                                checked={this.state.value === '-1'}
                                onChange={this.changefilterValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M1'
                                name='radioGroup'
                                value='1'
                                checked={this.state.value === '1'}
                                onChange={this.changefilterValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M2'
                                name='radioGroup'
                                value='2'
                                checked={this.state.value === '2'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M3'
                                name='radioGroup'
                                value='3'
                                checked={this.state.value === '3'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}<Label style={{ color: 'black', backgroundColor: 'white', fontSize: '1em' }}>Product Status:</Label>
                            <Label basic color='red' style={{ color: 'red', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Overdue</Label>
                            <Label basic color='orange' style={{ color: '#ff7b00', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Pending</Label>
                            <Radio
                                label='All'
                                name='dieCutGroup'
                                value='-10'
                                checked={this.state.value === '-10'}
                                onChange={this.changeDieCutValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Dies Only'
                                name='dieCutGroup'
                                value='10'
                                checked={this.state.value === '10'}
                                onChange={this.changeDieCutValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Cutting Tools Only'
                                name='dieCutGroup'
                                value='20'
                                checked={this.state.value === '20'}
                                onChange={this.changeDieCutValue}
                            />
                        </Form.Field>

                    </Form>

                    <div className={this.props.container}>
                    </div>
                </div>

                <div
                    className={this.props.container + " " + this.props.controlDiv + " " + "whitebackground" + " " + this.props.barDiv}
                    //if the stuck div has been added to the dom, get the height of it to set the correct margin otherwise set the margin to 0
                    style={{ marginTop: document.getElementsByClassName(this.props.stuck).length > 0 ? document.getElementsByClassName(this.props.stuck)[0].clientHeight + "px" : '0px' }}
                >
                    {message}
                    <span >{items}</span>
                </div>
            </div>)
        }

        else if (this.state.width >= 1280 && isDueToday != "") {
            return (<div classname={this.props.main}>

                <div className={this.props.stuck + " " + this.props.printHide}>
                    <Navbar selectedPage="dashboardView" />
                    <MultiColorProgressBarLegend readings={readings} />
                    <Form style={{ paddingLeft: '1em' }}>
                        <Form.Field>
                            <Radio
                                label='No filter'
                                name='radioGroup'
                                value='-1'
                                checked={this.state.value === '-1'}
                                onChange={this.changefilterValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M1'
                                name='radioGroup'
                                value='1'
                                checked={this.state.value === '1'}
                                onChange={this.changefilterValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M2'
                                name='radioGroup'
                                value='2'
                                checked={this.state.value === '2'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M3'
                                name='radioGroup'
                                value='3'
                                checked={this.state.value === '3'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}<Label style={{ color: 'black', backgroundColor: 'white', fontSize: '1em' }}>Product Status:</Label>
                            <Label basic color='red' style={{ color: 'red', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Overdue</Label>
                            <Label basic color='orange' style={{ color: '#ff7b00', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Pending</Label>
                            <Radio
                                label='All'
                                name='dieCutGroup'
                                value='-10'
                                checked={this.state.value === '-10'}
                                onChange={this.changeDieCutValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Dies Only'
                                name='dieCutGroup'
                                value='10'
                                checked={this.state.value === '10'}
                                onChange={this.changeDieCutValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Cutting Tools Only'
                                name='dieCutGroup'
                                value='20'
                                checked={this.state.value === '20'}
                                onChange={this.changeDieCutValue}
                            />
                        </Form.Field>

                    </Form>

                    <div className={this.props.container}>
                    </div>
                </div>

                <div
                    className={this.props.container + " " + this.props.controlDiv + " " + "whitebackground" + " " + this.props.printable + " " + this.props.barDiv}
                    //if the stuck div has been added to the dom, get the height of it to set the correct margin otherwise set the margin to 0
                    style={{ marginTop: document.getElementsByClassName(this.props.stuck).length > 0 ? document.getElementsByClassName(this.props.stuck)[0].clientHeight + "px" : '0px' }}
                >
                    {message}
                    <span>{items}</span>
                </div>
            </div>)
        }

        else if (this.state.width >= 1280 && this.state.cuttingOrDies == 20) {
            return (<div>

                <div className={this.props.stuck + " " + this.props.printHide}>
                    <Navbar selectedPage="dashboardView" />
                    <MultiProgCut readings={readings} />
                    <Form style={{ paddingLeft: '1em' }}>
                        <Form.Field>
                            <Radio
                                label='No filter'
                                name='radioGroup'
                                value='-1'
                                checked={this.state.value === '-1'}
                                onChange={this.changefilterValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M1'
                                name='radioGroup'
                                value='1'
                                checked={this.state.value === '1'}
                                onChange={this.changefilterValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M2'
                                name='radioGroup'
                                value='2'
                                checked={this.state.value === '2'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M3'
                                name='radioGroup'
                                value='3'
                                checked={this.state.value === '3'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}<Label style={{ color: 'black', backgroundColor: 'white', fontSize: '1em' }}>Product Status:</Label>
                            <Label basic color='red' style={{ color: 'red', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Overdue</Label>
                            <Label basic color='orange' style={{ color: '#ff7b00', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Pending</Label>
                            <Radio
                                label='All'
                                name='dieCutGroup'
                                value='-10'
                                checked={this.state.value === '-10'}
                                onChange={this.changeDieCutValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Dies Only'
                                name='dieCutGroup'
                                value='10'
                                checked={this.state.value === '10'}
                                onChange={this.changeDieCutValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Cutting Tools Only'
                                name='dieCutGroup'
                                value='20'
                                checked={this.state.value === '20'}
                                onChange={this.changeDieCutValue}
                            />
                        </Form.Field>
                    </Form>
                    <div className={this.props.container}>
                    </div>
                </div>

                <div
                    className={this.props.container + " " + this.props.controlDiv + " " + "whitebackground" + " " + this.props.barDiv}
                    //if the stuck div has been added to the dom, get the height of it to set the correct margin otherwise set the margin to 0
                    style={{ marginTop: document.getElementsByClassName(this.props.stuck).length > 0 ? document.getElementsByClassName(this.props.stuck)[0].clientHeight + "px" : '0px' }}
                >
                    {message}
                    <span>{items}</span>
                </div>
            </div>)
        }

        else if (this.state.width >= 1280) {
            return (<div>

                <div className={this.props.stuck + " " + this.props.printHide}>
                    <Navbar selectedPage="dashboardView" />
                    <MultiColorProgressBarLegend readings={readings} />
                    <Form style={{ paddingLeft: '1em' }}>
                        <Form.Field>
                            <Radio
                                label='No filter'
                                name='radioGroup'
                                value='-1'
                                checked={this.state.value === '-1'}
                                onChange={this.changefilterValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M1'
                                name='radioGroup'
                                value='1'
                                checked={this.state.value === '1'}
                                onChange={this.changefilterValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M2'
                                name='radioGroup'
                                value='2'
                                checked={this.state.value === '2'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M3'
                                name='radioGroup'
                                value='3'
                                checked={this.state.value === '3'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}<Label style={{ color: 'black', backgroundColor: 'white', fontSize: '1em' }}>Product Status:</Label>
                            <Label basic color='red' style={{ color: 'red', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Overdue</Label>
                            <Label basic color='orange' style={{ color: '#ff7b00', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Pending</Label>
                            <Radio
                                label='All'
                                name='dieCutGroup'
                                value='-10'
                                checked={this.state.value === '-10'}
                                onChange={this.changeDieCutValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Dies Only'
                                name='dieCutGroup'
                                value='10'
                                checked={this.state.value === '10'}
                                onChange={this.changeDieCutValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Cutting Tools Only'
                                name='dieCutGroup'
                                value='20'
                                checked={this.state.value === '20'}
                                onChange={this.changeDieCutValue}
                            />
                        </Form.Field>
                    </Form>
                    <div className={this.props.container}>
                    </div>
                </div>

                <div
                    className={this.props.container + " " + this.props.controlDiv + " " + "whitebackground" + " " + this.props.barDiv}
                    //if the stuck div has been added to the dom, get the height of it to set the correct margin otherwise set the margin to 0
                    style={{ marginTop: document.getElementsByClassName(this.props.stuck).length > 0 ? document.getElementsByClassName(this.props.stuck)[0].clientHeight + "px" : '0px' }}
                >
                    {message}
                    <span>{items}</span>
                </div>
            </div>)
        }

        else if (isDueToday != "" && this.state.cuttingOrDies == 20) {
            return (<div>

                <div className={this.props.stuck + " " + this.props.printHide}>
                    <Navbar selectedPage="dashboardView" />
                    <div className={this.props.container}>
                    </div>
                </div>

                <div
                    className={this.props.container + " " + this.props.controlDiv + " " + "whitebackground" + " " + this.props.barDiv}
                    //if the stuck div has been added to the dom, get the height of it to set the correct margin otherwise set the margin to 0
                    style={{ marginTop: document.getElementsByClassName(this.props.stuck).length > 0 ? document.getElementsByClassName(this.props.stuck)[0].clientHeight + "px" : '0px' }}
                >

                    <MultiProgCut readings={readings} />
                    {message}
                    <span>{items}</span>

                </div>

            </div>)
        }

        else if (isDueToday != "") {
            return (<div>

                <div className={this.props.stuck + " " + this.props.printHide}>
                    <Navbar selectedPage="dashboardView" />
                    <div className={this.props.container}>
                    </div>
                </div>

                <div
                    className={this.props.container + " " + this.props.controlDiv + " " + "whitebackground" + " " + this.props.barDiv}
                    //if the stuck div has been added to the dom, get the height of it to set the correct margin otherwise set the margin to 0
                    style={{ marginTop: document.getElementsByClassName(this.props.stuck).length > 0 ? document.getElementsByClassName(this.props.stuck)[0].clientHeight + "px" : '0px' }}
                >

                    <MultiColorProgressBarLegend readings={readings} />
                    {message}
                    <span>{items}</span>

                </div>

            </div>)
        }
        else if (this.state.cuttingOrDies == 20) {
            return (<div>

                <div className={this.props.stuck + " " + this.props.printHide}>
                    <Navbar selectedPage="dashboardView" />
                    <div className={this.props.container}>
                    </div>
                </div>

                <div
                    className={this.props.container + " " + this.props.controlDiv + " " + "whitebackground" + " " + this.props.barDiv}
                    //if the stuck div has been added to the dom, get the height of it to set the correct margin otherwise set the margin to 0
                    style={{ marginTop: document.getElementsByClassName(this.props.stuck).length > 0 ? document.getElementsByClassName(this.props.stuck)[0].clientHeight + "px" : '0px' }}
                >

                    <MultiProgCut readings={readings} />
                    <Form>
                        <Form.Field>
                            <Radio
                                label='No filter'
                                name='radioGroup'
                                value='-1'
                                checked={this.state.value === '-1'}
                                onChange={this.changefilterValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M1'
                                name='radioGroup'
                                value='1'
                                checked={this.state.value === '1'}
                                onChange={this.changefilterValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M2'
                                name='radioGroup'
                                value='2'
                                checked={this.state.value === '2'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M3'
                                name='radioGroup'
                                value='3'
                                checked={this.state.value === '3'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}<Label style={{ color: 'black', backgroundColor: 'white', fontSize: '1em' }}>Product Status:</Label>
                            <Label basic color='red' style={{ color: 'red', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Overdue</Label>
                            <Label basic color='orange' style={{ color: '#ff7b00', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Pending</Label>
                            <Radio
                                label='All'
                                name='dieCutGroup'
                                value='-10'
                                checked={this.state.value === '-10'}
                                onChange={this.changeDieCutValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Dies Only'
                                name='dieCutGroup'
                                value='10'
                                checked={this.state.value === '10'}
                                onChange={this.changeDieCutValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Cutting Tools Only'
                                name='dieCutGroup'
                                value='20'
                                checked={this.state.value === '20'}
                                onChange={this.changeDieCutValue}
                            />
                        </Form.Field>
                    </Form>
                    {message}
                    <span>{items}</span>
                </div>

            </div>)
        }
        else {
            return (<div>

                <div className={this.props.stuck + " " + this.props.printHide}>
                    <Navbar selectedPage="dashboardView" />
                    <div className={this.props.container}>
                    </div>
                </div>

                <div
                    className={this.props.container + " " + this.props.controlDiv + " " + "whitebackground" + " " + this.props.barDiv}
                    //if the stuck div has been added to the dom, get the height of it to set the correct margin otherwise set the margin to 0
                    style={{ marginTop: document.getElementsByClassName(this.props.stuck).length > 0 ? document.getElementsByClassName(this.props.stuck)[0].clientHeight + "px" : '0px' }}
                >

                    <MultiColorProgressBarLegend readings={readings} />
                    <Form>
                        <Form.Field>
                            <Radio
                                label='No filter'
                                name='radioGroup'
                                value='-1'
                                checked={this.state.value === '-1'}
                                onChange={this.changefilterValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M1'
                                name='radioGroup'
                                value='1'
                                checked={this.state.value === '1'}
                                onChange={this.changefilterValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M2'
                                name='radioGroup'
                                value='2'
                                checked={this.state.value === '2'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M3'
                                name='radioGroup'
                                value='3'
                                checked={this.state.value === '3'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}<Label style={{ color: 'black', backgroundColor: 'white', fontSize: '1em' }}>Product Status:</Label>
                            <Label basic color='red' style={{ color: 'red', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Overdue</Label>
                            <Label basic color='orange' style={{ color: '#ff7b00', backgroundColor: 'white', fontSize: '.8em' }}><Icon name="square full" />Pending</Label>
                            <Radio
                                label='All'
                                name='dieCutGroup'
                                value='-10'
                                checked={this.state.value === '-10'}
                                onChange={this.changeDieCutValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Dies Only'
                                name='dieCutGroup'
                                value='10'
                                checked={this.state.value === '10'}
                                onChange={this.changeDieCutValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Cutting Tools Only'
                                name='dieCutGroup'
                                value='20'
                                checked={this.state.value === '20'}
                                onChange={this.changeDieCutValue}
                            />
                        </Form.Field>
                    </Form>
                    {message}
                    <span>{items}</span>
                </div>

            </div>)
        }
    }
}

export default withStyles(DashboardView);
