import {createUseStyles} from 'react-jss';

const signinStyle = createUseStyles({
    

    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "20%",


        "& input.buttonLogin": {
            marginTop: "2em",
            width: "200px",
            height: "5em",
            backgroundColor: "#0066cc",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
        },

        "& input.buttonLogin:hover": {
            marginTop: "2em",
            width: "200px",
            height: "5em",
            backgroundColor: "#001a33",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
        },

        "& input.buttonLogout": {
            marginTop: "2em",
            width: "200px",
            height: "5em",
            backgroundColor: "#0066cc",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
        },

        "& input.buttonLogout:hover": {
            marginTop: "2em",
            width: "200px",
            height: "5em",
            backgroundColor: "#001a33",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
        },
        

        "& form": {

            width: "409px",

            marginBottom: "2em",          
         
        },

        "& label.greeting": {
            fontSize: "1.5em",
            color: "#0066cc",
            marginBottom: "2em"
        },
        
    },
});

export default signinStyle;