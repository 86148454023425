import {createUseStyles} from 'react-jss';

const containersStyle = createUseStyles({
  container: {
      margin: '10px',
      padding: '5px'
  },

  inlineContainer: {
      display: 'inline-block',
      paddingRight: '50px',
      marginBottom: '15px'
 },

  textBox: {
      marginRight: '20px'
  },

  enterList: {
      marginBottom: '10px',
      width: '150px'
  },

  productContainer: {
      marginBottom: '20px'
  },

  statusContainer: {
      width: '60%'
  }
})

export default  containersStyle;
