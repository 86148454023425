import React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import viewReworkQRCodes from '../jss/viewReworkQRCodes';

//css but in json form written in js
const withStyles = hocify(viewReworkQRCodes);

class ViewReworkQRCodes extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            reworkCodes: null,
            numberColumns: 3
        };

        this.abortController = new AbortController();
    }

    componentDidMount(){
        this.getReworkCodes();
    }

    async getReworkCodes(){

        let tokenJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "/resources/QRCreatorRework.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: tokenJSON,
                signal: this.abortController.signal
            });

            let responseJSON = await response.json();
            this.setState({reworkCodes: responseJSON["QRCodes"]});

        } catch (error) {
            if(error.name === 'AbortError'){
            }
        }

    }

    componentWillUnmount(){
        this.abortController.abort();
    }

    render(){

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null){
            return(<Redirect to="/signin" />);
        }

        //try to add the reworked qr codes to be viewed only if they have been retrieved by the server
        let codes = [];
        const rows = [];

        if(this.state.reworkCodes !== null){
            for(let i = 0; i < Object.keys(this.state.reworkCodes).length; i++){

                codes.push(<Grid.Column key={i}>
                    <div className={this.props.qrcodeDiv}>
                        <img src={process.env.REACT_APP_SERVER_ROOT + this.state.reworkCodes[i]["QRcode"]} alt="A QR Code to be scanned to select a rework for a product" className={this.props.qrcode}/>
                        <span className={this.props.qrcodeTitle}><h4>{this.state.reworkCodes[i]["reason"]}</h4></span>
                    </div>
                </Grid.Column>);

                //check if we have enough data for a full column
                if(codes.length === this.state.numberColumns){

                    rows.push(<Grid.Row key={i}>
                        {codes}
                    </Grid.Row>);

                    //empty codes for the next row
                    codes = [];
                }

            }

            //if there is any data left in codes but not enough for a full row
            if(codes.length > 0){
                rows.push(<Grid.Row key={rows.length * this.state.numberColumns}>
                    {codes}
                </Grid.Row>);
            }

        }

        return(<div>

            <div className={this.props.printHide}>

                <div className={this.props.container}>
                    <div className={this.props.inline + " " + this.props.reworkHeader}>
                        <h2>Product Rework QR Codes</h2>
                    </div>
                    <hr/>
                </div>

            </div>

            <Grid className="codes" columns={this.state.numberColumns}>
                {rows}
            </Grid>

        </div>);
    }

}

export default withStyles(ViewReworkQRCodes);
