import {createUseStyles} from 'react-jss';

const viewReportsStyle = createUseStyles({

  reportButton: {
      position: 'absolute',
      left: '200px'
  },

  exportBtn: {
      //position: 'absolute',
      marginLeft: '1%'
  },

  exportAllBTN: {
      //position: 'absolute',
      marginLeft: '1%'
  },

  requestAll: {
      paddingTop: '1%'
  },

  filter: {
    paddingRight: '1.4%'
  },
  printable: {
    display: 'none'
  },
  printHide: {},
  "@media print": { //hide/show things when printing
      printHide: {
          display: 'none'
      },
      printable: {
          display: 'block'
      }
  },

})

export default viewReportsStyle;
