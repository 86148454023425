import {createUseStyles} from 'react-jss';

const dashboardStyle = createUseStyles({
    tooltip: {
        backgroundColor: 'white',
        padding: '3%',
        border: 'solid lightgrey 1px'
    
    },
    label: {

    },
    colorLabel: {
        color: ''
    }
});

export default dashboardStyle;