import React from "react";
import { useState, useEffect } from "react";
import Box from "../Box/Box"; 
import "./AccordionMobile.css";

const AccordionMobile = (props) => {

  const onClick = () => {
    var acc = document.getElementsByClassName("accordion");
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        /* Toggle between adding and removing the "active" class,
                to highlight the button that controls the panel */
        this.classList.toggle("active");
        /* Toggle between hiding and showing the active panel */
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  };

  const [processInfoState, setProcessInfoState] = useState({});



  useEffect(() => {

    const evtSourceProcess = new EventSource(process.env.REACT_APP_SERVER_ROOT + "process/sseRead.php");


    function turnEventToJSONProcess(e) {
      let message = JSON.parse(e.data);
      delete message["status"]
      setProcessInfoState(message);
    }

    evtSourceProcess.onmessage = e => turnEventToJSONProcess(e);
  }, []);


  const formatDate = (date) => {
    let formattedDate = new Date(date);
    return formattedDate.toLocaleDateString("en-US");
  };


  return (
    <div class="infoContainer">
      {/* Customer: {props.customer} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Order: {props.order} */}
      <button class="accordion" onClick={onClick}><a class="list-group-item list-group-item-action">⬇️ {props.customer}({props.order})&nbsp;&nbsp;&nbsp;Pieces: {props.quantity}<hr></hr>
        <div>
          <div className="progressBarContainer">
          {Array.from(Array(Object.keys(processInfoState).length)).map((x, index) => (index < processInfoState[parseInt(props.process)]['numberOfStages']) ? <Box stageIDs={JSON.stringify(processInfoState[parseInt(props.process)]['stageIDs'])} stages={JSON.stringify(processInfoState[parseInt(props.process)]['stages'])} index={index} currentProcess={props.currentStage}></Box> : console.log(""))}
          </div>
        </div>
        <br></br><a id="a">Received: {formatDate(props.receive)}</a> <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Shipment: {formatDate(props.ship)}</a></a></button>
      <div class="panel">
        <p>Quantity: {props.quantity}</p>
        <p>Current Stage: {props.currentStage}</p>
      </div>
  
      {/* {Array.from(Array(Object.keys(processInfoState).length)).map((x, index) => console.log(processInfoState[parseInt(props.process)]['processID']))} */}
      
      


    </div>
  );
};

export default AccordionMobile;
