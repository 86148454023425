import React from 'react';
import Navbar from './navbar';
import { Button, Modal, Table } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import '../css/createProcess.css';

import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import createProcessStyle from '../jss/createProcess';
import containersStyle from '../jss/containers';

const withStyles = hocify(() => {
    const createProcess = createProcessStyle();
    const containers = containersStyle();

    return { createProcess, containers };
});


class CreateProcess extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            numStages: 0,
            processName: '',
            stages: null,
            stageOptions: null,
            viewProcessesOpen: false,
            viewCuttingToolProcesses: false,
            Processes: null,
            processStages: [],
            lastProcess: 1000000000
        };

        this.createProcess = this.createProcess.bind(this);
        this.checkProcess = this.checkProcess.bind(this);
        this.updateNumStages = this.updateNumStages.bind(this);
        this.updateProcessName = this.updateProcessName.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setChecked = this.setChecked.bind(this);

        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "process/sseRead.php");

        this.abortController = new AbortController();
    }

    componentDidMount() {
        this.getStages();
        this.processEvtSource.onmessage = e => this.receiveProcessData(e);
        this.setChecked();
    }

    componentWillUnmount() {
        this.abortController.abort();
        this.processEvtSource.close();
    }

    receiveProcessData(e) {
        let message = JSON.parse(e.data);
        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    async getStages() {

        try {
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "/stage/read.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                signal: this.abortController.signal,
                body: {
                    token: sessionStorage.getItem("token"),
                    jobRole: sessionStorage.getItem("jobRole")
                }
            });

            let responseJSON = await response.json();
            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 400) {
                return;
            } else if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 200) {
                delete responseJSON["status"];
            }


            const options = []
            let keys = Object.keys(responseJSON);
            for (let key in keys) {
                options.push(<option key={key}>
                    {responseJSON[keys[key]]}
                </option>);
            }

            this.setState({
                stages: responseJSON,
                stageOptions: options
            });

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }

    }

    lookUpStage(stageName) {
        for (let key in this.state.stages) {
            if (this.state.stages[key] === stageName) {
                return key;
            }
        }

    }

    async createProcess() {

        //make sure that there is at least one stage for a process
        if (this.state.numStages < 1) {
            alert("A process must include one stage");
            return;
        }

        //make sure the process has a name
        if (this.state.processName === "" || this.state.processName === null) {
            alert("A process must have a name");
            return;
        }

        let processJson = {
            processName: this.state.processName,
            numberOfStages: this.state.numStages,
            stageIDs: {},
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        };

        for (let i = 0; i < this.state.numStages; i++) {
            //let val = document.getElementById("stageDropdown" + i).value
            processJson["stageIDs"][i] = this.state.processStages[i];
        }

        processJson = JSON.stringify(processJson);
        try {
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "/process/create.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                signal: this.abortController.signal,
                body: processJson
            });

            let responseJSON = await response.json();
            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 400) {
                return;
            }
            else if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 415) {
                alert("A process with this name already exists please use a new one");
                return;
            }
            else if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 200) {
                alert("Process was created and given ID " + responseJSON["processID"]);
                this.state.lastProcess = responseJSON["processID"];
                delete responseJSON["status"];
            }

            //uncheck all the boxes
            for (let i = 0; i < 19; i++) {
                document.getElementById(i + 1).checked = false;
            }
            //empty out the processStages array
            this.state.processStages.splice(0, this.state.processStages.length);

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }

        //clear the fields
        this.setState({
            numStages: 0,
            processName: ''
        });

        this.setChecked();
    }

    async checkProcess() {

        //make sure that there is at least one stage for a process
        if (this.state.numStages < 1) {
            alert("A process must include one stage");
            return;
        }

        let processJson = {
            numberOfStages: this.state.numStages,
            stageIDs: {},
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        };

        for (let i = 0; i < this.state.numStages; i++) {
            //let val = document.getElementById("stageDropdown" + i).value
            processJson["stageIDs"][i] = this.state.processStages[i];
        }

        processJson = JSON.stringify(processJson);
        try {
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "/process/check.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                signal: this.abortController.signal,
                body: processJson
            });
            let responseJSON = await response.json();
            //console.log(responseJSON);
            if (responseJSON != -1) {
                alert("Process already exists: " + responseJSON);
            }
            else {
                alert("This process does not exist yet");
            }


            //uncheck all the boxes
            /*for (let i = 0; i < 19; i++) {
                document.getElementById(i + 1).checked = false;
            }
            //empty out the processStages array
            this.state.processStages.splice(0, this.state.processStages.length);
            return 0;*/
        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }


    updateNumStages(event) {
        let num = event.target.value;

        if (num > 1000) {
            num = 1000;
            alert("Max number of stages is capped to 1,000");
        }

        this.setState({ numStages: num });
    }

    updateProcessName(event) {
        this.setState({ processName: event.target.value });
        //this.setChecked();

    }

    setChecked() {
        //document.getElementById(1).checked = true;
        //console.log(document.getElementById(1));
        this.state.processStages.push("1");
        this.state.processStages.push("2");
        this.state.processStages.push("10");
        this.state.processStages.push("14");
        this.state.processStages.push("18");
        this.state.processStages.push("19");
        this.state.numStages = 6;
        //document.getElementById(2).checked = true;
        //document.getElementById(10).checked = true;
        //document.getElementById(14).checked = true;
        //document.getElementById(18).checked = true;
        //document.getElementById(19).checked = true;
    }

    handleChecking(event) {
        //this.setChecked();

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        if (value === true) {
            this.state.numStages++;
            this.state.processStages.push(name);
        }
        else if (value === false) {
            this.state.numStages--;
            if (this.state.numStages < 0)
                this.state.numstages = 0;
            const index = this.state.processStages.indexOf(name);
            if (index > -1)
                this.state.processStages.splice(index, 1);
        }
        this.state.processStages.sort(function (a, b) { return a - b });
    }


    handleInputChange(event) {
        //this.setChecked();
        //console.log(event.target);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        if (value === true) {
            this.state.numStages++;
            this.state.processStages.push(name);
        }
        else if (value === false) {
            this.state.numStages--;
            if (this.state.numStages < 0)
                this.state.numstages = 0;
            const index = this.state.processStages.indexOf(name);
            if (index > -1)
                this.state.processStages.splice(index, 1);
        }
        this.state.processStages.sort(function (a, b) { return a - b });
    }

    render() {

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") !== 'Manager') {
            return (<Redirect to="/signin" />);
        }

        //wait until we get process data
        if (this.state.Processes === null) {
            return (<Navbar />);
        }

        const processesModal = [];
        for (let i = 1; i <= Object.keys(this.state.Processes).length; i++) {
            let stageIDs = "";
            let stageNames = "";

            //append the stage names and ids
            for (let stageIndex = 1; stageIndex <= Object.keys(this.state.Processes[i].stageIDs).length; stageIndex++) {
                //Only show non-optional stages
                if (this.state.Processes[i].stageIDs[stageIndex] != 1 && this.state.Processes[i].stageIDs[stageIndex] != 2 && this.state.Processes[i].stageIDs[stageIndex] != 10 && this.state.Processes[i].stageIDs[stageIndex] != 14 && this.state.Processes[i].stageIDs[stageIndex] != 18 && this.state.Processes[i].stageIDs[stageIndex] != 19) {
                    //add the elements
                    stageIDs += this.state.Processes[i].stageIDs[stageIndex];
                    stageNames += this.state.Processes[i].stages[stageIndex];


                    //add a comma if this isn't the last element
                    if (stageIndex != Object.keys(this.state.Processes[i].stageIDs).length) {
                        stageIDs += ", ";
                        stageNames += ", ";
                    }
                }
            }

            processesModal.push(<Table.Row key={i}>
                <Table.Cell>{this.state.Processes[i].processID}</Table.Cell>
                <Table.Cell>{stageIDs}</Table.Cell>
                <Table.Cell>{stageNames}</Table.Cell>
            </Table.Row>);
        }

        return (<div>
            <Navbar />

            <div className={this.props.containers.container}>
                Process Name: <input type="text" className={this.props.createProcess.processName} value={this.state.processName} onChange={this.updateProcessName} />
                <Button primary onClick={this.checkProcess}>Check Existence</Button>
                <Button primary onClick={this.createProcess}>Create</Button>
                <div>
                    <Modal
                        onClose={() => this.setState({ viewProcessesOpen: false })}
                        onOpen={() => this.setState({ viewProcessesOpen: true })}
                        open={this.state.viewProcessesOpen}
                        trigger={<span className={this.props.createProcess.modalOpenLink}>View Processes</span>}>
                        <Modal.Header>View Processes</Modal.Header>
                        <Modal.Content>
                            <Table celled>
                                <Table.Header>
                                    <Table.HeaderCell>Process</Table.HeaderCell>
                                    <Table.HeaderCell>Stage IDs</Table.HeaderCell>
                                    <Table.HeaderCell>Stage Names</Table.HeaderCell>
                                </Table.Header>
                                <Table.Body>
                                    {processesModal}
                                </Table.Body>
                            </Table>
                        </Modal.Content>
                    </Modal>
                    {'\u00A0\u00A0\u00A0\u00A0\u00A0'}
                    <Modal
                        onClose={() => this.setState({ viewCuttingToolProcesses: false })}
                        onOpen={() => this.setState({ viewCuttingToolProcesses: true })}
                        open={this.state.viewCuttingToolProcesses}
                        trigger={<span className={this.props.createProcess.modalOpenLink}>View Cutting Tool Processes</span>}>
                        <Modal.Header>View Cutting Tool Processes</Modal.Header>
                        <Modal.Content>
                            <h1>
                                Process #83 - M1 coating only <br></br><br></br>
                                Process #84 - M2 coating only <br></br><br></br>
                                Process #85 - M1 coating and Aero post-lapping <br></br><br></br>
                                Process #86 - M2 coating and Aero post-lapping
                            </h1>
                        </Modal.Content>
                    </Modal>
                </div>

                <hr />

                <h3>Process Stage Setup</h3>
                <div className="checkboxes">
                    <label>
                        <input id="1" name="cb" type="checkbox" disabled="disabled" checked={true} onChange={this.handleInputChange} />
                        1: Pre-Inspection
                    </label>
                    <br />
                    <label>
                        <input id="2" name="cb" type="checkbox" disabled="disabled" checked={true} onChange={this.handleInputChange} />
                        2: Pre-Washing (Jig-Washer)
                    </label>
                    <br />
                    <label>
                        <input id="3" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        3: Stripping (Shot)
                    </label>
                    <br />
                    <label>
                        <input id="4" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        4: Stripping (Liquid)
                    </label>
                    <br />
                    <label>
                        <input id="5" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        5: WPC-SKH
                    </label>
                    <br />
                    <label>
                        <input id="6" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        6: WPC-Ceramic
                    </label>
                    <br />
                    <label>
                        <input id="7" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        7: Pre-Lapping (SMAP)
                    </label>
                    <br />
                    <label>
                        <input id="8" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        8: Pre-Lapping (AERO)
                    </label>
                    <br />
                    <label>
                        <input id="9" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        9: Pre-Lapping (Hand Lapping)
                    </label>
                    <br />
                    <label>
                        <input id="10" name="cb" type="checkbox" disabled="disabled" checked={true} onChange={this.handleInputChange} />
                        10: Pre-Washing (F1 Clean Washer)
                    </label>
                    <br />
                    <label>
                        <input id="11" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        11: Coating (M1) - Set/Disassemble
                    </label>
                    <br />
                    <label>
                        <input id="12" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        12: Coating (M2) - Set/Disassemble
                    </label>
                    <br />
                    <label>
                        <input id="13" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        13: Coating (M3) - Set/Disassemble
                    </label>
                    <br />
                    <label>
                        <input id="14" name="cb" type="checkbox" disabled="disabled" checked={true} onChange={this.handleInputChange} />
                        14: Post-Inspection
                    </label>
                    <br />
                    <label>
                        <input id="15" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        15: Post-Lapping (SMAP)
                    </label>
                    <br />
                    <label>
                        <input id="16" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        16: Post-Lapping (AERO)
                    </label>
                    <br />
                    <label>
                        <input id="17" name="cb" type="checkbox" checked={this.state.checked} onChange={this.handleInputChange} />
                        17: Post-Lapping (Hand Lapping)
                    </label>
                    <br />
                    <label>
                        <input id="18" name="cb" type="checkbox" disabled="disabled" checked={true} onChange={this.handleInputChange} />
                        18: Final Inspection
                    </label>
                    <br />
                    <label>
                        <input id="19" name="cb" type="checkbox" disabled="disabled" checked={true} onChange={this.handleInputChange} />
                        19: Packing/Shipping
                    </label>
                    <br />
                </div>
            </div>

        </div>);
    }

}

export default withStyles(CreateProcess);
