import React from 'react';
import { Redirect } from 'react-router-dom';
import { Menu, Dropdown, Sticky } from 'semantic-ui-react'
import hocify from 'hocify';
import fontStyle from '../jss/Font';

const withStyles = hocify(fontStyle);

//look at this for dynamic size changing https://codesandbox.io/s/325y47xk36

class Navbar extends React.Component {

    constructor(props) {
        super(props);

        //setup the state
        this.state = {
            activeItem: '',
            redirect: null
        };


        this.handleClick = this.handleClick.bind(this);

        //if a pre-selected page was passed in, select it
        if (props != null && props.selectedPage != null) {
            this.state = { activeItem: props.selectedPage };
        }
    }

    handleClick(event, { name }) {
        this.setState({ activeItem: name });
        this.setState({ redirect: "/" + name });
    }

    render() {

        //if a redirect was requested, complete it if not already on that page
        if (this.state.redirect && window.location.pathname !== this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        //get the current active item from the state
        const { activeItem } = this.state;

        let loginStatus;
        if (sessionStorage.getItem('token') !== null) {
            loginStatus = "Logout";
        } else {
            loginStatus = "Login";
        }

        let job = sessionStorage.getItem('jobRole');

        return (
            <Sticky context={this.contextRef}>
                <Menu>
                    {(job === 'Manager' || job === 'Employee') &&
                        <Dropdown item simple text='Product Entry' style={{ fontSize: '12px' }} className={this.props.Accuratist}>
                            <Dropdown.Menu style={{ fontSize: '14px' }}>
                                {(job === 'Manager') &&
                                    <Dropdown.Item className={this.props.Accuratist}
                                        name="enterProduct"
                                        active={activeItem === 'enterProduct'}
                                        onClick={this.handleClick}>
                                        Enter a Product
                                    </Dropdown.Item >
                                }
                                {(job === 'Manager') &&
                                    <Dropdown.Item className={this.props.Accuratist}
                                        name="enterProduct2"
                                        active={activeItem === 'enterProduct2'}
                                        onClick={this.handleClick}>
                                        Enter Sub-Product
                                    </Dropdown.Item>
                                }
                                {(job === 'Manager') &&
                                    <Dropdown.Item className={this.props.Accuratist}
                                        name="generateCSV"
                                        active={activeItem === 'generateCSV'}
                                        onClick={this.handleClick}>
                                        Create Input Files
                                    </Dropdown.Item>
                                }
                                {(job === 'Manager' || job === 'Employee' || job === 'Viewer') &&
                                    <Dropdown.Item className={this.props.Accuratist}
                                        name="cuttingTools"
                                        active={activeItem === 'cuttingTools'}
                                        onClick={this.handleClick}>
                                        Cutting Tool Input
                                    </Dropdown.Item>
                                }
                                {(job === 'Manager') &&
                                    <Dropdown.Item className={this.props.Accuratist}
                                        name="productInput"
                                        active={activeItem === 'productInput'}
                                        onClick={this.handleClick}>
                                        Submit Product Files
                                    </Dropdown.Item>
                                }
                                {(job === 'Manager') &&
                                    <Dropdown.Item className={this.props.Accuratist}
                                        name="takePicture"
                                        active={activeItem === 'takePicture'}
                                        onClick={this.handleClick}>
                                        Submit Product Photo
                                    </Dropdown.Item>
                                }

                            </Dropdown.Menu>
                        </Dropdown>}

                    {(job === 'Manager') &&
                        <Menu.Item className={this.props.Accuratist}
                            name='enterEmployee'
                            active={activeItem === 'enterEmployee'}
                            color={'blue'}
                            onClick={this.handleClick}>
                            Enter an Employee
                        </Menu.Item>
                    }
                    {(job === 'Manager' || job === 'Employee') &&
                        <Menu.Item className={this.props.Accuratist}
                            name='scanItem'
                            active={activeItem === 'scanItem'}
                            color={'blue'}
                            onClick={this.handleClick}>
                            Scan an Item
                        </Menu.Item>
                    }
                    {(job === 'Manager' || job === 'Viewer' || job === 'Employee') &&
                        <Dropdown item simple text='Analytics' style={{ fontSize: '12px' }} className={this.props.Accuratist}>
                            <Dropdown.Menu style={{ fontSize: '14px' }}>
                                {(job === 'Manager') &&
                                    <Dropdown.Item className={this.props.Accuratist}
                                        name="managerView"
                                        active={activeItem === 'managerView'}
                                        onClick={this.handleClick}>
                                        Manager View
                                    </Dropdown.Item>
                                }
                                {(job === 'Manager' || job === 'Employee' || job === 'Viewer') &&
                                    <Dropdown.Item className={this.props.Accuratist}
                                        name="dashboardView"
                                        active={activeItem === 'dashboardView'}
                                        onClick={this.handleClick}>
                                        Dashboard View
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    {(job === 'Manager') &&
                        <Menu.Item className={this.props.Accuratist}
                            name='viewReports'
                            active={activeItem === 'viewReports'}
                            color={'blue'}
                            onClick={this.handleClick}>
                            View Reports
                        </Menu.Item>
                    }
                    {(job === 'Manager') &&
                        <Menu.Item className={this.props.Accuratist}
                            name='createProcess'
                            active={activeItem === 'createProcess'}
                            color={'blue'}
                            onClick={this.handleClick}>
                            Create Process
                        </Menu.Item>
                    }
                    {(job === 'Manager' || job === 'Employee') &&
                        <Dropdown item simple text='Views' style={{ fontSize: '12px' }} className={this.props.Accuratist}>
                            <Dropdown.Menu style={{ fontSize: '14px' }}>
                                <Dropdown.Item className={this.props.Accuratist}
                                    name="recentlyCompleted"
                                    active={activeItem === 'recentlyCompleted'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Recently Completed
                                </Dropdown.Item>
                                <Dropdown.Item className={this.props.Accuratist}
                                    name="scheduleView2"
                                    active={activeItem === 'scheduleView2'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Coating Schedule
                                </Dropdown.Item>
                                <Dropdown.Item className={this.props.Accuratist}
                                    name="shippingMethod"
                                    active={activeItem === 'shippingMethod'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Shipping Method
                                </Dropdown.Item>
                                <Dropdown.Item className={this.props.Accuratist}
                                    name="customerView"
                                    active={activeItem === 'customerView'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Customer View
                                </Dropdown.Item>
                                <Dropdown.Item className={this.props.Accuratist}
                                    name="enterExcel"
                                    active={activeItem === 'enterExcel'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Inspections
                                </Dropdown.Item>
                                <Dropdown.Item className={this.props.Accuratist}
                                    name="mapItems"
                                    active={activeItem === 'mapItems'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Map of Items
                                </Dropdown.Item>
                                {/*<Dropdown.Item className={this.props.Accuratist}
                                    name="productImages"
                                    active={activeItem === 'productImages'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Product Images
                </Dropdown.Item>*/}
                                {/*(job === 'Manager') &&
                                    <Dropdown.Item className={this.props.Accuratist}
                                        name='productView'
                                        active={activeItem === 'productView'}
                                        color={'blue'}
                                        onClick={this.handleClick}>
                                        Product View
                </Dropdown.Item>*/}
                                {(job === 'Manager') &&
                                    <Dropdown.Item className={this.props.Accuratist}
                                        name='coatingTime'
                                        active={activeItem === 'coatingTime'}
                                        color={'blue'}
                                        onClick={this.handleClick}>
                                        Coating Time
                                    </Dropdown.Item>}
                                {/*<Dropdown.Item className={this.props.Accuratist}
                                    name="scheduleView"
                                    active={activeItem === 'scheduleView'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Coating Schedule
                </Dropdown.Item>*/}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    {(job === 'Manager' || job === 'Employee') &&
                        <Dropdown item simple text='Product Statistics' style={{ fontSize: '12px' }} lassName={this.props.Accuratist}>
                            <Dropdown.Menu style={{ fontSize: '14px' }}>
                                <Dropdown.Item className={this.props.Accuratist}
                                    name='productStatistics'
                                    active={activeItem === 'productStatistics'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Product Statistics
                                </Dropdown.Item>
                                <Dropdown.Item className={this.props.Accuratist}
                                    name='productStatisticsArchive'
                                    active={activeItem === 'productStatisticsArchive'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Product Statistics Archive
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    {(job === 'Manager' || job === 'Employee') &&
                        <Dropdown item simple text='Sub-Product Statistics' style={{ fontSize: '12px' }} className={this.props.Accuratist}>
                            <Dropdown.Menu style={{ fontSize: '14px' }}>
                                <Dropdown.Item className={this.props.Accuratist}
                                    name='productStatistics2'
                                    active={activeItem === 'productStatistics2'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Sub-Product Statistics
                                </Dropdown.Item>
                                <Dropdown.Item className={this.props.Accuratist}
                                    name='productStatisticsSubArchive'
                                    active={activeItem === 'productStatisticsSubArchive'}
                                    color={'blue'}
                                    onClick={this.handleClick}>
                                    Sub-Product Statistics Archive
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    <Menu.Item className={this.props.Accuratist}
                        name='signin'
                        active={activeItem === 'signin'}
                        color={'blue'}
                        onClick={this.handleClick}>
                        {loginStatus}
                    </Menu.Item>

                </Menu>
            </Sticky>
        );
    }

}

export default withStyles(Navbar);
