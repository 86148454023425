import React from 'react';
import Navbar from '../navbar';
import {Redirect} from 'react-router-dom';
import { Dropdown, Modal, Checkbox } from 'semantic-ui-react';

import TimelineMargins from './timelineMargins';
import MachineUptime from './machineUptime';
import EmployeeStatistics from './employeeStatistics';
import ExportCSV from './exportCSV';
import ExportAllCSV from './exportAllCSV';

import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import viewReportsStyle from '../../jss/reports/viewReports';
import containersStyle from '../../jss/containers';

const withStyles = hocify(() => {
  const viewReports = viewReportsStyle();
  const containers = containersStyle();

  return { viewReports, containers };
});

class ViewReports extends React.Component {

    constructor(props){
        super(props);

        //setup the state
        this.state = {
            activeItem: 0,
            report: 0,
            subReportData: null,
            reports: {
                "0": "Order Timeline Analysis",
                "1": "Machine Usage Analysis",
                "2": "Employee Statistics"
            },
            modalOpen: false,
            startDate: 0,
            timePeriodSelection: "daily",
            getAll: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.getReport = this.getReport.bind(this);
        this.getSubReportData = this.getSubReportData.bind(this);
        this.exportAllClick = this.exportAllClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    }

    handleClick(event, {name}){
        this.setState({
            activeItem: name,
            report: name
        });
    }

    handleTimeUpdate(event, {name}){
        this.setState({ timePeriodSelection: name });
    }

    handleChange(event){
        if(event.target.id === "getAll"){
            this.setState({getAll: event.target.checked});
        }else if(event.target.id === "startDate"){
            this.setState({startDate: event.target.value});
        }
    }

    exportAllClick(){
        this.setState({modalOpen: true});
    }

    renderExportAll(){

        //get the sheet names from the reports
        let sNames = [];
        for(let i = 0; i < Object.keys(this.state.reports).length; i++){
            sNames[i] = this.state.reports[i];
        }

        const customDateControls = []
        if(this.state.timePeriodSelection === "custom"){
            customDateControls.push(<div key='0'>
                <label>
                    Number of days to search:{" "}
                    <input type="number" className={this.props.containers.textBox} placeholder={this.state.startDate} onChange={this.handleChange} id="startDate" name="startDate" />
                </label>
            </div>);
        }

        return(<Modal
            open={this.state.modalOpen}
            onClose={() => this.setState({modalOpen: false})}
        >
            <Modal.Header>Export All Reports</Modal.Header>

            <div className={this.props.containers.container}>

                <span className={this.props.viewReports.filter}>
                    Time Period:{" "}
                    <Dropdown item text={this.state.timePeriodSelection}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                name="daily"
                                active={this.state.timePeriodSelection === 'daily'}
                                onClick={this.handleTimeUpdate}
                            >
                                Past 24 Hours
                            </Dropdown.Item>

                            <Dropdown.Item
                                name="weekly"
                                active={this.state.timePeriodSelection === 'weekly'}
                                onClick={this.handleTimeUpdate}
                            >
                                Weekly
                            </Dropdown.Item>

                            <Dropdown.Item
                                name="monthly"
                                active={this.state.timePeriodSelection === 'monthly'}
                                onClick={this.handleTimeUpdate}
                            >
                                Monthly
                            </Dropdown.Item>

                            <Dropdown.Item
                                name="custom"
                                active={this.state.timePeriodSelection === 'custom'}
                                onClick={this.handleTimeUpdate}
                            >
                                Custom
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>

                    {customDateControls}
                </span>

                <div className={this.props.viewReports.requestAll}>
                    <Checkbox name="getAll" id="getAll" label='Request All Products' onChange={this.handleChange}/>
                </div>

            </div>

            <Modal.Content>
                <ExportAllCSV
                    fileName={"reportExport_" + new Date().toJSON().split("T")[0] + "_" + this.state.timePeriodSelection}
                    sheetNames={sNames}
                    requestAll={this.state.getAll}
                    period={this.state.timePeriodSelection}
                    customRange={this.state.startDate}
                />
            </Modal.Content>
        </Modal>);
    }

    //setup the callback to recieve the current report's data
    getSubReportData(json){
        this.setState({subReportData: json}); 
        //this.addToJsonList(json);
    }

    getReport(event){
        this.setState({report: this.state.activeItem});
    }

    render(){

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null){
            return(<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager')
        {
            return (<Redirect t0="/signin" />);
        }

        //create dropdown items from the reports json
        const dropdowns = []
        for(let i = 0; i < Object.keys(this.state.reports).length; i++){
            dropdowns.push(<Dropdown.Item
                name={i}
                key={i}
                active={this.state.activeItem === {i}}
                onClick={this.handleClick}
            >
                {this.state.reports[i]}
            </Dropdown.Item>);
        }

        //get the report data
        const report = []

        //This might not be the best way to do it but flagging each report because they have different structures
        var flag = 0;

        if(this.state.report !== null){
            if(this.state.report === 0)
              {
                report.push(<TimelineMargins key="0" sendData={this.getSubReportData}/>);
                flag = 0;
              }
            if(this.state.report === 1)
              {
                report.push(<MachineUptime key="1" sendData={this.getSubReportData}/>);
                flag = 1;
              }
            if(this.state.report === 2)
              {
               report.push(<EmployeeStatistics key="2" sendData={this.getSubReportData}/>);
                flag = 2;
              }
        }

        var jsonFile = this.state.subReportData;


        //test csv
        var jsonString = ([ "",]);
        var empty = true;

            jsonString = (jsonFile);

          //real csv data
          if (jsonFile !== null && Object.keys(jsonFile).length !== 0 ) {
            empty = false;

            jsonString = (jsonFile);
            jsonString = (Object.values(jsonString));
            //The 2nd report has the data at jsonString[2];
            //It changed, is now at jsonString[1]
            if (flag === 1 && jsonString[2] !== null && jsonString[2] !== undefined) {
              if ((jsonString[1])) {
                jsonString = (Object.values(jsonString[1]));
                if (jsonString[0]) {
                  if (flag === 1 && jsonString[0].numberOfOrders === 0) {
                    empty = true;
                  }
                }
              }
            }

            //The 3rd report has the data at jsonString[0]
            //Has changed, is now just in jsonString
            /*if (flag === 2) {
              jsonString = (Object.values(jsonString[0]));
              console.log("employee jsonstring: " + jsonString);
            }*/

            if (jsonString.length === 0) {
              empty = true;
            }

          }



          //Put data back into jsonString if a time without entries is chosen after a time with entries to prevent crash
          else {
            empty = true;
            jsonString = ([ "",]);
            jsonString = (Object.values(jsonString));
          }

          var message = "";

          if (empty) {
            message =  <div class="ui negative message">
            <i class="close icon"></i>
            <div class="header">
            Warning: exporting blank file
            </div>
            <p>No items in specified range, export file will be blank
            </p></div>
            jsonString = [""];
          }
          
        return(<div >
            <span className={this.props.viewReports.printHide}>
                <Navbar selectedPage="viewReports" />

                {this.renderExportAll()}
            </span>


            <div className={this.props.containers.container + " " + "blueBackground" + " " + this.props.viewReports.printHide}>
                <Dropdown item text={this.state.reports[this.state.activeItem]}>
                    <Dropdown.Menu>
                        {dropdowns}
                    </Dropdown.Menu>
                </Dropdown>

                    <ExportCSV
                        className={this.props.viewReports.exportBtn}
                        csvData=
                        {jsonString}
                        fileName={(this.state.activeItem === 0) ? "timelineAnalysisReport_" + new Date().toJSON().split("T")[0] + "_" + this.state.timePeriodSelection : (this.state.activeItem === 1) ? "machineUsageReport_" + new Date().toJSON().split("T")[0] + "_" + this.state.timePeriodSelection : "employeeStatisticsReport_" + new Date().toJSON().split("T")[0] + "_" + this.state.timePeriodSelection}
                    />

                    <input className="exportAllBtn" type="button" value="Export All" onClick={this.exportAllClick}/>

                    {message}

                <hr />

            </div>

            <div className={this.props.containers.container + " " + "blueBackground"}>
            {report}
            </div>

        </div>);

    }
}

export default withStyles(ViewReports);
