import React from 'react';
import { Dropdown, Table, Menu, Icon, Input, Grid, Button, Label } from 'semantic-ui-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import ExportChart from './exportChart';

import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import timelineStyle from '../../jss/reports/timelineMargins';
import containersStyle from '../../jss/containers';

const withStyles = hocify(() => {
  const timeline = timelineStyle();
  const containers = containersStyle();

  return { timeline, containers };
});

class TimelineMargins extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            timePeriodSelection: 'daily',
            requestAll: false,
            startDate: 0,
            numberRowsToShow: 10,
            queryResults: null,
            currentDate: "",
            productsOnTime: null,
            orderTableCurrentPage: 1,
            onTimeTableCurrentPage: 1,
            filteredData: null,
            orderIDFilter: "",
            customerFilter: "",
            coatingTypeFilter: "",
            possibleOrderIDs: null,
            possibleCustomers: null,
            possibleCoatingTypes: null
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.orderTableHandlePageClick = this.orderTableHandlePageClick.bind(this);
        this.onTimeTableHandlePageClick = this.onTimeTableHandlePageClick.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.updateOrderID = this.updateOrderID.bind(this);
        this.updateCustomer = this.updateCustomer.bind(this);
        this.updateCoatingType = this.updateCoatingType.bind(this);

        this.abortController = new AbortController();
    }

    //callback to view reports, to send the report data
    sendData(){
        this.props.sendData(this.state.queryResults);
    }

    //get the report data after the component is ready
    componentDidMount(){
        this.getReportData(this.state.timePeriodSelection, this.state.startDate);
    }

    //sends a request to the server, and handles the recieved data
    async getReportData(customTimePeriod, customDate){

        //don't let the custom date selection have a empty or null value
        if(customTimePeriod === "custom" && (customDate === "" || customDate === null)){
            customDate = 0;
        }

        let reportJSON = JSON.stringify({
            requestAll: this.state.requestAll,
            orderPeriod: customTimePeriod,
            customRange: customDate,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try{
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "/analytics/timeline/read.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: reportJSON,
                signal: this.abortController.signal
                });

        //process the results of the operation
        let responseJSON = await response.json();
        let currentDate = responseJSON["currentTime"];
        delete responseJSON["currentTime"];
        this.setState({queryResults: responseJSON, currentDate: currentDate, filteredData: responseJSON});
        this.sendData();
        this.calculatePossibleFilters();
 
        if(this.state.queryResults !== null)
        {
          this.calculateReportsOnTime();
        }

      } catch (error) {
          if(error.name === 'AbortError'){
          }
      }
    }

    //if the component gets unmounted cancle the fetch request
    componentWillUnmount(){
        this.abortController.abort();
    }

    calculatePossibleFilters(){

        //three types of filter tags, all with a empty option if we don't want to filter that tag
        let customer = [];
        let coatingType = [];

        //check all rows for possible filter tags
        for(let i = 0; i < Object.keys(this.state.queryResults).length; i++){

            //add the current customer to the filter array if it hasn't been added yet
            if(!customer.includes(this.state.queryResults[i]["Customer"])){
                customer.push(this.state.queryResults[i]["Customer"]);
            }

            //add the current order type id to the filter array if it hasn't been added yet
            if(!coatingType.includes(this.state.queryResults[i]["Coating"])){
                coatingType.push(this.state.queryResults[i]["Coating"]);
            }

        }

        this.setState({
            possibleCustomers: customer,
            possibleCoatingTypes: coatingType
        });

    }

    calculateReportsOnTime(){

        let onTime = "On Time";
        let early = "Ahead";
        let late = "Behind";

        //format 1970-01-01 00:00:00
        let currentDate = this.state.currentDate;

        let jsonData = {};

        for(let i = 0; i < Object.keys(this.state.filteredData).length; i++){

            let shipDate = this.state.filteredData[i].DeliveryDate;
            let shipSchedule = this.state.filteredData[i].ShipmentSchedule;

            //if the current date is past the intended shipment date, and the product hasn't been shipped
            if(currentDate > shipSchedule && shipDate === null){
                jsonData[i] = late;
            }else if(shipDate > shipSchedule){ //shipped after intended date
                jsonData[i] = late;
            }else if(shipDate < shipSchedule){ //shipped before intended date
                jsonData[i] = early;
            }else if(shipDate === shipSchedule){ //shipped on intended date
                jsonData[i] = onTime;
            }

        }

        this.setState({productsOnTime: jsonData});

    }

    handleClick(event, {name}){
        this.setState({ timePeriodSelection: name });
        this.getReportData(name, this.state.startDate);
    }

    handleChange(event){

        //update the value for which ever field was changed
        if(event.target.id === "startDate"){
            this.setState({startDate: event.target.value});
            this.getReportData(this.state.timePeriodSelection, event.target.value)
        }

    }

    //control which page of the tabbed table is shown
    orderTableHandlePageClick(event, {value}) {
        //only go down a page if we aren't on the first page
        if(value === "left"){

            let curPage = this.state.orderTableCurrentPage;

            if(curPage >= 2){
                this.setState({orderTableCurrentPage: curPage-1});
            }

        }else if(value === "right"){//only go up a page if we aren't on the last page

            let curPage = this.state.orderTableCurrentPage

            if(curPage < Math.ceil(Object.keys(this.state.filteredData).length / this.state.numberRowsToShow)){
                this.setState({orderTableCurrentPage: curPage+1});
            }

        }else if(value === "start"){ //if the first page button was clicked
            this.setState({orderTableCurrentPage: 1});
        }else if(value === "end"){ //if the last page button was clicked
            this.setState({orderTableCurrentPage: Math.ceil(Object.keys(this.state.filteredData).length / this.state.numberRowsToShow)});
        }

        this.forceUpdate();
    }

    //control which page of the tabbed table is shown
    onTimeTableHandlePageClick(event, {value}) {
        //only go down a page if we aren't on the first page
        if(value === "left"){

            let curPage = this.state.onTimeTableCurrentPage;

            if(curPage >= 2){
                this.setState({onTimeTableCurrentPage: curPage-1});
            }

        }else if(value === "right"){//only go up a page if we aren't on the last page

            let curPage = this.state.onTimeTableCurrentPage

            if(curPage < Math.ceil(Object.keys(this.state.filteredData).length / this.state.numberRowsToShow)){
                this.setState({onTimeTableCurrentPage: curPage+1});
            }

        }else if(value === "start"){ //if the first page button was clicked
            this.setState({onTimeTableCurrentPage: 1});
        }else if(value === "end"){ //if the last page button was clicked
            this.setState({onTimeTableCurrentPage: Math.ceil(Object.keys(this.state.filteredData).length / this.state.numberRowsToShow)});
        }

        this.forceUpdate();
    }

    renderOrders(){

        //if data hasn't been recieved yet, render default state
        if(this.state.filteredData === null || this.state.productsOnTime === null){
            return(<div className={this.props.timeline.orderTable}>
            <h2>Orders</h2>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Order ID</Table.HeaderCell>
                        <Table.HeaderCell>Customer</Table.HeaderCell>
                        <Table.HeaderCell>Order Type</Table.HeaderCell>
                        <Table.HeaderCell>Date Received</Table.HeaderCell>
                        <Table.HeaderCell>Estimated Completion Date</Table.HeaderCell>
                        <Table.HeaderCell>Notes</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                </Table.Body>
            </Table>
        </div>);
        }

        const tableData = []

        for(let i = (this.state.orderTableCurrentPage - 1) * this.state.numberRowsToShow; i < Object.keys(this.state.filteredData).length && (i < this.state.orderTableCurrentPage * this.state.numberRowsToShow); i++){
            tableData.push(<Table.Row key={i}>
                <Table.Cell>{this.state.filteredData[i]["ProductID"]}</Table.Cell>
                <Table.Cell>{this.state.filteredData[i]["Customer"]}</Table.Cell>
                <Table.Cell>{this.state.filteredData[i]["Material"]}</Table.Cell>
                <Table.Cell>{this.state.filteredData[i]["DeliveryDate"]}</Table.Cell>
                <Table.Cell>{this.state.filteredData[i]["ShipmentSchedule"]}</Table.Cell>
                <Table.Cell>{this.state.filteredData[i]["Notes"]}</Table.Cell>
            </Table.Row>);
        }

        return(<div className={this.props.timeline.orderTable}>
            <h2>Number of Orders</h2>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Order ID</Table.HeaderCell>
                        <Table.HeaderCell>Customer</Table.HeaderCell>
                        <Table.HeaderCell>Order Type</Table.HeaderCell>
                        <Table.HeaderCell>Date Received</Table.HeaderCell>
                        <Table.HeaderCell>Estimated Completion Date</Table.HeaderCell>
                        <Table.HeaderCell>Notes</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {tableData}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>

                        <Table.HeaderCell colSpan='6'>
                            <Menu floated='right' pagination>
                                <Menu.Item>{this.state.orderTableCurrentPage}</Menu.Item>
                            </Menu>

                            <Menu floated='right' pagination>
                                <Menu.Item as='a' icon value="left" onClick={this.orderTableHandlePageClick}>
                                    <Icon name='chevron left'/>
                                </Menu.Item>
                                <Menu.Item as='a' value="start" onClick={this.orderTableHandlePageClick}>1</Menu.Item>
                                <Menu.Item>...</Menu.Item>
                                <Menu.Item as='a' value="end" onClick={this.orderTableHandlePageClick}>{Math.ceil(Object.keys(this.state.filteredData).length / this.state.numberRowsToShow)}</Menu.Item>
                                <Menu.Item as='a' icon value="right" onClick={this.orderTableHandlePageClick}>
                                    <Icon name='chevron right'/>
                                </Menu.Item>
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>);

    }

    renderSchedule(){

        //if data hasn't been recieved yet, render default state
        if(this.state.filteredData === null || this.state.productsOnTime === null){
            return(<div className={this.props.timeline.onTimeTable}>
                <h2>On Time</h2>

            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Order ID</Table.HeaderCell>
                        <Table.HeaderCell>Order Type</Table.HeaderCell>
                        <Table.HeaderCell>On Schedule</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                </Table.Body>

            </Table>

            </div>);
        }

        const tableData = []

        //add a color based status to the orders based on if there on time status
        for(let i = (this.state.onTimeTableCurrentPage - 1) * this.state.numberRowsToShow; i < Object.keys(this.state.filteredData).length && (i < this.state.onTimeTableCurrentPage * this.state.numberRowsToShow); i++){
          if (this.state.productsOnTime[i] === "Behind")
          {
            tableData.push(<Table.Row negative key={i}>
                  <Table.Cell>{this.state.filteredData[i]["ProductID"]}</Table.Cell>
                  <Table.Cell>{this.state.filteredData[i]["Material"]}</Table.Cell>
                  <Table.Cell> <Icon name='close' /> {this.state.productsOnTime[i]}</Table.Cell>
              </Table.Row>);
          }
          else if (this.state.productsOnTime[i] === "Ahead")
          {
            tableData.push(<Table.Row positive key={i}>
                  <Table.Cell>{this.state.filteredData[i]["ProductID"]}</Table.Cell>
                  <Table.Cell>{this.state.filteredData[i]["Material"]}</Table.Cell>
                  <Table.Cell> <Icon name='checkmark' /> {this.state.productsOnTime[i]}</Table.Cell>
              </Table.Row>);
          }
          else {
            tableData.push(<Table.Row key={i}>
                  <Table.Cell>{this.state.filteredData[i]["ProductID"]}</Table.Cell>
                  <Table.Cell>{this.state.filteredData[i]["Material"]}</Table.Cell>
                  <Table.Cell>{this.state.productsOnTime[i]}</Table.Cell>
              </Table.Row>);
          }
        }

        return(<div className={this.props.timeline.onTimeTable}>
            <h2>On Time</h2>

        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Order ID</Table.HeaderCell>
                    <Table.HeaderCell>Order Type</Table.HeaderCell>
                    <Table.HeaderCell>On Schedule</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {tableData}
            </Table.Body>

            <Table.Footer>
                    <Table.Row>

                        <Table.HeaderCell colSpan='6'>
                            <Menu floated='right' pagination>
                                <Menu.Item>{this.state.onTimeTableCurrentPage}</Menu.Item>
                            </Menu>

                            <Menu floated='right' pagination>
                                <Menu.Item as='a' icon value="left" onClick={this.onTimeTableHandlePageClick}>
                                    <Icon name='chevron left'/>
                                </Menu.Item>
                                <Menu.Item as='a' value="start" onClick={this.onTimeTableHandlePageClick}>1</Menu.Item>
                                <Menu.Item>...</Menu.Item>
                                <Menu.Item as='a' value="end" onClick={this.onTimeTableHandlePageClick}>{Math.ceil(Object.keys(this.state.filteredData).length / this.state.numberRowsToShow)}</Menu.Item>
                                <Menu.Item as='a' icon value="right" onClick={this.onTimeTableHandlePageClick}>
                                    <Icon name='chevron right'/>
                                </Menu.Item>
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>

        </Table>

        </div>);
    }

    renderBarGraph(){

        let numOnTime = 0;
        let numEarly = 0;
        let numLate = 0;

        //get the number of orders for each of the three possible time outcomes, only if the on time data has been set
        if(this.state.productsOnTime !== null){
            let timeJSON = this.state.productsOnTime;
            for(let i = 0; i < Object.keys(timeJSON).length; i++){

                switch(timeJSON[i]){
                    case "Ahead":
                        numEarly++;
                        break;
                    case "Behind":
                        numLate++;
                        break;
                    case "On Time":
                        numOnTime++;
                        break;
                    default:
                }

            }
        }

        //set the names and get the values for the bar graph
        const data = [
            {
              name: 'Early',
              orders: numEarly
            },
            {
              name: 'On Time',
              orders: numOnTime
            },
            {
              name: 'Late',
              orders: numLate
            }
          ];


        return(<div id="barChart" className={this.props.timeline.barGraphDiv}>
            <h4 style={{textAlign: "center"}}>Order Timeline Analysis</h4>
            <ResponsiveContainer>
                <BarChart
                    width={600}
                    height={305}
                    data={data}
                    margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name"/>
                    <YAxis label={{value: "Orders", position: "insideBottom", dx: -15, dy: -10}} allowDecimals={false}/>
                    <Tooltip />
                    <Bar dataKey="orders" fill="#3063A5" />
                    {/*green hex: #8884d8 yellow hex: #CCCC00 red hex: #FF0000 */}
                </BarChart>
            </ResponsiveContainer>
        </div>);
    }

    updateOrderID(event, {name}){
        this.setState({orderIDFilter: name}, () => {this.applyFilters();});
    }

    updateCustomer(event, {name}){
        this.setState({customerFilter: name}, () => {this.applyFilters();});
    }

    updateCoatingType(event, {name}){
        this.setState({coatingTypeFilter: name}, () => {this.applyFilters();});
    }

    renderFilterDropdowns(){

        const orderIDTags = []
        const customerTags = []
        const coatingTypeTags = []

        //setup the order id dropdown, only after the possible IDs have been filled
        if(this.state.possibleOrderIDs !== null){
            for(let i = 0; i < Object.keys(this.state.possibleOrderIDs).length; i++){
                orderIDTags.push(<Dropdown.Item
                    key={i}
                    name={this.state.possibleOrderIDs[i]}
                    onClick={this.updateOrderID}
                >
                        {this.state.possibleOrderIDs[i]}
                </Dropdown.Item>);
            }
        }

        //setup the customer dropdown, only after the possible customers have been filled
        if(this.state.possibleCustomers !== null){
            for(let i = 0; i < Object.keys(this.state.possibleCustomers).length; i++){
                customerTags.push(<Dropdown.Item
                    key={i}
                    name={this.state.possibleCustomers[i]}
                    onClick={this.updateCustomer}
                >
                        {this.state.possibleCustomers[i]}
                </Dropdown.Item>);
            }
        }

        //setup the order type dropdown, only after the possible types have been filled
        if(this.state.possibleCoatingTypes !== null){
            for(let i = 0; i < Object.keys(this.state.possibleCoatingTypes).length; i++){
                coatingTypeTags.push(<Dropdown.Item
                    key={i}
                    name={this.state.possibleCoatingTypes[i]}
                    onClick={this.updateCoatingType}
                >
                        {this.state.possibleCoatingTypes[i]}
                </Dropdown.Item>);
            }
        }

        return(<div>
            <div>
                <h3>Tags To Filter:</h3>
                <div>
                    Customer:{" "}
                    <Dropdown item text={this.state.customerFilter}>
                        <Dropdown.Menu>
                            {customerTags}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div>
                    Coating Type:{" "}
                    <Dropdown item text={this.state.coatingTypeFilter}>
                        <Dropdown.Menu>
                            {coatingTypeTags}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>);
    }

    applyFilters(){

        //apply the filters on the data gotten from the server, only if the filter is not empty
        const filteredResults = []
        for(let i = 0; i < Object.keys(this.state.queryResults).length; i++){
            let currentElement = this.state.queryResults[i];

            let idOK = true;
            let customerOK = true;
            let typeOK = true;

            if(this.state.orderIDFilter !== ""){
                if (currentElement["ProductID"] !== this.state.orderIDFilter){
                    idOK = false;
                }
            }

            if(this.state.customerFilter !== ""){
                if (currentElement["Customer"] !== this.state.customerFilter){
                    customerOK = false;
                }
            }

            if(this.state.coatingTypeFilter !== ""){
                if (currentElement["Coating"] !== this.state.coatingTypeFilter){
                    typeOK = false;
                }
            }

            if(idOK && customerOK && typeOK){
                filteredResults.push(currentElement);
            }

        }

        //update the filtered data, waiting until the state updates then updating the reports that are on time so they have the new data
        this.setState({filteredData: filteredResults}, () => {this.calculateReportsOnTime();});
    }

    //resets all filter fileds
    clearFilters(){
        this.setState({
            filteredData: this.state.queryResults,
            customerFilter: "",
            coatingTypeFilter: ""
        },
            this.applyFilters
        );
    }

    renderFilterControls(){

        //build the custom date selector if enabled
        const customDateControls = []
        if(this.state.timePeriodSelection === "custom"){
            customDateControls.push(<div key='0'>
                <label>
                    Number of days to search:{" "}
                    <input type="number" className={this.props.containers.textBox} placeholder={this.state.startDate} onChange={this.handleChange} id="startDate" name="startDate" />
                </label>
            </div>);
        }

        return(<div>
            <h3>Filters:</h3>

            <span className={this.props.timeline.filter}>
                Time Period:{" "}
                <Dropdown item text={this.state.timePeriodSelection}>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            name="daily"
                            active={this.state.timePeriodSelection === 'daily'}
                            onClick={this.handleClick}
                        >
                            Past 24 Hours
                        </Dropdown.Item>

                        <Dropdown.Item
                            name="weekly"
                            active={this.state.timePeriodSelection === 'weekly'}
                            onClick={this.handleClick}
                        >
                            Weekly
                        </Dropdown.Item>

                        <Dropdown.Item
                            name="monthly"
                            active={this.state.timePeriodSelection === 'monthly'}
                            onClick={this.handleClick}
                        >
                            Monthly
                        </Dropdown.Item>

                        <Dropdown.Item
                            name="custom"
                            active={this.state.timePeriodSelection === 'custom'}
                            onClick={this.handleClick}
                        >
                            Custom
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>

                {customDateControls}
            </span>

            <hr/>
            <ExportChart chart={"barChart"} orientation={"portrait"} chartName={"Timeline_Analysis_" + new Date().toJSON().split("T")[0] + "_" + this.state.timePeriodSelection}/>
        </div>);

    }

    render(){

        return(<div>

                {this.renderFilterControls()}

                <Grid>
                    <Grid.Column width={7}>
                        {this.renderBarGraph()}
                    </Grid.Column>
                    <Grid.Column width={4}>
                        {this.renderFilterDropdowns()}
                        <div className={this.props.timeline.clearFilters}>
                            <Button onClick={this.clearFilters}>Clear Filter Tags</Button>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={7}></Grid.Column>
                </Grid>

                {this.renderOrders()}

                {this.renderSchedule()}

        </div>);
    }

}

export default withStyles(TimelineMargins);
