import { createUseStyles } from 'react-jss';

const viewEmployeesStyle = createUseStyles({

    container: {    

        "& button": {
            marginTop: "2em",
            width: "200px",
            height: "5em",
            backgroundColor: "#0066cc",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
        },

        "& button:hover": {
            marginTop: "2em",
            width: "200px",
            height: "5em",
            backgroundColor: "#001a33",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
        },

        "& label[for='requestAll']": {
            marginLeft: '10px',
        }
    }
});

export default viewEmployeesStyle;