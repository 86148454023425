import {createUseStyles} from 'react-jss';

const viewReworkQRCodesStyle = createUseStyles({
    container: {
        margin: '10px',
        padding: '5px'
    },
    inline: {
        display: 'inline-block'
    },
    reworkHeader: {
        padding: {
            right: '1%'
        }
    },
    qrcodeDiv: {
        textAlign: 'center',
        position: 'relative',
        marginBottom: '3%',
        fontWeight: 'bold',
        fontSize: '20px'
    },
    qrcodeTitle: {

    },

});

export default viewReworkQRCodesStyle;
