import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportAllCSV = ({fileName, sheetNames, requestAll, period, customRange}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const getReportData = async (requestAll, period, customRange) => {
        //get order timeline report data
        let orderTimelineJSON = JSON.stringify({
            requestAll: requestAll,
            orderPeriod: period,
            customRange: customRange,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        let orderTimelineResponse = await fetch(process.env.REACT_APP_SERVER_ROOT + "analytics/timeline/read.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: orderTimelineJSON,
            //signal: this.abortController.signal
            });

        //process the results of the operation and remove the date field
        let orderTimelineResponseJSON = await orderTimelineResponse.json();
        delete orderTimelineResponseJSON["currentTime"];

        //get machine usage report data
        let machineUseageJSON = JSON.stringify({
            timePeriod: period,
            customRange: customRange,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        let machineUsageResponse = await fetch(process.env.REACT_APP_SERVER_ROOT + "analytics/machineUsage/read.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: machineUseageJSON
            });

        //process the results of the operation
        let machineUsageResponseJSON = await machineUsageResponse.json();
            
        //get production stat data
        let productionJSON = JSON.stringify({
            timePeriod: period,
            customRange: customRange,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        let productionResponse = await fetch(process.env.REACT_APP_SERVER_ROOT + "analytics/productionEfficiency/read.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: productionJSON
            });

        //process the results of the operation
        let productionResponseJSON = await productionResponse.json();

        //get employee report data
        let employeeJSON = JSON.stringify({
            timePeriod: period,
            customRange: customRange,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        let employeeResponse = await fetch(process.env.REACT_APP_SERVER_ROOT + "analytics/employeeStatistics/read.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: employeeJSON
            });

        //process the results of the operation
        let employeeResponseJSON = await employeeResponse.json();

        return [orderTimelineResponseJSON, machineUsageResponseJSON["machineOrders"], employeeResponseJSON];
    }

    const exportToCSV = async (fileName, sheetNames, requestAll, period, customRange) => {

        let reportData = await getReportData(requestAll, period, customRange);

        const wb = XLSX.utils.book_new();

        for (let i = 0; i < sheetNames.length; i++)
        {
            var ws = XLSX.utils.json_to_sheet(await Object.values(reportData[i]));
            var wsName = sheetNames[i];
            XLSX.utils.book_append_sheet(wb, ws, wsName);
        }

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <button className="exportAllBtn" onClick={(e) => exportToCSV(fileName, sheetNames, requestAll, period, customRange)}>Export All</button>
    )
}

export default ExportAllCSV;
