import { createUseStyles } from 'react-jss';

const enterProductStyle = createUseStyles({
    container: {
        
        "& label.uploadFileDesc": {
            marginTop: "5em",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.5em",
            color: "#0066cc"
        },


        "& form": {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "1%",



            "& input[type='file']": {
                border: "0",
                clip: "rect(0,0,0,0)",
                height: "1px",
                overflow: "hidden",
                padding: "0",
                position: "absolute !important",
                whiteSpace: "nowrap",
                width: "1px"
            },

            "& label": {
                marginTop: "2em",
                width: "200px",
                height: "5em",
                backgroundColor: "#0066cc",
                border: "none",
                color: "white",
                padding: "2em 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
            },

            "& label:hover": {
                marginTop: "2em",
                width: "200px",
                height: "5em",
                backgroundColor: "#001a33",
                border: "none",
                color: "white",
                padding: "2em 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
            },

            "& button": {
                marginTop: "2em",
                width: "200px",
                height: "5em",
                backgroundColor: "#0066cc",
                border: "none",
                color: "white",
                padding: "15px 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
            },

            "& button:hover": {
                marginTop: "2em",
                width: "200px",
                height: "5em",
                backgroundColor: "#001a33",
                border: "none",
                color: "white",
                padding: "15px 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
            }
        }
    },
    printable: {
        display: 'none'
    },
    printHide: {},
    "@media print": { //hide/show things when printing
        printHide: {
            display: 'none'
        },
        printable: {
            display: 'block'
        },
        qrcodeDiv: {
            pageBreakInside: 'avoid'
        }
    },
    qrcodeDiv: {
        textAlign: 'center',
        position: 'relative',
        marginBottom: '3%',
        fontWeight: 'bold',
        fontSize: '20px'
    },
    qrcodeData: {
        textAlign: 'left',
        fontSize: 'small',
        width: '300px'
    },
});

export default enterProductStyle;