import {createUseStyles} from 'react-jss';

const timelineStyle = createUseStyles({
    container: {
        margin: '10px',
        padding: '5px'
    },

    filter: {
        paddingRight: '1.4%'
    },

    orderTable: {
        marginTop: '1.5%'
    },

    onTimeTable: {
        marginTop: '1.5%'
    },

    barGraphDiv: {
        border: '4px solid',
        padding: '5%',
        paddingLeft: '0%',
        marginTop: '0%',
        width: '100%',
        height: '500px',
        backgroundColor: 'white'
    },

    pieChart: {
        margin: 'auto',
        marginBottom: '-20px'
    },

    pieChartTitle: {
        textAlign: 'center'
    },

    pieChartLegend: {
        textAlign: 'center'
    },

    square: {
        height: '5px',
        width: '5px',
        display: 'inline-block',
        marginRight: '.5%',
        marginLeft: '2.5%',
        backgroundColor: 'white'
    },

    searchBar: {
        marginRight: '2%'
    },

    clearFiltersDiv: {
        marginTop: '2%'
    },

    searchBarDiv: {
        marginTop: '2%'
    },

    updateTimeButton: {
        marginTop: '.1%'
    },

    timelineMargins_clearFilters: {
        marginTop: '5%'
    }
});

export default timelineStyle;
