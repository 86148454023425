import React from 'react';
import jspdf from 'jspdf';
import { Button } from 'semantic-ui-react';
import html2canvas from 'html2canvas';

    export const exportChart = ({chart, chartName, orientation }) =>
{

    const exportToChart = (chart, chartName, orientation ) =>
    {
        window.scrollTo(0,0);
        const input = document.getElementById(chart);
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jspdf(orientation );
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save(chartName + ".pdf");
        });


    };
    return (

        <Button onClick={(e) => exportToChart(chart,chartName, orientation)}>Export Chart</Button>
    )
}

export default exportChart;
