import {createUseStyles} from 'react-jss';

const machineUptimeStyle = createUseStyles({

  reportCopy: {
      marginTop: '2%'
  },

  statusIconLegend: {
      width: '200px',
      padding: '1%'
  },

  statusIconLegendItem: {
      padding: '.4%'
  },

  statusIcon: {
      verticalAlign: 'middle'
  },

  machineOrderTable: {
      marginTop: '2%'
  },

  machineOrderPieDiv: {
      border: '4px solid',
      marginTop: '1.5%',
      marginLeft: '5%',
      marginBottom: '2%',
      width: '500px'
  },

  machineOrderPieChart: {
      width: '300px',
      height: '300px'
  },

  machineOrderChartLegend: {
      marginTop: '90px',
      display: 'block',
      width: '300px'

  },

  machineFilter: {
      paddingRight: '1%'
  },

  whiteBackground: {
    border: '4px solid',
    padding: '3%',
    paddingLeft: '0%',
    marginTop: '0%',
    width: '650px',
    height: '400px',
    backgroundColor: 'white'
  },

  filter: {
      paddingRight: '1.4%'
  },

  floatContainer: {
    border: "3px solid #fff",
    padding: "20px"
},
floatChild: {
    width: "50%",
    float: "left",
    padding: "20px",
    border: "4px solid black",
    backgroundColor: 'white'
},
clearFilterButton: {
    marginLeft: '1%'
}

});

export default machineUptimeStyle;
