import { createUseStyles } from 'react-jss';

const employeeClockStyle = createUseStyles({
    container: {
        "& form": {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "8%",

            "& img": {
                marginBottom: "1em",
            },

            "& input[type=submit]": {
                marginTop: "2em",
                width: "200px",
                height: "5em",
                backgroundColor: "#0066cc",
                border: "none",
                color: "white",
                padding: "15px 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",                
            },

            "& input[type=submit]:hover": {
                marginTop: "2em",
                width: "200px",
                height: "5em",
                backgroundColor: "#001a33",
                border: "none",
                color: "white",
                padding: "15px 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
            },
            "& label": {
                fontSize: "1.5em",
                color: "#0066cc",
                marginBottom: "2em"
            }
        },

    }
});

export default employeeClockStyle;