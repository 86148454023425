import {createUseStyles} from 'react-jss';

const viewQRCodesStyle = createUseStyles({
    container: {
        margin: '10px',
        padding: '5px'
    },
    dropdown: {
        marginRight: '1%'
    },
    requestAll: {
        marginRight: '1%'
    },
    idInput: {
        marginRight: '2.5%'
    },
    qrcodeDiv: {
        textAlign: 'center',
        position: 'relative',
        marginBottom: '3%',
        fontWeight: 'bold',
        fontSize: '20px'
    },
    qrcodeData: {
        textAlign: 'left',
        fontSize: 'small',
        width: '300px'
    },
    printable: {
        display: 'none'
    },
    printHide: {},
    "@media print": { //hide/show things when printing
        printHide: {
            display: 'none'
        },
        printable: {
            display: 'block'
        },
        qrcodeDiv: {
            pageBreakInside: 'avoid'
        },
    },
});

export default viewQRCodesStyle;
