import React from 'react';
import Navbar from './navbar';
import { Redirect } from 'react-router-dom';
import { Table, Button, Form, Input } from 'semantic-ui-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Label } from 'recharts';

import hocify from 'hocify';
import containerStyle from '../jss/containers';

const withStyles = hocify(containerStyle);

class CustomerView extends React.Component {


    constructor(properties) {
        super(properties);

        //set form values
        this.state = {
            productID: '',
            requestAll: true,
            QueryData: null,
            QueryDataArchive: null,
            QueryDataSub: null,
            QueryDataSubArchive: null,
            Processes: null,
            typeChoice: 1
        };

        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");

        this.handleChange = this.handleChange.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.getProductsArch = this.getProductsArch.bind(this);
        this.getProductsSub = this.getProductsSub.bind(this);
        this.getProductsSubArch = this.getProductsSubArch.bind(this);
        this.setTypeChoice = this.setTypeChoice.bind(this);
        this.setTypeChoice = (e, { value }) => this.setState({ value })
        this.getProductsLimited = this.getProductsLimited.bind(this);
        this.getProductsArchLimited = this.getProductsArchLimited.bind(this);
        this.getProductsSubLimited = this.getProductsSubLimited.bind(this);
        this.getProductsSubArchLimited = this.getProductsSubArchLimited.bind(this);
        this.setProductWeights = this.setProductWeights.bind(this);

        this.abortController = new AbortController();
    }

    handleChange(event) {
        //console.log(event);
        //update the value for which ever field was changed
        /*  if(event.target.id === "productID"){
              this.setState({productID: event.target.value});
          }else if(event.target.id === "requestAll"){
              this.setState({requestAll: event.target.checked});
          }*/


        if (event !== "") {
            this.setState({ requestAll: false });
        }
        else {
            this.setState({ requestAll: true });
        }

        this.setState({ productID: event }, this.getProductsArch);
        this.setState({ productID: event }, this.getProducts);
        this.setState({ productID: event }, this.getProductsSub);
        this.setState({ productID: event }, this.getProductsSubArch);

    }

    setTypeChoice(event) {
        this.setState(this.state.typeChoice = event.target.value);
    }

    //get the product data from the db
    async getProductsLimited(start, end) {
        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID,
            startDate: start,
            endDate: end
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/readCustomersLimited.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryData: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async getProductsSubLimited(start, end) {
        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID,
            startDate: start,
            endDate: end
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/readCustomersSubLimited.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryDataSub: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }
        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    //get the product data from the db
    async getProducts() {
        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/readCustomers.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryData: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    //get the product data from the db
    async setProductWeights() {
        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/setProductWeights.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryData: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    //get the product data from the db
    async getProductsSub() {
        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/readCustomersSubproducts.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryDataSub: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    //get the product data from the db
    async getProductsArchLimited(start, end) {
        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID,
            startDate: start,
            endDate: end
        });
        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/readCustomersArchiveLimited.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];
                //store the product data in the state
                this.setState({
                    QueryDataArchive: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
        this.getProductsLimited(start, end);
        this.getProductsSubLimited(start, end);
        this.getProductsSubArchLimited(start, end);
    }

    //get the product data from the db
    async getProductsSubArchLimited(start, end) {
        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID,
            startDate: start,
            endDate: end
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/readCustomersSubArchiveLimited.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];
                //store the product data in the state
                this.setState({
                    QueryDataSubArchive: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    //get the product data from the db
    async getProductsArch() {
        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/readCustomersArchive.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryDataArchive: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    //get the product data from the db
    async getProductsSubArch() {
        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/readCustomersArchiveSubproducts.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryDataSubArchive: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }


    componentDidMount() {
        //send all onmessage events to the sse receive method
        this.processEvtSource.onmessage = e => this.receiveProcessData(e);
        var hashParams = window.location.hash.substr(1)
        //console.log(hashParams);
        if (hashParams.length > 0) {
            this.handleChange(decodeURIComponent(hashParams));
        }

        this.getProducts();
        this.getProductsArch();
        this.getProductsSub();
        this.setProductWeights();
        //this.getProductsSubArch();
    }


    componentWillUnmount() {
        this.processEvtSource.close();
        this.abortController.abort();
    }

    receiveProcessData(e) {
        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    //renders everything for controlling the page
    renderControls() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div><Navbar selectedPage="customerView" /></div>);
        }

        //convert the query data into a format the csv export wants
        let json = this.state.QueryData;
        let csvJson = {};

        //move all the json values into keys with the same name as the table
        for (let i = 0; i < Object.keys(json).length; i++) {
            csvJson[i] = {};

            csvJson[i]["Product ID"] = json[i]["ProductID"];
            csvJson[i]["Customer"] = json[i]["Customer"];
            csvJson[i]["Delivery Date"] = json[i]["DeliveryDate"];
            csvJson[i]["Received Date"] = json[i]["ReceivedDate"];
            csvJson[i]["Material"] = json[i]["Material"];
            csvJson[i]["Product Name"] = json[i]["ProductName"];
            csvJson[i]["Quantity"] = json[i]["Quantity"];
            csvJson[i]["Product Weight"] = json[i]["ProductWeight"];
            csvJson[i]["Shipment Schedule"] = json[i]["ShipmentSchedule"];
            csvJson[i]["PO Number"] = json[i]["PONumber"];
            csvJson[i]["Process"] = json[i]["Process"];
            csvJson[i]["Number of Items"] = json[i]["NumberOfItems"];
            csvJson[i]["Coating"] = json[i]["Coating"];
            csvJson[i]["Notes"] = json[i]["Notes"];

        }

        //console.log(csvJson[0]);

        //switch from the process id to a list of stages
        for (let i = 0; i < Object.keys(csvJson).length; i++) {
            //get stages
            let stageIDs = this.state.Processes[csvJson[i].Process].stageIDs

            let processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }

            csvJson[i]["Process"] = processStages;

        }

        return (<div>
            <Navbar selectedPage="productStatistics2" />

        </div>);
    }

    renderProducts() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const products = [];
        //add each product from the db to the table
        for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
            let value = this.state.QueryData[index];

            let processStages = null;

            let processID = value["Process"];

            //get stages
            let stageIDs = this.state.Processes[processID].stageIDs

            processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }

            let productWeightOutput = value["ProductWeight"];
            if (productWeightOutput !== null) {
                if (!productWeightOutput.includes("x")) {
                    productWeightOutput = productWeightOutput.slice(0, 6);
                }
            }

            products.push(<Table.Row key={index}>
                <Table.Cell>{value["ProductID"]}</Table.Cell>
                <Table.Cell>{value["Customer"]}</Table.Cell>
                <Table.Cell>{value["DeliveryDate"]}</Table.Cell>
                <Table.Cell>{value["ReceivedDate"]}</Table.Cell>
                <Table.Cell>{value["Material"]}</Table.Cell>
                <Table.Cell>{value["ProductName"]}</Table.Cell>
                <Table.Cell>{value["Quantity"]}</Table.Cell>
                <Table.Cell>{productWeightOutput}</Table.Cell>
                <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                <Table.Cell>{value["PONumber"]}</Table.Cell>
                <Table.Cell>{processStages}</Table.Cell>
                <Table.Cell>{value["NumberOfItems"]}</Table.Cell>
                <Table.Cell>{value["Coating"]}</Table.Cell>
                <Table.Cell>{value["Notes"]}</Table.Cell>
            </Table.Row>);


        }

        //console.log(products);
        if (products.length === 0) {
            products.push(<Table.Row key={0}>
                <Table.Cell>No products found.</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
            </Table.Row>);
            return (products);
        }

        return (products);

    }

    renderProductsSub() {

        if (this.state.QueryDataSub === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const products = [];
        //add each product from the db to the table
        for (let index = 0; index < Object.keys(this.state.QueryDataSub).length; index++) {
            let value = this.state.QueryDataSub[index];

            let processStages = null;

            let processID = value["Process"];

            //get stages
            let stageIDs = this.state.Processes[processID].stageIDs

            processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }
            products.push(<Table.Row key={index}>
                <Table.Cell>{value["ProductID"]}</Table.Cell>
                <Table.Cell>{value["Customer"]}</Table.Cell>
                <Table.Cell>{value["DeliveryDate"]}</Table.Cell>
                <Table.Cell>{value["ReceivedDate"]}</Table.Cell>
                <Table.Cell>{value["Material"]}</Table.Cell>
                <Table.Cell>{value["ProductName"]}</Table.Cell>
                <Table.Cell>{value["Quantity"]}</Table.Cell>
                <Table.Cell>{value["ProductWeight"]}</Table.Cell>
                <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                <Table.Cell>{value["PONumber"]}</Table.Cell>
                <Table.Cell>{processStages}</Table.Cell>
                <Table.Cell>{value["NumberOfItems"]}</Table.Cell>
                <Table.Cell>{value["Coating"]}</Table.Cell>
                <Table.Cell>{value["Notes"]}</Table.Cell>
            </Table.Row>);


        }

        //console.log(products);
        if (products.length === 0) {
            products.push(<Table.Row key={0}>
                <Table.Cell>No products found.</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
            </Table.Row>);
            return (products);
        }

        return (products);

    }

    renderProductsArchive() {
        //console.log(this.state.QueryDataArchive);
        if (this.state.QueryDataArchive === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const products = [];
        //add each product from the db to the table
        for (let index = 0; index < Object.keys(this.state.QueryDataArchive).length; index++) {
            let value = this.state.QueryDataArchive[index];

            let processStages = null;

            let processID = value["Process"];

            //get stages
            let stageIDs = this.state.Processes[processID].stageIDs

            processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }
            products.push(<Table.Row key={index}>
                <Table.Cell>{value["ProductID"]}</Table.Cell>
                <Table.Cell>{value["Customer"]}</Table.Cell>
                <Table.Cell>{value["DeliveryDate"]}</Table.Cell>
                <Table.Cell>{value["ReceivedDate"]}</Table.Cell>
                <Table.Cell>{value["Material"]}</Table.Cell>
                <Table.Cell>{value["ProductName"]}</Table.Cell>
                <Table.Cell>{value["Quantity"]}</Table.Cell>
                <Table.Cell>{value["ProductWeight"]}</Table.Cell>
                <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                <Table.Cell>{value["PONumber"]}</Table.Cell>
                <Table.Cell>{processStages}</Table.Cell>
                <Table.Cell>{value["NumberOfItems"]}</Table.Cell>
                <Table.Cell>{value["Coating"]}</Table.Cell>
                <Table.Cell>{value["Notes"]}</Table.Cell>
            </Table.Row>);


        }

        //console.log(products);
        if (products.length === 0) {
            products.push(<Table.Row key={0}>
                <Table.Cell>No products found.</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
            </Table.Row>);
            return (products);
        }

        return (products);

    }

    renderProductsSubArchive() {

        if (this.state.QueryDataSubArchive === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const products = [];
        //add each product from the db to the table
        for (let index = 0; index < Object.keys(this.state.QueryDataSubArchive).length; index++) {
            let value = this.state.QueryDataSubArchive[index];

            let processStages = null;

            let processID = value["Process"];

            //get stages
            let stageIDs = this.state.Processes[processID].stageIDs

            processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }
            products.push(<Table.Row key={index}>
                <Table.Cell>{value["ProductID"]}</Table.Cell>
                <Table.Cell>{value["Customer"]}</Table.Cell>
                <Table.Cell>{value["DeliveryDate"]}</Table.Cell>
                <Table.Cell>{value["ReceivedDate"]}</Table.Cell>
                <Table.Cell>{value["Material"]}</Table.Cell>
                <Table.Cell>{value["ProductName"]}</Table.Cell>
                <Table.Cell>{value["Quantity"]}</Table.Cell>
                <Table.Cell>{value["ProductWeight"]}</Table.Cell>
                <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
                <Table.Cell>{value["PONumber"]}</Table.Cell>
                <Table.Cell>{processStages}</Table.Cell>
                <Table.Cell>{value["NumberOfItems"]}</Table.Cell>
                <Table.Cell>{value["Coating"]}</Table.Cell>
                <Table.Cell>{value["Notes"]}</Table.Cell>
            </Table.Row>);


        }

        //console.log(products);
        if (products.length === 0) {
            products.push(<Table.Row key={0}>
                <Table.Cell>No products found.</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
            </Table.Row>);
            return (products);
        }

        return (products);

    }

    getCustomerCountSum() {


        var obj = [];
        var chartOBJ = {};

        if (this.state.QueryData !== null) {
            for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
                let value = this.state.QueryData[index];
                chartOBJ = { Customer: value["Customer"], Quantity: value["Quantity"], Weight: value["ProductWeight"], Count: 1 };
                obj.push(chartOBJ);
            }
        }
        //console.log(this.state.QueryData);

        if (this.state.QueryDataArchive !== null) {
            for (let index = 0; index < Object.keys(this.state.QueryDataArchive).length; index++) {
                let value = this.state.QueryDataArchive[index];
                chartOBJ = { Customer: value["Customer"], Quantity: value["Quantity"], Weight: value["ProductWeight"] };
                obj.push(chartOBJ);
            }
        }

        //console.log(this.state.QueryDataArchive);

        var objSub = [];
        var chartOBJSub = {};

        if (this.state.QueryDataSub !== null) {
            for (let index = 0; index < Object.keys(this.state.QueryDataSub).length; index++) {
                let value = this.state.QueryDataSub[index];
                chartOBJSub = { Customer: value["Customer"], Quantity: value["Quantity"], Weight: value["ProductWeight"], Count: 1 };
                objSub.push(chartOBJSub);
            }
        }

        if (this.state.QueryDataSubArchive !== null) {
            for (let index = 0; index < Object.keys(this.state.QueryDataSubArchive).length; index++) {
                let value = this.state.QueryDataSubArchive[index];
                chartOBJSub = { Customer: value["Customer"], Quantity: value["Quantity"], Weight: value["ProductWeight"] };
                objSub.push(chartOBJSub);
            }
        }

        //console.log(obj);

        var holder = {};
        var count = {};
        var totalWeight = {};

        obj.forEach(function (d) {
            if (holder.hasOwnProperty(d.Customer)) {
                holder[d.Customer] = parseInt(holder[d.Customer]) + parseInt(d.Quantity);
                count[d.Customer] += 1;

                if (!isNaN(parseInt(d.Weight))) {
                    totalWeight[d.Customer] = parseInt(totalWeight[d.Customer]) + parseInt(d.Weight);
                }
            } else {
                holder[d.Customer] = parseInt(d.Quantity);
                count[d.Customer] = 1;
                if (!isNaN(parseInt(d.Weight))) {
                    totalWeight[d.Customer] = parseInt(d.Weight);
                }
            }
        });


        var holderSub = {};
        var countSub = {};
        var totalWeightSub = {};

        objSub.forEach(function (d) {
            if (holderSub.hasOwnProperty(d.Customer)) {
                holderSub[d.Customer] = parseInt(holderSub[d.Customer]) + parseInt(d.Quantity);
                countSub[d.Customer] += 1;
                totalWeightSub[d.Customer] = parseInt(totalWeightSub[d.Customer]) + parseInt(d.Weight);
            } else {
                holderSub[d.Customer] = parseInt(d.Quantity);
                countSub[d.Customer] = 1;
                totalWeightSub[d.Customer] = parseInt(d.Weight);
            }
        });
        //console.log(holder);
        //console.log(count);
        //console.log(totalWeight);
        //console.log(totalWeightSub);

        var obj2 = [];

        for (var prop in holder) {
            obj2.push({ Customer: prop, Quantity: holder[prop], Count: count[prop], Weight: totalWeight[prop] });
            //obj2.push({ Customer: prop, Quantity: holder[prop] });
        }

        //console.log(obj2);

        return obj2;

    }

    render() {

        const COLORS = [/*'#0088FE', '#00C49F',*/ '#FFBB28', '#FF8042', '#C70039', '#8E44AD', '#229954', '#34495E', '#7B241C', '#F9E79F'];
        const COLORS2 = ["#6e0d25", "#ffffb3", "#dcab6b", "#774e24", "#6a381f", "#3083dc", "#2ebfa5", "#e56399", "#33658a", "#86bbd8", "#8E44AD"];
        const COLORS3 = ["#93827f", "#6ba292", "#35ce8d", "#bcd8b7", "#e0d2c3", "#5b2333", "#090c9b", "#0b132b", "#1c2541", "#353d2f", "#C70039"];
        const COLORS4 = ['#C70039', '#8E44AD', '#229954', '#34495E', '#7B241C', '#F9E79F'];
        const COLORS5 = ["#2a2d34", "#156588", "#009ddc", "#798186", "#f26430", "#d0644e", "#ad636c", "#6761a8", "#347e8d", "#009b72", "#8FE388"]


        var dataQ = this.getCustomerCountSum();
        dataQ.sort((a, b) => (a.Quantity > b.Quantity) ? -1 : 1);

        var top10Quant = 0;
        var totalQuant = 0;
        dataQ.forEach(element => {
            totalQuant += element.Quantity;
        });


        dataQ = dataQ.slice(0, 10);

        dataQ.forEach(element => {
            top10Quant += element.Quantity;
        });
        var diffQuant = totalQuant - top10Quant;
        //console.log(diffQuant);
        var top10sData = [];
        var adder = {};

        adder = { Customer: "Top 10", Quantity: top10Quant };
        top10sData.push(adder);
        adder = { Customer: "The rest", Quantity: diffQuant };
        dataQ.push(adder);

        var dataC = this.getCustomerCountSum();
        dataC.sort((a, b) => (a.Count > b.Count) ? -1 : 1);

        var top10Count = 0;
        var totalCount = 0;
        dataC.forEach(element => {
            totalCount += element.Count;
        });


        dataC = dataC.slice(0, 10);

        dataC.forEach(element => {
            top10Count += element.Count;
        });
        var diffCount = totalCount - top10Count;
        //console.log(diffCount);
        var top10sDataC = [];
        var adder = {};

        adder = { Customer: "Top 10", Count: top10Count };
        top10sDataC.push(adder);
        adder = { Customer: "The rest", Count: diffCount };
        dataC.push(adder);


        var dataW = this.getCustomerCountSum();
        dataW.sort((a, b) => (a.Weight > b.Weight) ? -1 : 1);
        var top10Weight = 0;
        var totalWeight = 0;
        dataW.forEach(element => {
            //console.log(element.Customer + " " + element.Weight)
            if (!isNaN(element.Weight)) {
                totalWeight += element.Weight;
            }
            //console.log("totalWeight:" + totalWeight);
        });

        dataW = dataW.slice(0, 10);

        dataW.forEach(element => {
            if (!isNaN(element.Weight)) {
                top10Weight += element.Weight;
            }
            if (isNaN(element.Weight)) {
                element.Weight = 0;
            }
            //console.log("top10Weight: " + top10Weight);
        });
        var diffWeight = totalWeight - top10Weight;

        var top10sDataW = [];
        var adder = {};

        adder = { Customer: "Top 10", Weight: top10Weight };
        top10sDataW.push(adder);
        adder = { Customer: "The rest", Weight: diffWeight };
        dataW.push(adder);
        //console.log(dataW);


        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager' && job !== 'Employee') {
            return (<Redirect to="/signin" />);
        }


        return (


            <div>


                {this.renderControls()}
                <div className={this.props.container + " " + "blueBackground"}>
                    <Form>
                        <Form.Field>
                            <label>Customer Search</label>
                            <input onChange={e => this.handleChange(e.target.value)} placeholder='Search by customer name:' />
                        </Form.Field>
                    </Form>
                    Custom Range: Between
                    <Input type="date" name="start" id="start" />
                    {'\u00A0'}and{'\u00A0'}
                    <Input type="date" name="end" id="end" />
                    <Button className="smallBtn" style={{ width: '25%' }}
                        onClick={() => this.getProductsArchLimited(document.getElementById("start").value, document.getElementById("end").value)
                        }>
                        Change Range</Button>
                    <br></br>
                    <div style={{ float: 'left' }}>
                        <h1>Top Customers by Product Quantity:</h1>
                        <ResponsiveContainer width={600} height={250}>
                            <PieChart width={300} height={300}>
                                <Label fontSize='20' value="TiAlN Thickness" position="outside" />
                                <Pie
                                    data={dataQ}
                                    cx="50%"
                                    cy="50%"
                                    label nameKey="Customer"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="Quantity"
                                    startAngle={90}
                                    endAngle={-270}
                                    isAnimationActive={false}
                                >
                                    {dataQ.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                                    ))}
                                </Pie>
                                <Legend
                                    verticalAlign="middle" layout="vertical"
                                    align="left"
                                />

                            </PieChart>


                        </ResponsiveContainer>
                    </div>
                    <div style={{ float: "right" }}>
                        <h1>Top Customers By Orders:</h1>
                        <ResponsiveContainer width={600} height={250}>
                            <PieChart width={300} height={300}>
                                <Pie
                                    data={dataC}
                                    cx="50%"
                                    cy="50%"
                                    label nameKey="Customer"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="Count"
                                    startAngle={90}
                                    endAngle={-270}
                                    isAnimationActive={false}
                                >
                                    {dataC.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS3[index % COLORS3.length]} />
                                    ))}
                                </Pie>
                                <Legend
                                    verticalAlign="middle" layout="vertical"
                                    align="right"
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>

                    <div style={{ float: 'left' }}>
                        <h1>Top Customers By Weight:</h1>
                        <ResponsiveContainer width={600} height={250}>
                            <PieChart width={300} height={300}>
                                <Pie
                                    data={dataW}
                                    cx="50%"
                                    cy="50%"
                                    label nameKey="Customer"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="Weight"
                                    startAngle={90}
                                    endAngle={-270}
                                    isAnimationActive={false}
                                >
                                    {dataW.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS5[index % COLORS5.length]} />
                                    ))}
                                </Pie>
                                <Legend
                                    verticalAlign="middle" layout="vertical"
                                    align="left"
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <br></br>
                    <h3>Current products:</h3>
                    <Table compact color='blue' celled unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Product ID</Table.HeaderCell>
                                <Table.HeaderCell>Customer</Table.HeaderCell>
                                <Table.HeaderCell>Delivery Date</Table.HeaderCell>
                                <Table.HeaderCell>Received Date</Table.HeaderCell>
                                <Table.HeaderCell>Material</Table.HeaderCell>
                                <Table.HeaderCell>Product Name</Table.HeaderCell>
                                <Table.HeaderCell>Quantity</Table.HeaderCell>
                                <Table.HeaderCell>Product Weight</Table.HeaderCell>
                                <Table.HeaderCell>Shipment Schedule</Table.HeaderCell>
                                <Table.HeaderCell>PO Number</Table.HeaderCell>
                                <Table.HeaderCell>Process</Table.HeaderCell>
                                <Table.HeaderCell>Number of Items</Table.HeaderCell>
                                <Table.HeaderCell>Coating</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.renderProducts()}
                        </Table.Body>
                    </Table>
                    <h4>Current Sub-Products:</h4>
                    <Table compact color='blue' celled unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Product ID</Table.HeaderCell>
                                <Table.HeaderCell>Customer</Table.HeaderCell>
                                <Table.HeaderCell>Delivery Date</Table.HeaderCell>
                                <Table.HeaderCell>Received Date</Table.HeaderCell>
                                <Table.HeaderCell>Material</Table.HeaderCell>
                                <Table.HeaderCell>Product Name</Table.HeaderCell>
                                <Table.HeaderCell>Quantity</Table.HeaderCell>
                                <Table.HeaderCell>Product Weight</Table.HeaderCell>
                                <Table.HeaderCell>Shipment Schedule</Table.HeaderCell>
                                <Table.HeaderCell>PO Number</Table.HeaderCell>
                                <Table.HeaderCell>Process</Table.HeaderCell>
                                <Table.HeaderCell>Number of Items</Table.HeaderCell>
                                <Table.HeaderCell>Coating</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.renderProductsSub()}
                        </Table.Body>
                    </Table>
                    <br></br>
                    <h3>Past products:</h3>
                    <Table compact color='blue' celled unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Product ID</Table.HeaderCell>
                                <Table.HeaderCell>Customer</Table.HeaderCell>
                                <Table.HeaderCell>Delivery Date</Table.HeaderCell>
                                <Table.HeaderCell>Received Date</Table.HeaderCell>
                                <Table.HeaderCell>Material</Table.HeaderCell>
                                <Table.HeaderCell>Product Name</Table.HeaderCell>
                                <Table.HeaderCell>Quantity</Table.HeaderCell>
                                <Table.HeaderCell>Product Weight</Table.HeaderCell>
                                <Table.HeaderCell>Shipment Schedule</Table.HeaderCell>
                                <Table.HeaderCell>PO Number</Table.HeaderCell>
                                <Table.HeaderCell>Process</Table.HeaderCell>
                                <Table.HeaderCell>Number of Items</Table.HeaderCell>
                                <Table.HeaderCell>Coating</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.renderProductsArchive()}
                        </Table.Body>
                    </Table>
                    <h4>Past Sub-Products:</h4>
                    <Table compact color='blue' celled unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Product ID</Table.HeaderCell>
                                <Table.HeaderCell>Customer</Table.HeaderCell>
                                <Table.HeaderCell>Delivery Date</Table.HeaderCell>
                                <Table.HeaderCell>Received Date</Table.HeaderCell>
                                <Table.HeaderCell>Material</Table.HeaderCell>
                                <Table.HeaderCell>Product Name</Table.HeaderCell>
                                <Table.HeaderCell>Quantity</Table.HeaderCell>
                                <Table.HeaderCell>Product Weight</Table.HeaderCell>
                                <Table.HeaderCell>Shipment Schedule</Table.HeaderCell>
                                <Table.HeaderCell>PO Number</Table.HeaderCell>
                                <Table.HeaderCell>Process</Table.HeaderCell>
                                <Table.HeaderCell>Number of Items</Table.HeaderCell>
                                <Table.HeaderCell>Coating</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.renderProductsSubArchive()}
                        </Table.Body>
                    </Table>
                </div>
            </div>);
    }

}

export default withStyles(CustomerView);
