import React from 'react';
import {Route, BrowserRouter as Router, Redirect} from 'react-router-dom';

import Signin from './components/signin';
import EnterProduct from './components/enterProduct';
import EnterProduct2 from './components/enterProduct2';
import EnterEmployee from './components/enterEmployee';
import ScanItem from './components/scanItem';
import ManagerView from './components/managerView';
import DashboardView from './components/dashboardView';
import ProductView from './components/productView';
import MachineStatistics from './components/machineStatistics';
import ProductStatistics from './components/productStatistics';
import ProductStatistics2 from './components/productStatistics2';
import ViewReports from './components/reports/viewReports';
import CreateProcess from './components/createProcess';
import EmployeeClock from './components/employeeClock';
import EmployeeHours from './components/employeeHours';
import ViewQRCodes from './components/viewQRCodes';
import ScheduleView from './components/scheduleView';
import ScheduleView2 from './components/scheduleView2';
import CustomerView from './components/customerView';
import EnterExcel from './components/enterExcel';
import GenerateCSV from './components/generateCSV';
import RecentlyCompleted from './components/recentlyCompleted';
import ShippingMethod from './components/shippingMethod';
import takePicture from './components/takePicture';
import productStatisticsArchive from './components/productStatisticsArchive';
import productStatisticsSubArchive from './components/productStatisticsSubArchive';
import productInput from './components/productInput';
import productImages from './components/productImages';
import mapItems from './components/mapItems';
import SignInMobile from './components/SignInMobile/SignInMobile';
import dashboardMobile from './components/DashboardMobile/DashboardMobile';
import AnalyticsDashboardMobile from './components/AnalyticsDashboardMobile/AnalyticsDashboardMobile';


import coatingTime from './components/coatingTime';
import cuttingTools from './components/generateCuttingTools';
import enterCutting from './components/enterCuttingTools';

function App() {
  return (
    <div>
        <Router>
          {/* Map the urls to the correct pages */}
          
          <Route path="/analytics" component={AnalyticsDashboardMobile}/> 
          <Route path="/signintest" component={SignInMobile}/>
          <Route path="/dashboard" component={dashboardMobile}/>
          <Route path="/signin" component={Signin}/>
          <Route path='/' exact={true} component={SignInMobile}/>
          <Route path="/employeeClock" component={EmployeeClock}/>
          <Route path="/enterProduct" component={EnterProduct}/>
          <Route path="/enterProduct2" component={EnterProduct2}/>
          <Route path="/enterEmployee" component={EnterEmployee}/>
          <Route path="/scanItem" component={ScanItem}/>
          <Route path="/managerView" component={ManagerView}/>
          <Route path="/dashboardView" component={DashboardView}/>
          <Route path="/productView" component={ProductView}/>
          <Route path="/machineStatistics" component={MachineStatistics}/>
          <Route path="/productStatistics" component={ProductStatistics}/>
          <Route path="/productStatistics2" component={ProductStatistics2}/>
          <Route path="/viewReports" component={ViewReports}/>
          <Route path="/employeeHours" component={EmployeeHours}/>
          <Route path="/createProcess" component={CreateProcess}/>
          <Route path="/viewQRCodes" component={ViewQRCodes}/>
          <Route path="/scheduleView" component={ScheduleView}/>
          <Route path="/scheduleView2" component={ScheduleView2}/>
          <Route path="/customerView" component={CustomerView}/>
          <Route path="/enterExcel" component={EnterExcel}/>
          <Route path="/generateCSV" component={GenerateCSV}/>
          <Route path="/recentlyCompleted" component={RecentlyCompleted}/>
          <Route path="/shippingMethod" component={ShippingMethod}/>
          <Route path="/takePicture" component={takePicture}/>
          <Route path="/productStatisticsArchive" component={productStatisticsArchive}/>
          <Route path="/productStatisticsSubArchive" component={productStatisticsSubArchive}/>
          <Route path="/productInput" component={productInput}/>
          <Route path="/productImages" component={productImages}/>
          <Route path="/mapItems" component={mapItems}/>
          <Route path="/coatingTime" component={coatingTime}/>
          <Route path="/cuttingTools" component={cuttingTools}/>
          <Route path="/enterCuttingTools" component={enterCutting}/>
        </Router>
    </div>
  );
}

export default App;
