import React from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Input,
  Dropdown,
} from "semantic-ui-react";

import hocify from "hocify";
import viewEmployeesStyle from "../jss/viewEmployees";

const withStyles = hocify(viewEmployeesStyle);

class ViewEmployee extends React.Component {
  constructor(properties) {
    super(properties);

    //set form values
    this.state = {
      QueryData: null,
      productID: "",
      newStage: "",
    };

    this.productEvtSource = new EventSource(
      process.env.REACT_APP_SERVER_ROOT + "analytics/sseRead.php"
    );

    //bind event handlers
    this.handleChange = this.handleChange.bind(this);
    this.getProductInformation = this.getProductInformation.bind(this);
    this.setProductScheduleDay = this.setProductScheduleDay.bind(this);
    this.abortController = new AbortController();
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  async setProductStage(pid, stageChangeForm) {
    let StagesetJSON = JSON.stringify({
      productID: pid,
      stage: stageChangeForm,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    const requestPath =
      process.env.REACT_APP_SERVER_ROOT + "product/setProductStage.php";
    let response = await fetch(requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: StagesetJSON,
      signal: this.abortController.signal,
    });
    //let responseJSON = await response.json();
    this.getProductInformation(pid);
    //this.getReportData(this.state.timePeriodSelection, this.state.startDate);
  }

  async getProductInformation(pid) {
    let productInfo = JSON.stringify({
      productID: pid,
    });
    const requestPath =
      process.env.REACT_APP_SERVER_ROOT +
      "analytics/productionSchedule/getProductInfo.php";
    let response = await fetch(requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: productInfo,
      signal: this.abortController.signal,
    });
    let responseJSON = await response.json();
    var currentStage = responseJSON[0].CurrentStage;
    var customer = responseJSON[0].Customer;
    var productName = responseJSON[0].ProductName;
    this.setProductScheduleDay(pid, currentStage, customer, productName);
  }

  async setProductScheduleDay(pid, currentStage, customer, productName) {
    var machine = "";
    switch (currentStage) {
      case "11":
        machine = "M1";
        break;
      case "12":
        machine = "M2";
        break;
      case "13":
        machine = "M3";
        break;
      default:
        return;
    }
    var day = "";
    var d = new Date();
    var n = d.getDay()
    switch (n) {
      case 1:
        day = "Mon";
        break;
      case 2:
        day = "Tue";
        break;
      case 3:
        day = "Wed";
        break;
      case 4:
        day = "Thu";
        break;
      case 5:
        day = "Fri";
        break;
      default:
        return;
    }
    var MDay = machine + "_" + day;
    let ScheduleAddJSON = JSON.stringify({
      MachineDay: MDay,
      ProductID: pid,
      Customer: customer,
      ProductName: productName
    });

    const requestPath =
      process.env.REACT_APP_SERVER_ROOT + "analytics/productionSchedule/setCoatingItems.php";
    let response = await fetch(requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: ScheduleAddJSON,
      signal: this.abortController.signal,
    });
    let responseJSON = await response.json();
  }

  async setReworkStage(pid, stageChangeForm) {
    let StagesetJSON = JSON.stringify({
      productID: pid,
      stage: stageChangeForm,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    const requestPath =
      process.env.REACT_APP_SERVER_ROOT + "product/setReworkStage.php";
    let response = await fetch(requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: StagesetJSON,
      signal: this.abortController.signal,
    });
    let responseJSON = await response.json();
    //this.getReportData(this.state.timePeriodSelection, this.state.startDate);
  }

  async setReworkProcess(pid, processChangeForm) {
    let StagesetJSON = JSON.stringify({
      productID: pid,
      processID: processChangeForm,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    const requestPath =
      process.env.REACT_APP_SERVER_ROOT + "product/setReworkProcess.php";
    let response = await fetch(requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: StagesetJSON,
      signal: this.abortController.signal,
    });
    let responseJSON = await response.json();
    //this.getReportData(this.state.timePeriodSelection, this.state.startDate);
  }

  async setProductWeight(pid, weightForm) {
    let WeightsetJSON = JSON.stringify({
      productID: pid,
      weight: weightForm,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    const requestPath =
      process.env.REACT_APP_SERVER_ROOT + "product/setWeight.php";
    let response = await fetch(requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: WeightsetJSON,
      signal: this.abortController.signal,
    });
    let responseJSON = await response.json();
    //this.getReportData(this.state.timePeriodSelection, this.state.startDate);
    if (weightForm.includes("x")) {
      var dl = weightForm.split("x");
      var diameter = dl[0];
      var length = dl[1];
      alert(
        "Product #" + pid + " has had its diameter changed to " + diameter + " and its length changed to " + length + "."
      );
    }
    else {
      alert(
        "Product #" + pid + " has had its weight changed to " + weightForm + "."
      );
    }
    document.getElementById("weightForm").value = "";
    document.getElementById("weightChangeForm").value = "";
  }

  async updateQuantity(pid, quant) {
    //encode the token, productID if not requesting all products, or if all products are being requested
    let productJSON = JSON.stringify({
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
      productID: pid,
      quantity: quant,
    });

    try {
      //send the request data to the product stat read page
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT + "product/updateProductQuantity.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.json();
      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow eaiser process of the data
        delete responseJSON["status"];

        //store the product data in the state
        this.setState({
          QueryData: responseJSON,
        });
      } else {
        alert("Could update quantity");
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
    alert(
      "Product #" + pid + " has had its quantity changed to " + quant + "."
    );
    document.getElementById("quantityForm").value = "";
    document.getElementById("quantityChangeForm").value = "";
  }

  async setProductPO(pid, POchangeForm) {
    let POsetJSON = JSON.stringify({
      productID: pid,
      PONumber: POchangeForm,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    const requestPath =
      process.env.REACT_APP_SERVER_ROOT + "product/setProductPO.php";
    let response = await fetch(requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: POsetJSON,
      signal: this.abortController.signal,
    });
    let responseJSON = await response.json();
    //this.getReportData(this.state.timePeriodSelection, this.state.startDate);
    alert(
      "Product #" +
      pid +
      ' has had its PO number changed to "' +
      POchangeForm +
      '".'
    );
    document.getElementById("pidForm").value = "";
    document.getElementById("POchangeForm").value = "";
  }

  async setShipSchedule(pid, shipDate) {
    let shipJSON = JSON.stringify({
      productID: pid,
      date: shipDate,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    alert(
      "Product #" +
      pid +
      " has had its shipping date changed to " +
      shipDate +
      "."
    );
    document.getElementById("shipForm").value = "";
    document.getElementById("shipChangeForm").value = "";
    const requestPath =
      process.env.REACT_APP_SERVER_ROOT + "product/setShipSchedule.php";
    let response = await fetch(requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: shipJSON,
      signal: this.abortController.signal,
    });
    let responseJSON = await response.json();
    //this.getReportData(this.state.timePeriodSelection, this.state.startDate);
  }

  async resetPriority() {
    let resetJSON = JSON.stringify({
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });
    alert("All priorities have been reset to defaults based on ship date");
    const requestPath =
      process.env.REACT_APP_SERVER_ROOT + "product/resetPriority.php";
    let response = await fetch(requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: resetJSON,
      signal: this.abortController.signal,
    });
    let responseJSON = await response.json();
    //this.getReportData(this.state.timePeriodSelection, this.state.startDate);
    alert("All priorities have been reset to defaults based on ship date");
  }

  async setComplete(pid) {
    let requestJSON = JSON.stringify({
      productID: pid,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    try {
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT + "completeManual.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestJSON,
        signal: this.abortController.signal,
      });

      let responseJSON = await response.text();
      if (
        responseJSON.hasOwnProperty("status") &&
        responseJSON["status"] === 400
      ) {
        alert(responseJSON["error"]);
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
    alert("Product #" + pid + " has been manually completed.");
    document.getElementById("compForm").value = "";
  }

  async getStages(productID) {
    let itemJSON = JSON.stringify({
      productID: productID,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    try {
      //send the item db
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT + "/scannedItem/checkStages.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: itemJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.text();

      if (
        responseJSON.hasOwnProperty("status") &&
        responseJSON["status"] === 400
      ) {
        alert("Could not scan Item");
      }
      if (
        responseJSON.hasOwnProperty("status") &&
        responseJSON["status"] === 200
      ) {
        alert("Item scanned successfully");
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  componentDidMount() {
    this.productEvtSource.onmessage = (e) => this.receiveSSEData(e);
  }

  receiveSSEData(e) {
    let message = JSON.parse(e.data);

    if (message["status"] === 200) {
      delete message["status"];

      //the stored data is not the same as the recived data, update it
      if (JSON.stringify(message) !== JSON.stringify(this.state.QueryData)) {
        this.setState({ QueryData: message });
      }
    }
  }
  componentWillUnmount() {
    this.productEvtSource.close();
    this.abortController.abort();
  }

  //renders everything for controlling the page
  renderControls() {
    return <div></div>;
  }

  render() {
    const ddChoices = [];
    var choiceOBJ = {};

    if (this.state.QueryData != null) {
      for (
        let index = 0;
        index < Object.keys(this.state.QueryData).length;
        index++
      ) {
        let val = this.state.QueryData[index];
        choiceOBJ = {
          key: val.productID,
          text: val.productID,
          value: val.productID,
        };
        ddChoices.push(choiceOBJ);
      }
    }

    //if the token is not present, the user is not signed in send them to signin
    if (sessionStorage.getItem("token") === null) {
      return <Redirect to="/signin" />;
    }

    return (
      <div>
        {this.renderControls()}
        <div>
          <b>Manual Stage Change</b>
        </div>
        <div>
          {/*<Input type="number" placeholder="Product ID" name="productID" id="stageForm2" />*/}
          <Dropdown
            placeholder="Product ID"
            id="stageForm"
            selection
            clearable
            search
            options={ddChoices}
          ></Dropdown>
          {"\u00A0\u00A0\u00A0\u00A0"}
          <Input
            type="number"
            placeholder="New Stage"
            name="newStage"
            id="stageChangeForm"
          />
          {"\u00A0"}
          <Button
            className="smallBtn"
            style={{ width: "25%" }}
            onClick={() =>
              this.setProductStage(
                document.querySelector("#stageForm > div.text").innerHTML,
                document.getElementById("stageChangeForm").value
              )
            }
          >
            Change Stage
          </Button>
        </div>

        <div>
          <b>Manual Rework Stage Change</b>
        </div>
        <div>
          <Input
            type="number"
            placeholder="Rework ID"
            name="reworkID"
            id="reworkForm"
          />

          {"\u00A0\u00A0\u00A0\u00A0"}
          <Input
            type="number"
            placeholder="New Stage"
            name="reworkStage"
            id="reworkChangeForm"
          />
          {"\u00A0"}
          <Button
            className="smallBtn"
            style={{ width: "25%" }}
            onClick={() =>
              this.setReworkStage(
                document.getElementById("reworkForm").value,
                document.getElementById("reworkChangeForm").value
              )
            }
          >
            Change Stage
          </Button>
        </div>

        <div>
          <b>Manual Rework Process Change</b>
        </div>
        <div>
          <Input
            type="number"
            placeholder="Rework ID"
            name="reworkID"
            id="reworkFormP"
          />

          {"\u00A0\u00A0\u00A0\u00A0"}
          <Input
            type="number"
            placeholder="New Process"
            name="reworkStage"
            id="reworkProcessForm"
          />
          {"\u00A0"}
          <Button
            className="smallBtn"
            style={{ width: "25%", marginBottom: '0em' }}
            onClick={() =>
              this.setReworkProcess(
                document.getElementById("reworkFormP").value,
                document.getElementById("reworkProcessForm").value
              )
            }
          >
            Change Rework Process
          </Button>
          <br></br>This control will also set the rework's stage back to 1 to avoid invalid stages.
        </div>
        <br></br>
        <div className={this.props.container}></div>
        <div>
          <b>Change PO Number</b>
        </div>
        <div>
          {/*<Input type="number"  placeholder="Product ID" name="productID" id="pidForm" />*/}
          <Dropdown
            placeholder="Product ID"
            id="pidForm"
            selection
            clearable
            search
            options={ddChoices}
          ></Dropdown>
          {"\u00A0\u00A0\u00A0\u00A0"}
          <Input
            type="text"
            placeholder="New PO Number"
            name="newPO"
            id="POchangeForm"
          />
          {"\u00A0"}
          <Button
            className=" smallBtn"
            style={{ width: "25%" }}
            onClick={() =>
              this.setProductPO(
                document.querySelector("#pidForm > div.text").innerHTML,
                document.getElementById("POchangeForm").value
              )
            }
          >
            Change PO
          </Button>
        </div>
        <div>
          <b>Change Quantity</b>
        </div>
        <div>
          {/*<Input type="number" placeholder="Product ID" name="productID" id="quantityForm" />*/}
          <Dropdown
            placeholder="Product ID"
            id="quantityForm"
            selection
            clearable
            search
            options={ddChoices}
          ></Dropdown>
          {"\u00A0\u00A0\u00A0\u00A0"}
          <Input
            type="number"
            placeholder="New Quantity"
            name="newQuantity"
            id="quantityChangeForm"
          />
          {"\u00A0"}
          <Button
            className="smallBtn"
            style={{ width: "25%" }}
            onClick={() =>
              this.updateQuantity(
                document.querySelector("#quantityForm > div.text").innerHTML,
                document.getElementById("quantityChangeForm").value
              )
            }
          >
            Change Quantity
          </Button>
        </div>
        <div>
          <b>Change Weight (Dimensions (D x L) if using cutting tools)</b>
        </div>
        <div>
          {/*<Input type="number" placeholder="Product ID" name="productID" id="weightForm" />*/}
          <Dropdown
            placeholder="Product ID"
            id="weightForm"
            selection
            clearable
            search
            options={ddChoices}
          ></Dropdown>
          {"\u00A0\u00A0\u00A0\u00A0"}
          <Input
            type="text"
            placeholder="New Weight"
            name="newWeight"
            id="weightChangeForm"
          />
          {"\u00A0"}
          <Button
            className="smallBtn"
            style={{ width: "25%" }}
            onClick={() =>
              this.setProductWeight(
                document.querySelector("#weightForm > div.text").innerHTML,
                document.getElementById("weightChangeForm").value
              )
            }
          >
            Change Weight
          </Button>
        </div>
        <div>
          <b>Change Ship Date</b>
        </div>
        <div>
          {/*<Input type="number" placeholder="Product ID" name="productID" id="shipForm" />*/}
          <Dropdown
            placeholder="Product ID"
            id="shipForm"
            selection
            clearable
            search
            options={ddChoices}
          ></Dropdown>
          {"\u00A0\u00A0\u00A0\u00A0"}
          <Input type="date" name="newShip" id="shipChangeForm" />
          {"\u00A0\u00A0\u00A0"}
          <Button
            className="smallBtn"
            style={{ width: "25%" }}
            onClick={() =>
              this.setShipSchedule(
                document.querySelector("#shipForm > div.text").innerHTML,
                document.getElementById("shipChangeForm").value
              )
            }
          >
            Change Ship Date
          </Button>
        </div>
        <div>
          <b>Manual Completion</b>
        </div>
        <div>
          <Input
            type="number"
            placeholder="Product ID"
            name="productIDComplete"
            id="compForm"
          />

          {"\u00A0"}
          <Button
            className="smallBtn"
            style={{ width: "25%" }}
            onClick={() =>
              this.setComplete(document.getElementById("compForm").value)
            }
          >
            Complete
          </Button>
        </div>
        <div className={this.props.container}></div>
        <div>
          <b>Reset Priority Order</b>
        </div>
        <div>
          <Button
            className="smallBtn"
            style={{ width: "25%" }}
            onClick={() => this.resetPriority()}
          >
            Reset Priority
          </Button>
        </div>
        <div className={this.props.container}></div>
      </div>
    );
  }
}

export default withStyles(ViewEmployee);
