import React, { createRef } from 'react';
import { Redirect } from 'react-router-dom'
import Navbar from './navbar';
import { Form, TextArea } from 'semantic-ui-react';

import hocify from 'hocify';
import containerStyle from '../jss/containers';
import ScanItemStyles from '../jss/scanItem';

const withStyles = hocify(ScanItemStyles);

class ScanItem extends React.Component {

    constructor(properties) {
        super(properties);

        //set form values
        this.state = {
            QRCode: "",
            signin: false,
            complete: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);

        this.abortController = new AbortController();
    }

    submit() {

        this.getEntries();
        //make sure we have something for a qrcode
        if (this.state.QRCode !== null && this.state.QRCode !== undefined && this.state.QRCode !== "") {

            this.scanAudit();

            //check if we want to toggle signin mode
            let codes = this.state.QRCode.split(":");
            if (codes[1] === "Signin") {
                this.setState({
                    signin: true,
                    QRCode: ""
                });
            }
            else if (codes[1] === "Complete") {
                this.setState({
                    complete: true,
                    QRCode: ""
                });
            }
            else if (this.state.signin) {
                this.clockToggle();
            }
            else if (this.state.complete) {
                this.completeProduct();
            }
            else {
                this.addItem();
            }
        }
    }

    handleChange(event) {
        this.setState({ QRCode: event.target.value });
    }

    async handleEntries(event) {
        let outputBuilder = "";
        let row = event.split("?");
        let warning = "";

        for (let i = 0; i < row.length; i++) {
            let entry = row[i].split("*");
            for (let j = 0; j < entry.length; j++) {
                if (entry[2] !== undefined) {
                    entry[2] = entry[2].trim();
                }
                if (entry[3] !== undefined) {
                    entry[3] = entry[3].trim();
                }

                warning = await this.getSkippedStages(entry[2]);
                warning += await this.checkInvalidStages(entry[2], entry[3]);

                if (j == 0 || j == 1) {
                    while (entry[j].length < 10) {
                        entry[j] += " ";
                    }
                }
                if (j == 2 || j == 3) {
                    while (entry[j].length < 6) {
                        entry[j] += " ";
                    }
                }
                if (j < 4) {
                    if (entry[j].length > 10) {
                        entry[j] = entry[j].substring(0, 10);
                    }
                }

                outputBuilder += entry[j] += " "
            }
            outputBuilder += warning + "\n";
        }

        if (document.getElementById("entryLogs") != null) {
            document.getElementById("entryLogs").value =
                "Name              Product  Machine  Date       Time\n" +
                outputBuilder;
        }
    }

    //sign in and out employee
    async clockToggle() {

        let itemJSON = JSON.stringify({
            QRCode: this.state.QRCode,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        this.setState({ QRCode: "", signin: false });

        try {
            //send the item db
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "employeeTimeCard/toggle.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: itemJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 400) {
                alert(responseJSON['error']);
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    componentDidMount() {
        if(this.scanInput)
        {
            this.scanInput.focus();
        }
        this.getEntries();
    }

    calculateInvalidStages(stages, MachineID) {
        let flag = 0;
        let invalidMessage = "";
        stages = stages.split("*")
        for (let i = 0; i < (stages.length); i++) {
            if (stages[i] == MachineID) {
                flag = 1;
            }

        }

        if (flag === 0 && MachineID !== undefined) {
            invalidMessage = "Current stage not in process's stages";
        }
        return invalidMessage;
    }

    calculateSkippedStages(stages) {
        let flag = 0;
        let skippedMessage = "";
        stages = stages.split("*")
        for (let i = 0; i < (stages.length - 1); i++) {
            let result = ((stages[i + 1]) - stages[i]);
            result = 0;
            if ((stages[i + 1]) - stages[i] > 1) {
                if (flag == 0) {
                    flag = 1;
                    skippedMessage += "     Warning, skipped stages: ";
                }
                skippedMessage += " " + stages[i] + " - " + stages[i + 1] + " ";
            }
        }
        return skippedMessage;
    }


    async getSkippedStages(productID) {
        let itemJSON = JSON.stringify({
            productID: productID,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            //send the item db
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "/scannedItem/requestSkippedStages.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: itemJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.text();
            let result = this.calculateSkippedStages(responseJSON);
            //result += "returned";
            return result;

            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 400) {
                alert("Could not scan Item");
            }
            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 200) {
                alert("Item scanned successfully");
            }
        } catch (error) {

            if (error.name === 'AbortError') {
            }
        }
    }


    async checkInvalidStages(productID, MachineID) {
        let itemJSON = JSON.stringify({
            productID: productID,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            //send the item db
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "/scannedItem/checkStages.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: itemJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.text();
            let result = this.calculateInvalidStages(responseJSON, MachineID);
            return result;

            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 400) {
                alert("Could not scan Item");
            }
            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 200) {
                alert("Item scanned successfully");
            }
        } catch (error) {

            if (error.name === 'AbortError') {
            }
        }
    }

    async getEntries() {
        let itemJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            //send the item db
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "/scannedItem/requestEntries.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: itemJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.text();
            this.handleEntries(responseJSON);

            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 400) {
                alert("Could not scan Item");
            }
            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 200) {
                alert("Item scanned successfully");
            }
        } catch (error) {

            if (error.name === 'AbortError') {
            }
        }
    }

    //add the item to the db
    async addItem() {
        let itemJSON = JSON.stringify({
            QRCode: this.state.QRCode,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });
        this.setState({ QRCode: "" });

        try {
            //send the item db
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "/scannedItem/create.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: itemJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.text();

            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 400) {
                alert("Could not scan Item");
            }
            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 200) {
                alert("Item scanned successfully");
            }
        } catch (error) {

            if (error.name === 'AbortError') {
            }
        }
    }

    //add the item to the db
    async scanAudit() {
        let itemJSON = JSON.stringify({
            QRCode: this.state.QRCode,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });
        this.setState({ QRCode: "" });

        try {
            //send the item db
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "/scannedItem/scanAudit.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: itemJSON,
                signal: this.abortController.signal
            });


        } catch (error) {

            if (error.name === 'AbortError') {
            }
        }
    }

    async completeProduct() {
        let requestJSON = JSON.stringify({
            QRCode: this.state.QRCode,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });
        this.setState({ QRCode: "", complete: false });

        try {
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "completeProductEndpoint.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: requestJSON,
                signal: this.abortController.signal
            });

            let responseJSON = await response.text();
            if (responseJSON.hasOwnProperty('status') && responseJSON['status'] === 400) {
                alert(responseJSON['error']);
            }
        } catch (error) {
            if (error.name === 'AbortError') { }
        }
    }


    componentWillUnmount() {
        this.abortController.abort();
    }

    render() {

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager' && job !== 'Employee') {
            return (<Redirect to="/signin" />);
        }

        return (<div>
            <Navbar selectedPage={"scanItem"} />

            <div className={this.props.container + " blueBackground"}>

                <Form>
                    <label for="textInput">Scan or Enter Item ID</label>
                    <input
                        ref={(input) => { this.scanInput = input; }}
                        id="textInput"
                        type="text"
                        style={{ width: "200px" }}
                        name="QRCode"
                        value={this.state.QRCode}
                        onChange={this.handleChange} />
                    <input
                        type="submit"
                        name="Submit"
                        onClick={this.submit} />

                </Form>
                {(job === 'Manager') &&
                    <Form><TextArea id="entryLogs" placeholder="logs will go here" readOnly rows="13" wrap='off' value={this.state.value} onChange={this.handleEntries} /></Form>
                }
            </div>



        </div>);
    }

}

export default withStyles(ScanItem);
