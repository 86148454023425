import {createUseStyles} from 'react-jss';

const mapItemsStyle = createUseStyles({

    page:{
        backgroundColor: 'white',
        position: 'relative'
    },

    items: {
        color: '#000000',
        fontWeight: 'bold',
        padding: '0.8vh',
        backgroundColor: 'white',
        fontSize: '2vh',
        fontFamily: "Domine"
    },

    items2: {
        extend: 'items',
        backgroundColor: '#bbf0fc',
    },

    items3: {
        extend: 'items',
        backgroundColor: '#ff6200',
    },

    items4: {
        extend: 'items',
        backgroundColor: '#8B0000',
        color: '#ffffff',
        fontWeight: '500',
    },

    items6: {
        extend: 'items',
        backgroundColor: '#ffd000',
    },

    items7: {
        extend: 'items',
        backgroundColor: '#b9ff69',
    },

    items9: {
        extend: 'items',
        backgroundColor: '#70d100',
    },

    items10: {
        extend: 'items',
        backgroundColor: '#00a82d',
    },

    items11: {
        extend: 'items',
        backgroundColor: '#c9c9c9',
    },

    items12: {
        extend: 'items',
        backgroundColor: '#9e9d9d',
    },

    items13: {
        extend: 'items',
        backgroundColor: '#000000',
        color: '#ffffff'
    },

    
    items15: {
        extend: 'items',
        backgroundColor: '#0077ff',
        color: '#000000'
    },

    
    items17: {
        extend: 'items',
        backgroundColor: '#002885',
        color: '#ffffff',
        fontWeight: '500',
    },

    
    items19: {
        extend: 'items',
        backgroundColor: '#855900',
        color: '#ffffff',
        fontWeight: '500',
    },

});

export default mapItemsStyle;
