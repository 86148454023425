import React from 'react';
import '../css/multiColorProgressBarStyle.css';

class MultiColorProgressBarLegend extends React.Component {

    render() {

        const parent = this.props;

        //process each element given to this component's readings tag
        let legends = parent.readings && parent.readings.length && parent.readings.map(function(item, i) {
            //exclude
            if(item.value > 0 && i != 0 && i != 1  && i != 10  && i != 11 && i != 13 && i!= 2 && i!= 3 && i != 4 && i != 5 && i != 6 && i != 7 && i != 8 && i != 9
                 && i!=12 && i!=14 && i != 15 && i != 16 && i != 17) {
                return (
                    <div className="legend" key={i}>
                        <span className="label"style={{'background-color': item.color}}>{i+1 + ": " + item.name}</span>
                    </div>
                )
            }

            if(item.value > 0 && i == 0)
            {
                return (
                    <div className="legend" key={i}>
                        <span className="label" style={{'background-color': item.color, 'color': 'black'}}>{"1: Pre-Inspection"}</span>
                    </div>
                ) 
            }

            if (item.value > 0 && i == 9) {
                return (
                    <div className="legend" key={i}>
                        <span className="label" style={{ 'background-color': item.color }}>{"10: Pre-Wash F1"}</span>
                    </div>
                )
            }

            if(item.value > 0 && i == 10)
            {
                return (
                    <div className="legend" key={i}>
                        <span className="label" style={{'background-color': item.color, 'color': 'black'}}>{"11:  Coating (M1)"}</span>
                    </div>
                ) 
            }

            if(item.value > 0 && i == 11)
            {
                return (
                    <div className="legend" key={i}>
                        <span className="label" style={{'background-color': item.color, 'color': 'black'}}>{"12:  Coating (M2)"}</span>
                    </div>
                ) 
            }

            if(item.value > 0 && i == 16)
            {
                return (
                    <div className="legend" key={i}>
                        <span className="label" style={{'background-color': item.color, 'color': 'white'}}>{"16: Post-Lapping AERO"}</span>
                    </div>
                ) 
            }

            if(item.value > 0 && i == 17)
            {
                return (
                    <div className="legend" key={i}>
                        <span className="label" style={{'background-color': item.color, 'color': 'white'}}>{"18: Final Inspection"}</span>
                    </div>
                ) 
            }
            //return("");
        }, this);

        return (

                <div className="multicolor-bar">
                    <div className="legends">
                        {legends === ''?'':legends}
                    </div>
                </div>
        );

    }
}

export default MultiColorProgressBarLegend;
