import React, { useCallback } from "react";
import { isMobile } from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SignIn.css';
import { Link, useHistory, Redirect } from "react-router-dom";

class SignInMobile extends React.Component {

    constructor(properties) {
        super(properties);

        //set form values
        this.state = {
            QRCode: '',
            jobRole: '',
            flag: '0'
        };

        // setting up service worker

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', function () {
                navigator.serviceWorker.register('/serviceWorker.js').then(function (registration) {
                    // Registration was successful
                    console.log('ServiceWorker registration successful with scope: ', registration.scope);
                }, function (err) {
                    // registration failed :(
                    console.log('ServiceWorker registration failed: ', err);
                });
            });
        }

        //setup event handlers
        this.getUserJobRole = this.getUserJobRole.bind(this);
        this.login = this.login.bind(this);
        this.abortController = new AbortController();
    }

    onSubmit = (event) => {
        /* Prevents submission of form. Great for input check without
            having to deal with it submitting a GET request and posting
            everything to the URL.
        */
        event.preventDefault();
        this.login();
    }

    async login() {
        var pass = document.getElementById("password");
        var userName = document.getElementById("username");

        await this.getUserJobRole(userName.value, pass.value)
        if (this.state.jobRole != null) {
            this.setState({ flag: 1 });
        }
        var loginJSON = JSON.stringify({
            scannerID: "",
            determiner: "",
            username: userName.value,
            password: pass.value,
            jobRole: this.state.jobRole
        });

        try {
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "login.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: loginJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON.hasOwnProperty('message')) {
                alert(responseJSON['message']);
            }
            if (responseJSON.hasOwnProperty('token')) {
                sessionStorage.setItem('token', responseJSON['token']);
                sessionStorage.setItem('jobRole', responseJSON['jobRole']);
                this.forceUpdate();
                //TODO use local storage if want remembered
            }

        } catch (error) {
            if (error.name === 'AbortError') { }
        }
    }

    async getUserJobRole(userName, pass) {

        let roleJSON = JSON.stringify({
            password: pass,
            username: userName
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "getRole.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: roleJSON,
                signal: this.abortController.signal
            });
            let responseJSON = await response.json();
            this.setState({ jobRole: responseJSON[0].JobRole })
        } catch (error) {
            if (error.name == 'AbortError') {

            }
        }
    }

    render() {
        if (sessionStorage.getItem('token') !== null && this.state.flag == 1) {
            return (<Redirect to="/dashboard" />);
        }
        else {
            return (

                <div className="container">
                    <div className="MobileSigninRow">
                        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                            <div className="card border-2 shadow rounded-3 my-5">
                                <div className="card-body p-4 p-sm-5 text-center">
                                    <img className="logo" src="seavacLogo.png"></img>
                                    <form className="form" onSubmit={this.onSubmit}>

                                        <div className="form-inputs">
                                            <input
                                                className="formInputs"
                                                style={{
                                                    border: '1px solid black',
                                                    borderRadius: '20px',
                                                    margin: '20px auto'
                                                }}
                                                type="text"
                                                id="username"
                                                name="username"
                                                placeholder="Username"
                                                class="form-control"
                                                required
                                            />
                                        </div>

                                        <div className="form-inputs">
                                            <input className="formInputs"
                                                style={{
                                                    border: '1px solid black',
                                                    borderRadius: '20px',
                                                    margin: '20px auto'
                                                }}
                                                type="text"
                                                id="password"
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                class="form-control"
                                                required
                                            />
                                        </div>
                                        <button className="btn btn-danger" id="" type="submit"> Sign In</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            )
        }
    }
}




export default SignInMobile;