import React from 'react';
import jspdf from 'jspdf';
import { Button } from 'semantic-ui-react';
import html2canvas from 'html2canvas';

    export const exportChart = ({chart, chartName, orientationH, orientationW }) =>
{

    const exportToChart = (chart, chartName, orientationH, orientationW ) =>
    {
        window.scrollTo(0,0);
        const input = document.getElementById(chart);
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jspdf('p', 'in', [orientationW+=200, orientationH+=200] );
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save(chartName + ".pdf");
        });


    };
    return (

        <Button onClick={(e) => exportToChart(chart,chartName, orientationH, orientationW)}>Export Chart</Button>
    )
}

export default exportChart;
