import React from 'react';
import Navbar from './navbar';
import { Dropdown, Checkbox, Input, Button, Grid } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import viewQRCodes from '../jss/viewQRCodes';

//css but in json form written in js
const withStyles = hocify(viewQRCodes);

class ViewQRCodes extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            codes: null,
            qrcodetype: "Machine",
            requestAll: false,
            id: null,
            requestedID: null,
            requestedAll: null,
            requestedType: "",
            numberColumns: 3,
            Processes: null
        };

        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateID = this.updateID.bind(this);
        this.getCodes = this.getCodes.bind(this);

        this.abortController = new AbortController();
    }

    componentDidMount(){
        //send all onmessage events to the sse receive method
        this.processEvtSource.onmessage = e => this.receiveProcessSSEData(e);
    }

    componentWillUnmount(){
        this.processEvtSource.close();
        this.abortController.abort();
    }

    receiveProcessSSEData(e)
    {
        let message = JSON.parse(e.data);

        if (message["status"] === 200)
        {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes))
            {
                this.setState({Processes: message});
            }
        }
    }

    //sets what type of qr code to get from the server
    handleClick(event, {name}){
        this.setState({ qrcodetype: name });
    }

    //updates if everything should be gotten from the server
    handleChange(event, data){
        this.setState({requestAll: data.checked});
    }

    //pulls the id to filter for from the page
    updateID(event){
        this.setState({id: event.target.value});
    }

    //uses the data from ther controls of the page to get the requested qrcode(s)
    async getCodes(){

        this.setState({
            codes: null,
            requestedID: this.state.id,
            requestedAll: this.state.requestAll,
            requestedType: this.state.qrcodetype
        });

        let queryTarget;
        let queryBody = {
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        };

        //based on the given info from the page, figure out where the request should go and what should be sent
        if(this.state.qrcodetype === "Machine"){ //get machine qr codes

            if(this.state.requestAll){ //get all qr codes
                queryTarget = process.env.REACT_APP_SERVER_ROOT + "machine/readAllCodes.php";
            }else{ //get the requested qr code

                if(this.state.id === null || this.state.id === ""){
                    alert("Must provide an id, or request all instead");
                    return;
                }

                queryTarget = process.env.REACT_APP_SERVER_ROOT + "machine/readCode.php";
                queryBody["machineID"] = this.state.id;
            }

        }else if(this.state.qrcodetype === "Product"){

            if(this.state.requestAll){ //get all qr codes
                queryTarget = process.env.REACT_APP_SERVER_ROOT + "product/readAllCodes.php";
            }else{ //get the requested qr code

                if(this.state.id === null || this.state.id === ""){
                    alert("Must provide an id, or request all instead");
                    return;
                }

                queryTarget = process.env.REACT_APP_SERVER_ROOT + "product/readCode.php";
                queryBody["productID"] = this.state.id;
                console.log(queryTarget);
                console.log(queryBody);

            }

        } else if (this.state.qrcodetype === "ReworkedProduct")
        {
            if (this.state.requestAll)
            {
                queryTarget = process.env.REACT_APP_SERVER_ROOT + "analytics/rework/ReadAllCodes.php";
            } else
            {
                if (this.state.id === null || this.state.id === "")
                {
                    alert("Must provide an id, or request all instead");
                    return;
                }
                queryTarget = process.env.REACT_APP_SERVER_ROOT + "analytics/rework/readCode.php";
                queryBody['reworkID'] = this.state.id;
            }
        } else{ //get employee qr codes

            if(this.state.requestAll){ //get all qr codes
                queryTarget = process.env.REACT_APP_SERVER_ROOT + "employee/readAllCodes.php";
            }else{ //get the requested qr code

                if(this.state.id === null || this.state.id === ""){
                    alert("Must provide an id, or request all instead");
                    return;
                }

                queryTarget = process.env.REACT_APP_SERVER_ROOT + "employee/readCode.php";
                queryBody["employeeID"] = this.state.id;
            }

        }

        //get the json ready to be sent
        queryBody = JSON.stringify(queryBody);

        try {
            let response = await fetch(queryTarget, {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: queryBody,
                signal: this.abortController.signal
            });

            let responseJSON = await response.json();

            if(responseJSON['status'] === 200){
                delete responseJSON["status"];
            }

            if(responseJSON['status'] === 201){
                return;
            }

            this.setState({
                codes: responseJSON
            });
           
        } catch (error) {

            if(error.name === 'AbortError'){
            }
        }

    }

    render(){

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null){
            return(<Redirect to="/signin" />);
        }

        //try to add the qr codes to be viewed only if they have been retrieved by the server
        let codes = [];
        const rows = [];

        if(this.state.codes !== null && this.state.codes !== undefined){
            let index = 0;

            for(let key of Object.keys(this.state.codes)){

                let name;
                if(this.state.requestedAll){ //if all codes were requested the key is the name of the code so use that

                    if(this.state.requestedType === "Machine"){ //if a machine was requested add a machine tag to the front to show what the code goes to
                        name = "Machine " + key;
                    }else if(this.state.requestedType === "Product"){ //if a product was requested add a product tag to the front to show what the code goes to
                        name = "Product " + key;
                    } else if (this.state.requestedType === "ReworkedProduct")
                    {
                        name = "Rework " + key;
                    } else{ //employee code, name explains itself so just use that
                        name = key;
                    }

                }else{ //if only one code was requested use the id that was requested

                    if(this.state.requestedType === "Machine"){ //if a machine was requested add a machine tag to the front to show what the code goes to
                        name = "Machine " + this.state.requestedID;
                    }else if(this.state.requestedType === "Product"){ //if a product was requested add a product tag to the front to show what the code goes to
                        name = "Product " + this.state.requestedID;
                    } else if (this.state.requestedType === "ReworkedProduct")
                    {
                        name = "Rework " + this.state.requestedID;
                    }else{ //employee code, name explains itself so just use that
                        name = key;
                    }

                }

                //i = productid
                //this.state.codes[4]
                // 4:
                //     QRCode: "\resources\qrCodes\products\P5f91f97f23666.png"
                //     coating: null
                //     customer: "AdvanSix Inc"
                //     process: "23"
                //     productID: "4"
                //     productName: "Konmatfix"
                //     quantity: "218"
                //     weight: null

                let processStages = null;
                //get process id
                if ((this.state.requestedType === "Product" || this.state.requestedType === "ReworkedProduct") && this.state.codes[key].process !== null) {
                    let processID = this.state.codes[key].process

                    //get stages
                    let stageIDs = this.state.Processes[processID].stageIDs
                    
                    processStages = "";
                    for(let i = 0; i < Object.keys(stageIDs).length; i++){
                        processStages += stageIDs[i+1];
                       
                        if(i !== Object.keys(stageIDs).length - 1){
                            processStages += ", ";
                        }
                    }
                }
                codes.push(<Grid.Column key={index}>
                    <div className={this.props.qrcodeDiv}>
                            <Grid columns={2} padded>
                                <Grid.Row>
                                    <Grid.Column>
                                        <img src={process.env.REACT_APP_SERVER_ROOT + this.state.codes[key].QRCode} alt="A QR Code to be scanned to select a machine, product, rework, or employee"/>
                                        <span><h4>{name}</h4></span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        {(this.state.requestedType === "Product") &&
                                            <span className={this.props.printable + " " + this.props.qrcodeData}>
                                                Product Name: {this.state.codes[key].productName}<br/>
                                                Customer: {this.state.codes[key].customer}<br/>
                                                Coating: {this.state.codes[key].coating}<br/>
                                                Quantity: {this.state.codes[key].quantity}<br/>
                                                Product Weight (lb): {Math.round(this.state.codes[key].weight * 1000)/1000}<br/>
                                                Process: {processStages}<br/>
                                            </span>
                                        }
                                        {(this.state.requestedType === "ReworkedProduct") &&
                                            <span className={this.props.printable + " " + this.props.qrcodeData}>
                                                ProductID: {this.state.codes[key].productID}<br/>
                                                Reason: {this.state.codes[key].reason}<br/>
                                                Process: {processStages}<br/>
                                            </span>

                                        }
                                        {(this.state.requestedType === "Machine") &&
                                            <span className={this.props.printable + " " + this.props.qrcodeData}>
                                                Machine ID: {this.state.codes[key].MachineID}<br/>
                                                Machine Type: {this.state.codes[key].MachineType}<br/>
                                            </span>

                                        }
                                        {(this.state.requestedType === "Employee") &&
                                            <span className={this.props.printable + " " + this.props.qrcodeData}>
                                                ID: {this.state.codes[key].ID}<br/>
                                                Employee Name: {this.state.codes[key].EmployeeName}<br/>
                                            </span>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                    </div>
                </Grid.Column>);

                //check if we have enough data for a full column
                if(codes.length === this.state.numberColumns){

                    rows.push(<Grid.Row key={index}>
                        {codes}
                    </Grid.Row>);

                    //empty codes for the next row
                    codes = [];
                }

                index++;
            }

            //if there is any data left in codes but not enough for a full row
            if(codes.length > 0){
                rows.push(<Grid.Row key={rows.length * this.state.numberColumns}>
                    {codes}
                </Grid.Row>);
            }

        }

        return(<div>

            <div className={this.props.container}>
                <h2>View QR Codes</h2>
                <div>
                    <span className={this.props.dropdown}>
                        QR Code Type:{" "}
                        <Dropdown item text={this.state.qrcodetype}>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    name="Machine"
                                    active={this.state.qrcodetype === 'Machine'}
                                    onClick={this.handleClick}
                                >
                                    Machine
                                </Dropdown.Item>

                                <Dropdown.Item
                                    name="Employee"
                                    active={this.state.qrcodetype === 'Employee'}
                                    onClick={this.handleClick}
                                >
                                    Employee
                                </Dropdown.Item>

                                <Dropdown.Item
                                    name="Product"
                                    active={this.state.qrcodetype === 'Product'}
                                    onClick={this.handleClick}
                                >
                                    Product
                                </Dropdown.Item>
                                <Dropdown.Item
                                    name="ReworkedProduct"
                                    active={this.state.qrcodetype === 'ReworkedProduct'}
                                    onClick={this.handleClick}
                                >
                                    Rework
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </span>

                    <Checkbox className={this.props.requestAll} label='Request All' onChange={this.handleChange}/>

                    <Input className={this.props.idInput} placeholder='ID' label='Target ID' onChange={this.updateID}/>

                    <Button primary onClick={this.getCodes}>Get Codes</Button>
                </div>
                <hr/>

                <Grid className="codes" columns={this.state.numberColumns}>
                    {rows}
                </Grid>

            </div>

        </div>);
    }

}

export default withStyles(ViewQRCodes);
