import React from 'react';
import Navbar from './navbar';
import { Redirect } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import hocify from 'hocify';
import containerStyle from '../jss/containers';

const withStyles = hocify(containerStyle);

class ProductImages extends React.Component {


    constructor(properties) {
        super(properties);

        //set form values
        this.state = {
            productID: '',
            requestAll: true,
            QueryData: null,
            Processes: null,
            typeChoice: 1,
            flag: " ASC"
        };

        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");

        this.handleChange = this.handleChange.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.getProductID = this.getProductID.bind(this);
        this.setTypeChoice = this.setTypeChoice.bind(this);
        this.setTypeChoice = (e, { value }) => this.setState({ value })
        this.abortController = new AbortController();
    }

    handleChange(event) {
        //update the value for which ever field was changed
        /*  if(event.target.id === "productID"){
              this.setState({productID: event.target.value});
          }else if(event.target.id === "requestAll"){
              this.setState({requestAll: event.target.checked});
          }*/

        if (event.target.value !== "") {
            this.setState({ requestAll: false });
        }
        else {
            this.setState({ requestAll: true });
        }
        this.setState({ productID: event.target.value }, this.getProducts);

    }

    //get the product data from the db
    async getProducts(sortby) {

        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            flagAD: this.state.flag,
            orderby: sortby,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID
        });
        if (this.state.flag == " ASC") {
            this.state.flag = " DESC";
        }
        else {
            this.state.flag = " ASC";
        }

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/read.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryData: responseJSON
                });

            } else {
                alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async setProductPO(pid, POchangeForm) {
        let POsetJSON = JSON.stringify({
            productID: pid,
            PONumber: POchangeForm,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        const requestPath = process.env.REACT_APP_SERVER_ROOT + "product/setProductPO.php";
        let response = await fetch(requestPath, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: POsetJSON,
            signal: this.abortController.signal
        });
        let responseJSON = await response.json();
        //this.getReportData(this.state.timePeriodSelection, this.state.startDate);
    }

    componentDidMount() {
        //send all onmessage events to the sse receive method
        this.processEvtSource.onmessage = e => this.receiveProcessData(e);

        this.getProducts("productID");
    }

    componentWillUnmount() {
        this.processEvtSource.close();
        this.abortController.abort();
    }

    receiveProcessData(e) {
        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    getProductID(value) {
        let redir = "/productStatistics2";
        //console.log(redir);
        return (<Redirect to="/signin" />);
    }

    setTypeChoice(event) {
        this.setState(this.state.typeChoice = event.target.value);
    }

    //renders everything for controlling the page
    renderControls() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div><Navbar selectedPage="productImages" /></div>);
        }

        //convert the query data into a format the csv export wants
        let json = this.state.QueryData;

        let csvJson = {};

        //move all the json values into keys with the same name as the table
        for (let i = 0; i < Object.keys(json).length; i++) {
            csvJson[i] = {};

            csvJson[i]["Product ID"] = json[i]["ProductID"];
            csvJson[i]["Customer"] = json[i]["Customer"];
            csvJson[i]["Delivery Date"] = json[i]["DeliveryDate"];
            csvJson[i]["Received Date"] = json[i]["ReceivedDate"];
            csvJson[i]["Material"] = json[i]["Material"];
            csvJson[i]["Product Name"] = json[i]["ProductName"];
            csvJson[i]["Quantity"] = json[i]["Quantity"];
            csvJson[i]["Product Weight"] = json[i]["ProductWeight"];
            csvJson[i]["Shipment Schedule"] = json[i]["ShipmentSchedule"];
            csvJson[i]["PO Number"] = json[i]["PONumber"];
            csvJson[i]["Process"] = json[i]["Process"];
            csvJson[i]["Number of Items"] = json[i]["NumberOfItems"];
            csvJson[i]["Coating"] = json[i]["Coating"];
            csvJson[i]["Notes"] = json[i]["Notes"];

        }

        //console.log(csvJson[0]);

        //switch from the process id to a list of stages
        for (let i = 0; i < Object.keys(csvJson).length; i++) {
            //get stages
            let stageIDs = this.state.Processes[csvJson[i].Process].stageIDs

            let processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }

            csvJson[i]["Process"] = processStages;

        }

        return (<div>
            <Navbar selectedPage="productImages" />
        </div>);
    }



    renderProducts() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const products = [];
        //add each product from the db to the table
        for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
            let value = this.state.QueryData[index];

            let processStages = null;

            let processID = value["Process"];

            //get stages
            let stageIDs = this.state.Processes[processID].stageIDs

            processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }
            let bgColor = 'black';
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd;
            let desc = value["Notes"];

            if (value["ShipmentSchedule"] <= today && !desc.includes('\u231B')) {
                bgColor = 'red';
            }

            if (value["ShipmentSchedule"] <= today && desc.includes('\u231B')) {
                bgColor = 'orange';
            }

            if (value["CurrentStage"] == null) {
                value["CurrentStage"] = "N/A";
            }


            //console.log(value["ProductID"], value.ProductWeight);
            let productWeightOutput = value["ProductWeight"];
            if (productWeightOutput !== null)
            {
                if (!productWeightOutput.includes("x")) {
                    productWeightOutput = productWeightOutput.slice(0, 6);
                }
            }


            if (window.innerWidth < 1280) {
                products.push(<Table.Row key={index}>
                    <Table.Cell>Product ID: {value["ProductID"]}<br></br>
                        <a href={process.env.REACT_APP_SERVER_ROOT + 'uploads/' + value["ProductID"] + '.png'} ><img style={{ width: '100%' }}
                            src={process.env.REACT_APP_SERVER_ROOT + 'uploads/' + value["ProductID"] + '.png'}  alt=""/></a>
                        <br></br>Customer: {value["Customer"]}
                        <br></br>Name: {value["ProductName"]}
                        <br></br>Received: {value["ReceivedDate"]}
                        <br></br>Delivery date: {value["DeliveryDate"]}
                        <br></br>Material:{value["Material"]}
                        <br></br>Quantity: {value["Quantity"]}
                        <br></br>Weight: {productWeightOutput}
                        <br></br>Coating: {value["Coating"]}
                    </Table.Cell>
                </Table.Row>);
            }
            else
            {
                products.push(<Table.Row key={index}>
                    <Table.Cell>Product ID: {value["ProductID"]}<br></br>
                        <a href={process.env.REACT_APP_SERVER_ROOT + 'uploads/' + value["ProductID"] + '.png'} ><img style={{ width: '400px' }}
                            src={process.env.REACT_APP_SERVER_ROOT + 'uploads/' + value["ProductID"] + '.png'} alt=""/></a>
                        <br></br>Customer: {value["Customer"]}
                        <br></br>Name: {value["ProductName"]}
                        <br></br>Received: {value["ReceivedDate"]}
                        <br></br>Delivery date: {value["DeliveryDate"]}
                        <br></br>Material:{value["Material"]}
                        <br></br>Quantity: {value["Quantity"]}
                        <br></br>Weight: {productWeightOutput}
                        <br></br>Coating: {value["Coating"]}
                    </Table.Cell>
                </Table.Row>);
            }
        }


        return (products);

    }


    render() {

        const ddChoices = [];
        var choiceOBJ = {};

        if (this.state.QueryData != null) {

            for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
                let val = this.state.QueryData[index];
                //console.log(val.ProductID);
                choiceOBJ = { key: val["ProductID"], text: val["ProductID"], value: val["ProductID"] }
                ddChoices.push(choiceOBJ);
            }
        }
        //console.log(ddChoices);

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager' && job !== 'Employee') {
            return (<Redirect to="/signin" />);
        }
        return (<div>

            {this.renderControls()}
            <div className={this.props.container + " " + "blueBackground"}>
                {(job === 'Manager') && <div>
                </div>}
                <Table compact celled>
                    <Table.Body>
                        {this.renderProducts()}
                    </Table.Body>
                </Table>
            </div>
        </div>);
    }

}

export default withStyles(ProductImages);
