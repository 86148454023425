import React from 'react';
import Navbar from './navbar';
import { Redirect } from 'react-router-dom';
import ExportCSV from './reports/exportCSV';
import MultiColorProgressBarLegend from './multiColorProgressBarLegend';
import { Checkbox, Table, Button, Form, Radio, Input, Dropdown } from 'semantic-ui-react';

import hocify from 'hocify';
import containerStyle from '../jss/containers';

const withStyles = hocify(containerStyle);

class ProductStatistics2 extends React.Component {


    constructor(properties) {
        super(properties);

        //set form values
        this.state = {
            productID: '',
            requestAll: true,
            QueryData: null,
            Processes: null,
            typeChoice: 1
        };

        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");

        this.handleChange = this.handleChange.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.setTypeChoice = this.setTypeChoice.bind(this);
        this.setTypeChoice = (e, { value }) => this.setState({ value })

        this.abortController = new AbortController();
    }

    handleChange(event) {
        //console.log(event);
        //update the value for which ever field was changed
        /*  if(event.target.id === "productID"){
              this.setState({productID: event.target.value});
          }else if(event.target.id === "requestAll"){
              this.setState({requestAll: event.target.checked});
          }*/

        if (event !== "") {
            this.setState({ requestAll: false });
        }
        else {
            this.setState({ requestAll: true });
        }
        this.setState({ productID: event }, this.getProducts);
    }

    setTypeChoice(event) {
        this.setState(this.state.typeChoice = event.target.value);
    }

    //get the product data from the db
    async getProducts() {

        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            requestAll: this.state.requestAll,
            productID: this.state.productID
        });

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/productStatistics/read2.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];
                //console.log(responseJSON);
                //store the product data in the state
                this.setState({
                    QueryData: responseJSON
                });

            } else {
                //alert("Could not get product(s)");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }


    async updateQuantity(pName, quant) {

        //encode the token, productID if not requesting all products, or if all products are being requested
        let productJSON = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            productName: "'" + pName + "'",
            quantity: quant
        });
        console.log(productJSON);

        try {
            //send the request data to the product stat read page
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "product/updateQuantity.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON['status'] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON['status'];

                //store the product data in the state
                this.setState({
                    QueryData: responseJSON
                });

            } else {
                alert("Could update quantity");
            }

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async setProductWeight(pName, weightForm) {
        let WeightsetJSON = JSON.stringify({
            productName: pName,
            weight: weightForm,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });
        console.log(WeightsetJSON);

        const requestPath = process.env.REACT_APP_SERVER_ROOT + "product/setSubproductWeight.php";
        let response = await fetch(requestPath, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: WeightsetJSON,
            signal: this.abortController.signal
        });
        let responseJSON = await response.json();
        console.log(responseJSON);
        //this.getReportData(this.state.timePeriodSelection, this.state.startDate);
    }

    componentDidMount() {
        //send all onmessage events to the sse receive method
        this.processEvtSource.onmessage = e => this.receiveProcessData(e);
        var hashParams = window.location.hash.substr(1)
        //console.log(hashParams);
        if (hashParams.length > 0) {
            this.handleChange(decodeURIComponent(hashParams));
        }

        this.getProducts();
    }

    componentWillUnmount() {
        this.processEvtSource.close();
        this.abortController.abort();
    }

    receiveProcessData(e) {
        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    //renders everything for controlling the page
    renderControls() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div><Navbar selectedPage="productStatistics2" /></div>);
        }

        //convert the query data into a format the csv export wants
        let json = this.state.QueryData;

        let csvJson = {};

        //move all the json values into keys with the same name as the table
        for (let i = 0; i < Object.keys(json).length; i++) {
            csvJson[i] = {};

            csvJson[i]["Product ID"] = json[i]["ProductID"];
            csvJson[i]["Customer"] = json[i]["Customer"];
            csvJson[i]["Delivery Date"] = json[i]["DeliveryDate"];
            csvJson[i]["Received Date"] = json[i]["ReceivedDate"];
            csvJson[i]["Material"] = json[i]["Material"];
            csvJson[i]["Product Name"] = json[i]["ProductName"];
            csvJson[i]["Quantity"] = json[i]["Quantity"];
            csvJson[i]["Product Weight"] = json[i]["ProductWeight"];
            csvJson[i]["Shipment Schedule"] = json[i]["ShipmentSchedule"];
            csvJson[i]["PO Number"] = json[i]["PONumber"];
            csvJson[i]["Process"] = json[i]["Process"];
            csvJson[i]["Number of Items"] = json[i]["NumberOfItems"];
            csvJson[i]["Coating"] = json[i]["Coating"];
            csvJson[i]["Notes"] = json[i]["Notes"];

        }

        //console.log(csvJson[0]);

        //switch from the process id to a list of stages
        for (let i = 0; i < Object.keys(csvJson).length; i++) {
            //get stages
            let stageIDs = this.state.Processes[csvJson[i].Process].stageIDs

            let processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }

            csvJson[i]["Process"] = processStages;

        }

        return (<div>
            <Navbar selectedPage="productStatistics2" />

            <div className={this.props.container + " " + "blueBackground"}>
                <div className={this.props.inlineContainer}>
                    <ExportCSV
                        csvData={Object.values(csvJson)}
                        fileName={"productStatistics_" + new Date().toJSON().split("T")[0]}
                    />
                </div>
            </div>
        </div>);
    }

    renderProducts() {

        if (this.state.QueryData === null || this.state.Processes === null) {
            return (<div></div>);
        }

        const products = [];
        //add each product from the db to the table
        for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
            let value = this.state.QueryData[index];

            let processStages = null;

            let processID = value["Process"];

            //get stages
            let stageIDs = this.state.Processes[processID].stageIDs

            processStages = "";
            for (let i = 0; i < Object.keys(stageIDs).length; i++) {
                processStages += stageIDs[i + 1];

                if (i !== Object.keys(stageIDs).length - 1) {
                    processStages += ", ";
                }
            }

            let bgColor = 'black';
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd;
            let desc = value["Notes"];

            if (value["ShipmentSchedule"] <= today && !desc.includes('\u231B')) {
                bgColor = 'red';
            }

            if (value["ShipmentSchedule"] <= today && desc.includes('\u231B')) {
                bgColor = 'orange';
            }

            if (value["CurrentStage"] == null) {
                value["CurrentStage"] = "N/A";
            }

            //console.log(value["CurrentStage"]);
            let stagecolor = 'white';
            let textcolor = 'black';



            switch (value["CurrentStage"]) {
                case '2':
                    stagecolor = '#bbf0fc';
                    break;
                case '3':
                    stagecolor = '#ff6200';
                    break;
                case '4':
                    stagecolor = '#8B0000';
                    textcolor = 'white';
                    break;
                case '5':
                    stagecolor = '#ffd000';
                    break;
                case '6':
                    stagecolor = '#ffd000';
                    break;
                case '7':
                    stagecolor = '#89ff00';
                    break;
                case '8':
                    stagecolor = '#70d100';
                    break;
                case '9':
                    stagecolor = '#70d100';
                    break;
                case '10':
                    stagecolor = '#00a82d';
                    break;
                case '11':
                    stagecolor = '#c9c9c9';
                    break;
                case '12':
                    stagecolor = '#9e9d9d';
                    break;
                case '13':
                    stagecolor = '#000000';
                    textcolor = 'white';
                    break;
                case '14':
                    stagecolor = '#df6bff';
                    break;
                case '15':
                    stagecolor = '#0077ff';
                    break;
                case '16':
                    stagecolor = '#0040d6';
                    break;
                case '17':
                    stagecolor = '#002885';
                    textcolor = 'white';
                    break;
                case '18':
                    stagecolor = '#4b0085';
                    break;
                case '19':
                    stagecolor = '#855900';
                    break;
                default:
                    textcolor = 'black';
                    break;
            }

            if (this.state.typeChoice == "cutting tools" && value.ProductWeight === null) {
                products.push(<Table.Row key={index}>
                    <Table.Cell>{value["ProductID"]}</Table.Cell>
                    <Table.Cell>{value["Customer"]}</Table.Cell>
                    <Table.Cell>{value["DeliveryDate"]}</Table.Cell>
                    <Table.Cell>{value["ReceivedDate"]}</Table.Cell>
                    <Table.Cell>{value["Material"]}</Table.Cell>
                    <Table.Cell>{value["ProductName"]}</Table.Cell>
                    <Table.Cell>{value["Quantity"]}</Table.Cell>
                    <Table.Cell>{value["ProductDiameter"]}</Table.Cell>
                    <Table.Cell>{value["ProductLength"]}</Table.Cell>
                    <Table.Cell style={{ color: bgColor }}>{value["ShipmentSchedule"]}</Table.Cell>
                    <Table.Cell>{value["PONumber"]}</Table.Cell>
                    <Table.Cell>{processStages}</Table.Cell>
                    <Table.Cell>{value["NumberOfItems"]}</Table.Cell>
                    <Table.Cell>{value["Coating"]}</Table.Cell>
                    <Table.Cell>{value["Notes"]}</Table.Cell>
                </Table.Row>);
            }
            else if (this.state.typeChoice == "dies" && value.ProductWeight !== null) {
                products.push(<Table.Row key={index}>
                    <Table.Cell>{value["ProductID"]}</Table.Cell>
                    <Table.Cell>{value["Customer"]}</Table.Cell>
                    <Table.Cell>{value["DeliveryDate"]}</Table.Cell>
                    <Table.Cell>{value["ReceivedDate"]}</Table.Cell>
                    <Table.Cell>{value["Material"]}</Table.Cell>
                    <Table.Cell>{value["ProductName"]}</Table.Cell>
                    <Table.Cell>{value["Quantity"]}</Table.Cell>
                    <Table.Cell>{value["ProductWeight"]}</Table.Cell>
                    <Table.Cell style={{ color: bgColor }}>{value["ShipmentSchedule"]}</Table.Cell>
                    <Table.Cell>{value["PONumber"]}</Table.Cell>
                    <Table.Cell>{processStages}</Table.Cell>
                    <Table.Cell>{value["NumberOfItems"]}</Table.Cell>
                    <Table.Cell>{value["Coating"]}</Table.Cell>
                    <Table.Cell>{value["Notes"]}</Table.Cell>
                </Table.Row>);
            }
            else if (this.state.typeChoice != 'dies' && this.state.typeChoice != 'cutting tools') {
                products.push(<Table.Row key={index}>
                    <Table.Cell>{value["ProductID"]}</Table.Cell>
                    <Table.Cell>{value["Customer"]}</Table.Cell>
                    <Table.Cell>{value["DeliveryDate"]}</Table.Cell>
                    <Table.Cell>{value["ReceivedDate"]}</Table.Cell>
                    <Table.Cell>{value["Material"]}</Table.Cell>
                    <Table.Cell>{value["ProductName"]}</Table.Cell>
                    <Table.Cell>{value["Quantity"]}</Table.Cell>
                    {value["ProductWeight"] !== null &&
                        <Table.Cell>{value["ProductWeight"]}</Table.Cell>}
                    {value["ProductWeight"] === null &&
                        <Table.Cell>Cutting Tool</Table.Cell>}
                    <Table.Cell style={{ color: bgColor }}>{value["ShipmentSchedule"]}</Table.Cell>
                    <Table.Cell>{value["PONumber"]}</Table.Cell>
                    <Table.Cell style={{ backgroundColor: stagecolor, color: textcolor }}>
                        Current stage: <b>{value["CurrentStage"]}</b>{"\u00A0\u00A0"} Stages in process: {processStages}</Table.Cell>
                    <Table.Cell>{value["NumberOfItems"]}</Table.Cell>
                    <Table.Cell>{value["Coating"]}</Table.Cell>
                    <Table.Cell>{value["Notes"]}</Table.Cell>
                </Table.Row>);
            }
        }

        //console.log(products);
        if (products.length === 0) {
            products.push(<Table.Row key={0}>
                <Table.Cell>No products found.</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
            </Table.Row>);
            return (products);
        }

        return (products);

    }


    render() {

        const ddChoices = [];
        var choiceOBJ = {};

        if (this.state.QueryData != null) {

            for (let index = 0; index < Object.keys(this.state.QueryData).length; index++) {
                let val = this.state.QueryData[index];
                //console.log(val.ProductName);
                choiceOBJ = { key: val["ProductName"], text: val["ProductName"], value: val["ProductName"] }
                ddChoices.push(choiceOBJ);
            }
        }
        //console.log(ddChoices);

        var readings = [
            {
                name: 'Pre-Inspection',
                value: 16.6,
                color: '#c8a2c8',
                inprogress: false
            },
            {
                name: 'Pre-Washing (Jig-Washer)',
                value: 16.6,
                color: '#bbf0fc',
                inprogress: false
            },
            {
                name: 'Stripping (Shot)',
                value: 16.6,
                color: '#ff6200',
                inprogress: false
            },
            {
                name: 'Stripping (Liquid)',
                value: 16.6,
                color: '#8B0000',
                inprogress: false
            },
            {
                name: 'WPC-SKH',
                value: 16.6,
                color: '#ffe261',
                inprogress: false
            },
            {
                name: 'WPC-Ceramic',
                value: 16.6,
                color: '#ffd000',
                inprogress: false
            },
            {
                name: 'Pre-Lapping (SMAP)',
                value: 16.6,
                color: '#b9ff69',
                inprogress: false
            },
            {
                name: 'Pre-Lapping (AERO)',
                value: 16.6,
                color: '#89ff00',
                inprogress: false
            },
            {
                name: 'Pre-Lapping (Hand Lapping)',
                value: 16.6,
                color: '#70d100',
                inprogress: false
            },
            {
                name: 'Pre-Washing (F1 Clean Washer)',
                value: 16.6,
                color: '#00a82d',
                inprogress: false
            },
            {
                name: 'Coating (M1) - Set/Disassemble',
                value: 16.6,
                color: '#c9c9c9',
                inprogress: false
            },
            {
                name: 'Coating (M2) - Set/Disassemble',
                value: 16.6,
                color: '#9e9d9d',
                inprogress: false
            },
            {
                name: 'Coating (M3) - Set/Disassemble',
                value: 16.6,
                color: '#000000',
                inprogress: false
            },
            {
                name: 'Post-Inspection',
                value: 16.6,
                color: '#df6bff',
                inprogress: false
            },
            {
                name: 'Post-Lapping (SMAP)',
                value: 16.6,
                color: '#0077ff',
                inprogress: false
            },
            {
                name: 'Post-Lapping (AERO)',
                value: 16.6,
                color: '#0040d6',
                inprogress: false
            },
            {
                name: 'Post-Lapping (Hand Lapping)',
                value: 16.6,
                color: '#002885',
                inprogress: false
            },
            {
                name: 'Final Inspection',
                value: 16.6,
                color: '#4b0085',
                inprogress: false
            },
            {
                name: 'Packing/Shipping',
                value: 16.6,
                color: '#855900',
                inprogress: false
            }
        ];

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager' && job !== 'Employee') {
            return (<Redirect to="/signin" />);
        }


        return (
            <div>


                {this.renderControls()}
                <div className={this.props.container + " " + "blueBackground"}>
                    <MultiColorProgressBarLegend readings={readings} />
                    <Form>
                        <Form.Field>
                            <label>Product Search</label>
                            <input onChange={e => this.handleChange(e.target.value)} placeholder='Search by product ID:' />
                        </Form.Field>
                    </Form>
                    <br></br>
                    {(job === 'Manager') && <div>
                        <label>Change Quantity</label>
                        <div>{/*<Input type="text" name="productID"  placeholder="Product Name" id="stageForm" />*/}
                            <Dropdown placeholder='Product Name' id="stageForm" selection clearable search options={ddChoices}></Dropdown>
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<Input type="text" placeholder="New Quantity" name="newStage" id="stageChangeForm" />
                            {'\u00A0'}
                            <Button className="smallBtn2" onClick={() => { this.updateQuantity(document.querySelector("#stageForm > div.text").innerHTML, document.getElementById("stageChangeForm").value); setTimeout(() => window.location.reload(), 1); }}>
                                Change Quantity</Button></div>
                        <label>Change Weight</label>
                        <div>{/*<Input type="text" name="productID" placeholder="Product Name" id="weightForm" />*/}
                            <Dropdown placeholder='Product Name' id="weightForm" selection clearable search options={ddChoices}></Dropdown>
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<Input type="text" placeholder="New Weight" name="newWeight" id="weightChangeForm" />
                            {'\u00A0'}
                            <Button className="smallBtn2" onClick={() => { this.setProductWeight(document.querySelector("#weightForm > div.text").innerHTML, document.getElementById("weightChangeForm").value); setTimeout(() => window.location.reload(), 1); }}>
                                Change Weight</Button></div>
                    </div>}
                    <Form>
                        <Form.Field>
                            Current filter: <b>{this.state.typeChoice = this.state.value}</b>
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='No filter'
                                name='radioGroup'
                                value='none'
                                checked={this.state.value === 'none'}
                                onChange={this.setTypeChoice}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Dies'
                                name='radioGroup'
                                value='dies'
                                checked={this.state.value === 'dies'}
                                onChange={this.setTypeChoice}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='Cutting Tools'
                                name='radioGroup'
                                value='cutting tools'
                                checked={this.state.value === 'cutting tools'}
                                onChange={this.setTypeChoice}
                            />
                        </Form.Field>
                    </Form>
                    {this.state.typeChoice != 'cutting tools' &&
                    <Table compact color='blue' celled unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Product ID</Table.HeaderCell>
                                <Table.HeaderCell>Customer</Table.HeaderCell>
                                <Table.HeaderCell>Delivery Date</Table.HeaderCell>
                                <Table.HeaderCell>Received Date</Table.HeaderCell>
                                <Table.HeaderCell>Material</Table.HeaderCell>
                                <Table.HeaderCell>Product Name</Table.HeaderCell>
                                <Table.HeaderCell>Quantity</Table.HeaderCell>
                                <Table.HeaderCell>Product Weight</Table.HeaderCell>
                                <Table.HeaderCell>Shipment Schedule</Table.HeaderCell>
                                <Table.HeaderCell>PO Number</Table.HeaderCell>
                                <Table.HeaderCell>Process</Table.HeaderCell>
                                <Table.HeaderCell>Number of Items</Table.HeaderCell>
                                <Table.HeaderCell>Coating</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.renderProducts()}
                        </Table.Body>
                    </Table>}
                    {this.state.typeChoice == 'cutting tools' &&
                    <Table compact celled unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ProductID") }}>Product ID</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("Customer") }}>Customer</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("DeliveryDate") }}>Delivery Date</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ReceivedDate") }}>Received Date</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("Material") }}>Material</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ProductName") }}>Product Name</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("Quantity") }}>Quantity</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ProductDiameter") }}>Product Diameter</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ProductLength") }}>Product Length</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("ShipmentSchedule") }}>Shipment Schedule</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("PONumber") }}>PO Number</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("Process") }}>Process</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("NumberOfItems") }}>Number of Items</Table.HeaderCell>
                                <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => { this.getProducts("Coating") }}>Coating</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.renderProducts()}
                        </Table.Body>
                    </Table>}
                </div>
            </div>);
    }

}

export default withStyles(ProductStatistics2);
