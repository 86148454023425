import {createUseStyles} from 'react-jss';

const productViewStyle = createUseStyles({

    requestAll: {
        paddingTop: '0%',
        paddingBottom: '1%',        
    }    
});

export default productViewStyle;
