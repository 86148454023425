import React from 'react';
import {Redirect} from 'react-router-dom'
import {Grid, Button} from 'semantic-ui-react';
import Navbar from './navbar';

import hocify from 'hocify';
import enterEmployeeStyle from '../jss/enterEmployee';

const withStyles = hocify(enterEmployeeStyle);

class EnterEmployee extends React.Component {

    constructor(properties){
        super(properties);

        //set form values
        this.state = {
            employeePassword: '',
            firstName: '',
            lastName: '',
            hiredDate: '',
            job: 'Employee',
            status: '',
            codePath: null,
            employeeID: null,
            employeeName: null
        };

        //bind event handlers
        this.handleChange = this.handleChange.bind(this);
        this.pushEmployee = this.pushEmployee.bind(this);

        this.abortController = new AbortController();
    }

    handleChange(event){

        //update the value for which ever field was changed
        if(event.target.id === "employeePassword"){
            this.setState({employeePassword: event.target.value});
        }else if(event.target.id === "firstName"){
            this.setState({firstName: event.target.value});
        }else if(event.target.id === "lastName"){
            this.setState({lastName: event.target.value});
        }else if(event.target.id === "hiredDate"){
            this.setState({hiredDate: event.target.value});
        }else if(event.target.id === "job"){
            this.setState({job: event.target.value});
        }else if(event.target.id === "status"){
            this.setState({status: event.target.value});
        }

    }

    //sends the employee data via json to the employee creation api
    async pushEmployee(){

        if (this.state.status==="");
        {
            this.state.status="fulltime";
        }

        let EmployeeJSON = JSON.stringify({
            employeePassword: this.state.employeePassword,
            firstname: this.state.firstName,
            lastname: this.state.lastName,
            hiredDate: this.state.hiredDate,
            job: this.state.job,
            status: this.state.status,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try{
            //send the employee to the creation api
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "employee/create.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: EmployeeJSON,
                signal: this.abortController.signal
                });

            //process the results of the operation
            let responseJSON = await response.json();
               
            if(responseJSON.hasOwnProperty('status')  && responseJSON['status'] === 400){
                alert("Could not enter employee");
            }else if(responseJSON.hasOwnProperty('status')  && responseJSON['status'] === 200){
                alert("Employee entered successfully with an employee ID of " + responseJSON['employeeID']);
            }

            //handle the response
            this.setState({
                codePath: responseJSON["qrCode"],
                employeeID: responseJSON["employeeID"],
                employeeName: this.state.firstName + " " + this.state.lastName
            });

            this.clearFields();

        } catch (error) {
      
      }

    }

    //emptys all the input fields
    clearFields(){
        this.setState({
            employeePassword: '',
            firstName: '',
            lastName: '',
            hiredDate: '',
            status: '',
            job: 'Employee'
        });
    }

    componentWillUnmount(){
        this.abortController.abort();
    }

    render(){

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null || sessionStorage.getItem('jobRole') === null){
            return(<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager')
        {
            return(<Redirect to="/signin" />);
        }

        const print = [];
        const code = [];
        //if the qrcode has been recieved
        if(this.state.codePath !== null && this.state.codePath !== undefined){
            code.push(<Grid.Column key={0}>
                <div className={this.props.qrcodeDiv}>
                    <Grid columns={2} padded>
                        <Grid.Row>
                            <Grid.Column>
                                <div className={this.props.printable + " " + this.props.centerTitleDiv}>
                                    <img src={process.env.REACT_APP_SERVER_ROOT + this.state.codePath} alt="A QR Code to be scanned to select a machine, product, rework, or employee" className={this.props.printable}/>
                                    <span className={this.props.printable + " " + this.props.centerTitle}><h4>{this.state.employeeName}</h4></span>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <span className={this.props.printable + " " + this.props.qrcodeData}>
                                    ID: {this.state.employeeID}<br/>
                                    Employee Name: {this.state.employeeName}<br/>
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Grid.Column>);

            print.push(<Button onClick={() => {
                window.print();
            }}>Print</Button>);
        }

        return(<div>
            <div className={this.props.printHide}>
            <Navbar selectedPage={"enterEmployee"}/>
            </div>

            <div className={this.props.container + " " + "blueBackground" + " " + this.props.printHide}>
                <label className="greeting">Scan QR Code or Enter Employee Information</label>

                <div className={this.props.enterList}>
                    <label for="firstName">
                        First Name:
                        <input type="text" className={this.props.textBox} placeholder="Enter first name" value={this.state.firstName} id="firstName" name="firstName" onChange={this.handleChange}/>
                    </label>
                </div>
                <div className={this.props.enterList}>
                    <label for="lastName">
                        Last Name:
                        <input type="text" className={this.props.textBox} placeholder="Enter last name" value={this.state.lastName} id="lastName" name="lastName" onChange={this.handleChange}/>
                    </label>
                </div>
                <div className={this.props.enterList}>
                    <label for="hiredDate">
                        Date Hired:
                        <input type="date" className={this.props.textBox} value={this.state.hiredDate} id="hiredDate" name="hiredDate" onChange={this.handleChange}/>
                    </label>
                </div>
                <div className={this.props.enterList}>
                    <label for="job">Job Role:{" "}</label>
                    <select
                        id="job" name="job"
                        value={this.state.job}
                        onChange={this.handleChange}
                    >
                        <option value="Employee">Employee</option>
                        <option value="Manager">Manager</option>
                        <option value="Viewer">Viewer</option>
                    </select>
                </div>
                <div className={this.props.enterList}>
                    <label for="status">
                        Status:
                        <input type="string" className={this.props.textBox} placeholder="fulltime" value={this.state.status} id="status" name="status" onChange={this.handleChange}/>
                    </label>
                </div>
                <button type="submit" onClick={this.pushEmployee}>Submit</button>

                {print}

            </div>

            {code}
            

        </div>);
    }

}

export default withStyles(EnterEmployee);
