import { createUseStyles } from 'react-jss';

const enterEmployeeStyle = createUseStyles({


    container: {

        position: "relative",
        //position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        //paddingLeft: "50vh",

        "& label.greeting": {
            marginTop: "10px"
        },

        "& label": {
            display: "inline-block",
            fontSize: "1.5em",
            color: "#0066cc",
            marginBottom: "20px",
            paddingBottom: "5px",

            "& input": {
                height: "35px",
                marginTop: "10px",
                display: "flex",
                width: "20em",
                align: "right",
                justifyContent: "left"
            },
        },

        "& select": {
            height: "35px",
            marginTop: "-20px",
            display: "flex",
            width: "30em",
            align: "right",
            justifyContent: "left",
            marginBottom: "1.5em",
            paddingLeft: "5px",

        },

        "& button": {
            marginTop: "2em",
            width: "200px",
            height: "5em",
            backgroundColor: "#0066cc",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
        },

        "& button:hover": {
            marginTop: "2em",
            width: "200px",
            height: "5em",
            backgroundColor: "#001a33",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
        },
    },
    printable: {
        display: 'none'
    },
    printHide: {},
    qrcodeDiv: {
        textAlign: 'center',
        position: 'relative',
        marginBottom: '3%',
        fontWeight: 'bold',
        fontSize: '20px'
    },
    qrcodeData: {
        textAlign: 'left',
        fontSize: 'small'
    },
    centerTitle: {
        display: 'none'
    },
    centerTitleDiv: {
        display: 'none'
    },
    "@media print": { //hide/show things when printing
        printHide: {
            display: 'none'
        },
        printable: {
            display: 'block'
        },
        qrcodeDiv: {
            pageBreakInside: 'avoid',
        },
        centerTitle: {
            display: 'block'
        },
        centerTitleDiv: {
            verticleAlign: 'top',
            display: 'inline-block',
            textAlign: 'center',
        }
    },
});

export default enterEmployeeStyle;
