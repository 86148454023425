import React from 'react';
import { Redirect } from 'react-router-dom'
import Navbar from './navbar';
import ProgressBar from './progressBar';
import MultiColorProgressBarLegend from './multiColorProgressBarLegend';
import { Icon, Button, Modal, Input, Dropdown, Form, Radio } from 'semantic-ui-react'

import ViewEmployee from './viewEmployee';
import ViewReworkQRCodes from './viewReworkQRCodes';
import ViewQRCodes from './viewQRCodes';
import Controls from './controls';

import hocify from 'hocify';
import managerViewStyle from '../jss/managerView';

const withStyles = hocify(managerViewStyle);

class ManagerView extends React.Component {

    constructor(props) {
        super(props);

        //set the state of this component to have a item named QueryData
        this.state = {
            QueryData: null,
            CompletedData: null,
            Processes: null,
            ScannerError: null,
            ReworkedData: null,
            scannerToReset: '',
            barHeight: 1.5,
            barWidth: 3.8,
            viewEmployeesOpen: false,
            viewReworkQRCodesOpen: false,
            viewAllQRCodesOpen: false,
            controlsOpen: false,
            showCompleted: false,
            filterValue: -1
        };

        //setup sse connections
        this.productEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "analytics/sseRead.php");
        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "process/sseRead.php");
        this.reworkEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "analytics/rework/sseRead.php");
        this.completedEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "analytics/completedDashboard/sseRead.php");
        this.scannerErrorEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "analytics/scannerError/read.php");

        this.priorityUp = this.priorityUp.bind(this);
        this.priorityDown = this.priorityDown.bind(this);
        this.rangeUpdate = this.rangeUpdate.bind(this);
        this.toggleCompleted = this.toggleCompleted.bind(this);
        this.reworkCountChange = this.reworkCountChange.bind(this);
        this.reworkNotesChange = this.reworkNotesChange.bind(this);
        this.productNotesChange = this.productNotesChange.bind(this);
        this.resetScannerErrors = this.resetScannerErrors.bind(this);
        this.dropDownClick = this.dropDownClick.bind(this);
        this.setWaiting = this.setWaiting.bind(this);

        this.abortController = new AbortController();
    }

    dropDownClick(event, { name }) {
        this.setState({ scannerToReset: name });
    }

    windowResize = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        //send all onmessage events to the sse receive method
        this.productEvtSource.onmessage = e => this.receiveSSEData(e);
        this.processEvtSource.onmessage = e => this.receiveProcessData(e);
        this.reworkEvtSource.onmessage = e => this.receiveReworkedSSEData(e);
        this.completedEvtSource.onmessage = e => this.receiveCompletedData(e);
        this.scannerErrorEvtSource.onmessage = e => this.receiveScannerErrorData(e);


        //setup a resize event handler to force a rerender of the page so the stuck header margins get recalculated
        window.addEventListener('resize', this.windowResize);
    }

    componentWillUnmount() {
        this.productEvtSource.close();
        this.processEvtSource.close();
        this.reworkEvtSource.close();
        this.completedEvtSource.close();
        this.scannerErrorEvtSource.close();
        this.abortController.abort();

        window.removeEventListener('resize', this.windowResize);
    }

    toggleCompleted(event, data) {
        this.setState({ showCompleted: data.checked });
    }

    rangeUpdate(event) {
        this.setState({
            barHeight: event.target.value,
            barWidth: event.target.value * 2
        });
    }

    async priorityUp(event, { prodid, id }) {

        //remove focus from the clicked button
        document.getElementById(id).blur();

        let jsonstring = JSON.stringify({
            productID: prodid,
            increase: true,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/updatePriority.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: jsonstring,
                signal: this.abortController.signal
            });

            /*
                * Moves the progress bars on the client, instead of waiting for the server to send the updated data.
                * The tweaked client data will be replaced as soon as the server sends the new data
                */
            //get the index of the requested product id, doing nothing if it is null
            let index = this.getIndexFromProdID(prodid);

            if (index !== null) {

                //check if we are wanting to move the first item up, which is impossible
                if (index === 0) {
                    return;
                }

                //get the item above the index of the request item to move
                let above = this.state.QueryData[index - 1];

                //swap the requested item and the one above it
                this.state.QueryData[index - 1] = this.state.QueryData[index];

                this.state.QueryData[index] = above;

            }
            this.forceUpdate();

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async priorityDown(event, { prodid, id }) {

        //remove focus from the clicked button
        document.getElementById(id).blur();

        let jsonstring = JSON.stringify({
            productID: prodid,
            increase: false,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/updatePriority.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: jsonstring,
                signal: this.abortController.signal
            });

            /*
            * Moves the progress bars on the client, instead of waiting for the server to send the updated data.
            * The tweaked client data will be replaced as soon as the server sends the new data
            */

            //get the index of the requested product id, doing nothing if it is null
            let index = this.getIndexFromProdID(prodid);
            if (index !== null) {

                //check if we are wanting to move the last item down, which is impossible
                if (index === parseInt(Object.keys(this.state.QueryData).length) - 1) {
                    return;
                }

                //get the item below the index of the request item to move
                let below = this.state.QueryData[index + 1];

                //swap the requested item and the one below it
                this.state.QueryData[index + 1] = this.state.QueryData[index];

                this.state.QueryData[index] = below;

            }
            this.forceUpdate();

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    //finds the index in QueryData of the given product id
    getIndexFromProdID(productID) {

        //search for the product id in all the data gotten from the server
        for (let i = 0; i < Object.keys(this.state.QueryData).length; i++) {
            let current = this.state.QueryData[i];

            //we found the id return the index
            if (current["productID"] === productID) {
                return i;
            }

        }

        return null;

    }

    receiveSSEData(e) {

        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            //the stored data is not the same as the recived data, update it
            if (JSON.stringify(message) !== JSON.stringify(this.state.QueryData)) {
                this.setState({ QueryData: message });
            }

        }

    }

    receiveCompletedData(e) {
        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            //the stored data is not the same as the recived data, update it
            if (JSON.stringify(message) !== JSON.stringify(this.state.QueryData)) {
                this.setState({ CompletedData: message });
            }

        }

    }

    receiveReworkedSSEData(e) {

        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            //the stored data is not the same as the recived data, update it
            if (JSON.stringify(message) !== JSON.stringify(this.state.ReworkedData)) {
                this.setState({ ReworkedData: message });
            }

        }
    }

    receiveProcessData(e) {
        let message = JSON.parse(e.data);
        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    receiveScannerErrorData(e) {

        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (Object.keys(message).length === 0) {
                message = null;
            }

            //the stored data is not the same as the recived data, update it
            if (JSON.stringify(message) !== JSON.stringify(this.state.ScannerError)) {
                this.setState({ ScannerError: message });
            }

        }

    }

    getStages(process) {
        for (var key in this.state.Processes) {
            if (this.state.Processes.hasOwnProperty(key) && this.state.Processes[key].processID === process) {
                return this.state.Processes[key].stages;
            }
        }
    }

    findIndex(stages, stage) {

        for (let i = 0; i < Object.keys(stages).length; i++) {
            if (stages[i] === stage) {
                return i - 1;
            }
            if (stage === stages[Object.keys(stages).length]) {
                return Object.keys(stages).length - 1;
            }
        }

    }

    reworkCountChange(event, { reworkid }) {

        if (!isNaN(event.target.value) && Number.isInteger(Number.parseFloat(event.target.value))) {
            let number = Number.parseInt(event.target.value);
            this.updateRework(reworkid, number);
        }
    }

    reworkNotesChange(event, { reworkid }) {
        let notes = event.target.value;
        this.updateReworkNotes(reworkid, notes);
    }

    productNotesChange(event, { productID }) {
        let notes = event.target.value;
        //console.log(productID + " " + notes);
        this.updateProductNotes(productID, notes);
    }

    setWaiting(productID) {
        console.log("test");
        let notes = "⌛";
        console.log(productID + " " + notes);
        this.appendProductNotes(productID, notes);
    }

    async updateRework(reworkID, number) {

        let reworkJson = JSON.stringify({
            reworkID: reworkID,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            number: number
        });
        try {

            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "analytics/rework/updateReworkNumber.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: reworkJson,
                signal: this.abortController.signal
            });

            let responseJson = await response.json();
        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async updateReworkNotes(reworkID, notes) {

        let reworkJson = JSON.stringify({
            reworkID: reworkID,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            notes: notes
        });
        try {

            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "analytics/rework/updateReworkNotes.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: reworkJson,
                signal: this.abortController.signal
            });

            let responseJson = await response.json();
        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async updateProductNotes(productID, notes) {
        let productJSON = JSON.stringify({
            productID: productID,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            notes: notes
        });
        try {

            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/updateDescription.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            let responseJson = await response.json();
        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async appendProductNotes(productID, notes) {
        let productJSON = JSON.stringify({
            productID: productID,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
            notes: notes
        });
        try {

            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/appendDescription.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: productJSON,
                signal: this.abortController.signal
            });

            let responseJson = await response.json();
        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    buildProgressBar(process, currentStage, height, width, completed, requirement) {

        let flag = "";
        //missing data skip this product
        if (process === null || currentStage === null || this.state.Processes === null || this.state.Processes === undefined) {
            return ("");
        }

        let stages = this.getStages(process);

        //scaffold the progressBarData object as a blank formatted object
        var progressBarData = {};

        for (let i = 0; i < Object.keys(stages).length; i++) {

            let currentIndex = i + 1;
            let stageNumber = "";

            progressBarData[i] =
            {
                filter: "",
                state: "",
                tooltipText: stages[currentIndex]
            };
            if (stages[currentIndex] === "Pre-Inspection")
                progressBarData[i].filter = "filter-preinspection";
            else if (stages[currentIndex] === "Pre-Washing (Jig-Washer)")
                progressBarData[i].filter = "filter-prewashing";
            else if (stages[currentIndex] === "Stripping (Shot)")
                progressBarData[i].filter = "filter-strippingShot";
            else if (stages[currentIndex] === "Stripping (Liquid)")
                progressBarData[i].filter = "filter-strippingLiquid";
            else if (stages[currentIndex] === "WPC (SKH+Ceramic)")
                progressBarData[i].filter = "filter-wpcskh";
            else if (stages[currentIndex] === "WPC-Ceramic") {
                progressBarData[i].filter = "filter-ceramic";
                progressBarData[i].tooltipText = "WPC";
            }
            else if (stages[currentIndex] === "Pre-Lapping (SMAP)")
                progressBarData[i].filter = "filter-prelapSMAP";
            else if (stages[currentIndex] === "Pre-Lapping (AERO)")
                progressBarData[i].filter = "filter-prelapAERO";
            else if (stages[currentIndex] === "Pre-Lapping (Hand Lapping)")
                progressBarData[i].filter = "filter-prelapHand";
            else if (stages[currentIndex] === "Pre-Washing (F1 Clean Washer)")
                progressBarData[i].filter = "filter-washing";
            else if (stages[currentIndex] === "Coating (M1) - Set/Disassemble") {
                flag = 1;
                progressBarData[i].filter = "filter-coatingM1";
            }
            else if (stages[currentIndex] === "Coating (M2) - Set/Disassemble") {
                flag = 2;
                progressBarData[i].filter = "filter-coatingM2";
            }
            else if (stages[currentIndex] === "Coating (M3) - Set/Disassemble") {
                flag = 3;
                progressBarData[i].filter = "filter-coatingM3";
            }

            else if (stages[currentIndex] === "Post-Inspection")
                progressBarData[i].filter = "filter-postInspection";
            else if (stages[currentIndex] === "Post-Lapping (SMAP)")
                progressBarData[i].filter = "filter-postlappingSMAP";
            else if (stages[currentIndex] === "Post-Lapping (AERO)")
                progressBarData[i].filter = "filter-postlappingAERO";
            else if (stages[currentIndex] === "Post-Lapping (Hand Lapping)")
                progressBarData[i].filter = "filter-postlappingHand";
            else if (stages[currentIndex] === "Final Inspection")
                progressBarData[i].filter = "filter-finalInspect";
            else if (stages[currentIndex] === "Packing/Shipping")
                progressBarData[i].filter = "filter-shipping";
        }

        //only bother getting the stage index if the product isn't completed
        var currentStageIndex = 0;
        if (!completed) {
            currentStageIndex = this.findIndex(stages, currentStage);
        }

        for (let i = 0; i < Object.keys(progressBarData).length; i++) {

            //base case is segment that is in progress
            let segmentType = "striped"
            if (i < currentStageIndex || completed) { //stages that are done
                segmentType = "filled";
            } else if (i > currentStageIndex) { //stages that haven't been started yet
                segmentType = "hollow"
            }
            //update the state of the segment
            progressBarData[i].state = segmentType;
        }

        //if requirement is invalid or undefined just show everything
        if (flag == requirement || requirement == undefined || requirement == -1) {
            return (<ProgressBar data={progressBarData} height={height} width={width} />)
        }
        else
            return (false);
    }

    //checks if there are any reworks for the given product returning the data render ready, returns "" if there are none
    getReworksForProduct(prodID) {

        if (this.state.ReworkedData === null || Object.keys(this.state.ReworkedData).length === 0) {
            return ("");
        }

        let reworkJSON = this.state.ReworkedData;

        //grab all reworks for this product
        const reworkForProduct = [];
        for (let i = 0; i < Object.keys(reworkJSON["reworkedProducts"]).length; i++) {
            let current = reworkJSON["reworkedProducts"][i];
            if (parseInt(prodID) === parseInt(current["ProductID"])) {
                reworkForProduct.push(current);
            }

        }

        if (reworkForProduct.length > 0) {
            const reworkBars = [];
            for (let i = 0; i < reworkForProduct.length; i++) {
                reworkBars.push(<div key={i + reworkForProduct.length}>
                    <div className={this.props.barCountContainer}>
                        <span className={this.props.title}>
                            <b key={i}>{reworkForProduct[i].ReworkID + " - " + reworkForProduct[i].Reason}</b>
                        </span>
                        <span className={this.props.count}>
                            <Input label='Number of Rework Items' placeholder={reworkForProduct[i].NumberofItems} onChange={this.reworkCountChange} reworkid={reworkForProduct[i].ReworkID} />
                        </span>
                        <span className={this.props.notes}>
                            <Input label='Notes' placeholder={reworkForProduct[i].Notes} onChange={this.reworkNotesChange} reworkid={reworkForProduct[i].ReworkID} />
                        </span>
                    </div>
                    {this.buildProgressBar(reworkForProduct[i].Process, reworkForProduct[i].CurrentStage, this.state.barHeight * .7, this.state.barWidth * .7, false)}
                </div>);
            }

            return (reworkBars);
        }

        return ("");

    }

    changefilterValue = (e, data) => {
        console.log(data);
        this.state.filterValue = data.value;
        this.handlechange(e, data);
    }

    handlechange = (e, { value }) => this.setState({ value })

    async resetScannerErrors(event, { id }) {
        //remove focus from the clicked button
        document.getElementById(id).blur();

        let sentJson = JSON.stringify({
            ScannerID: this.state.scannerToReset
        });

        try {

            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "scannedItem/resetWarning.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: sentJson,
                signal: this.abortController.signal
            });

            let responseJson = await response.json();

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }

    }

    render() {
        let DataUse = null;
        let completed = false;

        //pick which data to use for the progress bars
        if (!this.state.showCompleted) {
            DataUse = this.state.QueryData;
        } else {
            DataUse = this.state.CompletedData;
            completed = true;
        }

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager') {
            return (<Redirect to="/signin" />);
        }

        //don't render if query data hasn't been gotten set yet
        if (this.state.QueryData == null || this.state.Processes === null) {
            return (<div>
                <Navbar selectedPage="managerView" />
            </div>)
        }

        const items = []

        if (DataUse !== null) {
            //loop through each element returned from the dashboard read
            for (let index = 0; index < Object.keys(DataUse).length; index++) {
                if (this.state.QueryData[index] !=undefined)
                {
                var due = this.state.QueryData[index].ShipmentSchedule;
                var today = new Date().toJSON().slice(0, 10);
                var ontime = (due > today);
                }


                //get the current row from the list of elements
                let value = DataUse[index];

                let ship = value.ShipmentSchedule.split('-');
                let shipOutput = ship[1] + "/" + ship[2] + "/" + ship[0];

                let receivedOutput = 0;

                //check to prevent crash when viewing completed
                if (value.ReceivedDate !== undefined) {
                    let received = value.ReceivedDate.split('-');
                    receivedOutput = received[1] + "/" + received[2] + "/" + received[0];
                }
                //don't use the priority buttons if viewing the completed dashbaord
                if (completed) {
                    items.push(<div id="ProductID" className={this.props.productContainer} key={index}>
                        <b>{value.productID + " - (" + value.ProductName + ", " + value.Customer + ")"}</b>{" "}
                        <div className={this.props.progressBar}><br />{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, completed)}</div>
                        <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                        <hr />
                    </div>);
                }
                else {
                    if ((this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, completed, this.state.filterValue)) !== false) {
                        if (this.state.QueryData[index].productWeight === null)
                        {
                            this.state.QueryData[index].productWeight= "-"; 
                        }
                        //LATE
                        if (!ontime) {
                            if (this.state.QueryData[index].productWeight.includes("x")) {
                                items.push(<div id="ProductID" style={{ color: 'red' }} className={this.props.productContainer} key={index}>
                                    <div className={this.props.progressBarTitle}><Icon name='cut' /><b>{value.productID + " - (" + value.ProductName + ", " + value.Customer + ") - Received: " + receivedOutput + ", Expected Shipment: " + shipOutput}</b></div>
                                    <Button icon onClick={this.priorityUp} prodid={value.productID} id={"upArrow" + value.productID}>
                                        <Icon name='arrow alternate circle up' />
                                    </Button>
                                    <Button icon onClick={this.priorityDown} prodid={value.productID} id={"downArrow" + value.productID}>
                                        <Icon name='arrow alternate circle down' />
                                    </Button>
                                    <Input label='Change Description:' style={{width: '60%'}} defaultValue={this.state.QueryData[index].Notes} onChange={this.productNotesChange} productID={value.productID} />
                                    <Button className="smallBtn" onClick={() =>{this.setWaiting(value.productID)}} productID={value.productID}>Set to: Waiting for customer approval</Button>
                                    <div className={this.props.progressBar}><br />{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, completed, this.state.filterValue)}</div>
                                    <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                    <hr />
                                </div>);
                            }
                            else {
                                items.push(<div id="ProductID" style={{ color: 'red' }} className={this.props.productContainer} key={index}>
                                    <div className={this.props.progressBarTitle}><b>{value.productID + " - (" + value.ProductName + ", " + value.Customer + ") - Received: " + receivedOutput + ", Expected Shipment: " + shipOutput}</b></div>
                                    <Button icon onClick={this.priorityUp} prodid={value.productID} id={"upArrow" + value.productID}>
                                        <Icon name='arrow alternate circle up' />
                                    </Button>
                                    <Button icon onClick={this.priorityDown} prodid={value.productID} id={"downArrow" + value.productID}>
                                        <Icon name='arrow alternate circle down' />
                                    </Button>
                                    <Input label='Change Description:' style={{width: '60%'}} defaultValue={this.state.QueryData[index].Notes} onChange={this.productNotesChange} productID={value.productID} />
                                    <Button className="smallBtn" onClick={() =>{this.setWaiting(value.productID)}} productID={value.productID}>Set to: Waiting for customer approval</Button>
                                    <div className={this.props.progressBar}><br />{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, completed, this.state.filterValue)}</div>
                                    <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                    <hr />
                                </div>);
                            }
                        }
                        //NOT LATE
                        else {
                            if (this.state.QueryData[index].productWeight.includes("x")) {
                                items.push(<div id="ProductID" className={this.props.productContainer} key={index}>
                                    <div className={this.props.progressBarTitle}><Icon name='cut' /><b>{value.productID + " - (" + value.ProductName + ", " + value.Customer + ") - Received: " + receivedOutput + ", Expected Shipment: " + shipOutput}</b></div>
                                    <Button icon onClick={this.priorityUp} prodid={value.productID} id={"upArrow" + value.productID}>
                                        <Icon name='arrow alternate circle up' />
                                    </Button>
                                    <Button icon onClick={this.priorityDown} prodid={value.productID} id={"downArrow" + value.productID}>
                                        <Icon name='arrow alternate circle down' />
                                    </Button>
                                    <Input label='Change Description:'  style={{width: '60%'}} defaultValue={this.state.QueryData[index].Notes} onChange={this.productNotesChange} productID={value.productID} />
                                    <div className={this.props.progressBar}><br />{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, completed, this.state.filterValue)}</div>
                                    <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                    <hr />
                                </div>);
                            }
                            else {
                                items.push(<div id="ProductID" className={this.props.productContainer} key={index}>
                                    <div className={this.props.progressBarTitle}><b>{value.productID + " - (" + value.ProductName + ", " + value.Customer + ") - Received: " + receivedOutput + ", Expected Shipment: " + shipOutput}</b></div>
                                    <Button icon onClick={this.priorityUp} prodid={value.productID} id={"upArrow" + value.productID}>
                                        <Icon name='arrow alternate circle up' />
                                    </Button>
                                    <Button icon onClick={this.priorityDown} prodid={value.productID} id={"downArrow" + value.productID}>
                                        <Icon name='arrow alternate circle down' />
                                    </Button>
                                    <Input label='Change Description:' style={{width: '60%'}} defaultValue={this.state.QueryData[index].Notes} onChange={this.productNotesChange} productID={value.productID} />
                                    <div className={this.props.progressBar}><br />{this.buildProgressBar(value.Process, value.CurrentStage, this.state.barHeight, this.state.barWidth, completed, this.state.filterValue)}</div>
                                    <div className={this.props.reworkProgressBar}>{this.getReworksForProduct(value.productID)}</div>
                                    <hr />
                                </div>);
                            }
                        }
                    }
                }
            }
        }

        //create another array for the legened
        var readings = [
            {
                name: 'Pre-Inspection',
                value: 16.6,
                color: '#c8a2c8',
                inprogress: false
            },
            {
                name: 'Pre-Washing (Jig-Washer)',
                value: 16.6,
                color: '#bbf0fc',
                inprogress: false
            },
            {
                name: 'Stripping (Shot)',
                value: 16.6,
                color: '#ff6200',
                inprogress: false
            },
            {
                name: 'Stripping (Liquid)',
                value: 16.6,
                color: '#8B0000',
                inprogress: false
            },
            {
                name: 'WPC-SKH',
                value: 16.6,
                color: '#ffe261',
                inprogress: false
            },
            {
                name: 'WPC-Ceramic',
                value: 16.6,
                color: '#ffd000',
                inprogress: false
            },
            {
                name: 'Pre-Lapping (SMAP)',
                value: 16.6,
                color: '#b9ff69',
                inprogress: false
            },
            {
                name: 'Pre-Lapping (AERO)',
                value: 16.6,
                color: '#89ff00',
                inprogress: false
            },
            {
                name: 'Pre-Lapping (Hand Lapping)',
                value: 16.6,
                color: '#70d100',
                inprogress: false
            },
            {
                name: 'Pre-Washing (F1 Clean Washer)',
                value: 16.6,
                color: '#00a82d',
                inprogress: false
            },
            {
                name: 'Coating (M1) - Set/Disassemble',
                value: 16.6,
                color: '#c9c9c9',
                inprogress: false
            },
            {
                name: 'Coating (M2) - Set/Disassemble',
                value: 16.6,
                color: '#9e9d9d',
                inprogress: false
            },
            {
                name: 'Coating (M3) - Set/Disassemble',
                value: 16.6,
                color: '#000000',
                inprogress: false
            },
            {
                name: 'Post-Inspection',
                value: 16.6,
                color: '#df6bff',
                inprogress: false
            },
            {
                name: 'Post-Lapping (SMAP)',
                value: 16.6,
                color: '#0077ff',
                inprogress: false
            },
            {
                name: 'Post-Lapping (AERO)',
                value: 16.6,
                color: '#0040d6',
                inprogress: false
            },
            {
                name: 'Post-Lapping (Hand Lapping)',
                value: 16.6,
                color: '#002885',
                inprogress: false
            },
            {
                name: 'Final Inspection',
                value: 16.6,
                color: '#4b0085',
                inprogress: false
            },
            {
                name: 'Packing/Shipping',
                value: 16.6,
                color: '#855900',
                inprogress: false
            }
        ];;

        var message = "";
        if (this.state.ScannerError !== null) {

            let scannerString = "";
            const dropdownItems = [];
            let counter = 0;
            for (let key of Object.keys(this.state.ScannerError)) {

                dropdownItems.push(<Dropdown.Item
                    key={counter}
                    name={key}
                    active={key === this.state.scannerToReset}
                    onClick={this.dropDownClick}
                >
                    Scanner {key}
                </Dropdown.Item>);

                //use the correct plural of errors based on if the scanner had one or more errors
                if (this.state.ScannerError[key] == 1) {
                    scannerString += "Scanner '" + key + "' had " + this.state.ScannerError[key] + " error";
                }
                else {
                    scannerString += "Scanner '" + key + "' had " + this.state.ScannerError[key] + " errors";
                }

                //only add the comma if this isn't the last element
                if (counter < Object.keys(this.state.ScannerError).length - 1) {
                    scannerString += ", ";
                }

                counter++;
            }



            //build the error message
            message =
                <div class="ui negative message">
                    <div class="header">
                        Scanner Errors
                    </div>
                    <p>
                        {scannerString}
                    </p>

                    <span style={{ paddingRight: '1%' }}><Button onClick={this.resetScannerErrors} id="scannerResetBTN">Reset</Button></span>
                    <span>
                        Scanner:{" "}
                        <Dropdown text={this.state.scannerToReset}>
                            <Dropdown.Menu>
                                {dropdownItems}
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>


                </div>;
        }

        const optionsArray = [
            {
                key: 'none',
                text: 'no filter',
                value: '-1',
            },
            {
                key: 'M1',
                text: 'M1',
                value: '1',
            },
            {
                key: 'M2',
                text: 'M2',
                value: '2',
            },
            {
                key: 'M3',
                text: 'M3',
                value: '3',
            },]



        return <div>

            <div className={this.props.stuck}>
                {
                    document.getElementsByClassName(this.props.stuck).length > 0
                }
                <span className={this.props.printHide}>
                    <Navbar selectedPage="managerView" />

                    <MultiColorProgressBarLegend readings={readings} />
                </span>
                <div className={this.props.container + " " + this.props.controlDiv + " " + this.props.printHide + " " + "whitebackground"}>
                    <span className={this.props.control}>
                        {/* View Employees Modal */}
                        <Modal
                            onClose={() => this.setState({ viewEmployeesOpen: false })}
                            onOpen={() => this.setState({ viewEmployeesOpen: true })}
                            open={this.state.viewEmployeesOpen}
                            trigger={<span className={this.props.modalOpenLink}>View Employees</span>}
                        >
                            <Modal.Header>View Employees</Modal.Header>
                            <Modal.Content>
                                <ViewEmployee />
                            </Modal.Content>
                        </Modal>
                    </span>
                    {/* <span className={this.props.control}><Link to={'/viewQRCodes'} target="_blank">View All QR Codes</Link></span> */}
                    <span className={this.props.control}>
                        {/* View Reworked QR Codes Modal */}
                        <Modal
                            onClose={() => this.setState({ viewReworkQRCodesOpen: false })}
                            onOpen={() => this.setState({ viewReworkQRCodesOpen: true })}
                            open={this.state.viewReworkQRCodesOpen}
                            trigger={<span className={this.props.modalOpenLink}>View Reworked QR Codes</span>}
                            id={"reworkedQRCodes"}
                        >
                            <Modal.Content>
                                <ViewReworkQRCodes />
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => {
                                    this.setState({ viewReworkQRCodesOpen: false }, () => { window.print() });
                                    document.getElementsByClassName("printarea")[0].innerHTML = document.getElementsByClassName("codes")[0].innerHTML;
                                }}>Print</Button>
                            </Modal.Actions>
                        </Modal>
                    </span>
                    <span className={this.props.control + " " + this.props.printHide}>
                        {/* View QR Codes Modal */}
                        <Modal
                            onClose={() => this.setState({ viewAllQRCodesOpen: false })}
                            onOpen={() => this.setState({ viewAllQRCodesOpen: true })}
                            open={this.state.viewAllQRCodesOpen}
                            trigger={<span className={this.props.modalOpenLink}>View QR Codes</span>}
                            id={"allQRCodes"}
                        >
                            <Modal.Content>
                                <ViewQRCodes />
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => {
                                    this.setState({ viewAllQRCodesOpen: false }, () => { window.print() });
                                    document.getElementsByClassName("printarea")[0].innerHTML = document.getElementsByClassName("codes")[0].innerHTML;
                                }}>Print</Button>
                            </Modal.Actions>
                        </Modal>
                    </span>
                    <span className={this.props.control}>
                        {/* View Employees Modal */}
                        <Modal
                            onClose={() => this.setState({ controlsOpen: false })}
                            onOpen={() => this.setState({ controlsOpen: true })}
                            open={this.state.controlsOpen}
                            trigger={<span className={this.props.modalOpenLink}>Controls</span>}
                        >
                            <Modal.Header>Manager Controls</Modal.Header>
                            <Modal.Content>
                                <Controls />
                            </Modal.Content>
                        </Modal>
                    </span>
                    {/*<Checkbox className={this.props.control} label='Show only completed' onClick={this.toggleCompleted} />*/}
                    {/*<Dropdown upward
                        placeholder='Filter by coating type:'
                        search
                        selection
                        options={optionsArray}
                        onChange={this.changefilterValue}
                    />*/}
                    <Form>
                        <Form.Field>
                            <Radio
                                label='No filter'
                                name='radioGroup'
                                value={'-1'}
                                checked={this.state.value === '-1'}
                                onChange={this.changefilterValue}
                                defaultChecked
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M1'
                                name='radioGroup'
                                value='1'
                                checked={this.state.value === '1'}
                                onChange={this.changefilterValue}
                            />{"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M2'
                                name='radioGroup'
                                value='2'
                                checked={this.state.value === '2'}
                                onChange={this.changefilterValue}
                            />
                            {"\u00A0\u00A0\u00A0\u00A0"}
                            <Radio
                                label='M3'
                                name='radioGroup'
                                value='3'
                                checked={this.state.value === '3'}
                                onChange={this.changefilterValue}
                            />
                        </Form.Field>
                    </Form>
                    {/*<span className={this.props.control}>Progress Bar Size:</span>}
                    <input className={this.props.slider} type="range" min="1" max="2" step=".1" value={this.state.barHeight} onChange={this.rangeUpdate}></input>*/}

                </div>
            </div>

            <div
                className={this.props.container + " " + this.props.controlDiv + " " + this.props.printHide + " " + "whitebackground" + " " + this.props.barDiv}
                style={{ marginTop: document.getElementsByClassName(this.props.stuck).length > 0 ? document.getElementsByClassName(this.props.stuck)[0].clientHeight + "px" : '0px' }}
            >
                {message}
                <span className={this.props.printHide}>{items}</span>
            </div>

            <div className={this.props.printable + " " + "printarea"}></div>
        </div>
    }
}

export default withStyles(ManagerView);
