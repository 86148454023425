import React from 'react';
import { Redirect } from 'react-router-dom'
import { Table, Button, Grid, Input, Checkbox, Dropdown, Label } from 'semantic-ui-react';
import Navbar from './navbar';
import hocify from 'hocify';
import enterProductStyle from '../jss/enterProduct';

const withStyles = hocify(enterProductStyle);

class ProductInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            QueryData: null,
            response: null,
            codes: null,
            Processes: null,
            latest: " ",
            productID: '',
            customer: '',
            DeliveryDate: '',
            ReceivedDate: '',
            Material: '',
            ProductName: '',
            Quantity: '',
            ProductWeight: '',
            ShipmentSchedule: '',
            PO: '',
            Process: '',
            NumberOfItems: '',
            Coating: '',
            Notes: '',
            weightSerial: '',
            weightArrSerial: '',
            weightHolder: '',
            fileName: 'export.csv',
            fileNameFull: '',
            isSubProduct: 'false',
            cuttingtools: 0,
            blob: '',
            productList: '',
            string: [['ProductID', 'Customer', 'DeliveryDate', 'ReceivedDate', 'Material', 'ProductName',
                'Quantity', 'ProductWeight', 'ShipmentSchedule', 'PO', 'Process', 'NumberOfItems', 'Coating', 'Notes'],
            ],
            outputString: [['']],
            userAgentText: '',
            screnWidth: ''

        }

        this.productIDChange = this.productIDChange.bind(this);
        this.getCustomers = this.getCustomers.bind(this);
        this.showAllFiles = this.showAllFiles.bind(this);
        this.deleteAllFiles = this.deleteAllFiles.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.customerChange = this.customerChange.bind(this);
        this.deliveryChange = this.deliveryChange.bind(this);
        this.receivedChange = this.receivedChange.bind(this);
        this.materialChange = this.materialChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.quantityChange = this.quantityChange.bind(this);
        this.weightChange = this.weightChange.bind(this);
        this.diameterChange = this.diameterChange.bind(this);
        this.lengthChange = this.lengthChange.bind(this);
        this.shipChange = this.shipChange.bind(this);
        this.poChange = this.poChange.bind(this);
        this.processChange = this.processChange.bind(this);
        this.numberChange = this.numberChange.bind(this);
        this.coatingChange = this.coatingChange.bind(this);
        this.notesChange = this.notesChange.bind(this);
        this.addToString = this.addToString.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleCut = this.handleCut.bind(this);
        this.getCharsLength = this.getCharsLength.bind(this);
        this.processEvtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php");
        this.abortController = new AbortController();
    }

    getCharsLength() {
        var results = "";
        //console.log(this.state.weightHolder.length);
        for (let i = 0; i < this.state.weightHolder.length; i++) {
            results += (String.fromCharCode(this.state.weightHolder[i]));
        }
        console.log(results);
        document.getElementById("weightBox").value = results;
        this.state.ProductWeight = results;
        results = "";
        this.state.weightHolder = "";
        this.state.weightSerial = "";
        this.state.weightArrSerial = "";
    }

    handleCut() {
        if (this.state.cuttingtools === 0) {
            this.setState({ cuttingtools: 1 });
        }
        else if (this.state.cuttingtools === 1) {
            this.setState({ cuttingtools: 0 });
        }
    }

    async getCustomers() {

        //get the json ready to be sent
        let queryBody = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/getCustomers.php", {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: queryBody,
                signal: this.abortController.signal
            });


            let responseJSON = await response.json();
            this.setState({
                QueryData: responseJSON
            });

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }


    diameterChange(event) {
        this.state.ProductDiameter = event.target.value;
        this.setState({ ProductWeight: this.state.ProductDiameter + "x" + this.state.ProductLength });
        console.log(this.state.ProductDiameter + " " + this.state.ProductLength);
    }
    lengthChange(event) {
        this.state.ProductLength = event.target.value;
        this.setState({ ProductWeight: this.state.ProductDiameter + "x" + this.state.ProductLength });
        console.log(this.state.ProductDiameter + " " + this.state.ProductLength);
    }

    exportToCsv(filename, rows) {
        var d = new Date();
        if (this.state.isSubProduct == "true") {
            var filename = (this.state.fileName + " " + this.state.customer + " Sub-Product.csv").toString();
        }
        else {
            var filename = (this.state.fileName + " " + d.getHours() + " " + d.getMinutes() + " " + this.state.customer + ".csv").toString();
        }
        this.state.fileNameFull = filename;
        console.log(this.state.fileNameFull);

        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        //use blob when letting users directly download
        //blob is not needed when sending to server
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        //console.log(blob)
        console.log(csvFile);
        this.uploadFile(csvFile, this.state.fileNameFull);

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            /*var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }*/
        }
        this.setState({
            string: [['ProductID', 'Customer', 'DeliveryDate', 'ReceivedDate', 'Material', 'ProductName',
                'Quantity', 'ProductWeight', 'ShipmentSchedule', 'PO', 'Process', 'NumberOfItems', 'Coating', 'Notes'],
            ],
            outputString: [['']]
        });
    }



    async uploadFile(inputCSV, filename) {
        //console.log(inputCSV);
        //console.log(process.env.REACT_APP_SERVER_ROOT);
        //var myReader = new FileReader();
        //myReader.onload = function(event){
        //    console.log(JSON.stringify(myReader.result));
        //};
        //myReader.readAsText(blob);

        let jsonstring = JSON.stringify({
            file: inputCSV,
            fileName: filename
        })

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "csvUpload.php", {
                method: "POST",
                signal: this.abortController.signal,
                body: jsonstring

            });
            let responseJSON = await response.json();
            console.log(responseJSON);
            console.log("success");

        } catch (error) {
            console.log(error.toString());
            if (error.name === 'AbortError') {
            }
        }
    }

    async showAllFiles() {
        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "showAllProductFiles.php", {
                method: "GET",
                signal: this.abortController.signal

            });
            let responseJSON = await response.json();
            this.state.productList = responseJSON;
            this.generateLinks();

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }

    async deleteAllFiles() {
        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "deleteAllProductFiles.php", {
                method: "POST",
                signal: this.abortController.signal

            });
            let responseJSON = await response.json();
            console.log(responseJSON);
            console.log("success");
            this.state.productList = responseJSON;
            this.generateLinks();

        } catch (error) {
            console.log(error.toString());
            if (error.name === 'AbortError') {
            }
        }
    }

    componentDidMount() {
        //send all onmessage events to the sse receive method
        this.getCustomers();
        this.processEvtSource.onmessage = e => this.receiveProcessSSEData(e);
        var d = new Date();
        this.state.fileName = (d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getFullYear().toString().substring(2);
        this.showAllFiles();
        this.state.userAgentText = navigator.userAgent;
    }

    componentDidUpdate() {
        this.showAllFiles();
    }

    componentWillUnmount() {
        this.abortController.abort();
        this.processEvtSource.close();
    }

    receiveProcessSSEData(e) {
        let message = JSON.parse(e.data);

        if (message["status"] === 200) {
            delete message["status"];

            if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
                this.setState({ Processes: message });
            }
        }
    }

    productIDChange(event) { this.state.productID = event.target.value; }
    //customerChange(event) { this.state.customer = event.target.value; }
    deliveryChange(event) {
        this.state.DeliveryDate = event.target.value;
        this.state.DeliveryDate = this.state.DeliveryDate.replace(new RegExp('-', "g"), "/");
    }
    receivedChange(event) {
        this.state.ReceivedDate = event.target.value;
        this.state.ReceivedDate = this.state.ReceivedDate.replace(new RegExp('-', "g"), "/");
    }
    materialChange(event) { this.state.Material = event.target.value; }
    nameChange(event) { this.state.ProductName = event.target.value; }
    quantityChange(event) { this.state.Quantity = event.target.value; }
    weightChange(event) { this.state.ProductWeight = event.target.value; }
    shipChange(event) {
        this.deliveryChange(event);
        this.state.ShipmentSchedule = event.target.value;
        this.state.ShipmentSchedule = this.state.ShipmentSchedule.replace(new RegExp('-', "g"), "/");
    }
    poChange(event) { this.state.PO = event.target.value; }
    processChange(event) { this.state.Process = event.target.value; }
    numberChange(event) { this.state.NumberOfItems = event.target.value; }
    coatingChange = (event, data) => {
        console.log(data.value);
        this.setState({ Coating: data.value });
    }
    notesChange(event) { this.state.Notes = event.target.value; }

    customerChange = (event, data) => {
        this.setState({ customer: data.value });
        console.log(this.state.customer)
    }

    handleAddition = (e, { value }) => {
        this.setState({
            customer: value
          })
      }



    addToString() {

        const strip = document.getElementById('strCheck');
        if (strip.checked) {
            this.state.Notes = this.state.Notes + " ADD STRIPPING";
        }

        const wpc = document.getElementById('wpcCheck');
        if (wpc.checked) {
            this.state.Notes = this.state.Notes + " ADD WPC";
        }

        const lap = document.getElementById('lapCheck');
        if (lap.checked) {
            this.state.Notes = this.state.Notes + " ADD LAPPING";
        }

        var stringAdd = [
            [this.state.productID, this.state.customer, this.state.DeliveryDate, this.state.ReceivedDate, this.state.Material, this.state.ProductName,
            this.state.Quantity, this.state.ProductWeight, this.state.ShipmentSchedule, this.state.PO, this.state.Process, this.state.NumberOfItems,
            this.state.Coating, this.state.Notes],
        ];
        this.state.string = this.state.string.concat(stringAdd);
        //console.log(this.state.string);
        this.state.outputString = JSON.parse(JSON.stringify(this.state.string));
        for (var i = 0; i < this.state.outputString.length; i++) {
            this.state.outputString[i].splice(0, 1);
            this.state.outputString[i].splice(7, 4);
        }
        console.log(this.state.outputString);

        var productName = document.getElementById("productName");
        productName.value = "";
        var material = document.getElementById("material");
        material.value = "";
        var quantity = document.getElementById("quantity");
        quantity.value = "";
        if (this.state.cuttingtools === 0)
        {
        var weightBox = document.getElementById("weightBox");
        weightBox.value = "";
        }
        if (this.state.cuttingtools === 1)
        {
        var diameterBox = document.getElementById("diameterBox");
        diameterBox.value = "";
        var lengthBox = document.getElementById("lengthBox");
        lengthBox.value = "";
        }
        var notes = document.getElementById("notes");
        notes.value = "";

        this.setState({
            itemvalues: [{}],
            productID: '',
            Material: '',
            ProductName: '',
            Quantity: '',
            ProductWeight: '',
            PO: '',
            Process: '',
            NumberOfItems: '',
            Notes: '',
        });

    }

    resetAllFields = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setState({
            itemvalues: [{}],
            productID: '',
            customer: '',
            DeliveryDate: '',
            ReceivedDate: '',
            Material: '',
            ProductName: '',
            Quantity: '',
            ProductWeight: '',
            ShipmentSchedule: '',
            PO: '',
            Process: '',
            NumberOfItems: '',
            Coating: '',
            Notes: '',
            string: [['ProductID', 'Customer', 'DeliveryDate', 'ReceivedDate', 'Material', 'ProductName',
                'Quantity', 'ProductWeight', 'ShipmentSchedule', 'PO', 'Process', 'NumberOfItems', 'Coating', 'Notes'],
            ],
            outputString: [['']]
        });

        document.getElementById("strCheck").checked = false;
        document.getElementById("wpcCheck").checked = false;
        document.getElementById("lapCheck").checked = false;
    }

    handleResetCSV = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setState({
            itemvalues: [{}],
            productID: '',
            customer: '',
            DeliveryDate: '',
            ReceivedDate: '',
            Material: '',
            ProductName: '',
            Quantity: '',
            ProductWeight: '',
            ShipmentSchedule: '',
            PO: '',
            Process: '',
            NumberOfItems: '',
            Coating: '',
            Notes: '',
            string: [['ProductID', 'Customer', 'DeliveryDate', 'ReceivedDate', 'Material', 'ProductName',
                'Quantity', 'ProductWeight', 'ShipmentSchedule', 'PO', 'Process', 'NumberOfItems', 'Coating', 'Notes'],
            ]
        });
    }

    handleResetFileOnly = () => {
        this.setState({
            string: [['ProductID', 'Customer', 'DeliveryDate', 'ReceivedDate', 'Material', 'ProductName',
                'Quantity', 'ProductWeight', 'ShipmentSchedule', 'PO', 'Process', 'NumberOfItems', 'Coating', 'Notes'],
            ]
        });
    }

    handleClick() {
        if (this.state.isSubProduct == "false") {
            this.state.isSubProduct = "true";
        }
        else this.state.isSubProduct = "false";
        //console.log(this.state.isSubProduct);
    }


    generateLinks() {
        let links = [];
        for (let i = 0; i < this.state.productList.length; i++) {
            this.state.productList[i] = this.state.productList[i].replace("/var/www/html/build/api/", "");
            //this.state.productList[i] = ":8080/" + this.state.productList[i];
            console.log(window.innerWidth);
            console.log("SERVER ROOT:" + process.env.REACT_APP_SERVER_ROOT);
            console.log("PRODUCTS:" + this.state.productList[i]);
            if (window.innerWidth < 1280) {
                ////Don't show these links to mobile users
                //links.push(<a href={process.env.REACT_APP_SERVER_ROOT + this.state.productList[i]} style={{ fontSize: "0.1em" }}>
                //    {process.env.REACT_APP_SERVER_ROOT + this.state.productList[i]}</a>);
                //links.push(<br></br>);
            }
            else {
                links.push(<a href={process.env.REACT_APP_SERVER_ROOT + this.state.productList[i]}>
                    {process.env.REACT_APP_SERVER_ROOT + this.state.productList[i]}</a>);
                links.push(<br></br>);
            }
        }
        return links;
    }

    viewExistingFiles() {
        if (window.innerWidth >= 1280) {
            return <a href={process.env.REACT_APP_SERVER_ROOT + "productUploads/?C=M;O=D"}>View all existing files</a>;
        }
    }

    deleteButton() {
        if (window.innerWidth >= 1280) {
            return <Button style={{ marginTop: "0em" ,  backgroundColor: "red" }} onClick={() => { this.deleteAllFiles(); window.location.reload(); }}>Delete All Files</Button>
        }
    }

    render() {

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager') {
            return (<Redirect to="/signin" />);
        }

        const customerChoices = [];
        var choiceOBJ = {};

        if (this.state.QueryData != null) {

            for (let index = 0; index < Object.keys(this.state.QueryData).length-1; index++) {
                let val = this.state.QueryData[index];
                choiceOBJ = { key: val["Customer"], text: val["Customer"], value: val["Customer"]}
                customerChoices.push(choiceOBJ);
            }
        }

        const coatingOptions = [
            {
                key: 'ZERO-I',
                text: 'ZERO-I',
                value: 'ZERO-I',
            },
            {
                key: 'TH',
                text: 'TH',
                value: 'TH',
            },
            {
                key: 'TiAlN',
                text: 'TiAlN',
                value: 'TiAlN',
            },
            {
                key: 'TiN',
                text: 'TiN',
                value: 'TiN',
            },
            {
                key: 'S-PVD',
                text: 'S-PVD',
                value: 'S-PVD',
            },
            {
                key: 'None',
                text: 'None',
                value: 'None',
            },

        ]

        return (
            <div>
                <div className={this.props.printHide}>
                    <Navbar selectedPage={"productInput"} />
                    <div className={this.props.container + " " + "blueBackground"}>
                        <div style={{ textAlign: "center" }}>

                        </div>
                        <div>
                            {/*<Input size='massive' label={'Customer:'} sizeclassname="input" style={{ padding: "0.2em" }} onChange={this.customerChange} />*/}
                            <br></br><Label size="massive" >
                            Customer:</Label><Dropdown id="customerForm" selection clearable search allowAdditions style={{ width: '54.5%' }} options={customerChoices} onAddItem={this.handleAddition} onChange={this.customerChange}></Dropdown>
                            <br></br>
                            <Grid divided='vertically'>
                                <Grid.Row columns={2} divided>
                                    <Grid.Column>
                                        <Input label={'Received:'} classname="input" style={{ padding: "0.2em" }} type="date" onChange={this.receivedChange} />
                                        <br></br>
                                        <Input label={'Due Date:'} classname="input" style={{ padding: "0.2em" }} type="date" onChange={this.shipChange} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label size="big" >Coating:</Label>
                                        <Dropdown clearable selection search options={coatingOptions} onChange={this.coatingChange} />
                                        <br></br>
                                        <Checkbox id="strCheck" label="Needs Stripping"></Checkbox>
                                        <br></br>
                                        <Checkbox id="wpcCheck" label="Needs WPC"></Checkbox>
                                        <br></br>
                                        <Checkbox id="lapCheck" label="Needs lapping"></Checkbox>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <hr></hr>
                            <Input label={'Product Name:'} id="productName" classname="input" style={{ padding: "0.2em" }} onChange={this.nameChange} />
                            <Input label={'Material:'} id="material" classname="input" style={{ padding: "0.2em" }} onChange={this.materialChange} />
                            <Input label={'Quantity:'} id="quantity" classname="input" style={{ padding: "0.2em" }} type="number" onChange={this.quantityChange} />
                            {this.state.cuttingtools === 0 &&
                                <Input label={'Product Weight:'} id="weightBox" classname="input" style={{ padding: "0.2em" }} onChange={this.weightChange} />}
                            {this.state.cuttingtools === 1 &&
                                <Input label={'Product Diameter:'} id="diameterBox" classname="input" style={{ padding: "0.2em" }} onChange={this.diameterChange} />}
                            {this.state.cuttingtools === 1 &&
                                <Input label={'Product Length:'} id="lengthBox" classname="input" style={{ padding: "0.2em" }} onChange={this.lengthChange} />}
                            <Checkbox id="cutCheck" label="Cutting Tools?" onClick={this.handleCut}></Checkbox>
                            <Input label={'Notes:'} id="notes" classname="input" style={{ padding: "0.2em" }} onChange={this.notesChange} />
                        </div>
                        <div>{this.state.outputString != '' &&
                            <Table celled unstackable>
                                <tbody>
                                    {
                                        this.state.outputString.map((numList, i) => (
                                            <tr key={i}>
                                                {
                                                    numList.map((num, j) =>
                                                        <td key={j}>{num}</td>
                                                    )
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <Button style={{ marginTop: "em" }} onClick={this.addToString}>Add Product</Button>
                            <Button style={{ marginTop: "0em" }} onClick={() => {
                                this.exportToCsv('export.csv', this.state.string);
                                window.location.reload();
                            }}>Submit File</Button>
                            <Button style={{ marginTop: "0em" }} onClick={this.resetAllFields}>Clear all fields</Button>
                            {this.deleteButton()}
                            <br></br>
                            {this.viewExistingFiles()}
                        </div>
                        <div>{this.generateLinks()}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(ProductInput);
