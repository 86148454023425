import React from 'react';
import '../css/multiColorProgressBarStyle.css';

class MultiColorProgressBarLegend extends React.Component {

    render() {

        const parent = this.props;
        var legends = '';

        if (window.innerWidth >= 1280) {
            //process each element given to this component's readings tag
            legends = parent.readings && parent.readings.length && parent.readings.map(function (item, i) {
                //exclude
                if (item.value > 0 && i != 0 && i != 13 && i != 3 && i != 4 && i != 5 && i != 7 && i != 8 && i != 12 && i != 15 && i != 16 && i != 17) {
                    return (
                        <div className="legend" key={i}>
                            <span className="label" style={{ 'background-color': item.color }}>{i + 1 + ": " + item.name}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 3) {
                    return (
                        <div className="legend" key={i}>
                            <span className="label" style={{ 'background-color': item.color, 'color': 'white' }}>{"4: Stripping (Liquid)"}</span>
                        </div>
                    )
                }

                if (item.value > 0 && i == 5) {
                    return (
                        <div className="legend" key={i}>
                            <span className="label" style={{ 'background-color': item.color }}>{"6: WPC"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 8) {
                    return (
                        <div className="legend" key={i}>
                            <span className="label" style={{ 'background-color': item.color }}>{"9: Pre-Lapping (AERO/Hand)"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 12) {
                    return (
                        <div className="legend" key={i}>
                            <span className="label" style={{ 'background-color': item.color, 'color': 'white' }}>{"13: Coating (M3) - Set/Disassemble"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 16) {
                    return (
                        <div className="legend" key={i}>
                            <span className="label" style={{ 'background-color': item.color, 'color': 'white' }}>{"17: Post-Lapping (AERO/Hand)"}</span>
                        </div>
                    )
                }
                //return("");
            }, this);
        }

        if (window.innerWidth < 1280) {
            legends = parent.readings && parent.readings.length && parent.readings.map(function (item, i) {
                //exclude
                if (item.value > 0 && i != 0 && i != 9 && i != 13 && i != 1 && i != 2 && i != 3 && i != 4 && i != 5 && i != 6 && i != 7 && i != 8 && i != 10 && i != 11 && i != 12 && i != 15 && i != 16 && i != 17 && i != 18) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color }}>{i + 1 + ": " + item.name}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 1) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color }}>{"2: Pre-Wash"}</span>
                        </div>
                    )
                }

                if (item.value > 0 && i == 2) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color }}>{"3: Shot Strip"}</span>
                        </div>
                    )
                }

                if (item.value > 0 && i == 3) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color, 'color': 'white' }}>{"4: Liquid Strip"}</span>
                        </div>
                    )
                }

                if (item.value > 0 && i == 5) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color }}>{"6: WPC"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 6) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color }}>{"7: Pre-Lap SMAP"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 8) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color }}>{"9: Pre-Lap (AERO/Hand)"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 9) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color }}>{"10: Pre-Wash F1"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 10) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color }}>{"11: Coat: M1"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 11) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color }}>{"12: Coat: M2"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 12) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color, 'color': 'white' }}>{"13: Coat: M3"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 16) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color, 'color': 'white' }}>{"17: Post-Lap (AERO/Hand)"}</span>
                        </div>
                    )
                }
                if (item.value > 0 && i == 18) {
                    return (
                        <div className="legend" key={i}>
                            <span className="labelMobile" style={{ 'background-color': item.color, 'color': 'white' }}>{"19: Pack/Ship"}</span>
                        </div>
                    )
                }
                //return("");
            }, this);
        }
        return (

            <div className="multicolor-bar">
                <div className="legends">
                    {legends === '' ? '' : legends}
                </div>
            </div>

        );

    }
}

export default MultiColorProgressBarLegend;
