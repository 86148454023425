import React from 'react';
import { Grid, Statistic, Menu, Icon, Dropdown, Table, Button } from 'semantic-ui-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import ExportChart from './exportChartReport3';

import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import employeeStatisticsStyle from '../../jss/reports/employeeStatistics';

//css but in json form written in js
const withStyles = hocify(employeeStatisticsStyle);

class EmployeeStatistics extends React.Component {

    constructor(props){
        super(props);

        let today = new Date();
        this.state = {
            customRange: "0",
            timePeriodSelection: 'daily',
            currentDate: (today.getMonth()+1) + '/' + today.getDate() + '/' + today.getFullYear(),
            queryResults: null,
            stageColors: {
                'Pre-Inspection': '#c8a2c8',
                'Pre-Washing (Jig-Washer)': '#bbf0fc',
                'Stripping (Shot)': '#ff4400',
                'Stripping (Liquid)': '#ff0000',
                'WPC-SKH': '#ffe261',
                'WPC-Ceramic': '#ffd000',
                'Pre-Lapping (SMAP)': '#b9ff69',
                'Pre-Lapping (AERO)': '#a5ff3d',
                'Pre-Lapping (Hand Lapping)': '#70d100',
                'Pre-Washing (F1 Clean Washer)': '#00a82d',
                'Coating (M1) - Set/Disassemble': '#c9c9c9',
                'Coating (M2) – Set/Disassemble': '#9e9d9d',
                'Coating (M3) – Set/Disassemble': '#000000',
                'Post-Inspection': '#df6bff',
                'Post-Lapping (SMAP)': '#0077ff',
                'Post-Lapping (AERO)': '#0040d6',
                'Post-Lapping (Hand Lapping)': '#002885',
                'Final Inspection': '#4b0085',
                'Packing/Shipping': '#855900'
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.abortController = new AbortController();
    }

    //callback to view reports, to send the report data
    sendData(){
        this.props.sendData(this.state.queryResults);
    }

    handleChange(event){

        //update the value for which ever field was changed
        if(event.target.id === "startDate"){
            this.setState({startDate: event.target.value});
            this.getReportData(this.state.timePeriodSelection, event.target.value)
        }

    }

    //store the time period selected and update the report data with that date
    handleClick(event, {name}){
        this.setState({ timePeriodSelection: name });
        this.getReportData(name, this.state.customRange);
    }

    //get the report data after the component is ready
    componentDidMount(){
        this.getReportData(this.state.timePeriodSelection, this.state.customRange);
    }

    //sends a request to the server, and handles the recieved data
    async getReportData(customTimePeriod, customDate){

        //don't let the custom date selection have a empty or null value
        if(customTimePeriod === "custom" && (customDate === "" || customDate === null)){
            customDate = 0;
        }

        let reportJSON = JSON.stringify({
            timePeriod: customTimePeriod,
            customRange: customDate,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try{
            const requestPath = process.env.REACT_APP_SERVER_ROOT + "analytics/employeeStatistics/read.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: reportJSON,
                signal: this.abortController.signal
                });

            //process the results of the operation
            let responseJSON = await response.json();
                this.setState({
                    queryResults: responseJSON,
                    workOrdersProcessed: responseJSON["totalOrders"]
                });

            this.sendData();
      } catch (error) {
          if(error.name === 'AbortError'){
          }
      }
    }

    //if the component gets unmounted cancle the fetch request
    componentWillUnmount(){
        this.abortController.abort();
    }

    renderFilterControls(){

        //build the custom date selector if enabled
        const customDateControls = []
        if(this.state.timePeriodSelection === "custom"){
            customDateControls.push(<span key='0' className={this.props.machineFilter}>
                <label>
                    Number of days to search:{" "}
                    <input type="number" placeholder={this.state.customRange} onChange={this.handleChange} id="startDate" name="startDate" />
                </label>
            </span>);
        }

        return(<div>
            <h3>Filters:</h3>

            <span>
                Time Period:{" "}
                <Dropdown item text={this.state.timePeriodSelection} className={this.props.machineFilter}>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            name="daily"
                            active={this.state.timePeriodSelection === 'daily'}
                            onClick={this.handleClick}
                        >
                            Past 24 Hours
                        </Dropdown.Item>

                        <Dropdown.Item
                            name="weekly"
                            active={this.state.timePeriodSelection === 'weekly'}
                            onClick={this.handleClick}
                        >
                            Weekly
                        </Dropdown.Item>

                        <Dropdown.Item
                            name="monthly"
                            active={this.state.timePeriodSelection === 'monthly'}
                            onClick={this.handleClick}
                        >
                            Monthly
                        </Dropdown.Item>

                        <Dropdown.Item
                            name="custom"
                            active={this.state.timePeriodSelection === 'custom'}
                            onClick={this.handleClick}
                        >
                            Custom
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </span>

            {customDateControls}
            <hr/>
            <ExportChart chart={"barChart"} orientationH={this.renderEmployeeTaskChart().props.height} orientationW={this.renderEmployeeTaskChart().props.width} chartName={"employeeStatistics" + new Date().toJSON().split("T")[0] + "_" + this.state.timePeriodSelection}/>

        </div>);

    }

    renderEmployeeTaskChart()
    {

        //build the bars from the stages and colors
        let stageBars = [];
        for(let key in this.state.stageColors)
        {
            stageBars.push(<Bar key={stageBars.length} dataKey={key} stackId="a" fill={this.state.stageColors[key]}/>);
        }

        return(<BarChart
            width={600} 
            height={Object.keys(this.state.queryResults).length * 100 + 200} //add 100 height for each row with an extra 200 for the base space 
            data={Object.values(this.state.queryResults)}
            layout="vertical"
            margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis type="number"/>
                <YAxis dataKey="Fullname" type="category"/>
                <Tooltip/>
                <Legend />
                {stageBars}
        </BarChart>);

    }

    render(){

        //don't render if data hasn't been recieved from the server yet
        if(this.state.queryResults === null){
            return("");
        }

        //get todays date and use the selected time period to decide how far back to go
        let startDate = new Date();
        switch(this.state.timePeriodSelection){
            case "daily":
                startDate.setDate(startDate.getDate() - 1);
                break;
            case "weekly":
                startDate.setDate(startDate.getDate() - 7);
                break;
            case "monthly":
                startDate.setDate(startDate.getDate() - 30);
                break;
            case "custom":
                startDate.setDate(startDate.getDate() - this.state.customRange);
                break;
            default:
        }

        var result = this.renderEmployeeTaskChart();
        console.log("width: " +  result.props.width);
        var width = result.props.width;
        console.log("height: " + result.props.height);

        return(<div>

            <div className={this.props.printHide}>
                {this.renderFilterControls()}
            </div>
            
            <div id={"barChart"} className="whiteBackground">
                {this.renderEmployeeTaskChart()}
            </div>

            {/* <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Statistic.Group horizontal>
                            <Statistic>
                                <Statistic.Value>{"TODO"}</Statistic.Value>
                                <Statistic.Label>PROCESSED TASKS BETWEEN {(startDate.getMonth()+1) + '/' + startDate.getDate() + '/' + startDate.getFullYear()} - {this.state.currentDate}</Statistic.Label>
                            </Statistic>
                        </Statistic.Group>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
            </Grid> */}

        </div>);
    }

}

export default withStyles(EmployeeStatistics);
