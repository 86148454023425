import React from "react";
import Navbar from "./navbar";
import { Redirect } from "react-router-dom";

import hocify from "hocify"; //needed to convert the functional hook of jss into a HOC
import productViewStyle from "../jss/productView";
import containersStyle from "../jss/containers";
import { Table, Dropdown, Button, Popup } from "semantic-ui-react";

const withStyles = hocify(() => {
  const productView = productViewStyle();
  const containers = containersStyle();

  return { productView, containers };
});

class ScheduleView2 extends React.Component {
  constructor(properties) {
    super(properties);

    //set form values
    this.state = {
      productID: "",
      requestAll: true,
      QueryData: null,
      QueryData2: "",
      ExistingItems: null,
      Processes: null,
      flag: " ASC",
      M1_Mon: "-",
      M1_Tue: "-",
      M1_Wed: "-",
      M1_Thu: "-",
      M1_Fri: "-",
      M2_Mon: "-",
      M2_Tue: "-",
      M2_Wed: "-",
      M2_Thu: "-",
      M2_Fri: "-",
      M3_Mon: "-",
      M3_Tue: "-",
      M3_Wed: "-",
      M3_Thu: "-",
      M3_Fri: "-",
      M1_Mon_Ct: "",
      M1_Tue_Ct: "",
      M1_Wed_Ct: "",
      M1_Thu_Ct: "",
      M1_Fri_Ct: "",
      M2_Mon_Ct: "",
      M2_Tue_Ct: "",
      M2_Wed_Ct: "",
      M2_Thu_Ct: "",
      M2_Fri_Ct: "",
      M3_Mon_Ct: "",
      M3_Tue_Ct: "",
      M3_Wed_Ct: "",
      M3_Thu_Ct: "",
      M3_Fri_Ct: "",
      wk2_M1_Mon: "-",
      wk2_M1_Tue: "-",
      wk2_M1_Wed: "-",
      wk2_M1_Thu: "-",
      wk2_M1_Fri: "-",
      wk2_M2_Mon: "-",
      wk2_M2_Tue: "-",
      wk2_M2_Wed: "-",
      wk2_M2_Thu: "-",
      wk2_M2_Fri: "-",
      wk2_M3_Mon: "-",
      wk2_M3_Tue: "-",
      wk2_M3_Wed: "-",
      wk2_M3_Thu: "-",
      wk2_M3_Fri: "-",
      wk2_M1_Mon_Ct: "",
      wk2_M1_Tue_Ct: "",
      wk2_M1_Wed_Ct: "",
      wk2_M1_Thu_Ct: "",
      wk2_M1_Fri_Ct: "",
      wk2_M2_Mon_Ct: "",
      wk2_M2_Tue_Ct: "",
      wk2_M2_Wed_Ct: "",
      wk2_M2_Thu_Ct: "",
      wk2_M2_Fri_Ct: "",
      wk2_M3_Mon_Ct: "",
      wk2_M3_Tue_Ct: "",
      wk2_M3_Wed_Ct: "",
      wk2_M3_Thu_Ct: "",
      wk2_M3_Fri_Ct: "",
      closestMonday: "",
      machineDisplay: "",
      dayDisplay: "",
      monDate: "",
      tueDate: "",
      wedDate: "",
      thuDate: "",
      friDate: "",
      wk2_monDate: "",
      wk2_tueDate: "",
      wk2_wedDate: "",
      wk2_thuDate: "",
      wk2_friDate: ""
    };

    this.processEvtSource = new EventSource(
      process.env.REACT_APP_SERVER_ROOT + "/process/sseRead.php"
    );

    this.handleChange = this.handleChange.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.getCoating = this.getCoating.bind(this);
    this.getAllCoating = this.getAllCoating.bind(this);
    this.setCoating = this.setCoating.bind(this);
    this.getThisDaysItems = this.getThisDaysItems.bind(this);
    this.getItemCount = this.getItemCount.bind(this);
    this.resetProduction = this.resetProduction.bind(this);
    this.resetProductionItems = this.resetProductionItems.bind(this);
    this.pushWeek = this.pushWeek.bind(this);
    this.setProductScheduleDay = this.setProductScheduleDay.bind(this);
    this.resetOneItem = this.resetOneItem.bind(this);
    this.abortController = new AbortController();
  }

  //get the report data after the component is ready
  componentDidMount() {
    this.processEvtSource.onmessage = (e) => this.receiveProcessData(e);
    this.getProducts("ShipmentSchedule");
    //this.setCoating("M1_Mon","TiAlN");
    this.getCoating("M1_Mon");
    this.getCoating("M1_Tue");
    this.getCoating("M1_Wed");
    this.getCoating("M1_Thu");
    this.getCoating("M1_Fri");
    this.getCoating("M2_Mon");
    this.getCoating("M2_Tue");
    this.getCoating("M2_Wed");
    this.getCoating("M2_Thu");
    this.getCoating("M2_Fri");
    this.getCoating("M3_Mon");
    this.getCoating("M3_Tue");
    this.getCoating("M3_Wed");
    this.getCoating("M3_Thu");
    this.getCoating("M3_Fri");

    this.getCoating("wk2_M1_Mon");
    this.getCoating("wk2_M1_Tue");
    this.getCoating("wk2_M1_Wed");
    this.getCoating("wk2_M1_Thu");
    this.getCoating("wk2_M1_Fri");
    this.getCoating("wk2_M2_Mon");
    this.getCoating("wk2_M2_Tue");
    this.getCoating("wk2_M2_Wed");
    this.getCoating("wk2_M2_Thu");
    this.getCoating("wk2_M2_Fri");
    this.getCoating("wk2_M3_Mon");
    this.getCoating("wk2_M3_Tue");
    this.getCoating("wk2_M3_Wed");
    this.getCoating("wk2_M3_Thu");
    this.getCoating("wk2_M3_Fri");

    this.getAllCoating();

    this.getItemCount("M1_Mon");
    this.getItemCount("M1_Tue");
    this.getItemCount("M1_Wed");
    this.getItemCount("M1_Thu");
    this.getItemCount("M1_Fri");
    this.getItemCount("M2_Mon");
    this.getItemCount("M2_Tue");
    this.getItemCount("M2_Wed");
    this.getItemCount("M2_Thu");
    this.getItemCount("M2_Fri");
    this.getItemCount("M3_Mon");
    this.getItemCount("M3_Tue");
    this.getItemCount("M3_Wed");
    this.getItemCount("M3_Thu");
    this.getItemCount("M3_Fri");

    this.getItemCount("wk2_M1_Mon");
    this.getItemCount("wk2_M1_Tue");
    this.getItemCount("wk2_M1_Wed");
    this.getItemCount("wk2_M1_Thu");
    this.getItemCount("wk2_M1_Fri");
    this.getItemCount("wk2_M2_Mon");
    this.getItemCount("wk2_M2_Tue");
    this.getItemCount("wk2_M2_Wed");
    this.getItemCount("wk2_M2_Thu");
    this.getItemCount("wk2_M2_Fri");
    this.getItemCount("wk2_M3_Mon");
    this.getItemCount("wk2_M3_Tue");
    this.getItemCount("wk2_M3_Wed");
    this.getItemCount("wk2_M3_Thu");
    this.getItemCount("wk2_M3_Fri");
    //ask for dummy value at start to avoid errors
    this.getThisDaysItems("M6_Sat");
    var d = new Date();
    d.setDate(d.getDate() + ((1 - 7 - d.getDay()) % 7));
    var dateStr = d.toString();
    var dateStrShort = "";
    var monday = new Date();
    monday.setDate(d.getDate());
    this.setState({ monDate: (monday.getMonth() + 1) + '/' + monday.getDate() });

    var tuesday = new Date();
    tuesday.setDate(d.getDate() + 1);
    this.setState({ tueDate: (tuesday.getMonth() + 1) + '/' + tuesday.getDate() });

    var wednesday = new Date();
    wednesday.setDate(d.getDate() + 2);
    this.setState({ wedDate: (wednesday.getMonth() + 1) + '/' + wednesday.getDate() });

    var thursday = new Date();
    thursday.setDate(d.getDate() + 3);
    this.setState({ thuDate: (thursday.getMonth() + 1) + '/' + thursday.getDate() });

    var friday = new Date();
    friday.setDate(d.getDate() + 4);
    this.setState({ friDate: (friday.getMonth() + 1) + '/' + friday.getDate() });

    var wk2_monday = new Date();
    wk2_monday.setDate(d.getDate() + 7);
    this.setState({ wk2_monDate: (wk2_monday.getMonth() + 1) + '/' + wk2_monday.getDate() });

    var wk2_tuesday = new Date();
    wk2_tuesday.setDate(d.getDate() + 8);
    this.setState({ wk2_tueDate: (wk2_tuesday.getMonth() + 1) + '/' + wk2_tuesday.getDate() });

    var wk2_wednesday = new Date();
    wk2_wednesday.setDate(d.getDate() + 9);
    this.setState({ wk2_wedDate: (wk2_wednesday.getMonth() + 1) + '/' + wk2_wednesday.getDate() });

    var wk2_thursday = new Date();
    wk2_thursday.setDate(d.getDate() + 10);
    this.setState({ wk2_thuDate: (wk2_thursday.getMonth() + 1) + '/' + wk2_thursday.getDate() });

    var wk2_friday = new Date();
    wk2_friday.setDate(d.getDate() + 11);
    this.setState({ wk2_friDate: (wk2_friday.getMonth() + 1) + '/' + wk2_friday.getDate() });

    dateStr = dateStr.split(" ");

    for (let i = 0; i < 4; i++) {
      dateStrShort += dateStr[i] + " ";
    }
    this.setState({ closestMonday: dateStrShort });
  }

  receiveProcessData(e) {
    let message = JSON.parse(e.data);

    if (message["status"] === 200) {
      delete message["status"];

      if (JSON.stringify(message) !== JSON.stringify(this.state.Processes)) {
        this.setState({ Processes: message });
      }
    }
  }

  //if the component gets unmounted cancle the fetch request
  componentWillUnmount() { }

  handleClick(event, { name }) { }

  handleChange = (e, { value, id }) => this.setCoating(id, value);

  renderFilterControls() { }

  //get the product data from the db
  async getProducts(sortby) {
    //encode the token, productID if not requesting all products, or if all products are being requested
    let productJSON = JSON.stringify({
      flagAD: this.state.flag,
      orderby: sortby,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
      requestAll: this.state.requestAll,
      productID: this.state.productID,
      //orderby: "ProductID",
      //flagAD: " ASC"
    });
    if (this.state.flag === " ASC") {
      this.state.flag = " DESC";
    } else {
      this.state.flag = " ASC";
    }

    try {
      //send the request data to the product stat read page
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT +
        "analytics/productStatistics/readUnscheduled.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.json();
      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow eaiser process of the data
        delete responseJSON["status"];

        //store the product data in the state
        this.setState({
          QueryData: responseJSON,
        });

      } else {
        //alert("Could not get product(s)");
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  async getCoating(machineDay) {
    let productJSON = JSON.stringify({
      MachineDay: machineDay,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    try {
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT +
        "analytics/productionSchedule/getCoating.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.json();
      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow eaiser process of the data
        delete responseJSON["status"];
      } else {
        //alert("Could not get product(s)");
      }
      if (machineDay === "M1_Mon") {
        this.setState({ M1_Mon: responseJSON[0].CoatingType });
      }
      if (machineDay === "M1_Tue") {
        this.setState({ M1_Tue: responseJSON[0].CoatingType });
      }
      if (machineDay === "M1_Wed") {
        this.setState({ M1_Wed: responseJSON[0].CoatingType });
      }
      if (machineDay === "M1_Thu") {
        this.setState({ M1_Thu: responseJSON[0].CoatingType });
      }
      if (machineDay === "M1_Fri") {
        this.setState({ M1_Fri: responseJSON[0].CoatingType });
      }
      if (machineDay === "M2_Mon") {
        this.setState({ M2_Mon: responseJSON[0].CoatingType });
      }
      if (machineDay === "M2_Tue") {
        this.setState({ M2_Tue: responseJSON[0].CoatingType });
      }
      if (machineDay === "M2_Wed") {
        this.setState({ M2_Wed: responseJSON[0].CoatingType });
      }
      if (machineDay === "M2_Thu") {
        this.setState({ M2_Thu: responseJSON[0].CoatingType });
      }
      if (machineDay === "M2_Fri") {
        this.setState({ M2_Fri: responseJSON[0].CoatingType });
      }
      if (machineDay === "M3_Mon") {
        this.setState({ M3_Mon: responseJSON[0].CoatingType });
      }
      if (machineDay === "M3_Tue") {
        this.setState({ M3_Tue: responseJSON[0].CoatingType });
      }
      if (machineDay === "M3_Wed") {
        this.setState({ M3_Wed: responseJSON[0].CoatingType });
      }
      if (machineDay === "M3_Thu") {
        this.setState({ M3_Thu: responseJSON[0].CoatingType });
      }
      if (machineDay === "M3_Fri") {
        this.setState({ M3_Fri: responseJSON[0].CoatingType });
      }

      if (machineDay === "wk2_M1_Mon") {
        this.setState({ wk2_M1_Mon: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M1_Tue") {
        this.setState({ wk2_M1_Tue: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M1_Wed") {
        this.setState({ wk2_M1_Wed: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M1_Thu") {
        this.setState({ wk2_M1_Thu: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M1_Fri") {
        this.setState({ wk2_M1_Fri: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M2_Mon") {
        this.setState({ wk2_M2_Mon: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M2_Tue") {
        this.setState({ wk2_M2_Tue: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M2_Wed") {
        this.setState({ wk2_M2_Wed: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M2_Thu") {
        this.setState({ wk2_M2_Thu: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M2_Fri") {
        this.setState({ wk2_M2_Fri: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M3_Mon") {
        this.setState({ wk2_M3_Mon: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M3_Tue") {
        this.setState({ wk2_M3_Tue: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M3_Wed") {
        this.setState({ wk2_M3_Wed: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M3_Thu") {
        this.setState({ wk2_M3_Thu: responseJSON[0].CoatingType });
      }
      if (machineDay === "wk2_M3_Fri") {
        this.setState({ wk2_M3_Fri: responseJSON[0].CoatingType });
      }
      return responseJSON[0].CoatingType;
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  async getAllCoating() {
    let productJSON = JSON.stringify({
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    try {
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT +
        "analytics/productionSchedule/getAllCoatingItems.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.json();
      this.setState({
        ExistingItems: responseJSON,
      });
      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow eaiser process of the data
        delete responseJSON["status"];
      } else {
        //alert("Could not get product(s)");
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  async getItemCount(machineDay) {
    let productJSON = JSON.stringify({
      MachineDay: machineDay,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    try {
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT +
        "analytics/productionSchedule/getCount.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.json();
      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow eaiser process of the data
        delete responseJSON["status"];
      } else {
        //alert("Could not get product(s)");
      }

      if (machineDay === "M1_Mon" && responseJSON[0].total !== "0") {
        this.setState({ M1_Mon_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M1_Tue" && responseJSON[0].total !== "0") {
        this.setState({ M1_Tue_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M1_Wed" && responseJSON[0].total !== "0") {
        this.setState({ M1_Wed_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M1_Thu" && responseJSON[0].total !== "0") {
        this.setState({ M1_Thu_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M1_Fri" && responseJSON[0].total !== "0") {
        this.setState({ M1_Fri_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M2_Mon" && responseJSON[0].total !== "0") {
        this.setState({ M2_Mon_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M2_Tue" && responseJSON[0].total !== "0") {
        this.setState({ M2_Tue_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M2_Wed" && responseJSON[0].total !== "0") {
        this.setState({ M2_Wed_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M2_Thu" && responseJSON[0].total !== "0") {
        this.setState({ M2_Thu_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M2_Fri" && responseJSON[0].total !== "0") {
        this.setState({ M2_Fri_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M3_Mon" && responseJSON[0].total !== "0") {
        this.setState({ M3_Mon_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M3_Tue" && responseJSON[0].total !== "0") {
        this.setState({ M3_Tue_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M3_Wed" && responseJSON[0].total !== "0") {
        this.setState({ M3_Wed_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M3_Thu" && responseJSON[0].total !== "0") {
        this.setState({ M3_Thu_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "M3_Fri" && responseJSON[0].total !== "0") {
        this.setState({ M3_Fri_Ct: "(" + responseJSON[0].total + " items)" });
      }

      if (machineDay === "wk2_M1_Mon" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M1_Mon_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M1_Tue" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M1_Tue_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M1_Wed" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M1_Wed_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M1_Thu" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M1_Thu_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M1_Fri" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M1_Fri_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M2_Mon" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M2_Mon_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M2_Tue" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M2_Tue_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M2_Wed" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M2_Wed_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M2_Thu" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M2_Thu_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M2_Fri" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M2_Fri_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M3_Mon" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M3_Mon_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M3_Tue" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M3_Tue_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M3_Wed" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M3_Wed_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M3_Thu" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M3_Thu_Ct: "(" + responseJSON[0].total + " items)" });
      }
      if (machineDay === "wk2_M3_Fri" && responseJSON[0].total !== "0") {
        this.setState({ wk2_M3_Fri_Ct: "(" + responseJSON[0].total + " items)" });
      }
      return responseJSON[0].total;
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  async setCoating(machineDay, coatingType) {
    //encode the token, productID if not requesting all products, or if all products are being requested
    let productJSON = JSON.stringify({
      MachineDay: machineDay,
      CoatingType: coatingType,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    try {
      //send the request data to the product stat read page
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT +
        "analytics/productionSchedule/setCoating.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.json();
      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow eaiser process of the data
        delete responseJSON["status"];

        //store the product data in the state
      } else {
        //alert("Could not get product(s)");
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  async resetProduction() {
    //encode the token, productID if not requesting all products, or if all products are being requested
    let productJSON = JSON.stringify({
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    try {
      //send the request data to the product stat read page
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT +
        "analytics/productionSchedule/clearProduction.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.json();
      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow eaiser process of the data
        delete responseJSON["status"];

        //store the product data in the state
      } else {
        //alert("Could not get product(s)");
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  async pushWeek() {
    //encode the token, productID if not requesting all products, or if all products are being requested
    let productJSON = JSON.stringify({
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    try {
      //send the request data to the product stat read page
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT +
        "analytics/productionSchedule/pushWeek.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.json();
      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow eaiser process of the data
        delete responseJSON["status"];

        //store the product data in the state
      } else {
        //alert("Could not get product(s)");
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  async resetProductionItems() {
    //encode the token, productID if not requesting all products, or if all products are being requested
    let productJSON = JSON.stringify({
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    try {
      //send the request data to the product stat read page
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT +
        "analytics/productionSchedule/clearProductionItems.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.json();
      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow eaiser process of the data
        delete responseJSON["status"];

        //store the product data in the state
      } else {
        //alert("Could not get product(s)");
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  async resetOneItem(productID) {
    //encode the token, productID if not requesting all products, or if all products are being requested
    let productJSON = JSON.stringify({
      ProductID: productID,
      token: sessionStorage.getItem("token"),
      jobRole: sessionStorage.getItem("jobRole"),
    });

    try {
      //send the request data to the product stat read page
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT +
        "analytics/productionSchedule/clearOneItem.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });

      //process the response
      let responseJSON = await response.json();
      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow eaiser process of the data
        delete responseJSON["status"];

        //store the product data in the state
      } else {
        //alert("Could not get product(s)");
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  getCoatingColors(status) {
    var color = "";
    switch (status) {
      case "TiN S-PVD":
        color = "rgba(255, 255, 0, 1)";
        break;
      case "TiAlN":
        color = "rgba(202, 0, 255, 1)";
        break;
      case "D-TiAlN":
        color = "rgba(202, 0, 255, 1)";
        break;
      case "ZERO-I":
        color = "rgba(0, 140, 255, 1)";
        break;
      case "TH":
        color = "rgba(255, 20, 147, 1)";
        break;
      case "TH-Plus":
        color = "rgba(255, 20, 147, 1)";
        break;
      default:
        break;
    }
    return color;
  }

  renderProducts() {
    if (this.state.QueryData === null || this.state.Processes === null) {
      return <div></div>;
    }
    const products = [];
    //add each product from the db to the table
    for (
      let index = 0;
      index < Object.keys(this.state.QueryData).length;
      index++
    ) {
      let value = this.state.QueryData[index];

      switch (value["Coating"]) {
        case "TH":
          products.push(
            <Table.Row key={index}>
              <Table.Cell>{value["ProductID"]}</Table.Cell>
              <Table.Cell>{value["Customer"]}</Table.Cell>
              <Table.Cell>{value["ProductName"]}</Table.Cell>
              <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
              <Table.Cell style={{ background: "rgba(255, 20, 147, 1)" }}>
                {value["Coating"]}
              </Table.Cell>
            </Table.Row>
          );
          break;
        case "TH-Plus":
          products.push(
            <Table.Row key={index}>
              <Table.Cell>{value["ProductID"]}</Table.Cell>
              <Table.Cell>{value["Customer"]}</Table.Cell>
              <Table.Cell>{value["ProductName"]}</Table.Cell>
              <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
              <Table.Cell style={{ background: "rgba(255, 20, 147, 1)" }}>
                {value["Coating"]}
              </Table.Cell>
            </Table.Row>
          );
          break;
        case "TiAlN":
          products.push(
            <Table.Row key={index}>
              <Table.Cell>{value["ProductID"]}</Table.Cell>
              <Table.Cell>{value["Customer"]}</Table.Cell>
              <Table.Cell>{value["ProductName"]}</Table.Cell>
              <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
              <Table.Cell style={{ background: "rgba(202, 0, 255, 1)" }}>
                {value["Coating"]}
              </Table.Cell>
            </Table.Row>
          );
          break;
        case "D-TiAlN":
          products.push(
            <Table.Row key={index}>
              <Table.Cell>{value["ProductID"]}</Table.Cell>
              <Table.Cell>{value["Customer"]}</Table.Cell>
              <Table.Cell>{value["ProductName"]}</Table.Cell>
              <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
              <Table.Cell style={{ background: "rgba(202, 0, 255, 1)" }}>
                {value["Coating"]}
              </Table.Cell>
            </Table.Row>
          );
          break;
        case "ZERO-I":
          products.push(
            <Table.Row key={index}>
              <Table.Cell>{value["ProductID"]}</Table.Cell>
              <Table.Cell>{value["Customer"]}</Table.Cell>
              <Table.Cell>{value["ProductName"]}</Table.Cell>
              <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
              <Table.Cell style={{ background: "rgba(0, 140, 255, 1)" }}>
                {value["Coating"]}
              </Table.Cell>
            </Table.Row>
          );
          break;
        case "TiN":
          products.push(
            <Table.Row key={index}>
              <Table.Cell>{value["ProductID"]}</Table.Cell>
              <Table.Cell>{value["Customer"]}</Table.Cell>
              <Table.Cell>{value["ProductName"]}</Table.Cell>
              <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
              <Table.Cell style={{ background: "rgba(255, 255, 0, 1)" }}>
                {value["Coating"]}
              </Table.Cell>
            </Table.Row>
          );
          break;
        case "S-PVD":
          products.push(
            <Table.Row key={index}>
              <Table.Cell>{value["ProductID"]}</Table.Cell>
              <Table.Cell>{value["Customer"]}</Table.Cell>
              <Table.Cell>{value["ProductName"]}</Table.Cell>
              <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
              <Table.Cell style={{ background: "rgba(255, 255, 0, 1)" }}>
                {value["Coating"]}
              </Table.Cell>
            </Table.Row>
          );
          break;
        default: {
          products.push(
            <Table.Row key={index}>
              <Table.Cell>{value["ProductID"]}</Table.Cell>
              <Table.Cell>{value["Customer"]}</Table.Cell>
              <Table.Cell>{value["ProductName"]}</Table.Cell>
              <Table.Cell>{value["ShipmentSchedule"]}</Table.Cell>
              <Table.Cell>{value["Coating"]}</Table.Cell>
            </Table.Row>
          );
        }
      }

      //Only show cutting tools
    }

    return products;
  }

  renderCoatingItems() {
    if (this.state.QueryData === null || this.state.Processes === null) {
      return <div></div>;
    }
    const products = [];
    //add each product from the db to the table
    for (
      let index = 0;
      index < Object.keys(this.state.QueryData2).length;
      index++
    ) {
      let value = this.state.QueryData2[index];
      products.push(
        <Table.Row key={index}>
          <Table.Cell>{value["ProductID"]}</Table.Cell>
          <Table.Cell>{value["Customer"]}</Table.Cell>
          <Table.Cell>{value["ProductName"]}</Table.Cell>
        </Table.Row>
      );

      //Only show cutting tools
    }

    return products;
  }

  async getThisDaysItems(md) {
    //encode the token, productID if not requesting all products, or if all products are being requested
    let productJSON = JSON.stringify({
      MachineDay: md,
    });
    var mday = md.split("_");
    switch (mday[0]) {
      case "M1":
        this.setState({ machineDisplay: "M1" })
        break;
      case "M2":
        this.setState({ machineDisplay: "M2" })
        break;
      case "M3":
        this.setState({ machineDisplay: "M3" })
        break;
      default:
        this.setState({ machineDisplay: "selected machine" })
        break;
    }
    switch (mday[1]) {
      case "Mon":
        this.setState({ dayDisplay: "Monday " + this.state.monDate })
        break;
      case "Tue":
        this.setState({ dayDisplay: "Tuesday " + this.state.tueDate })
        break;
      case "Wed":
        this.setState({ dayDisplay: "Wednesday " + this.state.wedDate })
        break;
      case "Thu":
        this.setState({ dayDisplay: "Thursday " + this.state.thuDate })
        break;
      case "Fri":
        this.setState({ dayDisplay: "Friday " + this.state.friDate })
        break;
      default:
        this.setState({ dayDisplay: "selected day" })
        break;
    }

    try {
      //send the request data to the product stat read page
      const requestPath =
        process.env.REACT_APP_SERVER_ROOT +
        "analytics/productionSchedule/getCoatingItems.php";
      let response = await fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: productJSON,
        signal: this.abortController.signal,
      });
      //process the response
      let responseJSON = await response.json();

      if (responseJSON["status"] === 200) {
        //remove the status element from the returned json, to allow easier process of the data
        delete responseJSON["status"];

        //store the product data in the state
        this.setState({
          QueryData2: responseJSON,
        });
      } else {
        //alert("Could not get product(s)");
      }
    } catch (error) {
      if (error.name === "AbortError") {
      }
    }
  }

  async setProductScheduleDay(pid, machine, day) {

    var customer = "";
    var productName = ""
    var test = Object.values(this.state.QueryData);
    for (var i = 0; i < test.length; i++) {
      if (test[i].ProductID === pid) {
        customer = test[i].Customer;
        productName = test[i].ProductName;
      }
    }
    if (machine === "Machine") {
      window.alert("Machine must be specified");
      return;
    }
    if (day === "Day") {
      window.alert("Day must be specified");
      return;
    }
    day = day.split(' ')[0];
    var MDay = machine + "_" + day;
    if (day.includes("\u200B")) {
      day = day.replace("\u200B", "");
      MDay = "wk2_" + machine + "_" + day;
    }
    let ScheduleAddJSON = JSON.stringify({
      MachineDay: MDay,
      ProductID: pid,
      Customer: customer,
      ProductName: productName
    });

    const requestPath =
      process.env.REACT_APP_SERVER_ROOT + "analytics/productionSchedule/setCoatingItems.php";
    let response = await fetch(requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: ScheduleAddJSON,
      signal: this.abortController.signal,
    });
    //let responseJSON = await response.json();
  }

  render() {

    //if the token is not present, the user is not signed in send them to signin
    if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
      return (<Redirect to="/signin" />);
    }
    var job = sessionStorage.getItem("jobRole");
    if (job !== 'Manager' && job !== 'Employee') {
      return (<Redirect to="/signin" />);
    }



    const ddChoices = [];
    const existingItems = [];
    var choiceOBJ = {};
    var choiceOBJ2 = {};

    if (this.state.QueryData != null) {
      for (
        let index = 0;
        index < Object.keys(this.state.QueryData).length;
        index++
      ) {
        let val = this.state.QueryData[index];
        choiceOBJ = {
          key: val.ProductID,
          text: val.ProductID,
          value: val.ProductID,
          customer: val.Customer,
          productName: val.ProductName
        };
        ddChoices.push(choiceOBJ);
      }
    }

    if (this.state.ExistingItems != null) {
      for (
        let index = 0;
        index < Object.keys(this.state.ExistingItems).length;
        index++
      ) {
        if (typeof (this.state.ExistingItems[index]) != 'undefined') {
          let val = this.state.ExistingItems[index];
          choiceOBJ2 = {
            key: val.ProductID,
            text: val.ProductID,
            value: val.ProductID,
            customer: val.Customer,
            productName: val.ProductName
          };
        }
        existingItems.push(choiceOBJ2);
      }
    }


    const M1Options = [
      { key: "TiAlN", text: "TiAlN", value: "TiAlN" },
      { key: "D-TiAlN", text: "D-TiAlN", value: "D-TiAlN" },
      { key: "TiN S-PVD", text: "TiN S-PVD", value: "TiN S-PVD" },
      { key: "Clear", text: "Clear", value: " " },
    ];

    const M2Options = [
      { key: "TH", text: "TH", value: "TH" },
      { key: "TH-Plus", text: "TH-Plus", value: "TH-Plus" },
      { key: "TiAlN", text: "TiAlN", value: "TiAlN" },
      { key: "D-TiAlN", text: "D-TiAlN", value: "D-TiAlN" },
      { key: "Clear", text: "Clear", value: " " },
    ];

    const M3Options = [
      { key: "ZERO-I", text: "ZERO-I", value: "ZERO-I" },
      { key: "Clear", text: "Clear", value: " " },
    ];

    const MachineOptions = [
      { key: "M1", text: "M1", value: "M1" },
      { key: "M2", text: "M2", value: "M2" },
      { key: "M3", text: "M3", value: "M3" }
    ];

    const DayOptions = [
      { key: "Mon", text: "Mon " + this.state.monDate, value: "Mon" },
      { key: "Tue", text: "Tue " + this.state.tueDate, value: "Tue" },
      { key: "Wed", text: "Wed " + this.state.wedDate, value: "Wed" },
      { key: "Thu", text: "Thu " + this.state.thuDate, value: "Thu" },
      { key: "Fri", text: "Fri " + this.state.friDate, value: "Fri" },
      { key: "wk2_Mon", text: "Mon\u200B " + this.state.wk2_monDate, value: "wk2_Mon" },
      { key: "wk2_Tue", text: "Tue\u200B " + this.state.wk2_tueDate, value: "wk2_Tue" },
      { key: "wk2_Wed", text: "Wed\u200B " + this.state.wk2_wedDate, value: "wk2_Wed" },
      { key: "wk2_Thu", text: "Thu\u200B " + this.state.wk2_thuDate, value: "wk2_Thu" },
      { key: "wk2_Fri", text: "Fri\u200B " + this.state.wk2_friDate, value: "wk2_Fri" },
    ];

    var job = sessionStorage.getItem("jobRole");

    return (
      <div>
        <Navbar selectedPage="scheduleView2" />

        <div>
          <h1>Production for the next two weeks starting {this.state.closestMonday}</h1>
        </div>
        {(job === 'Manager') && <div>
          <Dropdown
            placeholder="Product ID"
            id="stageForm"
            selection
            clearable
            search
            options={ddChoices}
          ></Dropdown>

          <Dropdown
            placeholder="Machine"
            id="machineOptionsForm"
            selection
            clearable
            search
            options={MachineOptions}
          ></Dropdown>

          <Dropdown
            placeholder="Day"
            id="dayOptionsForm"
            selection
            clearable
            search
            options={DayOptions}
          ></Dropdown>
          {<Button
            className="smallBtn"
            style={{ width: "25%", margin: "0px" }}
            onClick={() =>

              this.setProductScheduleDay(document.querySelector("#stageForm > div.text").innerHTML,
                document.querySelector("#machineOptionsForm > div.text").innerHTML,
                document.querySelector("#dayOptionsForm > div.text").innerHTML)



            }
          >
            Add to schedule
          </Button>}

          <Table celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell textAlign="center" width={3}>{"Monday " + this.state.monDate}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>{"Tuesday " + this.state.tueDate}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>{"Wednesday " + this.state.wedDate}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>{"Thursday " + this.state.thuDate}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>{"Friday " + this.state.friDate}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <b>M1</b>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M1_Mon"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M1Options.value}
                      options={M1Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M1_Tue"}
                      placeholder="Choose Coating"

                      onChange={this.handleChange}
                      value={M1Options.value}
                      options={M1Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M1_Wed"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M1Options.value}
                      options={M1Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M1_Thu"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M1Options.value}
                      options={M1Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M1_Fri"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M1Options.value}
                      options={M1Options}
                    />
                  }
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <b>M2</b>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M2_Mon"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M2Options.value}
                      options={M2Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M2_Tue"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M2Options.value}
                      options={M2Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M2_Wed"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M2Options.value}
                      options={M2Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M2_Thu"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M2Options.value}
                      options={M2Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M2_Fri"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M2Options.value}
                      options={M2Options}
                    />
                  }
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <b>M3</b>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M3_Mon"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M3Options.value}
                      options={M3Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M3_Tue"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M3Options.value}
                      options={M3Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M3_Wed"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M3Options.value}
                      options={M3Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M3_Thu"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M3Options.value}
                      options={M3Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"M3_Fri"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M3Options.value}
                      options={M3Options}
                    />
                  }
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Table celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell textAlign="center" width={3}>{"Monday " + this.state.wk2_monDate}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>{"Tuesday " + this.state.wk2_tueDate}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>{"Wednesday " + this.state.wk2_wedDate}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>{"Thursday " + this.state.wk2_thuDate}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>{"Friday " + this.state.wk2_friDate}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <b>M1</b>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M1_Mon"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M1Options.value}
                      options={M1Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M1_Tue"}
                      placeholder="Choose Coating"

                      onChange={this.handleChange}
                      value={M1Options.value}
                      options={M1Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M1_Wed"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M1Options.value}
                      options={M1Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M1_Thu"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M1Options.value}
                      options={M1Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M1_Fri"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M1Options.value}
                      options={M1Options}
                    />
                  }
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <b>M2</b>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M2_Mon"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M2Options.value}
                      options={M2Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M2_Tue"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M2Options.value}
                      options={M2Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M2_Wed"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M2Options.value}
                      options={M2Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M2_Thu"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M2Options.value}
                      options={M2Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M2_Fri"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M2Options.value}
                      options={M2Options}
                    />
                  }
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <b>M3</b>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M3_Mon"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M3Options.value}
                      options={M3Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M3_Tue"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M3Options.value}
                      options={M3Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M3_Wed"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M3Options.value}
                      options={M3Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M3_Thu"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M3Options.value}
                      options={M3Options}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {
                    <Dropdown
                      id={"wk2_M3_Fri"}
                      placeholder="Choose Coating"
                      onChange={this.handleChange}
                      value={M3Options.value}
                      options={M3Options}
                    />
                  }
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Button className="smallBtn5"
            onClick={() => {
              window.location.reload();
            }}
          >
            Update
          </Button>
          <hr></hr>
        </div>}
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell textAlign="center" width={3}>{"Monday " + this.state.monDate}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={3}>{"Tuesday " + this.state.tueDate}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={3}>{"Wednesday " + this.state.wedDate}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={3}>{"Thursday " + this.state.thuDate}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={3}>{"Friday " + this.state.friDate}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <b>M1</b>
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M1_Mon) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M1_Mon");
                }}
              >
                {this.state.M1_Mon} {this.state.M1_Mon_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M1_Tue) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M1_Tue");
                }}
              >
                {this.state.M1_Tue} {this.state.M1_Tue_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M1_Wed) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M1_Wed");
                }}
              >
                {this.state.M1_Wed} {this.state.M1_Wed_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M1_Thu) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M1_Thu");
                }}
              >
                {this.state.M1_Thu} {this.state.M1_Thu_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M1_Fri) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M1_Fri");
                }}
              >
                {this.state.M1_Fri} {this.state.M1_Fri_Ct}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>M2</b>
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M2_Mon) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M2_Mon");
                }}
              >
                {this.state.M2_Mon} {this.state.M2_Mon_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M2_Tue) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M2_Tue");
                }}
              >
                {this.state.M2_Tue} {this.state.M2_Tue_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M2_Wed) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M2_Wed");
                }}
              >
                {this.state.M2_Wed} {this.state.M2_Wed_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M2_Thu) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M2_Thu");
                }}
              >
                {this.state.M2_Thu} {this.state.M2_Thu_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M2_Fri) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M2_Fri");
                }}
              >
                {this.state.M2_Fri} {this.state.M2_Fri_Ct}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>M3</b>
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M3_Mon) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M3_Mon");
                }}
              >
                {this.state.M3_Mon} {this.state.M3_Mon_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M3_Tue) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M3_Tue");
                }}
              >
                {this.state.M3_Tue} {this.state.M3_Tue_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M3_Wed) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M3_Wed");
                }}
              >
                {this.state.M3_Wed} {this.state.M3_Wed_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M3_Thu) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M3_Thu");
                }}
              >
                {this.state.M3_Thu} {this.state.M3_Thu_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.M3_Fri) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("M3_Fri");
                }}
              >
                {this.state.M3_Fri} {this.state.M3_Fri_Ct}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell textAlign="center" width={3}>{"Monday " + this.state.wk2_monDate}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={3}>{"Tuesday " + this.state.wk2_tueDate}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={3}>{"Wednesday " + this.state.wk2_wedDate}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={3}>{"Thursday " + this.state.wk2_thuDate}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={3}>{"Friday " + this.state.wk2_friDate}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <b>M1</b>
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M1_Mon) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M1_Mon");
                }}
              >
                {this.state.wk2_M1_Mon} {this.state.wk2_M1_Mon_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M1_Tue) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M1_Tue");
                }}
              >
                {this.state.wk2_M1_Tue} {this.state.wk2_M1_Tue_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M1_Wed) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M1_Wed");
                }}
              >
                {this.state.wk2_M1_Wed} {this.state.wk2_M1_Wed_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M1_Thu) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M1_Thu");
                }}
              >
                {this.state.wk2_M1_Thu} {this.state.wk2_M1_Thu_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M1_Fri) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M1_Fri");
                }}
              >
                {this.state.wk2_M1_Fri} {this.state.wk2_M1_Fri_Ct}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>M2</b>
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M2_Mon) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M2_Mon");
                }}
              >
                {this.state.wk2_M2_Mon} {this.state.wk2_M2_Mon_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M2_Tue) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M2_Tue");
                }}
              >
                {this.state.wk2_M2_Tue} {this.state.wk2_M2_Tue_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M2_Wed) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M2_Wed");
                }}
              >
                {this.state.wk2_M2_Wed} {this.state.wk2_M2_Wed_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M2_Thu) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M2_Thu");
                }}
              >
                {this.state.wk2_M2_Thu} {this.state.wk2_M2_Thu_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M2_Fri) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M2_Fri");
                }}
              >
                {this.state.wk2_M2_Fri} {this.state.wk2_M2_Fri_Ct}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>M3</b>
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M3_Mon) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M3_Mon");
                }}
              >
                {this.state.wk2_M3_Mon} {this.state.wk2_M3_Mon_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M3_Tue) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M3_Tue");
                }}
              >
                {this.state.wk2_M3_Tue} {this.state.wk2_M3_Tue_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M3_Wed) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M3_Wed");
                }}
              >
                {this.state.wk2_M3_Wed} {this.state.wk2_M3_Wed_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M3_Thu) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M3_Thu");
                }}
              >
                {this.state.wk2_M3_Thu} {this.state.wk2_M3_Thu_Ct}
              </Table.Cell>
              <Table.Cell
                style={{ background: this.getCoatingColors(this.state.wk2_M3_Fri) }}
                selectable
                verticalAlign="middle"
                textAlign="center"
                onClick={() => {
                  this.getThisDaysItems("wk2_M3_Fri");
                }}
              >
                {this.state.wk2_M3_Fri} {this.state.wk2_M3_Fri_Ct}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {(job === 'Manager') && <div>
          <Button className="smallBtn5"
            onClick={() => {
              this.resetProduction(); window.location.reload();
            }}
          >
            Reset
          </Button>
          <Button className="smallBtn5"
            onClick={() => {
              this.resetProductionItems(); window.location.reload();
            }}

          >
            Reset Items
          </Button>
          {"\u00a0\u00a0\u00a0"}
          <Dropdown
            placeholder="Product ID"
            id="resetItem"
            selection
            clearable
            search
            options={existingItems}
          ></Dropdown>
          {"\u00a0"}
          {<Button
            className="smallBtn5"
            style={{ width: "25%", margin: "0px" }}
            onClick={() => {
              this.resetOneItem(document.querySelector("#resetItem > div.text").innerHTML); window.location.reload();
            }
            }
          >
            Remove one item
          </Button>}
          {"\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0"}
          <Popup content='Push next week&apos;s schedule into current week and clears next week.' size='large' position='right center' trigger={<Button style={{ backgroundColor: "red", height: '2em', marginTop: 0, marginBottom: 0, textAlign: 'center', width: '10%', padding: '3px 5px' }}
            onClick={() => {
              this.pushWeek(); setTimeout(() => window.location.reload(), 1);
            }}
          >
            Push back week
          </Button>}
          />
        </div>}
        <h3>Items being coated on {this.state.dayDisplay} in {this.state.machineDisplay}:</h3>
        <Table compact celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Product ID</Table.HeaderCell>
              <Table.HeaderCell>Customer</Table.HeaderCell>
              <Table.HeaderCell>Product Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{this.renderCoatingItems()}</Table.Body>
        </Table>

        {(job === 'Manager') && <div>
          <h3>Items not yet assigned to coating schedule:</h3>
          <Table compact celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.getProducts("ProductID");
                  }}
                >
                  Product ID
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.getProducts("Customer");
                  }}
                >
                  Customer
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.getProducts("ProductName");
                  }}
                >
                  Product Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.getProducts("ShipmentSchedule");
                  }}
                >
                  Ship Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.getProducts("Coating");
                  }}
                >
                  Coating
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>{this.renderProducts()}</Table.Body>
          </Table>
        </div>}
      </div>
    );
  }
}

export default withStyles(ScheduleView2);
