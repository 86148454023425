import React, { createRef } from 'react';
import {Redirect} from 'react-router-dom'
import Navbar from './navbar';
import { Table, Dropdown } from 'semantic-ui-react';

import hocify from 'hocify';
import containerStyle from '../jss/containers';

const withStyles = hocify(containerStyle);

class EmployeeHours extends React.Component{

    inputRef = createRef()

    constructor(properties){
        super(properties);

        //set form values
        this.state = {
            data: null,
            selectedData: null,
            selectedID: 'Request All',
            employeeID: ''
        };

        //setup event handlers
        this.getHours = this.getHours.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
        this.abortController = new AbortController();
    }

    componentDidMount(){
        this.getHours();
    }

    //add the item to the db
    async getHours(){

        let itemJSON = JSON.stringify({
            getAll: true,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try{
        //send the item db
          let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "employeeTimeCard/getEmployeeHours.php",
              {
              method: "POST",
              headers:
                  {
                  "Content-Type": "application/json"
              },
              body: itemJSON,
              signal: this.abortController.signal
          });
          //process the response
          let responseJSON = await response.json();
          if(responseJSON.hasOwnProperty('status')  && responseJSON['status'] === 200)
          {
              this.setState({
                  data: responseJSON['employees']
              });
          }

      } catch (error) {
          if(error.name === 'AbortError'){
          }
      }
    }

    componentWillUnmount(){
        this.abortController.abort();
    }

    //updates the current item in the dropdown
    handleSelected(event, {name}){

        let index;

        //find the index of the element with the slected id
        for(let i = 0; i < Object.keys(this.state.data).length; i++){
            if(this.state.data[i]["ID"] === name){
                index = i;
                break;
            }
        }

        this.setState({
            selectedID: name,
            selectedData: this.state.data[index]
        });
    }

    buildDropDown(){

        if(this.state.data == null)
        {
            return(<div></div>);
        }

        const dropdownItems = [];

        //add a request all option before adding the employee ids
        dropdownItems.push(<Dropdown.Item
            key={0}
            name={"Request All"}
            active={this.state.selectedID === "Request All"}
            onClick={this.handleSelected}
        >
            Request All
        </Dropdown.Item>);

        for(let i = 0; i < Object.keys(this.state.data).length; i++){
            dropdownItems.push(<Dropdown.Item
                key={i + 1}
                name={this.state.data[i]["ID"]}
                active={this.state.selectedID === this.state.data[i]["ID"]}
                onClick={this.handleSelected}
            >
                {this.state.data[i]["ID"]}
            </Dropdown.Item>);
        }

        return(<Dropdown item text={this.state.selectedID}>
            <Dropdown.Menu>
                {dropdownItems}
            </Dropdown.Menu>
        </Dropdown>);
    }

    renderControls()
    {

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null){
            return(<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager')
        {
            return (<Redirect to="/signin" />);
        }

        return(<div>
            <Navbar selectedPage={"employeeHours"}/>

            <div className={this.props.container + " " + "blueBackground"}>
                <div className={this.props.inlineContainer}>
                    <label>
                        EmployeeID: {" "}
                        {this.buildDropDown()}
                    </label>
                </div>

                <hr/>
            </div>
        </div>);
    }

    renderHours()
    {
        if(this.state.data == null)
        {
            return(<div></div>);
        }

        const hours = [];

        if(this.state.selectedID === "Request All"){ //if we want all the rows to show, use the json we got from the server without filtering
            for(let index = 0; index < Object.keys(this.state.data).length; index++)
            {
                let value = this.state.data[index];
                hours.push(<Table.Row key={index}>
                    <Table.Cell>{value["FullName"]}</Table.Cell>
                    <Table.Cell>{value["ID"]}</Table.Cell>
                    <Table.Cell>{value["hours"]}</Table.Cell>
                </Table.Row>);
            }
        }else{ //just show the filtered row

            if(this.state.selectedData == null)
            {
                return(<div></div>);
            }

            let value = this.state.selectedData;
            hours.push(<Table.Row key={0}>
                <Table.Cell>{value["FullName"]}</Table.Cell>
                <Table.Cell>{value["ID"]}</Table.Cell>
                <Table.Cell>{value["hours"]}</Table.Cell>
            </Table.Row>);

        }

        return(hours);
    }

    render(){

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null){
            return(<Redirect to="/signin" />);
        }

        return(<div>
            {this.renderControls()}
            <div className={this.props.container + " " + "blueBackground"}>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>FullName</Table.HeaderCell>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Hours</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.renderHours()}
                    </Table.Body>
                </Table>
            </div>
        </div>);
    }
}

export default withStyles(EmployeeHours);
