import React, { useState, useEffect } from "react";
import './AnalyticsDashboardMobile.css';
import { Navbar, Nav } from "react-bootstrap";
import { Bar, Doughnut } from 'react-chartjs-2';
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import AccordionMobile from "../AccordionMobile/AccordionMobile";
import getData from '../DashboardMobile/DashboardMobile';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);




const AnalyticsDashboardMobile = () => {
    function handleLogout() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('jobRole');
        this.forceUpdate();
    }
    
    const [orderData, setOrderData] = useState({"productID":"618","Customer":"Aisin","ReceivedDate":"2021-09-21","ProductName":"98180-01 + More","Quantity":"4","ShipmentSchedule":"2021-09-28","Priority":"1","Notes":"","Process":"6","productWeight":"13.62","CurrentStage":"Post-Lapping (AERO)"});



    const [data, setData] = useState({});
    const [labels, setLabels] = useState([]);
    const [orderValues, setOrderValues] = useState([]);

    // useEffect to update the date of the component so that the most up to date information is in the component
    useEffect(() => {

        const turnEventToJSON = (e) => {
            getAllNames(e);
            getNumberOfOrdersByCustomer(e);
            let message = JSON.parse(e.data);
            delete message["status"];
            setData(message); //this will not reflect immediately not until the next render of the screen
        }

        const getAllNames = (e) => {
            let message = JSON.parse(e.data);
            delete message["status"];
            let newArray = [];
            var myMap = new Map();

            // create a map object that will store all of the values with the associated names
            {Array.from(Array(Object.keys(message).length)).map((x, index) => (message[index].Customer in myMap) ? myMap[message[index].Customer] += 1: (!(message[index].Customer in myMap)) ? myMap[message[index].Customer] = 1: console.log() )}

            const uniqueCustomers = Object.keys(myMap);

            setLabels(uniqueCustomers);
        }

        const getNumberOfOrdersByCustomer = (e) => {
            let message = JSON.parse(e.data);
            delete message["status"];
            let newArray = [];
            var myMap = new Map();

            // create a map object that will store all of the values with the associated names
            {Array.from(Array(Object.keys(message).length)).map((x, index) => (message[index].Customer in myMap) ? myMap[message[index].Customer] += 1: (!(message[index].Customer in myMap)) ? myMap[message[index].Customer] = 1: console.log() )}
            
            for(const key in myMap){
                newArray.push(myMap[key]);
            }
            setOrderValues(newArray);
        }


        const evtSource = new EventSource(process.env.REACT_APP_SERVER_ROOT + "analytics/dashboard/sseRead.php");
        evtSource.onmessage = e => turnEventToJSON(e);


    }, []);




    const doughnutState = {
        
        labels: labels,
        datasets: [
            {
                label: 'Orders by Customer',
                backgroundColor: [
                    '#B21F00',
                    '#C9DE00',
                    '#2FDE00',
                    '#00A6B4',
                    '#6800B4',
                ],
                data: orderValues
            }
        ]
    }



    const state = {
        labels: ['Q1', 'Q2', 'Q3', 'Q4'],
        datasets: [
            {
                label: 'Order #',
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 1,
                data: [65, 59, 80, 81, 56]
            }
        ]
    }



    const findLatestCustomerName = (data) => {
        const lastIndexValue = Object.keys(data).length - 1;
        // console.log(Object.keys(data).length); // the length of the object
        // console.log(Array(Object.keys(data).length)); // create an array of 10 empty spaces
        // console.log(Array.from(Array(Object.keys(data).length))); // now all of the space are undefined
        const x = Array.from(Array(Object.keys(data).length));
        return x.map((x, index) => (index === lastIndexValue) ? data[index].Customer : console.log());
    }


    // order
    const findLatestOrder = (data) => {
        const lastIndexValue = Object.keys(data).length - 1;
        // console.log(Object.keys(data).length); // the length of the object
        // console.log(Array(Object.keys(data).length)); // create an array of 10 empty spaces
        // console.log(Array.from(Array(Object.keys(data).length))); // now all of the space are undefined
        const x = Array.from(Array(Object.keys(data).length));
        return x.map((x, index) => (index === lastIndexValue) ? data[index].ProductName : console.log());
    }
    // quantity
    const findLatestQuantity = (data) => {
        const lastIndexValue = Object.keys(data).length - 1;
        // console.log(Object.keys(data).length); // the length of the object
        // console.log(Array(Object.keys(data).length)); // create an array of 10 empty spaces
        // console.log(Array.from(Array(Object.keys(data).length))); // now all of the space are undefined
        const x = Array.from(Array(Object.keys(data).length));
        return x.map((x, index) => (index === lastIndexValue) ? data[index].Quantity : console.log());
    }


    const formatDate = (date) => {
        let formattedDate = new Date(date);
        return formattedDate.toLocaleDateString("en-US");

    };



    if (sessionStorage.getItem('token') == null) {
        return (<Redirect to="/" />);
    }
    else {
        return (
            <div className="container">
                <Navbar bg="dark" expand="lg" variant="dark">
                    <Navbar.Brand href="#home" style={{ marginLeft: 10 }}>SEAVAC</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {/* Link is transpiled to an <a> tag so you can just use the css for an a tag to change the color */}
                            <Link id="link1" to="/dashboard" style={{ textDecoration: 'none' }}>Orders</Link>
                            <Link id="link2" to="/analytics" style={{ textDecoration: 'none' }}>Analytics</Link>

                            <button id="logoutButton" onClick={handleLogout} className="btn btn-danger">Logout </button>
                        </Nav>
                    </Navbar.Collapse>

                </Navbar>
                <h2>Analytics</h2>
                <div className="one">
                    <h3>Latest Order</h3>
                    <div id="latestOrder">
                        <br></br>
                        <h4>Customer Name: {findLatestCustomerName(data)}</h4>
                        <h4>Customer Order: {findLatestOrder(data)} </h4>
                        <h4>Order Quantity: {findLatestQuantity(data)}</h4>
                    </div>
                </div>
                <div className="twoAnalytics">
                    <div id="numberCurrentOrders">
                        <h4>Active Order #</h4>
                        <br></br>
                        <br></br>
                        <br></br>
                        <p id="numCurrent">{Object.keys(data).length}</p>

                    </div>
                </div>
                <div className="threeAnalytics">
                    <h4>Orders by Customer</h4>
                    <Doughnut
                        data={doughnutState}
                        options={{
                            title: {
                                display: true,
                                text: 'Average Rainfall per month',
                                fontSize: 20
                            },
                            legend: {
                                display: true,
                                position: 'right'
                            }
                        }}
                    />
                </div>
                <div className="four">
                    <h4>Sales by Quarter</h4>
                    <Bar className="chart"
                        data={state}
                        options={{
                            maintainAspectRatio: false,
                            title: {
                                display: true,
                                text: 'Average Rainfall per month',
                                fontSize: 25
                            },
                            legend: {
                                display: true,
                                position: 'right'
                            }
                        }}
                    />
                </div>
            </div>

        )
    }
}

export default AnalyticsDashboardMobile;