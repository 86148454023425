import React from 'react';
import { Redirect } from 'react-router-dom';
import CameraPhoto, { FACING_MODES } from 'jslib-html5-camera-photo';
import { Button, Input, Message } from 'semantic-ui-react';
import Navbar from './navbar';

class takePicture extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.cameraPhoto = null;
        this.videoRef = React.createRef();
        this.state = {
            dataUri: '',
            imageLocations: '',
            imagesSplit: '',
            imageProductID: 'default',
            imageOutput: '',
            doesExist: '',
            latest: ' '
        }

        this.abortController = new AbortController();
        this.displayImages = this.displayImages.bind(this);
        this.productIDChange = this.productIDChange.bind(this);
        this.getImages = this.getImages.bind(this);
        this.getLatestProduct = this.getLatestProduct.bind(this);
        this.checkImageName = this.checkImageName.bind(this);
    }

    productIDChange(event) { this.state.imageProductID = event.target.value; this.checkImageName(event.target.value); }

    componentDidMount() {
        // We need to instantiate CameraPhoto inside componentDidMount because we
        // need the refs.video to get the videoElement so the component has to be
        // mounted.
        this.cameraPhoto = new CameraPhoto(this.videoRef.current);
        //this.startCamera(FACING_MODES.ENVIRONMENT, {});
        this.getLatestProduct();
        this.getImages();
        this.displayImages();
    }

    startCamera(idealFacingMode, idealResolution) {
        this.cameraPhoto.startCamera(idealFacingMode, idealResolution)
            .then(() => {
                console.log('camera is started !');
            })
            .catch((error) => {
                console.error('Camera not started!', error);
            });
    }


    takePhoto() {
        const config = {
            sizeFactor: 1
        };

        let dataUri = this.cameraPhoto.getDataUri(config);
        this.setState({ dataUri });
    }

    stopCamera() {
        this.cameraPhoto.stopCamera()
            .then(() => {
                console.log('Camera stopped!');
            })
            .catch((error) => {
                console.log('No camera to stop!:', error);
            });
    }

    FileUpload() {

        if (typeof (this.state.dataUri) != "undefined") {
            let removeDataType = this.state.dataUri.split(',')
            //console.log(removeDataType[1]);
            this.state.dataUri = removeDataType[1];
            //console.log(this.state.dataUri);
            this.uploadFile();
        }
    }


    async uploadFile() {

        let jsonstring = JSON.stringify({
            file: this.state.dataUri,
            fileName: this.state.imageProductID
        })

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "fileUpload.php", {
                method: "POST",
                signal: this.abortController.signal,
                body: jsonstring

            });
            let responseJSON = await response.text();
            console.log(responseJSON);
            console.log("success");

        } catch (error) {
            console.log(error.toString());
            if (error.name === 'AbortError') {
            }
        }
    }

    async getImages() {

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "getImages.php", {
                method: "POST",
                signal: this.abortController.signal

            });
            let responseJSON = await response.text();
            //console.log(responseJSON);
            this.state.imageOutput = responseJSON;
            this.displayImages(responseJSON);

            return responseJSON;

        } catch (error) {
            console.log(error.toString());
            if (error.name === 'AbortError') {
            }
        }

    }

    async checkImageName(productID) {

        let jsonstring = JSON.stringify({
            fileName: productID
        })

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "checkImageName.php", {
                method: "POST",
                signal: this.abortController.signal,
                body: jsonstring

            });
            let responseJSON = await response.text();
            console.log(responseJSON);
            this.state.doesExist = responseJSON;
            this.setState({ doesExist: responseJSON });
            return responseJSON;

        } catch (error) {
            console.log(error.toString());
            if (error.name === 'AbortError') {
            }
        }
    }

    displayImages(responseJSON) {
        if (typeof responseJSON != 'undefined') {
            let images = responseJSON.split("<br />")
            for (let i = 0; i < images.length; i++) {
                if (images[i].length > 0) {
                    images[i] = images[i].slice(34, -4);
                    images[i] = images[i].replace(' ', '%20')
                    //this.setState({ imageLocations: this.state.imageLocations.concat(images[i]) });
                    this.setState({ imageLocations: this.state.imageLocations.concat(images[i]) + "$" });
                    //console.log(this.state.imageLocations);
                }
            }
            let splitImages = this.state.imageLocations.split("$")
            {
                this.setState({ imagesSplit: splitImages })
                //console.log(this.state.imagesSplit[0]);
            }
        }
    }

    async getLatestProduct() {
        //get the json ready to be sent
        let queryBody = JSON.stringify({
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "product/getLatestProduct.php", {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json"
                },
                body: queryBody,
                signal: this.abortController.signal
            });


            let responseJSON = await response.json();
            //console.log(responseJSON);
            this.state.latest = responseJSON;

        } catch (error) {
            if (error.name === 'AbortError') {
            }
        }
    }


    render() {

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager') {
            return (<Redirect to="/signin" />);
        }

        this.getLatestProduct();
        return (
            <div>
                {<Navbar selectedPage={"takePicture"} />}
                <div>
                    <label className="uploadFileDesc" style={{ marginTop: "1em" }}>Most recent ProductID: {this.state.latest}</label>
                </div>
                <Input size='big' label={'ProductID:'} classname="input" style={{ width: '60%', padding: "0.2em" }} type="number" onChange={this.productIDChange} />
                <div>
                    {this.state.doesExist != '' &&
                        <p>{<Message size='big' negative>{this.state.doesExist}</Message>}</p>}
                </div>
                <div>
                    <Button className="smallBtn4" onClick={() => {
                        let facingMode = FACING_MODES.ENVIRONMENT;
                        let idealResolution = { width: 1280, height: 720 };
                        this.startCamera(facingMode, idealResolution);
                    }}> Turn on Camera </Button>
                    {/*<button onClick={() => {
                    let facingMode = FACING_MODES.USER;
                    this.startCamera(facingMode, {});
                }}> Camera ON </button>*/}
                    <br></br>
                    <Button className="smallBtn4" onClick={() => {
                        this.takePhoto();
                        this.stopCamera();
                    }}> Take photo </Button>
                    <br></br>
                    <Button className="smallBtn4" onClick={() => {
                        this.FileUpload();
                    }}> Save photo </Button>
                </div>
                {/*<Button className="smallBtn3" onClick={() => {
                    this.getImages();
                }}> View Images </Button>*/}
                {/*<button onClick={() => {
                    this.stopCamera();
                }}> Camera OFF </button>*/}
                <video
                    ref={this.videoRef}
                    autoPlay="true" />
                <img
                    alt="imgCamera"
                    src={this.state.dataUri}
                    img style={{ width: window.innerWidth }}
                />
                <br></br>
                {/*<h3>Most recent images:</h3>*/}
                {/*<div>
                    <img
                        src={process.env.REACT_APP_SERVER_ROOT + this.state.imagesSplit[this.state.imagesSplit.length - 2]} />
                    {/*console.log(process.env.REACT_APP_SERVER_ROOT + 'uploads/' + this.state.imageProductID + '.png')}
                    <img
                        src={process.env.REACT_APP_SERVER_ROOT + this.state.imagesSplit[this.state.imagesSplit.length - 3]} />
                    <img
                        src={process.env.REACT_APP_SERVER_ROOT + this.state.imagesSplit[this.state.imagesSplit.length - 4]} />
                </div>*/}

            </div>
        );
    }
}

export default takePicture;