import React, { createRef } from 'react';
import {Redirect} from 'react-router-dom'
import Navbar from './navbar';
import { Form } from 'semantic-ui-react';

import hocify from 'hocify';
import employeeClockStyle from '../jss/employeeClock';
import xclock from '../resources/xclock.png';

const withStyles = hocify(employeeClockStyle);

class EmployeeClock extends React.Component{

    inputRef = createRef()

    constructor(properties){
        super(properties);

        //set form values
        this.state = {QRCode: ''};

        //setup event handlers
        this.handleChange = this.handleChange.bind(this);
        this.clockToggle = this.clockToggle.bind(this);

        this.abortController = new AbortController();
    }

    handleChange(event){
        this.setState({QRCode: event.target.value});
    }

    componentDidMount(){
        this.scanInput.focus();
    }

    //add the item to the db
    async clockToggle(){

        let itemJSON = JSON.stringify({
            QRCode: this.state.QRCode,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole")
        });

        this.setState({QRCode: ""});

        try{
        //send the item db
          let response = await fetch(process.env.REACT_APP_SERVER_ROOT + "employeeTimeCard/toggle.php", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: itemJSON,
              signal: this.abortController.signal
              });

          //process the response
          let responseJSON = await response.json();
          if(responseJSON.hasOwnProperty('status')  && responseJSON['status'] === 400){
              alert(responseJSON['error']);
          }

      } catch (error) {
          if(error.name === 'AbortError'){
          }
      }
    }

    componentWillUnmount(){
        this.abortController.abort();
    }

    render(){

        //if the token is not present, the user is not signed in send them to signin
        if(sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null){
            return(<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager' && job !== 'Employee')
        {
            return(<Redirect to="/signin" />);
        }

        return(<div>
            <Navbar selectedPage={"employeeClock"}/>

            <div className={this.props.container + " " + "blueBackground"}>

                <Form>
                    <img src={xclock} alt="Clock" />
                    <label for="textInput">Scan or Enter Employee ID</label>
                    <input ref={(input) => {this.scanInput = input;}} id="textInput" type="text" style={{width: "200px"}} name="EmployeeID" value={this.state.QRCode} onChange={this.handleChange} />
                    <input type="submit" name="Submit" onClick={this.clockToggle}/>
                </Form>
            </div>
        </div>);
    }

}

export default withStyles(EmployeeClock);
