import { createUseStyles } from 'react-jss';

const dashboardStyle = createUseStyles({
  control: {
    paddingRight: '1%',
    verticalAlign: 'middle'
  },

  progressBarTitle: {
    fontWeight: 'bold',
    fontFamily: "\"Oxygen Mono\" ,\"Courier New\", monospace"
  },

  pBTitleBig: {
    fontWeight: 'bold',
    fontFamily: "\"Oxygen Mono\" ,\"Courier New\", monospace"
  },

  slider: {
    verticalAlign: 'middle',
    width: '20%'
  },
  productProgressBar: {
    width: '236%',
    height: '100%',
    paddingTop: '2px'
  },
  container: {
    height: 'auto',
    margin: '0px',
    paddingTop: '1px',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: 'white',
    boxSizing: 'borderBox',
    overflowX: 'hidden'
  },
  statusContainer: {
    width: '60%'
  },
  productContainer: {
    fontSize: '2em',
    marginTop: '6px',
    marginBottom: '16px'
  },
  reworkProgressBar: {
    width: '200%',
    height: '100%',
    marginLeft: '6%'
  },
  title: {
    display: 'inline-block',
    marginRight: '1.2%',
    marginBottom: '.6%',
    marginTop: '.7%'
  },

  count: {
    display: 'inline-block',
    width: '',
    marginRight: '1%'
  },
  barCountContainer: {
    width: '100%',
  },
  printHide: {},
  "@media print": { //hide/show things when printing
    printHide: {
      display: 'none'
    },
    container: {
      marginTop: '0 !important',
    },

    progressBarTitle:{
      fontSize: '50%',
    },

    pBTitleBig:{
      fontSize: '35%',
    },

    ProductID:{
      marginTop: '3px',
      marginBottom: '3px'
    },
    qrcodeDiv: {
      pageBreakInside: 'avoid'
    },
    productContainer: {
      pageBreakInside: 'avoid'
    }
  },
  stuck: {
    overflow: 'visible',
    position: 'fixed',
    backgroundColor: 'white',
    top: '0',
    width: '100%',
    zIndex: '99'
  },

});

export default dashboardStyle;
