import React from 'react';
import PropTypes from 'prop-types';

import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import machineUptimeTooltipStyle from '../../jss/reports/machineUptimeTooltipStyle';

//css but in json form written in js
const withStyles = hocify(machineUptimeTooltipStyle);

class MachineUptimeTooltip extends React.Component {

    render(){

        const { active } = this.props;

        if (active) {
            const { payload, label } = this.props;
            return (<div className={this.props.tooltip}>
                <p className={this.props.label}>{`${this.props.stageNames[this.getIndexOfLabel(label)]}`}</p>
                <p style={{color: this.props.color}} className={this.props.colorLabel}> {`Orders: ${payload[0].value}`}</p>
            </div>);
        }

        return null;
    }

    //take the label given to the tooltip and search in the display names to find the index
    getIndexOfLabel(label){

        for(let i = 0; i < Object.keys(this.props.displayNames).length; i++){

            if(this.props.displayNames[i] === label){
                return i;
            }

        }

        return -1;

    }

}

MachineUptimeTooltip.propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.string,
};

export default withStyles(MachineUptimeTooltip);