import React from "react";
import "../Box/Box.css";
const Box = (props) => {


    const stages = JSON.parse(props.stages);
    const stagesIDs = JSON.parse(props.stageIDs);
    const current = props.currentProcess;
    const index = props.index + 1;
    let currentIndex = 0

    let classString = "box"
    let tooltipString = "toolTipText"
    // console.log("Stage: "+props.currentProcess);
    // console.log(stages)
    // console.log(stagesIDs)
    // {"1":"Pre-Inspection","2":"Pre-Washing (Jig-Washer)","3":"Stripping (Liquid)","4":"WPC (SKH+Ceramic)","5":"WPC-Ceramic","6":"Pre-Lapping (SMAP)","7":"Pre-Lapping (AERO)","8":"Pre-Washing (F1 Clean Washer)","9":"Coating (M3) - Set/Disassemble","10":"Post-Inspection","11":"Post-Lapping (AERO)","12":"Final Inspection","13":"Packing/Shipping"}

    // getting current stage and coverting it into an index number

    for(let i in stages){
        
        if(current== stages[i]){
            currentIndex = i
        }
    }

    // building class string
    switch(parseInt(stagesIDs[index])){
        case 2:
            classString+=" second"
            tooltipString+= " second"
            break;
        case 3:
            classString+=" third"
            tooltipString+= " third"
            break
        case 4:
            classString+=" fourth"
            tooltipString+= " fourth"
            break;
        case 5:
        case 6:
            classString+=" sixth"
            tooltipString+= " sixth"
            break;
        case 7:
            classString+=" seventh"
            tooltipString+= " seventh"
            break;
        case 8:
            classString+=" eighth"
            tooltipString+= " eighth"
            break;

        case 9:
            classString+=" ninth"
            tooltipString+= " ninth"
            break;
        case 10:
            classString+=" tenth"
            tooltipString+= " tenth"
            break;
        case 11:
            classString+=" eleventh"
            tooltipString+= " eleventh"
            break;
        case 12:
            classString+=" twelfth"
            tooltipString+= " twelfth"
            break;
        case 13:
            classString+=" thirteenth"
            tooltipString+= " thirteenth"
            break;
        case 15:
            classString+=" fifteenth"
            tooltipString+= " fifteenth"
            break;
        case 16:
            classString+=" sixteenth"
            tooltipString+= " sixteenth"
            break;
        case 17:
            classString+=" seventeenth"
            tooltipString+= " seventeenth"
            break;
        case 19:
            classString+=" nineteenth"
            tooltipString+= " nineteenth"
            break;
        default:
            break;
    }
    let stageMarker = ''
    if(index == currentIndex){
        stageMarker = '🔄'
    }
    if(index >= currentIndex){
        classString += "-unfilled"
    }
    
    // These process ids will not be rendered
    if(stagesIDs[index] == 1 || stagesIDs[index] == 14 || stagesIDs[index] == 18) {
        return (
            <></>
        )
    }
    else if(stagesIDs[index] == 5 || stagesIDs[index] == 6) {
        const terms = ["5", "6"];
        const str = props.stages;
        const result = terms.every(term => str.includes(term));
        if (stagesIDs[index] == 5 && result == true) {
            return (
                <></>
            )
        } 
    }
    return (
        <div className={classString}>
            {stageMarker}
            <span className={tooltipString}>{stages[index]}</span>
        </div>
    )
}
export default Box;