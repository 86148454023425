import React from 'react';
import Navbar from './navbar';
import { Redirect } from 'react-router-dom'


import hocify from 'hocify'; //needed to convert the functional hook of jss into a HOC
import mapItemsStyle from '../jss/mapItems';
import mapImage from '../resources/map2.png';

const withStyles = hocify(mapItemsStyle);


class MapItems extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            prewashCount: 0,
            shotstripCount: 0,
            liquidstripCount: 0,
            wpcskhCount: 0,
            wpcceramicCount: 0,
            prelapSmapCount: 0,
            prelapAeroCount: 0,
            prelapHandCount: 0,
            prewashF1Count: 0,
            m1Count: 0,
            m2Count: 0,
            m3Count: 0,
            postlapSmapCount: 0,
            postlapAeroCount: 0,
            postlapHandCount: 0,
            packshipCount: 0,
            inspectionstageCount: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.getItems = this.getItems.bind(this);

        this.abortController = new AbortController();
    }

    handleChange(event) {

    }

    async getItems(stageNumber) {
        let productJSON = JSON.stringify({
            stage: stageNumber,
            token: sessionStorage.getItem("token"),
            jobRole: sessionStorage.getItem("jobRole"),
        });
        try {
            const requestPath =
                process.env.REACT_APP_SERVER_ROOT +
                "analytics/productStatistics/getStageNumberCounts.php";
            let response = await fetch(requestPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: productJSON,
                signal: this.abortController.signal,
            });

            //process the response
            let responseJSON = await response.json();
            if (responseJSON["status"] === 200) {
                //remove the status element from the returned json, to allow eaiser process of the data
                delete responseJSON["status"];
            } else { }
            if (stageNumber === 2) {
                this.setState({ prewashCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 3) {
                this.setState({ shotstripCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 4) {
                this.setState({ liquidstripCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 5) {
                this.setState({ wpcskhCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 6) {
                this.setState({ wpcceramicCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 7) {
                this.setState({ prelapSmapCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 8) {
                this.setState({ prelapAeroCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 9) {
                this.setState({ prelapHandCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 10) {
                this.setState({ prewashF1Count: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 11) {
                this.setState({ m1Count: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 12) {
                this.setState({ m2Count: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 13) {
                this.setState({ m3Count: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 15) {
                this.setState({ postlapSmapCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 16) {
                this.setState({ postlapAeroCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 17) {
                this.setState({ postlapHandCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 19) {
                this.setState({ packshipCount: parseInt(responseJSON[0].counter) });
            }
            if (stageNumber === 1 || stageNumber === 14 || stageNumber === 18) {
                this.setState({ inspectionstageCount: this.state.inspectionstageCount + parseInt(responseJSON[0].counter) })
            }
            return responseJSON;
        } catch (error) {
            if (error.name === "AbortError") {
            }
        }
    }


    componentDidMount() {

        //Get items for every stage
        for (let i = 1; i <= 19; i++) {
            this.getItems(i);
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    render() {

        //if the token is not present, the user is not signed in send them to signin
        if (sessionStorage.getItem("token") === null || sessionStorage.getItem("jobRole") === null) {
            return (<Redirect to="/signin" />);
        }
        var job = sessionStorage.getItem("jobRole");
        if (job !== 'Manager') {
            return (<Redirect to="/signin" />);
        }

        //Store viewWidth and viewHeight for each item
        var vwStorage = ['28vw', '28vw', '28vw', '28vw', '35vw', '61vw', '28vw', '43vw', '41vw', '40vw', '35vw', '61vw', '50vw', '75vw']
        var vhStorage = ['62vh', '84vh', '11vh', '89vh', '84vh', '11vh', '47vh', '40vh', '58vh', '70vh', '89vh', '17vh', '80vh', '84vh']

        //Adjust height for 1920 x 1080 monitors, (width is fine)
        if (window.innerWidth === 1920) {
            vhStorage = ['60vh', '77vh', '11vh', '81vh', '85vh', '10vh', '44vh', '36vh', '55vh', '67vh', '85vh', '16vh', '74vh', '84vh']
        }

        return (<div class={this.props.page}>
            {<Navbar selectedPage="mapItems" />}
            <div style={{ width: '100%' }}>
                <img src={mapImage} alt="map of facility" width="46%" style={{ verticalAlign: "middle", maxWidth: '50%', margin: "auto", display: "block", marginTop: "1em" }}></img>
                <div class={this.props.items2} style={{ position: "absolute", left: vwStorage[0], top: vhStorage[0] }}>Prewash jig: {this.state.prewashCount}</div>
                <div class={this.props.items3} style={{ position: "absolute", left: vwStorage[1], top: vhStorage[1] }}>Shot strip: {this.state.shotstripCount}</div>
                <div class={this.props.items4} style={{ position: "absolute", left: vwStorage[2], top: vhStorage[2] }}>Liquid strip: {this.state.liquidstripCount}</div>
                <div class={this.props.items6} style={{ position: "absolute", left: vwStorage[3], top: vhStorage[3] }}>WPC: {this.state.wpcskhCount + this.state.wpcceramicCount}</div>
                <div class={this.props.items7} style={{ position: "absolute", left: vwStorage[4], top: vhStorage[4] }}>Prelap SMAP: {this.state.prelapSmapCount}</div>
                <div class={this.props.items9} style={{ position: "absolute", left: vwStorage[5], top: vhStorage[5] }}>Prelap AERO/Hand: {this.state.prelapAeroCount + this.state.prelapHandCount}</div>
                <div class={this.props.items10} style={{ position: "absolute", left: vwStorage[6], top: vhStorage[6] }}>Prewash F1: {this.state.prewashF1Count}</div>
                <div class={this.props.items11} style={{ position: "absolute", left: vwStorage[7], top: vhStorage[7] }}>M1: {this.state.m1Count}</div>
                <div class={this.props.items12} style={{ position: "absolute", left: vwStorage[8], top: vhStorage[8] }}>M2: {this.state.m2Count}</div>
                <div class={this.props.items13} style={{ position: "absolute", left: vwStorage[9], top: vhStorage[9] }}>M3: {this.state.m3Count}</div>
                <div class={this.props.items15} style={{ position: "absolute", left: vwStorage[10], top: vhStorage[10] }}>Postlap SMAP: {this.state.postlapSmapCount}</div>
                <div class={this.props.items17} style={{ position: "absolute", left: vwStorage[11], top: vhStorage[11] }}>Postlap AERO/Hand: {this.state.postlapAeroCount + this.state.postlapHandCount}</div>
                <div class={this.props.items19} style={{ position: "absolute", left: vwStorage[12], top: vhStorage[12] }}>Packing: {this.state.packshipCount}</div>
                <div class={this.props.items} style={{ position: "absolute", left: vwStorage[13], top: vhStorage[13] }}>Orders in an inspection stage: {this.state.inspectionstageCount}</div>
            </div>
        </div>);

    }

}

export default withStyles(MapItems);
